import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MessagesService} from './services/messages.service';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromMessages from './store/messages.reducer';
import {MessagesEffects} from './store/messages.effects';
import {MessagesResolver} from './services/messages.resolver';

@NgModule( {
	imports: [
		CommonModule,
		StoreModule.forFeature('messages', fromMessages.reducer),
		EffectsModule.forFeature([MessagesEffects])
	],

	providers: [
		MessagesService,
		MessagesResolver
	]
})
export class MessagesModule {}
