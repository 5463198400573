import {createSelector} from '@ngrx/store';
import {selectMessagesEntities} from './messages.reducer';
import {MessageModel} from '../models';
import {selectIdFromUrl} from '@suite/tm-common';


/**
 * selectMessagesByTreatmentId - needed for resolver and in any case where
 *                               there is no valid id in router.state.params
 *
 * @params treatmentId  - treatment id should be given from select like below:
 *                        ...pipe( select(selectMessagesByTreatmentId, TREATMENT_ID), ...
 */
export const selectMessagesByTreatmentId = (treatmentId: string) => createSelector(
	selectMessagesEntities,
	(entities) => {   // treatmentId comes in as argument from select
		const messages: MessageModel[] = Object.values(entities);
		return messages.filter( msg => msg.context.id === treatmentId);
	}
);

/**
 * selectMessagesOfCurrentTreatment
 *
 *    uses router id (via selectCurrentId) to get the treatmentId
 *    for further filtering
 */
export const selectMessagesOfCurrentTreatment = createSelector(
	selectIdFromUrl,
	selectMessagesEntities,
	(treatmentId, entities) => {
		const messages: MessageModel[] = Object.values(entities);
		return messages.filter( msg => msg.context && msg.context.id === treatmentId);
	}
);
