import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { Webviewer } from "libs/treatments/src/lib/models/treatment.model";
import { FormsModule } from "@angular/forms";
import { TreatmentBaseService } from "libs/treatments/src/lib/services/treatment.base.service";
import { MediaBaseService } from "@suite/media";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { ClipboardModule } from 'ngx-clipboard';

const CA_WEBVIEWER_APP_URL = 'https://' + window.location.host + '/caWebViewer/index.html';

@Component({
	selector: "ux-webviewer-preview",
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		ClipboardModule,
		MatCardModule,
		MatFormFieldModule,
		MatSelectModule,
		FormsModule,
		TranslateModule
	],
	templateUrl: "./webviewer-preview.component.html",
	styleUrls: ["./webviewer-preview.component.scss"]
})
export class WebviewerPreviewComponent implements OnInit{
	@Input() webviewers: Webviewer[] = []
	@ViewChild('webviewerIframe') webviewerIframe: ElementRef;

	selectedWebviewer: Webviewer;
	sanitizedAppUrl;

	constructor(
		private treatmentBaseService: TreatmentBaseService,
		private mediaBaseService: MediaBaseService,
		@Inject('CONFIG') private config
		){
	}

	ngOnInit() {
		// sanitize app url to bypass security restrictions
		this.sanitizedAppUrl = this.treatmentBaseService.getCaWebViewerSource(CA_WEBVIEWER_APP_URL);
		this.selectedWebviewer = this.webviewers.at(-1);
		const mediaId = this.getMediaIdFromUrl(this.webviewers.at(-1).url);
		this.getWebviewerFile(mediaId);
	}

	onSelectionChange(value: Webviewer){
		//reset webviewer link to refresh iframe to prevent multiple 3d models in one viewer
		this.sanitizedAppUrl = "";
		this.sanitizedAppUrl = this.treatmentBaseService.getCaWebViewerSource(CA_WEBVIEWER_APP_URL);

		const mediaId = this.getMediaIdFromUrl(value.url);
		this.getWebviewerFile(mediaId);
	}

	getMediaIdFromUrl(url: string): string{
		const urlParts = url.split('/');
		return urlParts[urlParts.length - 1]; //media id of .json file
	}

	getWebviewerFile(mediaId: string) {
		this.mediaBaseService.getFile(mediaId)
			.subscribe(webViewerInput => {
				// post data to iframe
				if (this.webviewerIframe?.nativeElement && webViewerInput) {
					setTimeout(() => {
						const contentWindow = this.webviewerIframe.nativeElement.contentWindow;
						contentWindow.postMessage({language: this.config.locale}, CA_WEBVIEWER_APP_URL);
						contentWindow.postMessage(webViewerInput, CA_WEBVIEWER_APP_URL);
					}, 1000);
				}
			});
	}

	//helper function to determine a default value for the mat-select component, since
	//angular < 16 is not capable of doing it out of the box for non primitve values (resp. objects)
	compareWebviewers(viewer1: any, viewer2: any): boolean {
		return viewer1.name === viewer2.name && viewer1.url === viewer2.url;
	}
}
