import { Injectable } from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {CustomerActionTypes, AddressesRequested, AddressesLoaded} from './customer.actions';
import { SapService } from 'libs/sap/src/lib/services/sap.service';



@Injectable()
export class CustomerEffects {


	addresses$ = createEffect(() => this.actions$.pipe(
		ofType<AddressesRequested>(CustomerActionTypes.AddressesRequested),
		mergeMap(action => this.sapService.getCustomerAddresses()),
		map(addresses => new AddressesLoaded(addresses)),
		catchError(err => {
			console.error('error loading customer addresses');
			return throwError( () => new Error(err));
		})
	));

	constructor(private actions$: Actions, private sapService: SapService) {}
}
