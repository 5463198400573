import * as Sentry from "@sentry/angular-ivy";

export function sentryOptions( appConfig ) : Sentry.BrowserOptions {
	return {

		environment: window.location.hostname.split('.', 1)[0], //'local', 'dev', 'stage', 'order'
		dsn: appConfig.sentryDSN,
		integrations: [
			// Registers and configures the Tracing integration,
			// which automatically instruments your application to monitor its
			// performance, including custom Angular routing instrumentation
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
			// Registers the Replay integration,
			// which automatically captures Session Replays
			new Sentry.Replay(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: appConfig.production ? 0.2 : 1,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", appConfig.apiUrl, appConfig.catUrl, appConfig.ioApiUrl],

		// Capture Replay for 10% of all sessions (disabled for now),
		// plus for 100% of sessions with an error
		//replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1
	}
}
