<section class="container" *ngIf="data">

	<section class="top-header">
		<h1>{{'UPS.ModelPapers' | translate}}</h1>
		<img src="assets/img/logo_scheu.svg" alt="SCHEU-DENTAL" class="logo">
	</section>

	<section class="main">
 		<h3>{{'Product' | translate}}:</h3>
		<p [innerHTML]="data.treatment.name"></p>
		<p><strong>{{'Customer' | translate}}:</strong> {{data.user.displayName}} - <strong>[{{data.user.wmsId}}]</strong></p>
		<p><strong>{{'Patient' | translate}}:</strong> {{data.treatment.patient.displayName}} - <strong>[{{data.treatment.patient.patientId}}]</strong></p>
		<p><strong>{{'Order' | translate}}#:</strong> {{data.treatment.quotation.docNum}}</p>
		<p><strong>{{'PickupReference' | translate}}:</strong> {{data.treatment.quotation.upsPickUpReference.prn}}</p>
		<br>
		<p>{{'UPS.Hint' | translate}}</p>
		<hr>
	</section>

	<section class="action-buttons">
		<button mat-raised-button color="primary" (click)="print()">{{'Print' | translate }}</button>
		<button mat-raised-button color="primary" mat-dialog-close>Close</button>
	</section>

</section>
