import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'ux-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./_confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit() {
	}

}
