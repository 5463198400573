import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CutFileNamePipe, EnumToArrayPipe, SafeUrlPipe} from './pipes';
import {ConsoleLoggerService, LoggerService, NotificationService} from './services';
import {TreatmentStatusToStringPipe} from './pipes/treatmentStatusToString.pipe';
@NgModule({
	declarations: [
		CutFileNamePipe,
		EnumToArrayPipe,
		TreatmentStatusToStringPipe,
		SafeUrlPipe
	],
	imports: [
		CommonModule
	],
	exports: [
		CutFileNamePipe,
		EnumToArrayPipe,
		TreatmentStatusToStringPipe,
		SafeUrlPipe,
	],
	providers: [
		NotificationService,
		TreatmentStatusToStringPipe,
		SafeUrlPipe,
		{provide: LoggerService, useClass: ConsoleLoggerService}
	]


})
export class TmCommonModule {
}
