import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import { MessageTypes, MessageTypeIcons } from "@suite/messages";

@Component({
	selector: "ux-comment",
	templateUrl: "./comment.component.html",
	styleUrls: ["./_comment.component.scss"]
})
export class CommentComponent implements OnInit {
	@Input() options;
	@Input() type = 0;
	@Input() htmlContent = "";
	@Output() valueChanges = new EventEmitter<string>();

	maxLength = 1024;
	commentControl = new UntypedFormControl();
	MessageTypes = MessageTypes;
	MessageTypeIcons = MessageTypeIcons;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "15rem",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "yes"
	};

	constructor(@Inject('CONFIG') private config) {}

	ngOnInit() {
		this.commentControl.valueChanges.subscribe((value) => {
			this.valueChanges.emit(value);
		});

		if( this.config.app === "tm2")
		{
			this.editorConfig.toolbarHiddenButtons = [
				[
					'subscript',
					'superscript',
					'justifyLeft',
					'justifyCenter',
					'justifyRight',
					'justifyFull',
					'indent',
					'outdent',
					'heading',
					'fontName'
				],
				[
					'fontSize',
					'textColor',
					'backgroundColor',
					'customClasses',
					'insertImage',
					'insertVideo',
					'insertHorizontalRule',
					'removeFormat',
					'toggleEditorMode'
				]
			];
		}
	}

	htmlChange(val) {
		this.valueChanges.emit(val);
	}

	clear() {
		this.commentControl.setValue("");
	}
}
