import { Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { EMPTY, Observable, Subscription } from "rxjs";
import { concatMap, first, map } from "rxjs/operators";

import { TreatmentStatusToStringPipe } from "@suite/tm-common";
import { TreatmentModel, TreatmentStatus } from "libs/treatments/src/lib/models";
import { MessagesService } from "libs/messages/src/lib/services/messages.service";
import {
	MessageModel,
	MessageTypes,
	MessageTypeIcons,
	SendersTypes,
	MessageScopes
} from "libs/messages/src/lib/models/message.model";
import {
	selectTreatmentFromUrl,
	TreatmentActionTypes,
	UpsertTreatment,
	UpsertTreatmentOnDb
} from "@suite/treatments";
import {
	AddMessageOnDb,
	MessagesState,
	selectMessagesOfCurrentTreatment,
	sortByCreated
} from "@suite/messages";
import { Admin, selectAdmins } from "@suite/admins";
import { TranslateService } from "@ngx-translate/core";
import { ProductInfo, ProductInfoForKey, ProductUrlKey } from "libs/product/src/lib/models/product.model";

const INTERN_ONLY_MESSAGE_TYPES: MessageTypes[] = [
	MessageTypes.StatusChange,
	MessageTypes.CaseWorker,
	MessageTypes.CompletionDate,
	MessageTypes.MessagesReset
];

@Component({
	selector: 'ux-treatment-messages',
	templateUrl: './treatment-messages.component.html',
	styleUrls: ['./treatment-messages.component.scss']
})
export class TreatmentMessagesComponent implements OnInit, OnDestroy {
	@ViewChild('commentComponent') messageInput;

	@Input() isInputEnabled: boolean = true;

	// prepare messages - used in template
	messages$: Observable<MessageModel[]>;

	// output
	message = '';
	messageType: MessageTypes = MessageTypes.Note;

	messageInputOptions = {
		required: false
	};

	treatment: TreatmentModel;
	treatmentId: string;

	// for admin message (type: StatusChange)
	newTreatmentStatus: TreatmentStatus;

	MessageTypes = MessageTypes;
	MessageTypeIcons = MessageTypeIcons;
	SendersTypes = SendersTypes;
	TreatmentStatus = TreatmentStatus;
	MessageScopes = MessageScopes;

	uid: string;
	admins$: Observable<Admin[]>;

	// flags for admin ux - should always be false if we not in tmadmin
	internOnly = this.config.app === 'tmadmin';
	isInternOnlyCheckboxDisabled = false;

	sendMailToCustomer = this.config.app === 'tmadmin';
	currentTreatmentSubscription: Subscription;


	constructor(
		private messagesService: MessagesService,
		private translate: TranslateService,
		private store: Store<MessagesState>,
		private actions$: Actions,
		private treatmentStatusPipe: TreatmentStatusToStringPipe,
		@Inject('CONFIG') public config,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: { isInputEnabled: boolean }
	) {
		if (data) {
			this.isInputEnabled = data.isInputEnabled;
		}
	}

	ngOnInit(): void {
		this.messages$ = this.store.pipe(
			select(selectMessagesOfCurrentTreatment),
			// filter out what is not for customer if not admin
			map((data) =>
				data.filter((msg) => {
					if (
						msg.type === MessageTypes.CaseWorker ||
						msg.type === MessageTypes.CompletionDate
					) {
						return this.config.app === "tmadmin";
					}
					return true;
				})
			),
			map((data) =>
				data.map((msg) => ({ ...msg, message: this.getMessageHtml(msg) }))
			),
			map((data) => data.sort(sortByCreated))
		);

		// get current treatment
		this.currentTreatmentSubscription = this.store.pipe(select(selectTreatmentFromUrl))
			.subscribe( treatment => {
				this.treatment = treatment;
			});

		// prepare get admins for later use
		this.admins$ = this.store.select(selectAdmins);
	}

	ngOnDestroy() {
		this.currentTreatmentSubscription.unsubscribe();
	}

	onMessageChange(value) {
		this.message = value;
	}


	onStatusChange(event: {status: TreatmentStatus}) {
		this.newTreatmentStatus = event.status;
	}

	onNewMessageType(type) {
		this.messageType = type;

		if (INTERN_ONLY_MESSAGE_TYPES.includes(this.messageType)) {
			this.internOnly = true;
			this.isInternOnlyCheckboxDisabled = true;
		} else {
			this.isInternOnlyCheckboxDisabled = false;
		}
	}
	onInternOnlyChange() {
		this.internOnly = !this.internOnly;
	}

	onSendMailToCustomerChange() {
		this.sendMailToCustomer = !this.sendMailToCustomer;
	}

	onClick() {

		// determine status change and get diff if any otherwise empty object
		let diff: Partial<TreatmentModel> = {};
		let statusChangeMessage: string;
		let treatmentStatusChange: { oldStatus: TreatmentStatus, newStatus: TreatmentStatus } = null;

		// order status
		if (this.newTreatmentStatus && this.newTreatmentStatus !== this.treatment.status) {
			treatmentStatusChange = {oldStatus: this.treatment.status as TreatmentStatus, newStatus: this.newTreatmentStatus};
			statusChangeMessage = `Treatment-Status von
				[${this.treatmentStatusPipe.transform(treatmentStatusChange.oldStatus)}] auf
				[${this.treatmentStatusPipe.transform(treatmentStatusChange.newStatus)}] geändert`;
			diff.status = this.newTreatmentStatus;
		}

		let addStatusChangeMessageOnDbAction;
		if (statusChangeMessage) {
			// build message
			const message = this.getMessageModel(statusChangeMessage, this.MessageTypes.StatusChange);
			message.sendersType = SendersTypes.System;
			if (treatmentStatusChange) {
				message.context.status = treatmentStatusChange.newStatus;
			}

			// Create message of status change(s).
			addStatusChangeMessageOnDbAction = new AddMessageOnDb({ message });
		}

		// update db if any to update
		let upsertTreatmentOnDbAction;
		if (Object.keys(diff).length) {
			upsertTreatmentOnDbAction = new UpsertTreatmentOnDb({
				treatmentId: this.treatment._id,
				diff
			});
		}

		let addMessageOnDbAction;
		if (this.message) {
			const message = this.getMessageModel(this.message, this.messageType, treatmentStatusChange?.newStatus);

			addMessageOnDbAction = new AddMessageOnDb({message});

			this.message = '';
		}

		// XXX Depending on the context, we chain different Actions to update the treatment and
		// to create the message entries. This avoids concurrency problems (in Magento...).
		if (upsertTreatmentOnDbAction) {
			// Treatment update, dispatch message creations after completion.
			this.store.dispatch(upsertTreatmentOnDbAction);
			this.actions$.pipe(
				ofType(TreatmentActionTypes.UpsertTreatment),
				first(),
				concatMap(() => {
					if (addStatusChangeMessageOnDbAction) this.store.dispatch(addStatusChangeMessageOnDbAction);
					if (addMessageOnDbAction) this.store.dispatch(addMessageOnDbAction);
					return EMPTY;
				}),
			).subscribe();
		} else if (addMessageOnDbAction) {
			// No treatment update, just dispatch the message creation.
			this.store.dispatch(addMessageOnDbAction);
		}

		this.messageInput.clear();
	}

	private getMessageModel(message: string, type: MessageTypes, newStatus?: TreatmentStatus): MessageModel {
		let messageScope: MessageScopes;
		if (
			this.internOnly ||
			INTERN_ONLY_MESSAGE_TYPES.includes(this.messageType)
		) {
			messageScope = MessageScopes.Intern;
		} else {
			messageScope = this.sendMailToCustomer
				? MessageScopes.PublicWithEmailNotification
				: MessageScopes.Public;
		}

		const msg: MessageModel = {
			message: message,
			sendersType: this.config.app === 'tmadmin' ? SendersTypes.Technican : SendersTypes.Customer,
			scope: messageScope,
			type: type,
			context: {
				id: this.treatment._id,
				status: newStatus ? newStatus : this.treatment.status,
				ownerId: this.treatment.uid.toString()
			}
		};

		return msg;
	}

	resetMessagesCount() {
		this.messagesService.resetMessageCount(this.treatment._id)
			.subscribe( () =>
				this.store.dispatch(new UpsertTreatment({treatment: {...this.treatment, newMessagesCount: 0}})));
	}

	getProductName(urlKey: string ) : string
	{
		const productInfo: ProductInfo = ProductInfoForKey[urlKey as ProductUrlKey];
		let name = productInfo?.name ? this.translate.instant(productInfo?.name) : '';
		if(productInfo?.variantName){
			name += " ";
			name += this.translate.instant(productInfo.variantName);
		}
		//if product info could not be found, its probably a legacy treatment
		return name ? name : this.treatment.name;
	}

	getMessageHtml(messageModel: MessageModel) {
		if (messageModel.message.includes("/ca-viewer/#/view")) {
			// The message appears to contain a web-viewer url (possibly plain text),
			// so to make sure they are clickable, we wrap them in <a> HTML-Tags if needed.

			// Regex: Matches web-viewer URLs that are NOT already part of an <a> tag or attribute value (e.g. href="...")
			const urlRegex: RegExp =
				/(?<!<\/a>)(https?:\/\/[^\s<>"']+[a-z0-9]{24})(?![^<>]*<\/a>)(?![^<>]*href=)(?![^<>]*['"])/g;
			return messageModel.message.replace(
				urlRegex,
				`<a href="$1" target="_blank">$1</a>`
			);
		} else {
			return messageModel.message;
		}
	}
}
