<div class="grid-container">
	<div class="grid">
		<ux-ups-pickup
			(action)="onAction($event)"
			[shippingAddresses]="shippingAddresses$ | async"
			[user]="user$ | async"
			[treatment]="treatment$ | async"
			[fields]="formFields"
			[loading]="loading"
			[prn]="prn"
			[showDetails]="false">
		</ux-ups-pickup>
	</div>
</div>
