<section>
		<div>
			<mat-card (click)="onNext(ACTION.SELECT)">
				<mat-card-content>
					<i class="fal fa-list-ul"></i>
					<h3>{{'Select Patient' | translate}}</h3>
				</mat-card-content>
				<mat-card-actions>
					<button mat-raised-button
							color="primary"
							(click)="onNext(ACTION.SELECT)">
						{{'Select' | translate}}
					</button>
				</mat-card-actions>

			</mat-card>
		</div>

		<div>
			<mat-card (click)="onNext(ACTION.CREATE)">
				<mat-card-content>
					<i class="fal fa-angle-double-right"></i>
					<h3>{{'Create Patient' | translate }}</h3>
				</mat-card-content>
				<mat-card-actions>
					<button mat-raised-button
							color="primary"
							(click)="onNext(ACTION.CREATE)">
						{{'Select' | translate}}
					</button>
				</mat-card-actions>
			</mat-card>
		</div>
</section>
