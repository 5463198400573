import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { CHANGE_ADDRESS_TEXT } from "./change-address-text";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "change-address-dialog",
	templateUrl: "change-address-dialog.component.html",
	styleUrls: ["./_change-address-dialog.component.scss"]
})
export class ChangeAddressDialogComponent implements OnDestroy {
	private translateSubscription: Subscription;
	protected changeAddressText: string;

	constructor(private translate: TranslateService) {
		this.changeAddressText =
			CHANGE_ADDRESS_TEXT[this.translate.currentLang] ||
			CHANGE_ADDRESS_TEXT.en;
		this.translateSubscription = this.translate.onLangChange.subscribe(
			(e) =>
				(this.changeAddressText =
					CHANGE_ADDRESS_TEXT[e.lang] || CHANGE_ADDRESS_TEXT.en)
		);
	}

	ngOnDestroy(): void {
		this.translateSubscription.unsubscribe();
	}
}
