import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MessageModel, MessageScopes, MessageTypeIcons, MessageTypes, SendersTypes } from '@suite/messages/models';
import { OrderStati, TmCommonModule, TreatmentModel, TreatmentStati, TreatmentStatus, TreatmentStatusToStringPipe } from '@suite/tm-common';
import { Subscription } from 'rxjs';

enum SenderFilter {
	NONE = "none",
	INTERN_ONLY = "intern_only",
	EXTERN_ONLY = "extern_only",
	CUSTOMER_ONLY = "customer_only"
}

enum ContextFilter {
	NONE = "none",
	CURRENT_STATUS = "current_state",
	CONSULTATION_STATUS = "consultation_status"
}

const DATE_FORMAT_DE = "dd.MM.yy HH:mm";
const DATE_FORMAT_EN = "dd/MM/yy hh:mm a";

@Component({
	selector: 'ux-messages-view-admin',
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatExpansionModule,
		MatIconModule,
		MatTabsModule,
		MatTableModule,
		MatTooltipModule,
		MatButtonToggleModule,
		TranslateModule,
		TmCommonModule
	],
	templateUrl: './messages-view-admin.component.html',
	styleUrls: ['./messages-view-admin.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
		trigger('rowExpand', [
			state('collapsed', style({ height: "6px", fontSize: "0", lineHeight: "0" })),
			state('expanded', style({ height: '*', fontSize: '*', lineHeight: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class MessagesViewAdminComponent implements OnInit, OnDestroy {
	protected MessageTypeIcons = MessageTypeIcons;
	protected TreatmentStatus = TreatmentStatus;
	protected MessageTypes = MessageTypes;
	protected SenderFilter = SenderFilter;
	protected ContextFilter = ContextFilter;

	@Input({ required: true }) messages: MessageModel[];
	@Input() currentTreatment: TreatmentModel = null;

	protected messageTabTableColumns: string[] = ['date', 'status', 'icons', 'sender', 'message'];
	protected systemTabTableColumns: string[] = ['date', 'icons', 'sender', 'message', 'expand'];
	protected dateFormat = DATE_FORMAT_DE;
	protected expandedMessage = null;

	private translationSubscription: Subscription;

	constructor(
		private translate: TranslateService,
		private treatmentStatusPipe: TreatmentStatusToStringPipe
	){ }

	ngOnInit(): void {
		this.translationSubscription = this.translate.onLangChange.subscribe(evt => {
			this.dateFormat = evt.lang === "de" ? DATE_FORMAT_DE : DATE_FORMAT_EN;
		});
	}

	ngOnDestroy(): void {
		this.translationSubscription.unsubscribe();
	}

	protected communicationMessages(messages: MessageModel[]): MessageModel[] {
		return messages.filter(m => m.sendersType !== SendersTypes.System);
	}

	protected eventLogMessages(messages: MessageModel[]): MessageModel[] {
		return messages;
	}

	protected sendersTypeLabel(type: SendersTypes) {
		switch (type) {
			case SendersTypes.Customer:
				return "DOC";
			case SendersTypes.System:
				return "SYS";
			case SendersTypes.Technican:
				return "TEC";
			case SendersTypes.Support:
				return "SUP";
			default:
				return "???";
		}
	}
	protected formatTreatmentStatusText(status: TreatmentStatus | TreatmentStati) {
		let text = '';
		if(typeof(status) === "string"){
			text = status ? this.treatmentStatusPipe.transform(status) : "";
		}else{
			// handle legacy messages pre 2.0.0
			text = status ? this.translate.instant(TreatmentStati[status]) : '';
		}
		return this.truncateString(text);
	}

	// still needed for messages pre 2.0.0
	protected formatOrderStatusText(status: OrderStati) {
		const text = status ? this.translate.instant('TREATMENT.STATUS.' + status) : "";
		return this.truncateString(text);
	}

	private truncateString(text: string, maxTotalLength = 10, maxWordLength = 4) {
		return (text.length >= maxTotalLength)
			? text.split(' ', maxWordLength - 2).map(s => ((s.length >= maxWordLength)
				? `${s.substring(0, maxWordLength)}.`
				: s)).join("")
			: text;
	}

	protected systemLogMessageHtml(message: MessageModel) {
		let text = message.message;
		text = text.replace(/<[^>]*>/g, ' '); // Remove html tags.
		if (text.length >= 100) {
			text = text.substring(0, 100) + '...'; // Truncate to fit in one line.
		}
		if (message.sendersType !== SendersTypes.System) {
			text = `"${text}"`; // Add quotes for communication messages.
		}
		return text;
	}

	protected isInternalMessage(message: MessageModel) {
		return message.scope === MessageScopes.Intern;
	}

	protected hasMailNotification(message: MessageModel) {
		return message.scope === MessageScopes.PublicWithEmailNotification;
	}

	protected isStaffMemberMessage(message: MessageModel) {
		return message.sendersType === SendersTypes.Support
			|| message.sendersType === SendersTypes.Technican
			|| message.sendersType === SendersTypes.System;
	}

	protected isRowVisible(
		message: MessageModel,
		senderFilter: SenderFilter,
		contextFilter: ContextFilter
	) {
		return (senderFilter === null
			|| (senderFilter === SenderFilter.NONE)
			|| (senderFilter === SenderFilter.EXTERN_ONLY && message.scope !== MessageScopes.Intern)
			|| (senderFilter === SenderFilter.CUSTOMER_ONLY && message.sendersType === SendersTypes.Customer)
			|| (senderFilter === SenderFilter.INTERN_ONLY && message.scope === MessageScopes.Intern))
			&& (contextFilter === null
				|| contextFilter === ContextFilter.NONE
				|| contextFilter === ContextFilter.CURRENT_STATUS && (message.context?.status === this.currentTreatment?.status)
				|| contextFilter === ContextFilter.CONSULTATION_STATUS && (message.context?.orderStatus === OrderStati.ruecksprache || message.context?.newOrderStatus === OrderStati.ruecksprache)
				|| contextFilter === ContextFilter.CONSULTATION_STATUS && (message.context?.orderStatus === OrderStati.complaint_received || message.context?.newOrderStatus === OrderStati.complaint_received)
				|| contextFilter === ContextFilter.CONSULTATION_STATUS && (message.context?.status === TreatmentStatus.ComplaintReceived || message.context?.status === TreatmentStatus.ComplaintResolved)
				|| contextFilter === ContextFilter.CONSULTATION_STATUS && (message.context?.status === TreatmentStatus.ConsultationRequired || message.context?.status === TreatmentStatus.ConsultationComplete)
			);
	}

	protected toggleExpandMessage(message: MessageModel) {
		this.expandedMessage = ((this.expandedMessage === message) ? null : message);
	}
}
