import { Component, EventEmitter, Inject, Input, OnChanges, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";

import { OrderService } from "@suite/order";
import { MediaService } from "@suite/media";
import {
	TreatmentModel,
	TreatmentRequested,
	TreatmentState,
	TreatmentStatus,
	UpsertTreatmentOnDb
} from "@suite/treatments";
import {
	QuestionairePrintDialogComponent,
	QuestionairePrintDialogData
} from "@suite/questionaire";
import { Anamnese, AnamneseReleaseAction } from "@suite/anamnese";

import {
	ReleaseDialogType,
	ReleaseDialogActionData,
	TreatmentReleaseDialogComponent
} from "./treatment-release-dialog/treatment-release-dialog.component";
import { MessageModel, MessageScopes, MessageTypes, SendersTypes } from "@suite/messages/models";
import { AddMessageOnDb, MessagesRequested } from "@suite/messages";
import { ProductInfoForKey, ProductUrlKey } from "libs/product/src/lib/models/product.model";

@Component({
	selector: 'tm2-treatment-release',
	templateUrl: './treatment-release.component.html',
	styleUrls: ['./treatment-release.component.scss']
})
export class TreatmentReleaseComponent implements OnChanges {
	@Input() treatment: TreatmentModel;
	@Output() backButtonClicked = new EventEmitter<any>();

	anamnese: Anamnese = null;
	processing: boolean;

	constructor(
				private store: Store<TreatmentState>,
				public dialog: MatDialog,
				private orderService: OrderService,
				private mediaService: MediaService,
				@Inject('CONFIG') public config) {
	}


	ngOnChanges() {
		if (this.treatment.quotation?.anamnese) {
			this.anamnese = structuredClone(this.treatment.quotation.anamnese);
		}
	}

	onRelease() {
		this.processing = true;
		this.openCRDialog(ReleaseDialogType.RELEASE, this.treatment).afterClosed().subscribe((releaseData: ReleaseDialogActionData) => {
			if (releaseData) {
				// cleanup upgrade data if any
				if (this.treatment.upgrade) {
					this.treatment.upgrade = null;
				}
				if (this.treatment.quotation.anamnese.releaseData.release.webViewerUrlUpgrade) {
					this.treatment.quotation.anamnese.releaseData.release.webViewerUrlUpgrade = '';
				}
				this.handleOrder(ReleaseDialogType.RELEASE, releaseData);
			} else {
				this.processing = false;
			}
		});
	}

	onUpgrade() {
		this.processing = true;
		this.openCRDialog(ReleaseDialogType.RELEASE_UPGRADE, this.treatment).afterClosed().subscribe((upgradeData: ReleaseDialogActionData) => {
			if (upgradeData) {
				this.handleOrder(ReleaseDialogType.RELEASE_UPGRADE, upgradeData);

			} else {
				this.processing = false;
			}
		});
	}


	onCorrection() {
		this.processing = true;
		this.openCRDialog(ReleaseDialogType.CORRECTION, this.treatment).afterClosed().subscribe((correctionComment: string) => {
			if (correctionComment) {
				this.updateTreatmentStatusWithMessage(correctionComment, TreatmentStatus.CorrectionRequestReceived);
			} else {
				this.processing = false;
			}
		});
	}

	onCancel() {
		this.processing = true;
		this.openCRDialog(ReleaseDialogType.CANCEL, this.treatment).afterClosed().subscribe((cancelData: ReleaseDialogActionData) => {
			if (cancelData?.comment) {
				this.handleOrder(ReleaseDialogType.CANCEL, cancelData);
			} else {
				this.processing = false;
			}
		});
	}

	/**
	 * Handle the release action that places one of the following orders in the backend:
	 * - originally requested product order (chosenAction: RELEASE)
	 * - upgrade option product order (chosenAction: RELEASE_UPGRADE)
	 * - storno "product" order (chosenAction: CANCEL)
	 */
	handleOrder(
		chosenAction: ReleaseDialogType,
		actionData: ReleaseDialogActionData
	) {
		if (chosenAction !== ReleaseDialogType.CANCEL) {
			const isUpgrade = chosenAction === ReleaseDialogType.RELEASE_UPGRADE;
			this.store.dispatch(new AnamneseReleaseAction({ treatmentId: this.treatment._id, comment: actionData.comment, isUpgrade: isUpgrade}));
		} else {
			// chosenAction === ReleaseDialogType.CANCEL
			this.orderService.cancelOrder(this.treatment._id, actionData.comment)
				.subscribe({
					next: (response: TreatmentModel) => {
						this.store.dispatch(new MessagesRequested());
						this.store.dispatch(new TreatmentRequested({id: response._id}));
					},
					error: (error) => console.error("Error while canceling order", error)
				}
			);
		}
	}

	openCRDialog(type: ReleaseDialogType, treatment: TreatmentModel = null) {
		return this.dialog
			.open(TreatmentReleaseDialogComponent,
				{
					panelClass: 'blue-dialog',
					data: {type, treatment}
				}
			);
	}


	private updateTreatmentStatusWithMessage(message: string, newStatus: TreatmentStatus) {
		let anamnese = structuredClone(this.treatment.quotation.anamnese);
		//If a doctor request a correction, set 'planningFixed' to 'false'
		//to allow the technician to adjust the number of steps
		if (newStatus === TreatmentStatus.CorrectionRequestReceived) {
			anamnese.planingFixed = false;
		}

		const diff: Partial<TreatmentModel> = {
			status: newStatus,
			quotation: {
				...this.treatment.quotation,
				anamnese: anamnese
			},
		};

		// save comment if any
		if (message) {
			const messageModel = this.buildMessageModel(message, newStatus);
			this.store.dispatch(new AddMessageOnDb({ message: messageModel }));
		}

		this.store.dispatch(new UpsertTreatmentOnDb({
			treatmentId: this.treatment._id,
			diff: diff
		}));
	}

	private buildMessageModel(message: string, status: TreatmentStatus): MessageModel {
		const messageModel: MessageModel = {
			message: message,
			sendersType: SendersTypes.Customer,
			scope: MessageScopes.Public,
			type: MessageTypes.Note,
			context: {
				id: this.treatment._id,
				status: status,
				ownerId: this.treatment.uid.toString()
			}
		};
		return messageModel;
	}

	openQuestionaireDialog() {
		const dialogData: QuestionairePrintDialogData = {
			treatment: this.treatment,
		};
		this.dialog.open(QuestionairePrintDialogComponent,
			{data: dialogData, maxWidth: '100vw', width: '100%', height: '100%'});

	}

	getUpgradeProductName() : string
	{
		return ProductInfoForKey[this.treatment.upgrade.product.info.urlKey as ProductUrlKey]?.name;
	}

	downloadMediaFile(id) {
		this.mediaService.getFile(id);
	}

	hasRequestedOptionForRelease(treatment: TreatmentModel): boolean {
		return treatment?.packages?.steps?.length > 0;
	}

	hasUpgradeOptionForRelease(treatment: TreatmentModel): boolean {
		return !!treatment.upgrade;
	}

	onBackButtonClicked() {
		this.backButtonClicked.emit();
	}
}
