import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {first, tap} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import {MessagesRequested} from '../store/messages.actions';
import {MessagesState} from '../store/messages.reducer';
import {MessageModel} from '../models';
import {selectMessagesByTreatmentId} from '../store/messages.selectors';
@Injectable()

export class MessagesResolver  {
    constructor(
        private store: Store<MessagesState>) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MessageModel[]> {

        // NOTE: we just want to trigger action TreatmentsRequested here if there are
        // no entities in store, so its enough to selector selectTotal
        // important:  observable must complete, otherwise no routing
        return this.store
            .pipe(
            	select(selectMessagesByTreatmentId(route.params.id)),
                tap((messages) => {
                	if (!messages.length) {
						this.store.dispatch(new MessagesRequested());
					}
				}),
				first()
            );
    }

}

