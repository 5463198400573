import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { MediaFileDeletedAction, selectAnamneseJaws, selectAnamnesePatientId, selectAnamneseUploadedFiles } from "@suite/anamnese";
import { ACTION, AppState } from "@suite/tm-common";
import { MediaModel } from "libs/media/src/lib";
import { Subscription, first, forkJoin } from "rxjs";

@Component({
	selector: "tm2-anamnese-fileupload",
	templateUrl: "./anamnese-fileupload.component.html",
	styleUrls: ["./_anamnese-fileupload.component.scss"]
})
export class AnamneseFileuploadComponent implements OnInit, OnDestroy {

	@Output() next = new EventEmitter<any>();
	jaws = { uj: undefined, lj: undefined }; // defines, which jaws are selected for the treatment
	uploadedStlFiles: {uj: Partial<MediaModel>, lj: Partial<MediaModel> } = {uj: {}, lj: {}};  // stores parts of the mediaModel for each jaw
	uploadedAdditionalFiles : { index: number, mediaModel: Partial<MediaModel>  }[]= [] // stores parts of the mediaModel for each index
	disableNext = true;
	patientId: string; //needed for fileupload without treatment
	disabledNextSubscription: Subscription;

	constructor(
		private store: Store<AppState>) {}

	ngOnInit(): void {
		this.uploadedStlFiles = {uj: {}, lj: {}};
		this.uploadedAdditionalFiles = [];
		forkJoin({
				files: this.store.pipe(select(selectAnamneseUploadedFiles), first()),
				anamneseJaws: this.store.pipe(select(selectAnamneseJaws), first()),
				patientId : this.store.pipe(select(selectAnamnesePatientId),first())
		}).subscribe( (value) => {
			//check if files were already uploaded (e.g. if user goes back)
			this.uploadedStlFiles.uj.orgFilename = value.files.uj?.orgFilename;
			this.uploadedStlFiles.lj.orgFilename = value.files.lj?.orgFilename;
			this.uploadedStlFiles.uj._id =  value.files.uj?.mediaId;
			this.uploadedStlFiles.lj._id =  value.files.lj?.mediaId;
			value.files.additionals.forEach((file: any) => {
				this.uploadedAdditionalFiles.push({ index: file.index, mediaModel: {
					_id: file.mediaId,
					orgFilename: file.orgFilename
				}});
			});

			this.jaws.uj = value.anamneseJaws.uj;
			this.jaws.lj = value.anamneseJaws.lj;
			this.patientId = value.patientId;
			this.disableNext = !(this.checkForCompleteness(value.files));
		});

		this.disabledNextSubscription = this.store.pipe(select(selectAnamneseUploadedFiles)).subscribe((uploadedAnamneseFiles: any) => {
			this.disableNext = !(this.checkForCompleteness(uploadedAnamneseFiles));
		});
	}

	ngOnDestroy(): void {
		this.disabledNextSubscription.unsubscribe();
	}

	onAction(action){
		switch (action.type) {
			case ACTION.BACK:
				this.next.emit({type: ACTION.BACK});
				break;
			case ACTION.NEXT:
				this.next.emit({type: ACTION.OPTIONS});
				break;
			case ACTION.UPLOADED:
				break;
			case ACTION.DELETED:
				this.store.dispatch(new MediaFileDeletedAction(action.payload.uploadType, action.payload.index))
				break;
		}
	}

	private checkForCompleteness(uploadedFiles): boolean{
		if ( this.jaws.uj ) {
			return !!uploadedFiles.uj && !!uploadedFiles.lj
		}
		if ( this.jaws.lj ) {
			return !!uploadedFiles.lj
		}
		return true;
	}
}
