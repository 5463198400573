import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACTION } from '@suite/tm-common';
import { DataTransferMethod, TreatmentModel } from '@suite/treatments/models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@suite/ux-components';

@Component({
  selector: 'tm2-anamnese-transfer',
  templateUrl: './anamnese-transfer.component.html',
  styleUrls: ['./_anamnese-transfer.component.scss']
})
export class AnamneseTransferComponent implements OnInit {

	@Input({required: true}) treatment: TreatmentModel;
	@Input({required: true}) wmsId: string;
	@Output() next = new EventEmitter<any>();

	dataTransfer = undefined;
	disableNext = true;
	nextText = "gotoTreatment"
	showUpsPickUpContainer = false;
	constructor(
				 private dialog: MatDialog
				 )
	{
	}

	ngOnInit(): void {
		if (this.treatment) {
				this.dataTransfer = this.treatment.product.options.dataTransfer;
				this.showUpsPickUpContainer = this.dataTransfer === DataTransferMethod.UPS;
				this.disableNext = this.showUpsPickUpContainer; //disable, and wait for the ups pickup request
		}
	}


	onAction(action) {
		switch (action.type) {
			case ACTION.NEXT:
				this.next.emit({type: ACTION.NEXT, payload: undefined});
				break;

			case ACTION.ENABLE_NEXT:
				//XXX triggered by ups-pickup-container.component.ts
				this.disableNext = false;
				break;
			default:
		}
	}

	onSkipUpsPickup(): void {
		const dialogData = {
			title: "Pickup Incomplete",
			msg: "Pickup Required For Processing",
			no: "Request Pickup Now",
			yes: "Request Pickup Later",
		};
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData,
		});

		// Proceed only if user confirms to send files later.
		dialogRef.afterClosed().subscribe((result) => {
			if (result == "yes") {
				this.onAction({ type: ACTION.NEXT, payload: undefined });
			}
		});
	}
}
