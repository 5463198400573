<section *ngIf="packages?.steps?.length">
	<!-- JAW HEADER -->
	<div class="col jaw-header-col">
		<div class="vertical-spacer"></div>
		<div *ngFor="let jaw of jaws" class="jaw">{{jaw | translate}}</div>
	</div>

	<!-- PACKAGES -->
	<div class="col packet-col">
		<div class="header">
			{{'Behandlungspaket' | translate}}<br>
		</div>
		<div *ngFor="let jaw of jaws">

			<div class="packets">
				<ng-container *ngIf="packages?.steps?.length; else empty">
					<div *ngFor="let pkg of packages?.steps; let i = index"
						 [ngClass]="{delivered: noOfOrders > i}"
						 class="packet">
						{{'Paket' | translate}} <span>{{i + 1}}</span>
						({{pkg}})
					</div>
				</ng-container>
				<ng-template #empty>
					<div class="packet">
						{{'Paket' | translate}} <span>1</span>
						(?)
					</div>
				</ng-template>
			</div>

		</div>
	</div>

	<!-- FREE CORRECTIONS -->
	<div class="col free-corrections-col" *ngIf="packages?.freeCorrections?.length">
		<div class="header">
			{{'Korrekturpaket' | translate}}<br>
		</div>
		<div *ngFor="let jaw of jaws" class="c-packets">
			<ng-container *ngIf="packages?.freeCorrections?.length < 4; else moreCorrections">
				<div *ngFor="let pkg of packages?.freeCorrections; let i = index"
					 [ngClass]="{delivered: noOfOrders > i + packages?.steps.length}"
					 class="c-packet">
					{{'Paket' | translate}} <span>{{i + packages?.steps.length + 1}}</span>
					({{pkg}})
				</div>
			</ng-container>
			<ng-template #moreCorrections>
				<div [ngClass]="{delivered: noOfOrders >= firstShownCorrection + noPkgs}"
					 class="c-packet">
					<span *ngIf="prefix">...</span>{{'Paket' | translate}} {{noPkgs + firstShownCorrection}}
				</div>
				<div [ngClass]="{delivered: noOfOrders >= firstShownCorrection + noPkgs + 1}"
					 class="c-packet">
					{{'Paket' | translate}} {{noPkgs + firstShownCorrection + 1}}
				</div>
				<div class="c-packet">
					{{'Paket' | translate}} {{noPkgs + firstShownCorrection + 2}}<span *ngIf="suffix">...</span>
				</div>
			</ng-template>

		</div>

	</div>

	<!-- ADDITIONAL CORRECTIONS -->
	<div class="col add-corrections-col" *ngIf="packages?.addCorrections?.length">
		<div class="header">
			{{'zus. Korrekturpaket' | translate}}<br>
		</div>
		<div *ngFor="let jaw of jaws" class="c-packets">

			<div *ngFor="let pkg of packages?.addCorrections; let i = index"
				 [ngClass]="{delivered: noOfOrders > i + packages?.steps.length + packages?.freeCorrections.length}"
				 class="c-packet">
				{{'Paket' | translate}} <span>{{i + packages?.steps.length + packages?.freeCorrections.length + 1}}</span>
				({{pkg}})
			</div>

		</div>
	</div>

</section>

