import {NgModule} from '@angular/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {environment} from 'environments/environment';

@NgModule({
	imports: [
		BrowserModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})],
	exports: [
		CommonModule,
		TranslateModule
	],
	providers: []
})
export class CaTranslateModule {
}

export function HttpLoaderFactory(http: HttpClient) {
	// XXX We employ rudimentary cache-busting by appending a "revving" query string.
	// Ideally, we would use filename versioning instead, but that requires more work 
	// on the build system (assets hashing) and configuration of ngx-translate.
	// https://www.keycdn.com/support/what-is-cache-busting
	return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);
}
