import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { MessageModel} from '../models/message.model';

export enum MessagesActionTypes {
  MessagesRequested = '[Messages] Messages Requestd',
  LoadMessages = '[Messages] Load Messages',
  AddMessage = '[Messages] Add Message',
  AddMessageOnDb = '[Messages] Add Message on DB',
  UpsertMessage = '[Messages] Upsert Message',
  AddMessages = '[Messages] Add Messages',
  UpsertMessages = '[Messages] Upsert Messages',
  UpdateMessage = '[Messages] Update Message',
  UpdateMessages = '[Messages] Update Messages',
  DeleteMessage = '[Messages] Delete Message',
  DeleteMessages = '[Messages] Delete Messages',
  ClearMessages = '[Messages] Clear Messages'
}
export class MessagesRequested implements Action {
	readonly type = MessagesActionTypes.MessagesRequested;
}
export class LoadMessages implements Action {
  readonly type = MessagesActionTypes.LoadMessages;

  constructor(public payload: { messages: MessageModel[] }) {}
}

export class AddMessage implements Action {
  readonly type = MessagesActionTypes.AddMessage;

  constructor(public payload: { message: MessageModel }) {}
}
export class AddMessageOnDb implements Action {
	readonly type = MessagesActionTypes.AddMessageOnDb;

	constructor(public payload: { message: MessageModel }) {}
}
export class UpsertMessage implements Action {
  readonly type = MessagesActionTypes.UpsertMessage;

  constructor(public payload: { message: MessageModel }) {}
}

export class AddMessages implements Action {
  readonly type = MessagesActionTypes.AddMessages;

  constructor(public payload: { messages: MessageModel[] }) {}
}

export class UpsertMessages implements Action {
  readonly type = MessagesActionTypes.UpsertMessages;

  constructor(public payload: { messages: MessageModel[] }) {}
}

export class UpdateMessage implements Action {
  readonly type = MessagesActionTypes.UpdateMessage;

  constructor(public payload: { message: Update<MessageModel> }) {}
}

export class UpdateMessages implements Action {
  readonly type = MessagesActionTypes.UpdateMessages;

  constructor(public payload: { messages: Update<MessageModel>[] }) {}
}

export class DeleteMessage implements Action {
  readonly type = MessagesActionTypes.DeleteMessage;

  constructor(public payload: { id: string }) {}
}

export class DeleteMessages implements Action {
  readonly type = MessagesActionTypes.DeleteMessages;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearMessages implements Action {
  readonly type = MessagesActionTypes.ClearMessages;
}

export type MessagesActions =
 LoadMessages
 | AddMessage
 | AddMessageOnDb
 | UpsertMessage
 | AddMessages
 | UpsertMessages
 | UpdateMessage
 | UpdateMessages
 | DeleteMessage
 | DeleteMessages
 | ClearMessages;
