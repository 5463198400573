<section>
	<ux-product-visual
		[productKey]="anamnese.product.info.urlKey"
		[productInfo]="anamnese.product.info"
	></ux-product-visual>

	<div class="options">
		<tm2-anamnese-options-jaws
			*ngIf="currentSubState === OptionsSubstate.JAW_SELECTION"
			[options]="anamnese?.product?.options">
		</tm2-anamnese-options-jaws>

		<tm2-custom-options
			*ngIf="currentSubState === OptionsSubstate.TOOTH_AREA_SELECTION"
			[hasUpperJaw]="anamnese?.product?.options?.uj"
			[hasLowerJaw]="anamnese?.product?.options?.lj"
			(action)="onAction($event)">
		</tm2-custom-options>
	</div>
</section>
<ux-next-back-actions
	(action)="onAction($event)"
	[disabledNext]="(!anamnese.product.options.uj && !anamnese.product.options.lj) || disableNext"
></ux-next-back-actions>

