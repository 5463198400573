import {FormlyFieldConfig} from '@ngx-formly/core';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * service to get the translated form fields
 *   contains field definitions and form helper functions
 *   for initialzation & validation
 *
 *   Must be a angular service to inject TranslationService
 */
@Injectable()
export class UpsFormFieldsService {
	tomorrow;

	constructor(private translate: TranslateService) {
		this.tomorrow = new Date();
		this.tomorrow.setDate(this.tomorrow.getDate() + 1);
	}

	getFormFields(): FormlyFieldConfig[] {

		return [

			{ className: 'details', template: '<h3><i class="fal fa-address-card"></i> ' + this.translate.instant('UPS.pickupAddress') + '</h3>' },

			{
				key: 'address.CompanyName',
				className: 'details',
				type: 'input',
				props: {
					label: this.translate.instant('UPS.companyName'),
					required: true
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired')
					}
				}
			},
			{
				key: 'address.AddressLine',
				className: 'details',
				type: 'input',
				props: {
					label: this.translate.instant('UPS.address'),
					required: true
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired')
					}
				}
			},
			{
				key: 'address.PostalCode',
				className: 'details',
				type: 'input',
				props: {
					label: this.translate.instant('UPS.postalCode'),
					required: true
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired')
					}
				}
			},
			{
				key: 'address.City',
				className: 'details',
				type: 'input',
				props: {
					label: this.translate.instant('UPS.city'),
					required: true
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired')
					}
				}
			},
			{
				key: 'address.CountryCode',
				className: 'details',
				type: 'input',
				props: {
					label: this.translate.instant('UPS.countryCode'),
					required: true
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired')
					}
				}
			},

			{template: '<h3><i class="fal fa-user"></i> ' + this.translate.instant('UPS.contactPerson') + '</h3>'},
			{
				key: 'address.ContactName',
				type: 'input',
				props: {
					label: this.translate.instant('UPS.contactName'),
					minLength: 1,
					maxLength: 22,
					required: true
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired'),
						minLength: this.translate.instant('UPS.minlength'),
						maxLength: this.translate.instant('UPS.maxlength'),
					}
				}
			},
			{
				key: 'address.Phone.Number',
				type: 'input',
				props: {
					label: this.translate.instant('UPS.phone'),
					required: true
				},
				validators: {
					phoneNumber: {
						expression: function(control) {
							const value = control.value;
							return /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(value);
						},
						message: this.translate.instant('not a valid phone number')
					}
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired')
					}
				}
			},

			{template: '<h3><i class="fal fa-shipping-timed"></i> ' + this.translate.instant('UPS.pickupDateTime') + '</h3>'},
			{
				key: 'dateTimeInfo.PickupDate',
				type: 'datepicker',
				className: 'datepicker',
				props: {
					label: this.translate.instant('UPS.pickupDay'),
					description: this.translate.instant('UPS.date'),
					datepickerOptions: {
						min: this.tomorrow
					},
					required: true
				},
				validation: {
					messages: {
						required: this.translate.instant('UPS.valueRequired')
					}
				}
			},

			{ className: 'time-label', template: this.translate.instant('UPS.start') },
			{
				key: 'dateTimeInfo.ReadyTime',
				type: 'select',
				className: 'time',
				props: {
					description: this.translate.instant('UPS.from'),
					required: true,
					options: this.getTimeRange(800, 1400)
				},
				validators: {
					'dateTimeInfo.ReadyTime': {
						expression: this.validateTime,
						message: this.translate.instant('UPS.greater2Hours')
					},
				}
			},

			{className: 'time-label', template: this.translate.instant('UPS.end')},
			{
				key: 'dateTimeInfo.CloseTime',
				type: 'select',
				className: 'time',
				props: {
					description: this.translate.instant('UPS.to'),
					required: true,
					options: this.getTimeRange(1300, 1800)
				},
				validators: {
					'dateTimeInfo.CloseTime': {
						expression: this.validateTime,
						message: this.translate.instant('UPS.greater2Hours')
					},
				}
			},

			{className: 'clear-fix', template: '<div></div>'},
			{template: '<h3><i class="fal fa-info-circle"></i> ' + this.translate.instant('UPS.specialInstructionHeader') + '</h3>'},
			{
				key: 'specialInstruction',
				type: 'textarea',
				props: {
					label: this.translate.instant('UPS.specialInstruction'),
					minLength: 0,
					maxLength: 500
				},
				validation: {
					messages: {
						maxlength: this.translate.instant('UPS.maxlengthSpecialInstruction'),
					}
				}
			},
			{className: 'clear-fix', template: '<div></div>'},
		];

	}

	/**
	 * getTimeRange - Initializer for time options
	 * @param start - start of time range
	 * @param end - end of time range
	 * @returns {any[]} - option array ready to use in type select
	 */
	getTimeRange(start, end) {
		const options = [];

		for (let i = start; i <= end; i += 100) {
			for (let n = 0; n < 60; n += 15) {
				const hourStr = i < 1000 ? '0' + i / 100 : i / 100;
				const minuteStr = n ? n + '' : '0' + n;
				const option = {value: hourStr + minuteStr, label: hourStr + ':' + minuteStr};
				options.push(option);
				if (i === end) {
					break;
				}
			}
		}
		return options;
	}

	/**
	 * validateTime - validator expression to ensure that between start and end time are two hours
	 * @param control
	 * @returns {boolean}
	 */
	validateTime(control) {
		const hoursBetween = 2;
		const parent = control.parent;
		if (parent && parent.controls &&
			parent.controls.CloseTime && parent.controls.ReadyTime) {
			if (parent.controls.CloseTime.value - parent.controls.ReadyTime.value < hoursBetween * 100) {
				return false;
			}
		}
		return true;
	}

}
