import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';

import {GoAction, selectCurrentUrl} from '@suite/tm-common';
import {AuthState, LogoutAction, selectIsSignedIn, selectIsSignedOut, LogoutAdminAction, selectAdminRole, MagentoUserRole, selectDisplayName, selectAdminUsername} from '@suite/auth';
import { CmsBlockModel, CmsService } from '@suite/cms';
import { Subscription, filter, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ChangeAddressDialogComponent } from "libs/ux-components/src/lib/components";

export const CHANGE_ADDRESS_TARGET = "changeAddressDialog"

@Component({
	selector: 'ux-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
	@Input() showDiscount;
	@Input() menuEntries;
	@Input() subMenuEntries = [];
	@Input() adminMenuEntries = [];
	@Input() title = 'TREATMENT MANAGER';
	@Input() hotline = '';

	MagentoUserRole = MagentoUserRole;

	maintenanceTime: Date;
	timeToMaintenance$: string;
	maintenanceTimeSubscription: Subscription;

	url$;
	isSignedIn$;
	isSignedOut$;
	currentAdminRole$;
	adminDisplayName$;
	adminUsername$;

	constructor(@Inject('CONFIG') public config,
				private translate: TranslateService,
				private cmsService: CmsService,
				private dialog: MatDialog,
				private store: Store<AuthState>)
	{}

	ngOnInit(): void {
		this.url$ = this.store.pipe(select(selectCurrentUrl));
		this.isSignedIn$ = this.store.pipe(select(selectIsSignedIn));
		this.isSignedOut$ = this.store.pipe(select(selectIsSignedOut));
		this.currentAdminRole$ = this.store.pipe(select(selectAdminRole));
		this.adminDisplayName$ = this.store.pipe(select(selectDisplayName));
		this.adminUsername$ = this.store.pipe(select(selectAdminUsername));
		this.maintenanceTimeSubscription = this.isSignedIn$.pipe(
			filter((signedIn: boolean) => signedIn),
			switchMap( () => this.cmsService.getCmsBlock('maintenance_time')))
				.subscribe(cmsBlock => {
					this.calculateMaintenanceTimer(cmsBlock);

				});
	}

	ngOnDestroy(): void {
		this.maintenanceTimeSubscription.unsubscribe();
	}

	navigateTo(target) {
		if(target === CHANGE_ADDRESS_TARGET){
			this.dialog.open(ChangeAddressDialogComponent);
		}else{
			this.store.dispatch( new GoAction({path: [target]}) );
		}
	}

	signout() {
		if (this.config.app === 'tm2' || this.config.app === 'tmormco')
		{
			this.store.dispatch(new LogoutAction());
		}
		else
		{
			this.store.dispatch(new LogoutAdminAction());
		}
	}

	switchLanguage(language: string) {
		this.translate.use(language);
	}

	/**
	 * Checks if the CMS Block 'maintenance_time' is activated.
	 * If a valid date in format "yyyy-mm-dd hh:mm:ss" is received,
	 * a timer is calculated and displayed in all frontends.
	 */
	calculateMaintenanceTimer(cmsBlock: CmsBlockModel)
	{
		if (cmsBlock.activated && cmsBlock.content) {
			this.maintenanceTime = new Date(cmsBlock.content);
			setInterval(() => {
				const timeInMS = this.maintenanceTime.getTime() - new Date().getTime();
				this.timeToMaintenance$ = timeInMS > 0 ?
						new Date(timeInMS).toISOString().substring(11,19) :
						null;
			}, 1000);
		}
	}


}
