import {Inject, Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import {AuthService} from './';

import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Store} from '@ngrx/store';
import {AuthState, NoHttpAuthAction} from '../store';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

	constructor(public auth: AuthService,
				 private store: Store<AuthState>,
				 @Inject('CONFIG') private config) {}


	/**
	 * intercept all XHR request
	 * @param request
	 * @param next
	 * @returns {Observable<A>}
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// build additional headers
		const headers = new HttpHeaders({
			// 'Authorization': 'token 123',
			'Client-Version': this.config.version,
			'Client-Name': this.config.app
		});

		// clone the request and add new headers
		const clonedRequest = request.clone({headers});


		return next.handle(clonedRequest).pipe(
			catchError((error, caught) => {
				// intercept the response error
				console.error('AuthHttpInterceptor catched Error', error, request);

				// on signin panel we dont want handle the error
				// instead we want to let it handled by signin panel itself
				if (error.status === 401 && (request.url.endsWith('/customer') || request.url.endsWith('/admin'))) {
					return next.handle(request);
				}

				// handle teapot - it means version mismatch
				if (error.status === 418) {
					window.location.reload();
					return;
				}

				this.handleKnownErrors(error);
				return of(error);
			}) as any);
	}


	/**
	 * handleAuthError
	 * @param err
	 * @returns {any}
	 */
	private handleKnownErrors(err: HttpErrorResponse): Observable<any> {

		// handle your auth error or rethrow
		if (err.status === 401) {
			console.log(`AuthHttpInterceptor handled error ${err.status} and dispatch NoHttpAuth`);
			this.store.dispatch( new NoHttpAuthAction());
			// if you've caught / handled the error,
			// you don't want to rethrow it unless you also
			// want downstream consumers to have to handle it as well.
			return of(err.message);
		}
		throw err;
	}
}
