<section>
	<div class="options" *ngIf="uiState === UIState.OPTIONS">
		<mat-card class="primary"
				  *ngIf="showNext">
			<mat-card-header >
				<mat-card-title>
					<i class="fal fa-file-check primary"></i>
					<h3>{{'NEXTSTEPS.Order next steps without HEADER'|translate}}</h3>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<p>{{'NEXTSTEPS.Order next steps without INFO1'|translate}}</p>
				<p>{{'NEXTSTEPS.Order next steps without INFO2'|translate}}</p>
			</mat-card-content>
			<mat-card-actions>
				<button mat-raised-button color="primary" [mat-dialog-close]="{type: FollowupPackageType.NEXT, dataTransferMethod: null, uploadedMediaIds:[]}">
					<i class="fal fa-file-check"></i>
					{{'NEXTSTEPS.Order next steps without BUTTON' | translate}}
				</button>
			</mat-card-actions>
		</mat-card>

		<mat-card class="warning"
				  *ngIf="showNextWithData">
			<mat-card-header>
				<mat-card-title>
					<i class="fal fa-file-plus warning"></i>
					<h3>{{'NEXTSTEPS.Order next steps with HEADER'|translate}}</h3>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<p>{{'NEXTSTEPS.Order next steps with INFO1'|translate}}</p>
				<p>{{'NEXTSTEPS.Order next steps with INFO2'|translate}}</p>
			</mat-card-content>
			<mat-card-actions>
				<button mat-raised-button color="primary"
				(click)="showDataTransferState(FollowupPackageType.NEXT_WITH_IMPRINT_DATA)">
					<i class="fal fa-file-plus"></i>
					{{'NEXTSTEPS.Order next steps with BUTTON' | translate}}
				</button>
			</mat-card-actions>
		</mat-card>

		<mat-card class="error"
				  *ngIf="showCorrection">
			<mat-card-header>
				<mat-card-title>
					<i class="fal fa-file-exclamation error"></i>
					<h3>{{'NEXTSTEPS.Request Correction Steps HEADER'|translate}}</h3>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<p>{{'NEXTSTEPS.Request Correction Steps INFO1'|translate}}</p>
				<b>{{'NEXTSTEPS.Request Correction Steps INFO2'|translate}}</b>
			</mat-card-content>

			<ux-pricelist></ux-pricelist>
			<br>

			<mat-card-actions>
				<button mat-raised-button color="primary"
				(click)="showDataTransferState(FollowupPackageType.CORRECTION)">
					<i class="fal fa-file-exclamation"></i>
					{{'NEXTSTEPS.Request Correction Step NEXTBUTTON' | translate }}
				</button>
			</mat-card-actions>
		</mat-card>
	</div>
	<div *ngIf="uiState === UIState.DATA_TRANSFER" class="flex-column">
			<div *ngIf="treatment.product.options.dataTransfer === DataTransferMethod.UPS" class="flex-column">
				<h3>{{'Abholungsauftrag' | translate}}</h3>
				<ux-pickup-container
					[wmsId]="wmsId"
					[treatmentId]="treatment._id"
					(action)="onPickupRequested($event)">
				</ux-pickup-container>
			</div>
			<div *ngIf="treatment.product.options.dataTransfer === DataTransferMethod.UPLOAD"  class="flex-column">
				<h3>{{"Dataupload"| translate}}</h3>
				<mat-card>
					<mat-card-content>
						<ux-fileupload
						[patientId]="treatment.patient._id"
						(informParent)="onFileChanged($event)"
						></ux-fileupload>
					</mat-card-content>
				</mat-card>
			</div>
			<div *ngIf="treatment.product.options.dataTransfer === DataTransferMethod.OTHER"  class="flex-column">
				<h3>{{"Dataupload"| translate}} (optional)</h3>
				<mat-card>
					<mat-card-content>
						<ux-fileupload
						[showStl]="false"
						[patientId]="treatment.patient._id"
						(informParent)="onFileChanged($event)"
						></ux-fileupload>
					</mat-card-content>
				</mat-card>
			</div>

			<div class="buttons">
				<button mat-raised-button color="primary"
					[mat-dialog-close]="onDialogClose()"
					[disabled]="isOrderButtonDisabled()"
					data-cy="order-button">
					{{followupPackageType === FollowupPackageType.CORRECTION ?
						( 'NEXTSTEPS.Request Correction Step ORDERBUTTON' | translate ) :
						('Order next package' | translate) }}
				</button>
			</div>
	</div>
	<button mat-raised-button class="closer" [mat-dialog-close]="null">{{'Close' | translate }}</button>
</section>
