<div class="status" *ngIf="treatment">

	<div class="header">
		{{'Status' | translate}}
	</div>
	<div class="top">
		{{ treatment.status | statusToString:true | translate }}
	</div>
	<div class="detail">
		{{ 'created at'  | translate }}
		{{ treatment.created | date: 'mediumDate' : 'EUROPE/BERLIN' : config.locale }}
		<br>
		<span>{{'from' | translate}} {{user.displayName}}</span>
		<br>
		<ng-container *ngIf="treatment.product.options as options">
			<span *ngIf="options.uj && getToothAreaForProduct(true) as toothArea">
				{{'UJ' | translate}}: {{toothArea}}
			</span>
			<span *ngIf="options.lj && getToothAreaForProduct(false) as toothArea">
				{{'LJ' | translate}}: {{toothArea}}
			</span>
			<br>
		</ng-container>
		<span *ngIf="treatment.corrections">
			{{'Correctionsteps(free)' | translate}}: {{treatment.corrections}}<br>
		</span>
	</div>

</div>

<div class="order-status">

	<div class="header">
		{{ 'Order' | translate }}
	</div>
	<div class="top">
	</div>
	<div class="detail">
		{{ 'ordered at' | translate }}
		{{ getCreatedDate() | date: 'mediumDate' : 'EUROPE/BERLIN' : config.locale }}<br>
		{{ 'Order ID' | translate}}:
		{{ getQuotationOrOrderId() }}
		<span *ngIf="treatment.quotation?.salesOrders?.at(-1)?.docNum as docNum"><br>{{ 'Sales order' | translate}}: {{docNum}}</span><br>
	</div>

</div>

<div class="patient-info" *ngIf="patient && user">

	<div class="header">Patient</div>

	<div class="top">
		{{patient.displayName}}
	</div>

	<div class="detail">
		{{'Id' | translate}}: {{patient.patientId}}<br>
		{{'created at' | translate}} {{patient.created | date: 'mediumDate' : 'EUROPE/BERLIN' : config.locale }}
		<br>
		{{'from' | translate}} {{user.displayName}}<br>
	</div>

</div>

<div *ngIf="pickUpInfo"
	 class="pickup-info-grid"
	 [ngClass]="{'should-be-ready' : pickUpInfo.shouldBeReady}"
	 >
	 <div class="grid-item">
		<i class="fa-brands fa-ups"></i><strong> {{'UPS Abholung' | translate}}</strong><br>

		<ng-container *ngIf="!pickUpInfo.shouldBeReady; else shouldBeReady">
			{{'Paket sollte in' | translate}} {{pickUpInfo.mustBeReady?.hours}}
			{{'Stunden' | translate}} {{'und' | translate}}
			{{pickUpInfo.mustBeReady?.minutes}} {{'Minuten' | translate}} {{'abholbereit sein.' | translate}} <br>
		</ng-container>
		<ng-template #shouldBeReady>
			{{'Paket sollte ab jetzt abholbereit sein.' | translate}} <br>
		</ng-template>
		<div>
			{{'PickupReferenceInfo' | translate}}: <strong>{{pickUpInfo.prn}}</strong>
		</div>
		<div>
			{{'Contact' | translate}}: {{pickUpInfo.address?.ContactName}},
			{{pickUpInfo.address?.CompanyName}},
			{{pickUpInfo.address?.PostalCode}} {{pickUpInfo.address?.City}},
			{{pickUpInfo.address?.AddressLine}},
			{{pickUpInfo.address?.Phone?.Number}}
		</div>
	 </div>
	 <div class="grid-item">
		 <ux-ups-pickup-print [treatment]="treatment" [user]="user"></ux-ups-pickup-print>
	</div>
</div>
