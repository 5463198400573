import { Injectable } from "@angular/core";
import { SIMPLE_QUESTIONAIRE } from "@suite/questionaire";
import { QuestionnaireScopeLevel } from "libs/product/src/lib/models/product.model";


@Injectable()
export class AnamneseQuestionaireService {
	/**
	 * SHORT = eingeschränkte Fragen Version 1
	 * FULL = volle Questionaires Version 1 (professional, lingual)
	 * ABBREVIATED = professional lite Version 2
	 */

	levels = {
		[QuestionnaireScopeLevel.SHORT] : {
			excludesFromFull : ['overjet', 'overbite', 'okklusion', 'middleline'],
			excludesFromSimple:  ['distalisation_ok', 'distalisation_uk', 'classcorrection', 'konstriktion', 'middleline_ok', 'middleline_uk' ]
		},
		[QuestionnaireScopeLevel.ABBREVIATED] : {
			excludesFromFull : ['okklusion'],
			excludesFromSimple:  ['distalisation_ok', 'distalisation_uk', 'classcorrection']
		}
	};

	constructor() {}

    getSimpleQuestions(level:QuestionnaireScopeLevel) {

		if (level === QuestionnaireScopeLevel.COMPLETE) {
			return SIMPLE_QUESTIONAIRE;
		}

		if (this.levels[level] && this.levels[level].excludesFromSimple ) {
			return {
				...SIMPLE_QUESTIONAIRE,
				radios: SIMPLE_QUESTIONAIRE.radios.filter(radio => !this.levels[level].excludesFromSimple.includes(radio.name))
			};
		}

		throw new Error('invalid questionaire level');
	}
}
