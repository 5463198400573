import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MessageModel } from '../models/message.model';
import { MessagesActions, MessagesActionTypes } from './messages.actions';
import {createFeatureSelector} from '@ngrx/store';



export interface MessagesState extends EntityState<MessageModel> {
  // additional entities state properties
}
export function sortByCreated(m1: MessageModel, m2: MessageModel): number {
	return +new Date(m2.created) - +new Date(m1.created);
}
export const messagesAdapter: EntityAdapter<MessageModel> = createEntityAdapter<MessageModel>(
	{
		selectId: (message: MessageModel) => message._id,
		sortComparer: sortByCreated
	}
);

export const initialMessagesState: MessagesState = messagesAdapter.getInitialState({
  // additional entity state properties
});

export const selectMessagesState = createFeatureSelector<MessagesState>('messages');

export const {
	selectIds: selectMessagesIds,
	selectEntities: selectMessagesEntities,
	selectAll: selectMessagesAll,
	selectTotal: selectMessagesTotal,
} = messagesAdapter.getSelectors(selectMessagesState);

export function reducer(
  state = initialMessagesState,
  action: MessagesActions
): MessagesState {
  switch (action.type) {
    case MessagesActionTypes.AddMessage: {
      return messagesAdapter.addOne(action.payload.message, state);
    }

    case MessagesActionTypes.UpsertMessage: {
      return messagesAdapter.upsertOne(action.payload.message, state);
    }

    case MessagesActionTypes.AddMessages: {
      return messagesAdapter.addMany(action.payload.messages, state);
    }

    case MessagesActionTypes.UpsertMessages: {
      return messagesAdapter.upsertMany(action.payload.messages, state);
    }

    case MessagesActionTypes.UpdateMessage: {
      return messagesAdapter.updateOne(action.payload.message, state);
    }

    case MessagesActionTypes.UpdateMessages: {
      return messagesAdapter.updateMany(action.payload.messages, state);
    }

    case MessagesActionTypes.DeleteMessage: {
      return messagesAdapter.removeOne(action.payload.id, state);
    }

    case MessagesActionTypes.DeleteMessages: {
      return messagesAdapter.removeMany(action.payload.ids, state);
    }

    case MessagesActionTypes.LoadMessages: {
      return messagesAdapter.setAll(action.payload.messages, state);
    }

    case MessagesActionTypes.ClearMessages: {
      return messagesAdapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}
