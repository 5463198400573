	<section>
			<div class="patient">
				<img *ngIf="editPatientForm.value.image" src="{{editPatientForm.value.image}}"/>
				<img *ngIf="!editPatientForm.value.image"
					 src="assets/img/patient-placeholder-300x300.jpg"/>
				<!--<button mat-raised-button color="primary" class="button"-->
						<!--(click)="onNext('upload')">-->
					<!--{{'Upload Picture' | translate}}-->
				<!--</button>-->
			</div>
		<div class="patientData">
			<form
				#formRef="ngForm"
				[formGroup]="editPatientForm"
				(ngSubmit)="onNext(ACTION.SUBMIT)">

				<mat-form-field hideRequiredMarker="true">
					<mat-label>{{'Patient ID' | translate}}</mat-label>
					<input type="text" matInput #patientId
						   placeholder="{{('Patient ID' | translate)}}"
						   required
						   maxlength="{{maxCharacters}}"
						   formControlName="patientId"
						   (blur)="validatePatientId()">
					<mat-error *ngIf="patientIdError">{{patientIdError}}</mat-error>
					<mat-error *ngIf="editPatientForm.get('patientId').errors?.invalidCharacters">{{"invalidInputCharacter" | translate}}</mat-error>
				</mat-form-field>

				<mat-form-field hideRequiredMarker="true">
					<mat-label>{{'Firstname' | translate}}</mat-label>
					<input type="text" #firstName matInput
						   placeholder="{{'Firstname' | translate}}"
						   required
						   maxlength="{{maxCharacters}}"
						   formControlName="firstName">
					<mat-error *ngIf="editPatientForm.get('firstName').errors?.invalidCharacters">{{"invalidInputCharacter" | translate}}</mat-error>
				</mat-form-field>

				<mat-form-field hideRequiredMarker="true">
					<mat-label>{{'Lastname' | translate}}</mat-label>
					<input type="text" #lastName matInput
						   placeholder="{{'Lastname' | translate}}"
						   required
						   maxlength="{{maxCharacters}}"
						   formControlName="lastName">
					<mat-error *ngIf="editPatientForm.get('lastName').errors?.invalidCharacters">{{"invalidInputCharacter" | translate}}</mat-error>
				</mat-form-field>

				<mat-form-field hideRequiredMarker="true">
					<mat-label>{{'Date of birth' | translate}}</mat-label>
					<input matInput #birthday
						   [matDatepicker]="picker"
						   [min]="minDate"
						   [max]="maxDate"
						   placeholder="{{'Date of birth' | translate}}"
						   required
						   formControlName="birthday">
					<mat-datepicker-toggle matSuffix [for]="picker">
						<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
					</mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>


				<button type="submit" [disabled]="!editPatientForm.valid" mat-raised-button
						color="primary">{{'Save Patient' | translate}}
				</button>
			</form>
		</div>
	</section>
