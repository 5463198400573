import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnamneseQuestionaireService} from '@suite/anamnese';
import { AnswerOption, SimpleQuestionnaire, SimpleQuestionnaireTargetLabel } from '@suite/questionaire';
import {ACTION} from '@suite/tm-common';
import { AnamneseQuestionnaireFormData } from './anamnese-questionaire-simple/anamnese-questionaire-simple.component';
import { QuestionnaireScopeLevel } from 'libs/product/src/lib/models/product.model';

@Component({
	selector: 'tm2-anamnese-questionaire',
	templateUrl: './anamnese-questionaire.component.html',
	styleUrls: ['./anamnese-questionaire.component.scss']
})
export class AnamneseQuestionaireComponent implements OnInit {
	@Input() anamnese;
	@Input() level:QuestionnaireScopeLevel;
	@Output() next = new EventEmitter<any>();

	ACTION = ACTION;

	formData: AnamneseQuestionnaireFormData;

	// map values to Names
	mappedQuestionnaireValues : Record<SimpleQuestionnaireTargetLabel, AnswerOption> = {
		 [SimpleQuestionnaireTargetLabel.JA]: AnswerOption.YES,
		 [SimpleQuestionnaireTargetLabel.NEIN]: AnswerOption.NO,
		 [SimpleQuestionnaireTargetLabel.BEI_BEDARF]: AnswerOption.WHEN_NEEDED,
		[SimpleQuestionnaireTargetLabel.EMPTY]: null
	};
	constructor(
		private anamneseQuestionaire: AnamneseQuestionaireService
	) {}

	ngOnInit() {
		const questions: SimpleQuestionnaire = structuredClone(
			this.anamneseQuestionaire.getSimpleQuestions(this.level)
		);

		// Transform the bare set of questions to an empty data model of the form component.
		// I.e. we add the properties that will be capturing the input values (selections and text inputs).
		this.formData = {
			radioItems: questions.radios.map((question) => {
				return {
					...question,
					value: null, // Default selection: None.
					details: "", // Default comment: empty.
				};
			}),
			comment: questions.comment,
		};

		const answers = this.anamnese?.questionairePreparedAnswers;
		if ( answers?.data ){
			this.formData.comment = answers.comment;
			this.formData.radioItems.forEach(item => {
				if( item.pill ){ //determine whether option is UJ or LJ specific (not the best way, but it's accessible)
					const answerItem = answers.data.find( answerItem => item.name.indexOf(answerItem.label) >= 0);
					const data = item.pill === "OK" ? answerItem.ok : answerItem.uk;
					item.value = this.mappedQuestionnaireValues[data.target];
					item.details = data.details;
				}else{
					const answerItem = answers.data.find(answerItem => item.name === answerItem.label );
					item.value = this.mappedQuestionnaireValues[answerItem.ok.target];
					item.details = answerItem.ok.details;
				}
			});
		}
	}

	onAction($event) {
		switch ($event.type) {
			case ACTION.NEXT:
				const payload = structuredClone(this.formData);
				this.next.emit({ type: ACTION.QUESTIONAIRE, payload });
				break;
			case ACTION.BACK:
				this.next.emit({ type: ACTION.BACK });

		}
	}

}
