<ux-grid-header [gridState]="dashboardState"
                [dataSource]="dataSource"
                (stateChange)="onGridStateChange($event)">
</ux-grid-header>

<div class="dashboard-container">

    <mat-table [dataSource]="dataSource" matSort>

        <!-- PatientId Column -->
        <ng-container matColumnDef="visual">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'Patient' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let row" class="patient">
                <img *ngIf="row.image" src="{{row?.image}}"/>
                <img *ngIf="!row.image"
                     src="assets/img/patient-placeholder-300x300.jpg"/>
            </mat-cell>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="patient">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <div>{{row.lastName}}, {{row.firstName}}<br>{{row?.patientId}}</div>
            </mat-cell>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'First Name' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.firstName}}</mat-cell>
        </ng-container>

        <!-- Birthday Column -->
        <ng-container matColumnDef="birthday">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'Birthday' | translate    }}</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.birthday | date:'mediumDate'}}</mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-raised-button color="primary"
                        (click)="selectEntry(row._id)">
                    {{actionLabel | translate}}
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"
                 [ngClass]="{hovered: row.hovered, highlighted: row._id === dashboardState?.id}"
                 (mouseover)="row.hovered = true"
                 (mouseout)="row.hovered = false"></mat-row>
    </mat-table>

</div>

