import { Inject, Injectable } from "@angular/core";
import { TreatmentModel } from "@suite/treatments/models";
import { Product, ProductUrlKey } from "../models/product.model";

// categories / logos
export const DEFAULT_LOGO_ASSET_URL = "assets/img/products/logo-default.png";
export const MEMOTAIN_LOGO_ASSET_URL = "assets/img/products/logo-me.jpg";
export const PRO_LOGO_ASSET_URL = "assets/img/products/logo-pro.png";
export const RETENTION_FOIL_LOGO_ASSET_URL = "assets/img/products/logo-re.jpg";
export const LEGACY_CA_LOGO_ASSET_URL = "assets/img/products/logo-ca_legacy.jpg";
export const LEGACY_ADD_ARTICLES_ASSET_URL = "assets/img/products/logo-ad.jpg";

export const CATEGORY_DATA_VERSION_ASSET_URL = "assets/img/products/ca_daten.png";
export const CATEGORY_SPLINT_VERSION_ASSET_URL = "assets/img/products/ca_schienen.png";

// product images
export const PRO_SMILE_IMAGE_ASSET_URL = "assets/img/products/professional_smile.jpg";
export const PRO_LITE_IMAGE_ASSET_URL = "assets/img/products/professional_lite.png";
export const PRO_FULL_IMAGE_ASSET_URL = "assets/img/products/professional_full.png";
export const MEMOTAIN_IMAGE_ASSET_URL = "assets/img/products/mtVisual.png";
export const RETENTION_FOIL_IMAGE_ASSET_URL = "assets/img/products/logo-re.jpg"; //currently same as the logo

// miscellaneous
export const TOOTHAREA_SMILE_ASSET_URL = "assets/img/products/zahnkranz_smile.jpg";
export const TOOTHAREA_LITE_ASSET_URL = "assets/img/products/zahnkranz_lite.jpg";
export const TOOTHAREA_FULL_ASSET_URL = "assets/img/products/zahnkranz_full.jpg";

export const INDICATION_PRO_SMILE_DE_ASSET_URL = "assets/img/products/indications/de/indikation_de_prof_smile.png";
export const INDICATION_PRO_LITE_DE_ASSET_URL = "assets/img/products/indications/de/indikation_de_prof_lite.png";
export const INDICATION_PRO_FULL_DE_ASSET_URL = "assets/img/products/indications/de/indikation_de_prof_full.png";
export const INDICATION_PRO_SMILE_EN_ASSET_URL = "assets/img/products/indications/en/indikation_en_prof_smile.png";
export const INDICATION_PRO_LITE_EN_ASSET_URL = "assets/img/products/indications/en/indikation_en_prof_lite.png";
export const INDICATION_PRO_FULL_EN_ASSET_URL = "assets/img/products/indications/en/indikation_en_prof_full.png";

@Injectable({
	providedIn: "root"
})
export class ProductAssetsService {

	constructor(@Inject("CONFIG") public config) {
	}

	getProductCategoryImageUrlForTreatment(treatment: TreatmentModel): string {
		return this.getProductCategoryImageUrlForProduct(treatment?.product);
	}

	getProductCategoryImageUrlForProduct(product: Product): string {
		return this.getProductCategoryImageUrl(product?.info?.urlKey as ProductUrlKey);
	}

	/**
	 * Returns the image url of the product category (not the image of the product itself)
	 */
	getProductCategoryImageUrl(urlKey: ProductUrlKey): string {
		switch (urlKey) {
			case ProductUrlKey.MEMOTAIN:
				return MEMOTAIN_LOGO_ASSET_URL;
			case ProductUrlKey.RETENTIONSSCHIENE:
				return RETENTION_FOIL_LOGO_ASSET_URL;
			case ProductUrlKey.PRO_SMILE_DATEN:
			case ProductUrlKey.PRO_SMILE_SCHIENE:
			case ProductUrlKey.PRO_LITE_DATEN:
			case ProductUrlKey.PRO_LITE_SCHIENE:
			case ProductUrlKey.PRO_FULL_DATEN:
			case ProductUrlKey.PRO_FULL_SCHIENE:
				return PRO_LOGO_ASSET_URL;
			case ProductUrlKey.ARTICLES:
				return LEGACY_ADD_ARTICLES_ASSET_URL;
			case ProductUrlKey.LEGACY_CATEGORY_CLEAR_ALIGNER:
			case ProductUrlKey.LEGACY_CATEGORY_CLEAR_ALIGNER_SCHIENE:
			case ProductUrlKey.LEGACY_CATEGORY_CLEAR_ALIGNER_DATEN:
			case ProductUrlKey.LEGACY_CATEGORY_INDIVIDUA:
				return LEGACY_CA_LOGO_ASSET_URL;
			default:
				return DEFAULT_LOGO_ASSET_URL;
		}
	}

	/**
	 * Returns the image url of the product image (not the category/logo)
	 */
	getProductImageUrl(urlKey: ProductUrlKey): string {
		switch(urlKey){
			case ProductUrlKey.MEMOTAIN:
				return MEMOTAIN_IMAGE_ASSET_URL;
			case ProductUrlKey.RETENTIONSSCHIENE:
				return RETENTION_FOIL_IMAGE_ASSET_URL;
			case ProductUrlKey.PRO_SMILE_DATEN:
			case ProductUrlKey.PRO_SMILE_SCHIENE:
				return PRO_SMILE_IMAGE_ASSET_URL;
			case ProductUrlKey.PRO_LITE_DATEN:
			case ProductUrlKey.PRO_LITE_SCHIENE:
				return PRO_LITE_IMAGE_ASSET_URL
			case ProductUrlKey.PRO_FULL_DATEN:
			case ProductUrlKey.PRO_FULL_SCHIENE:
				return PRO_FULL_IMAGE_ASSET_URL;
			case ProductUrlKey.ARTICLES:
				return LEGACY_ADD_ARTICLES_ASSET_URL;
			default:
				return LEGACY_CA_LOGO_ASSET_URL;
		}
	}

	/**
	 * Function that is exclusivly used in the product selection.
	 * Returns the image urls for the different levels.
	 */
	getProductSelectionImageUrl( urlKey: ProductUrlKey): string {
		switch (urlKey){
			//level 1 - Aligner / Memotain / Retentionfoil
			case ProductUrlKey.CATEGORY_ALIGNER:
				return PRO_LOGO_ASSET_URL;
			case ProductUrlKey.MEMOTAIN:
				return MEMOTAIN_LOGO_ASSET_URL;
			case ProductUrlKey.RETENTIONSSCHIENE:
				return RETENTION_FOIL_LOGO_ASSET_URL;
			//level 2 - Data Version / Splint Version
			case ProductUrlKey.CATEGORY_ALIGNER_DATEN:
				return CATEGORY_DATA_VERSION_ASSET_URL;
			case ProductUrlKey.CATEGORY_ALIGNER_SCHIENE:
				return CATEGORY_SPLINT_VERSION_ASSET_URL;
			//level 3 - Smile / Lite / Full
			case ProductUrlKey.PRO_SMILE_SCHIENE:
			case ProductUrlKey.PRO_SMILE_DATEN:
				return PRO_SMILE_IMAGE_ASSET_URL;
			case ProductUrlKey.PRO_LITE_SCHIENE:
			case ProductUrlKey.PRO_LITE_DATEN:
				return PRO_LITE_IMAGE_ASSET_URL;
			case ProductUrlKey.PRO_FULL_SCHIENE:
			case ProductUrlKey.PRO_FULL_DATEN:
				return PRO_FULL_IMAGE_ASSET_URL;
			default:
				return DEFAULT_LOGO_ASSET_URL;
		}
	}

	getToothAreaImageUrl(urlKey: ProductUrlKey): string {
		switch(urlKey) {
			case ProductUrlKey.PRO_SMILE_DATEN:
			case ProductUrlKey.PRO_SMILE_SCHIENE:
				return TOOTHAREA_SMILE_ASSET_URL;
			case ProductUrlKey.PRO_LITE_DATEN:
			case ProductUrlKey.PRO_LITE_SCHIENE:
				return TOOTHAREA_LITE_ASSET_URL;
			case ProductUrlKey.PRO_FULL_DATEN:
			case ProductUrlKey.PRO_FULL_SCHIENE:
				return TOOTHAREA_FULL_ASSET_URL;
		}
	}

	getProductIndicationImageUrl(urlKey: ProductUrlKey): string {
		const locale = this.config.locale;
		switch(urlKey) {
			case ProductUrlKey.PRO_SMILE_DATEN:
			case ProductUrlKey.PRO_SMILE_SCHIENE:
				return locale === 'de' ? INDICATION_PRO_SMILE_DE_ASSET_URL : INDICATION_PRO_SMILE_EN_ASSET_URL;
			case ProductUrlKey.PRO_LITE_DATEN:
			case ProductUrlKey.PRO_LITE_SCHIENE:
				return locale === 'de' ? INDICATION_PRO_LITE_DE_ASSET_URL : INDICATION_PRO_LITE_EN_ASSET_URL;
			case ProductUrlKey.PRO_FULL_DATEN:
			case ProductUrlKey.PRO_FULL_SCHIENE:
				return locale === 'de' ? INDICATION_PRO_FULL_DE_ASSET_URL : INDICATION_PRO_FULL_EN_ASSET_URL;
			default:
				return '';
		}
	}
}
