import {createSelector} from '@ngrx/store';
import {selectAdminEntities} from './admins.reducer';

//XXX both selector are not used currently
export const selectAdmins = createSelector(
	selectAdminEntities,
	adminEntities => Object.values(adminEntities).sort((a, b) => a.firstname.localeCompare(b.firstname))
);

export const selectAdminById = (id: string) => createSelector(
	selectAdminEntities,
	adminEntities => adminEntities[id]
);
