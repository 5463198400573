<div class="upload-wrapper">
	<div *ngIf="showStl">
		<h1>{{'Digitale Kiefer Daten' | translate}}</h1>
		<ux-upload-stl
		[treatment]="treatment"
		[patientId]="patientId"
		(action)="onAction($event)"
		></ux-upload-stl>
	</div>
	<div *ngIf="showAdditionals">
		<h1>{{'Zusätzliche diagnostische Unterlagen' | translate}}</h1>
		<ux-upload-additionals
		[treatment]="treatment"
		[patientId]="patientId"
		(action)="onAction($event)"
		></ux-upload-additionals>
	</div>
</div>
