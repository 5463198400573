import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerService} from './services/customer.service';
import {CustomerAddressesResolver} from './services/customer-addresses.resolver';
import * as fromCustomer from './store/customer.reducer';
import {CustomerEffects} from './store/customer.effects';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('customer', fromCustomer.reducer),
		EffectsModule.forFeature([CustomerEffects])
	],
	declarations: [],
	providers: [
		CustomerService,
		CustomerAddressesResolver
	]
})
export class CustomerModule {
}
