import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@suite/material';
import {AppPatientsModule} from '../patients/patients.module';
import {DashboardComponent} from './dashboard.component';
import {DashboardEntriesComponent} from './dashboard-entries/dashboard-entries.component';
import {UxComponentsModule} from '@suite/ux-components';
import { CaTranslateModule } from '@suite/translate';
import { TmCommonModule } from '@suite/tm-common';


@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		CaTranslateModule,
		AppPatientsModule,
		UxComponentsModule,
		TmCommonModule
	],
	declarations: [
		DashboardComponent,
		DashboardEntriesComponent
	],
	exports: [
		DashboardComponent,
	]
})
export class DashboardModule {
}

