<div class="outerWrapper">
	<section *ngIf="treatment as t">
		<h1>{{'Edit Treatment' | translate}}</h1>

		<div class="noDataTransfer" *ngIf="!dataTransfer">

			<ux-product-visual
				[productKey]="t.product.info.urlKey"
				[productInfo]="t.product.info"
				[options]="t.product.options"
			></ux-product-visual>

			<!-- MAIN AREA -->
			<div class="mainarea">

				<!-- TREATMENT DETAIL HEADER-->
				<ux-treatment-detail-header
					[treatment]="t"
					[patient]="t.patient"
					[user]="user$ | async"
				></ux-treatment-detail-header>

				<div class="treatment-control-buttons">
					<button mat-raised-button color="primary"
						*ngIf="showComplaintButton"
						(click)="onComplaintButtonClicked()">
						{{'Complaint' | translate}}
					</button>

					<button mat-raised-button color="primary"
						(click)="onNotificationsButtonClicked()">
						<i class="fal fa-envelope"></i>
						{{'Notifications' | translate}}
					</button>
					<button mat-raised-button color="primary"
						(click)="onDataExchangeButtonClicked()">
						<i class="fal fa-files"></i>
						{{'Treatment Files' | translate}}
					</button>

					<button *ngIf="showUpsButton"
						mat-raised-button color="primary"
						(click)="onUpsButtonClicked()">
						<i class="fa-brands fa-ups"></i>
						{{'UPS Abholservice' | translate}}
					</button>

					<button *ngIf="showCaViewerButton"
						mat-raised-button color="primary"
						(click)="onCaViewerButtonClicked()">
						{{'Web Viewer CA' | translate}}
					</button>
				</div>

				<ux-webviewer-preview
					*ngIf="getTreatmentWebviewers()?.length > 0"
					[webviewers]="getTreatmentWebviewers()"
				></ux-webviewer-preview>

				<!-- PACKAGES/PROCESSING -->
				<mat-card *ngIf="showPackages">
					<mat-card-content>
						<div class="treatment-title">
							<h3>
								<i class="fas fa-arrow-alt-circle-right"></i>
								<span *ngIf="t.packages.next === 0">{{'Angefragt' | translate}} -</span>{{getAlignerProductName()}}
							</h3>
							<button *ngIf="t.packages.next === 0"
								mat-raised-button color="primary"
								[disabled]="isDownloadingCostEstimatePdf"
								(click)="onCostEstimate()">
								{{'Cost Estimate' | translate}}
							</button>
						</div>
						<ux-treatment-packages class="packages"
										[treatment]="t"
										[packages]="t.packages"
						></ux-treatment-packages>
					</mat-card-content>
				</mat-card>

				<!-- RELEASE DATA -->
				<tm2-treatment-release
					*ngIf="t.schema_version >= 2 && t.status === TreatmentStatus.ReleaseRequested"
					[treatment]="treatment"
					(backButtonClicked)="onBackButtonClicked()"
				></tm2-treatment-release>

				<!-- SPECIAL STATI -->
				<tm2-treatment-special-status
					*ngIf="showSpecialState"
					[status]="t.status"
				></tm2-treatment-special-status>

			</div>

		</div>
		<tm2-treatment-actions
		*ngIf="t.status !== TreatmentStatus.ReleaseRequested"
		class="treatment-actions"
		[nextOpportunities]="nextOpportunities"
		[hideNext]="t.schema_version < 2 || hideNext "
		[treatment]="t"
		[wmsId]="(user$ | async).wmsId"
		(action)="onAction($event)"
		(backButtonClicked)="onBackButtonClicked()"
		></tm2-treatment-actions>


	</section>


</div>



