import { Injectable } from "@angular/core";
import { IoApiProxyService } from "@suite/ioApi";

@Injectable()
export class ForgotPasswordService {
	constructor(private ioApiService: IoApiProxyService) {}

	forgotPassword(email: string, next, error) {
		this.ioApiService.forgotPassword(email).subscribe(
			(data) => {
				if (!data["isvalid"]) {
					return error({ key: "email-unknown" });
				}
				next({ key: "email-send", value: data["email"] });
			},
			(errorData) => {
				console.error("Forgot password error", errorData);
				error({ key: "com-error" });
			}
		);
	}
}
