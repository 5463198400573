import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MessageTypes, MessageTypeIcons} from '@suite/messages';

@Component({
	selector: 'ux-message-types',
	templateUrl: './message-types.component.html',
	styleUrls: ['./message-types.component.scss']
})
export class MessageTypesComponent {
	@Input() messageType: MessageTypes;
	@Output() newMessageType = new EventEmitter<number>();

	MessageTypes = MessageTypes;
	MessageTypeIcons = MessageTypeIcons;

	constructor(@Inject('CONFIG') public config) {
	}

	set newType(type: MessageTypes) {
		this.newMessageType.emit(+type);
	}

}
