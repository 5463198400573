<tm2-patient-details
	*ngIf="action !== ACTION.EDIT"
	[patientTuple]="patientTuple$ | async"
	(next)="onPatientAction($event)"
></tm2-patient-details>
<tm2-patient-edit
	*ngIf="action === ACTION.EDIT"
	[patient]="patient$ | async"
	(next)="onPatientAction($event)"
></tm2-patient-edit>
<ux-next-back-actions
	[hideNext]="true"
	(action)="handleAction({type: ACTION.BACK})"
>
</ux-next-back-actions>
