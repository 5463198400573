import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';

import {first} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {ACTION, AppState} from '@suite/tm-common';
import {
	selectAnamnesePatient,
	ClearAnamneseAction,
	PatientSelectedAction,
	selectAnamneseState
} from '@suite/anamnese';
import {selectIsProfessionalCertified, selectWmsId} from '@suite/auth';
import { DataTransferMethod } from '@suite/treatments/models';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { selectAnamneseTreatment } from 'libs/treatments/src/lib/store';
import { PROFESSIONAL_PRODUCT_KEYS } from 'libs/product/src/lib/models/product.model';
import { ClearEstimatedCosts } from '@suite/order';

export enum AnamneseProcessStep{
	PATIENT_SELECTION = 0,
	PRODUCT_SELECTION = 1,
	SETUP_CHECKOUT = 2,
	TRANSFER_OPTIONAL_UPLOAD = 3, //only used if transfermethod is 'UPS' or 'OTHER'
}
@Component({
	selector: 'tm2-anamnese',
	templateUrl: './anamnese.component.html',
	styleUrls: ['./anamnese.component.scss']
})


export class AnamneseComponent implements OnInit, AfterViewInit, OnDestroy {
	anamnese$;
	categories$;

	treatment$;
	patient$;

	isUserProfessionalCertified$: Observable<boolean>;
	wmsId$: Observable<string>;

	products$;

	AnamneseProcessStep = AnamneseProcessStep;
	DataTransferMethod = DataTransferMethod;

	stepIndex = AnamneseProcessStep.PATIENT_SELECTION;
	isSetupProduct = true;
	dataTransferMethod: DataTransferMethod = DataTransferMethod.UPLOAD;
	anamneseSubscription: Subscription;

	previousProcessStep: AnamneseProcessStep;
	constructor(private store: Store<AppState>,
				private router: Router) {

	}

	ngOnInit() {
		this.anamnese$ = this.store.pipe(select(selectAnamneseState));
		this.treatment$ = this.store.pipe(select(selectAnamneseTreatment));
		this.patient$ = this.store.pipe(select(selectAnamnesePatient));
		this.isUserProfessionalCertified$ = this.store.pipe(select(selectIsProfessionalCertified));
		this.wmsId$ = this.store.pipe(select(selectWmsId));

		this.anamneseSubscription = this.anamnese$.pipe()
			.subscribe(anamnese =>
				this.isSetupProduct = PROFESSIONAL_PRODUCT_KEYS.includes(anamnese?.product?.info?.urlKey));
	}

	ngAfterViewInit() {
		this.dataTransferMethod = DataTransferMethod.UPLOAD; //set upload as default
	}

	ngOnDestroy() {
		this.store.dispatch(new ClearAnamneseAction());
		this.store.dispatch(new ClearEstimatedCosts());
		this.anamneseSubscription.unsubscribe();
	}

	nextStep( anamneseCompleted: boolean = false) {
		if ( anamneseCompleted ) {
			this.treatment$.pipe(first()).subscribe(treatment => {
				// navigate directly to the created treatment
				/*
				Note: 'replaceTrue' is set to true here to avoid returning back to the
						'/anamnese' UI, if the 'back' button is clicked in the treatment component.
						This works, since the '/anamnese' route is currently only called from the
						'/dashboard' state. If the '/anmanese' route is called manually via the
						browser url, the back button can lead to unwanted behaviour.
				*/
				this.router.navigate([`/treatments/edit/${treatment._id}`], {replaceUrl: true});
			})
		} else {
			this.stepIndex += 1;
		}
	}

	// ---------------------------------------------------------
	// ACTION HANDLER of steps
	// ---------------------------------------------------------
	onPatientAction(action) {
		switch (action.type) {
			case ACTION.SELECTED:
				this.store.dispatch(new PatientSelectedAction(action.payload._id));
				this.nextStep();
				break;
			case ACTION.SUBMIT:
				this.store.dispatch(new PatientSelectedAction(action.payload._id));
				this.nextStep();
				break;
		}
	}

	onTreatmentAction(action) {
		switch (action.type) {
			case ACTION.BACK:
				this.previousProcessStep = AnamneseProcessStep.PRODUCT_SELECTION;
				this.stepIndex = AnamneseProcessStep.PATIENT_SELECTION;
				return;
			case ACTION.NEXT:
				this.nextStep();
				return;
		}
	}

	onSetupAction(action) {
		switch (action.type) {
			case ACTION.BACK:
				this.previousProcessStep = AnamneseProcessStep.SETUP_CHECKOUT;
				this.stepIndex = AnamneseProcessStep.PRODUCT_SELECTION;
				break;
			case ACTION.SUBMIT:
				//order treatment button submitted (still in CART UI)
				this.anamnese$.pipe(first()).subscribe( anamnese => {
					this.dataTransferMethod = anamnese.product.options.dataTransfer;
				});
				break;
			case ACTION.NEXT:
				//ordered treatmentdata (from magento if directorder) is fetched
				//set anamnese as completed if transfer method is dataupload
				this.nextStep( this.dataTransferMethod === DataTransferMethod.UPLOAD );
				break;
		}
	}

	onTransferAction(action) {
		switch (action.type) {
			case ACTION.NEXT:
				this.nextStep( true );
				break;
		}
	}

	determineStepIcon(): string {
		switch(this.dataTransferMethod){
			case DataTransferMethod.UPS:
				return 'fa-brands fa-ups';
			case DataTransferMethod.OTHER:
				return 'fal fa-inbox-out';
			case DataTransferMethod.UPLOAD:
				return '';
		}
	}
}
