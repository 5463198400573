import { Inject, Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { select, Store } from "@ngrx/store";
import * as moment from "moment/moment";
import { TranslateService } from "@ngx-translate/core";

import {
	AnamneseState,
	selectAnamneseState
} from "libs/anamnese/src/lib/store/anamnese.reducer";
import {
	CaOpportunity,
	TreatmentModel,
	TreatmentPackages,
	TreatmentStatus,
	TreatmentTypes
} from "../models";
import { OrderModel, OrderStati } from "@suite/tm-common";
import { UpsPickupReference } from "@suite/anamnese";
import { Observable } from "rxjs";
import { ProductInfoForKey, ProductStepLimit, ProductUrlKey, ToothArea } from "libs/product/src/lib/models/product.model";
import { MessageModel } from "libs/messages/src/lib/models/message.model";


type OderModelWithTreatmentData = OrderModel & {
	treatmentId: string,
	patientId: string,
	patientFirstName: string,
	patientLastName: string
};

@Injectable()
export class TreatmentsService {

	anamnese;

	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		private store: Store<AnamneseState>,
		private translate: TranslateService,
		@Inject('CONFIG') private config) {

		this.store.pipe(select(selectAnamneseState))
			.subscribe(ae => this.anamnese = ae);
	}


	getTreatments() {
		return this.http.get(`${this.config.apiUrl}/all-treatments`, {withCredentials: true});
	}

	getTreatment(treatmentId: string){
		return this.http.get(`${this.config.apiUrl}/treatments/${treatmentId}`, {withCredentials: true});
	}

	getTreatmentsAdminPaginated(
		skip: number,
		limit: number,
		productFilter: string,
		textFilter: string,
		statusFilter: string,
		caseWorkerFilter: string,
		fromDateFilter: Date,
		activeSortFilter: string,
		sortDirection: string
		){
		const params = {
			skip: skip,
			limit: limit,
		};

		if(productFilter){
			params["productFilter"] = productFilter;
		}
		if(textFilter){
			params["textFilter"] = textFilter;
		}
		if(statusFilter){
			params["statusFilter"] = statusFilter;
		}
		if(caseWorkerFilter){
			params["caseWorkerFilter"] = caseWorkerFilter;
		}
		if(fromDateFilter){
			params["fromDateFilter"] = moment(fromDateFilter).format('YYYY-MM-DD');
		}
		if(activeSortFilter && sortDirection){
			params["activeSortFilter"] = activeSortFilter;
			params["sortDirection"] = sortDirection;
		}

		const options = {
			params: params,
			withCredentials: true
		};
		return this.http.get( `${this.config.apiUrl}/treatments/admin/paginated`, options);
	}

	getTreatmentsTmOrmco(fromDate?: Date): Observable<{ treatments: TreatmentModel[] }> {
		let params = {};
		if (fromDate) {
			// As it might be a considerable offset to Germany, we remove any timezone offset.
			// Code stolen from https://stackoverflow.com/a/29774197.
			const offset = fromDate.getTimezoneOffset() * 60 * 1000;
			const fromDateNoOffset = new Date(fromDate.getTime() - offset);
			const fromDateParam = fromDateNoOffset.toISOString().split("T")[0];

			params = { from: fromDateParam };
		}

		const options = {
			params: params,
			withCredentials: true
		};

		return this.http.get<{ treatments: TreatmentModel[] }>(`${this.config.apiUrl}/treatments/tmormco`, options);
	}

	createTreatment() {
		const createTreatmentDto = this.buildCreateTreatmentDto();
		return this.http.post(`${this.config.apiUrl}/treatments`, createTreatmentDto, {withCredentials: true});

	}

	updateTreatment(treatmentId:string, diff:Partial<TreatmentModel>): Observable<TreatmentModel> {
		return this.http.put<TreatmentModel>(`${this.config.apiUrl}/treatments/${treatmentId}`, diff, {withCredentials: true}); // XXX should be PATCH
	}

	submitReleaseRequest(treatmentId: string, payload){
		return this.http.post<{treatment: TreatmentModel, message: MessageModel}>(`${this.config.apiUrl}/treatments/${treatmentId}/releaseRequest`, payload, {withCredentials: true});
	}


	////// ^^^^^^  API    ^^^^^ /////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////// vvvvvv  Utils  vvvvv /////////////////////////////////////////////////////////////////////////////////


	calculatePlannedPackages(productKey: ProductUrlKey, totalSteps: number): TreatmentPackages {
		const maxDefaultSteps = this.getProductStepLimits( productKey ).maxStepsPlanning;
		const maxCorrectionSteps = this.getProductStepLimits( productKey ).maxStepsCorrection;
		const correctionStepSize = ProductInfoForKey[productKey].corrections[0]; // 5 or 10

		const overPlanned = totalSteps > maxDefaultSteps ? totalSteps - maxDefaultSteps : 0;

		// get step packages
		const steps = this.getPackageArray(totalSteps, ProductInfoForKey[productKey].steps[0]); // 10 or 20

		// get correction packages
		const freeCorrections = this.getPackageArray(
			overPlanned > 0 ? maxCorrectionSteps - overPlanned : maxCorrectionSteps,
			correctionStepSize);
		const result = {steps, freeCorrections, addCorrections: [], next: 0};
		return result;
	}

	getPackageArray(total, packageSize): number[] {
		const packages: number[] = [];
		// full packages
		for (let i = 0; i < Math.floor(total / packageSize); i++) {
			packages.push(packageSize);
		}
		// partly filled packages
		if (total % packageSize) {
			packages.push(total % packageSize);
		}
		return packages;
	}

	private buildCreateTreatmentDto() {
		const createTreatmentDto = { //see CreateTreatmentDto
			patientId: this.anamnese.patientId,
			productKey: this.anamnese.product.info.urlKey,
			uj: this.anamnese.product.options.uj ? this.anamnese.product.options.uj : ToothArea.NONE,
			lj: this.anamnese.product.options.lj ? this.anamnese.product.options.lj : ToothArea.NONE,
			questionnaire: this.anamnese.questionairePreparedAnswers,
			dataTransfer: this.anamnese.product.options.dataTransfer,
			comment: this.anamnese.comment ? this.anamnese.comment.trim() : null,
			shippingType: this.anamnese.product.handling.shipment.Method,
			shippingAddressCode: !this.anamnese.product.handling.hasUniqueShippingAddress
				? this.anamnese.product.handling.shipping.AddressName
				: undefined,
			country: this.anamnese.product.handling.shipping.Country
		};
		return createTreatmentDto;
	}

	analyseNextOpportunities(treatment: TreatmentModel): CaOpportunity[] {

		if (treatment.type !== TreatmentTypes.TmAnamnese) {
			return [];
		}
		if (treatment.status !== TreatmentStatus.Shipped &&
			treatment.status !== TreatmentStatus.ComplaintResolved ) {
			return [];
		}
		let noOfOrders = 0;
		if(treatment.quotation){
			noOfOrders = treatment.packages.next;
		}else if(treatment.orders){
		treatment.orders.forEach( (order, idx) => {
			if (idx) { // skip first
				if (order.status !== OrderStati.canceled) { // skip canceled
					noOfOrders++;
				}
			}
		});
		}

		// all product steps ordered ???
		if (noOfOrders >= treatment.packages.steps.length) {
			if (noOfOrders >= treatment.packages.steps.length + treatment.packages.freeCorrections.length) {
				return [CaOpportunity.ChargeableCorrection];
			} else {
				return [CaOpportunity.FreeCorrection];
			}
		} else {
			return [CaOpportunity.Package];
		}
	}

	checkPickUpInfo(pr: UpsPickupReference) {

		const UPS_DATE_FORMAT = 'YYYYMMDD';
		const UPS_TIME_FORMAT = 'hhmm';


		let shouldBeReady = false;
		let mustBeReadyInMinutes = 0;


		// get prepared dates
		const ready = moment(
			pr.dateTime.PickupDate + pr.dateTime.ReadyTime,
			UPS_DATE_FORMAT + UPS_TIME_FORMAT);
		const close = moment(
			pr.dateTime.PickupDate + pr.dateTime.CloseTime,
			UPS_DATE_FORMAT + UPS_TIME_FORMAT);

		// is close time over ?
		if (close.diff(moment()) <= 0) {
			return null;
		}

		// is ready time over ?
		if (ready.diff(moment()) <= 0) {
			shouldBeReady = true;
		} else {
			mustBeReadyInMinutes = ready.diff(moment(), 'minutes');
		}

		return {
			shouldBeReady,
			mustBeReady: {
				hours: Math.floor(mustBeReadyInMinutes / 60 || 0),
				minutes: mustBeReadyInMinutes % 60 || 0,
			},
			address: pr.address,
			prn: pr.prn
		};
	}

	hasPendingUpsPickupRequest(treatment: TreatmentModel): boolean {
		let pi;
		if (treatment.quotation?.upsPickUpReference) {
			pi = this.checkPickUpInfo(treatment.quotation.upsPickUpReference);
		} else {
			return false;
		}

		if (!pi || pi.schouldBeReady) {
			return false;
		}

		return true;
	}

	getWebViewerSource(webViewerUrl) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(webViewerUrl);
	}

	getProductStepLimits(urlKey: ProductUrlKey) {
		if( urlKey === ProductUrlKey.PRO_SMILE_DATEN ||
			urlKey === ProductUrlKey.PRO_SMILE_SCHIENE ){
			return {
				maxStepsPlanning: ProductStepLimit.PRO_SMILE_DEFAULT,
				maxStepsCorrection: ProductStepLimit.PRO_SMILE_CORRECTION,
				maxStepsTotal: ProductStepLimit.PRO_SMILE_TOTAL,
			}
		}
		if( urlKey === ProductUrlKey.PRO_LITE_DATEN ||
			urlKey === ProductUrlKey.PRO_LITE_SCHIENE ){
			return {
				maxStepsPlanning: ProductStepLimit.PRO_LITE_DEFAULT,
				maxStepsCorrection: ProductStepLimit.PRO_LITE_CORRECTION,
				maxStepsTotal: ProductStepLimit.PRO_LITE_TOTAL,
			}
		}
		if( urlKey === ProductUrlKey.PRO_FULL_DATEN ||
			urlKey === ProductUrlKey.PRO_FULL_SCHIENE ){
			return {
				maxStepsPlanning: ProductStepLimit.PRO_FULL_DEFAULT,
				maxStepsCorrection: ProductStepLimit.PRO_FULL_CORRECTION,
				maxStepsTotal: ProductStepLimit.PRO_FULL_TOTAL,
			}
		}
	}
}
