<section>
	<form
		#formRef="ngForm"
		[formGroup]="signinForm"
		class="animated"
		[ngClass]="{'shake': formAnimationState===1,
		            'pulse': formAnimationState===2, 'bounceOutDown': formAnimationState===3 }"
		(ngSubmit)="onSubmit()">

		<h1>{{config.appTitle1}}<br>{{config.appTitle2}}</h1>


		<mat-form-field appearance="fill" hideRequiredMarker="true">
			<mat-label>{{(config.app === 'tm2' ? 'Username' : 'Administrator-Name') | translate}}</mat-label>
			<input matInput type="email" #username autocomplete="username"
				   required
				   formControlName="username"> <!-- XXX Could also be an admin username.  -->

		</mat-form-field>

		<mat-form-field hideRequiredMarker="true">
			<mat-label>{{'Password' | translate}}</mat-label>
			<input type="password" #pwd matInput autocomplete="current-password"
				   required
				   formControlName="pwd">
		</mat-form-field>


		<div class="options">
			<span class="forgot-password"
				  (click)="forgotPassword(username.value)">
				{{'Forgot Password' | translate}}?
			</span>

			<i class="fa fa-circle"></i>

			<a class="signup" href="{{registrationLink}}">
				{{'Register' | translate}}!
			</a>
		</div>

		<mat-error *ngIf="usernameError">{{usernameError}}</mat-error>

		<button type="submit" mat-raised-button color="primary">LOGIN</button>

	</form>
</section>

