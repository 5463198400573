export enum ACTION {
	BACK,
	CREATE,
	COMMENTS,
	EDIT,
	EDIT_PATIENT,
	EDIT_TREATMENT,
	ENABLE_NEXT,
	DETAIL,
	INITIAL,
	NEXT,
	OPTIONS,
	SELECT,
	SELECTED,
	SETUP,
	PRODUCT_SELECTED,
	QUESTIONAIRE,
	SUBMIT,
	UPLOAD,
	UPLOADED,
	DISABLE_PREVIOUS_STEPS,
	DELETED
}
