import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@ngx-translate/core';

import {TreatmentsResolver, TreatmentsService, TreatmentBaseService, TreatmentResolver} from './services';
import {TreatmentEffects} from './store';
import * as fromTreatment from './store/treatment.reducer';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		StoreModule.forFeature('treatment', fromTreatment.reducer),
		EffectsModule.forFeature([TreatmentEffects])
	],
	declarations: [
	],
	providers: [
		TreatmentsService,
		TreatmentBaseService,
		TreatmentsResolver,
		TreatmentResolver,
	],
	exports: [
	]
})
export class CaTreatmentsModule {
}
