
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {MagentoActionTypes, StoererSeenAction} from './magento.actions';
import * as moment from 'moment';

@Injectable()
export class MagentoEffects {

	stoerer$ = createEffect(() => this.actions$.pipe(
		ofType<StoererSeenAction>(MagentoActionTypes.StoererSeen),
		tap(() => localStorage.setItem( 'lastStoererSeen', moment().format() ) )
	), {dispatch: false});

	constructor(private actions$: Actions) {
	}
}
