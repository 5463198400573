import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';

import {selectUser} from '@suite/auth';
import {ACTION, AppState, NotificationService} from '@suite/tm-common';
import {selectCurrentTreatment, NewPickUpReference} from '@suite/treatments';
import {selectShippingAddresses} from '@suite/customer';
import {UpsService, UpsFormFieldsService} from '@suite/ups';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
	selector: 'ux-pickup-container',
	templateUrl: './ups-pickup-container.component.html',
	styleUrls: ['./ups-pickup-container.component.scss']
})
export class UpsPickupContainerComponent implements OnInit, OnDestroy {

	@Output() action = new EventEmitter<any>();
	@Input() wmsId: string;
	@Input() treatmentId; // treatmentId

	shippingAddresses$ = this.store.pipe( select (selectShippingAddresses) );
	user$ = this.store.pipe( select(selectUser) );
	treatment$ = this.store.pipe( select(selectCurrentTreatment) );

	formFields: FormlyFieldConfig[];
	customerContext = {
		CustomerContext: 'wmsId(n/a),treatmentId(n/a)'
	};
	response;
	prn = null;

	translationsSubscription$;
	loading = false;

	constructor(private translate: TranslateService,
				 private upsService: UpsService,
				 public store: Store<AppState>,
				 private notification: NotificationService,
				 private upsFormFieldsService: UpsFormFieldsService) {
	}

	ngOnInit() {

		// initialization
		this.formFields = this.upsFormFieldsService.getFormFields();

		// handle language change in formly
		this.translationsSubscription$ =
			this.translate.onLangChange.subscribe(e => {
				this.formFields = this.upsFormFieldsService.getFormFields();
			});

		// initialize CustomerContext
		this.customerContext.CustomerContext = this.wmsId + ',' + `${this.treatmentId}`.substring(0, 8);
	}

	ngOnDestroy() {
		this.translationsSubscription$.unsubscribe();
	}

	onAction(action) {
		switch (action.type) {
			case ACTION.SUBMIT:
				this.orderPickupService(action.payload, this.customerContext);
				break;
			default:
				break;
		}
	}

	orderPickupService(payload, context) {
		this.loading = true;
		this.upsService
			.pickupRequest(payload.address, payload.dateTimeInfo, context, payload.specialInstruction)
			.pipe(
				map( res => res)
			)
			.subscribe({
				next: res => {
					this.loading = false;
					this.response = res;
					this.prn =  res['PickupCreationResponse'].PRN;
					this.store.dispatch( new NewPickUpReference(
						{
							prn: this.prn,
							address: payload.address,
							dateTime: payload.dateTimeInfo
						})
					);
					this.action.emit({type: ACTION.ENABLE_NEXT, payload: 'Next'});
				},
				error: error => {
					console.error(error);
					this.loading = false;
					this.notification.showErrorMessage("UPS.pickupRequestError", "", 5000);
				}
			});
	}

}
