import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {select, Store} from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';


import {selectUser} from '@suite/auth';
import {Anamnese} from '@suite/anamnese';
import {ClearMedias, LoadMedias, MediaService, selectMediaSorted} from '@suite/media';
import {NotificationService, selectIdFromUrl, TreatmentModel, TreatmentStatusToStringPipe, User} from '@suite/tm-common';
import {CaOpportunity, InterimScanOption, OrderTreatmentFollower, selectTreatmentFromUrl, SubmitComplaint, SubmitComplaintSuccess, TreatmentActionTypes, TreatmentSelected, TreatmentsService, TreatmentState, TreatmentTypes} from '@suite/treatments';
import {CaWebviewerComponent, ConfirmDialogComponent, TreatmentMediaComponent, TreatmentMessagesComponent, UpsPickupContainerComponent} from '@suite/ux-components';
import {Observable, Subscription} from 'rxjs';

import { FollowupPackageType } from './treatment-next-steps/treatment-next-steps.component';
import {Location} from '@angular/common';
import { DataTransferMethod, TreatmentStatus, Webviewer, WebviewerVisibilityScope } from '@suite/treatments/models';
import { ActivatedRoute } from '@angular/router';
import { ProductInfoForKey, ProductUrlKey } from 'libs/product/src/lib/models/product.model';
import { ComplaintsMailToService } from './complaints-mail-to.service';
import { SapService } from 'libs/sap/src/lib/services/sap.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageModel, MessageScopes, MessageTypes, SendersTypes } from 'libs/messages/src/lib/models/message.model';
import { AddMessageOnDb } from 'libs/messages/src/lib/store/messages.actions';

/**
 * TODO: Check for which states the input should be disabled
 * In these status, customer inputs, i.e. file uploads and messaging, should be disabled.
 * These are specified by process management.
 */
const INPUT_DISABLED_STATUS_LIST: TreatmentStatus[] = [
	TreatmentStatus.InProduction,
	TreatmentStatus.Planning,
	TreatmentStatus.CanceledByCustomer,
	TreatmentStatus.ConsultationComplete,
	TreatmentStatus.ComplaintResolved,
	TreatmentStatus.Closed,
	TreatmentStatus.Shipped,
];

@Component({
    selector: 'tm2-treatments',
    templateUrl: './treatments.component.html',
    styleUrls: ['./_treatments.component.scss']
})

export class TreatmentsComponent implements OnInit, OnDestroy, AfterViewInit {
    treatment$: Observable<TreatmentModel>;
    user$: Observable<User>;

    treatment: TreatmentModel;
    nextOpportunities: CaOpportunity[];

	showComplaintButton: boolean = false;
	showUpsButton: boolean = false;
	showCaViewerButton: boolean = false;

    showPackages: boolean;
    showSpecialState: boolean;
    hideNext: boolean;

    TreatmentStatus = TreatmentStatus;
    TreatmentTypes = TreatmentTypes;
	selectedTreatmentIdSubscription: Subscription;
	treamentSubscription: Subscription;
	showCaViewerButtonSubscription: Subscription;
	fileEventSubscription: Subscription;

	isDownloadingCostEstimatePdf: boolean = false;

	constructor(
		private readonly dialog: MatDialog,
		private readonly treatmentService: TreatmentsService,
		private readonly mediaService: MediaService,
		private readonly sapService: SapService,
		private readonly location: Location,
		private readonly store: Store<TreatmentState>,
		private readonly actions$: Actions,
		private readonly activeRoute: ActivatedRoute,
		private readonly complaintsMailToService: ComplaintsMailToService,
		private readonly treatmentStatusPipe: TreatmentStatusToStringPipe,
		private readonly notificationService: NotificationService,
		private readonly translationService: TranslateService
	) { }

    ngOnInit() {
		this.treatment$ = this.store.pipe(
            select(selectTreatmentFromUrl),
            filter((t: TreatmentModel) => !!t)
        );

		this.activeRoute.data.subscribe(data =>
			this.store.dispatch(new LoadMedias({medias: data.medias}))
		);

		this.user$ = this.store.pipe(select(selectUser));

		 // save treatmentId from url as selected treatment in store
		this.selectedTreatmentIdSubscription =  this.store.pipe(select(selectIdFromUrl))
		  	.subscribe( treatmentId => this.store.dispatch(new TreatmentSelected(treatmentId)) );


        this.treamentSubscription = this.treatment$.pipe(
            tap(t => console.log('Changed treatment', t)),
            // prepare visibilty flags
            tap(t => this.showPackages = this.determinePackageVisibiilty(t)),
            tap(t => this.showSpecialState =
                (t.type === TreatmentTypes.TmAnamnese && t.status === TreatmentStatus.NewRequestReceived) ||
                (t.type === TreatmentTypes.TmAnamnese && t.status === TreatmentStatus.NewImprintsRequested) ||
                (t.type === TreatmentTypes.TmAnamnese && t.status === TreatmentStatus.DataReceived) ||
                (t.type === TreatmentTypes.TmAnamnese && t.status === TreatmentStatus.CorrectionRequestReceived) ||
                (t.type === TreatmentTypes.TmAnamnese && t.status === TreatmentStatus.CanceledByCustomer) ||
                (t.type !== TreatmentTypes.TmAnamnese && t.status === TreatmentStatus.Shipped)
            ),
            tap(t => this.hideNext = !(t.type === TreatmentTypes.TmAnamnese
				&& (t.status === TreatmentStatus.Shipped || t.status === TreatmentStatus.ComplaintResolved ))),
			// complaint button
			tap(t => this.showComplaintButton =
				(  t.status === TreatmentStatus.Shipped
				|| t.status === TreatmentStatus.ComplaintResolved )
				),
			//ups button
			tap( t => this.showUpsButton =
                 	t.product?.options?.dataTransfer === DataTransferMethod.UPS
					&& t.status === TreatmentStatus.NewImprintsRequested
					&& !this.treatmentService.hasPendingUpsPickupRequest(t)
            ),
			//ca-viewer button
			tap ( t => {
				if ( t.status !== TreatmentStatus.ReleaseRequested)
				{
					const anamnese: Anamnese = t.quotation?.anamnese;
					const webViewerUrl = anamnese?.releaseData?.release?.webViewerUrl;
					if (webViewerUrl) {
						//if webviewer url contains 'ca-viewer', it is a ca-webviewer link
						const urlParts = webViewerUrl.split('/');
						if (urlParts.includes('ca-viewer')) {
							this.showCaViewerButton = true;
						}
					}
				}
			}),
            // get treatment
            tap((t: TreatmentModel) => this.treatment = structuredClone(t)),

            // determine product state
            tap((t: TreatmentModel) => this.nextOpportunities = this.treatmentService.analyseNextOpportunities(t))
        )
            .subscribe();

		this.fileEventSubscription = this.sapService.fileEvent.subscribe(
			(fileDownloadSuccess: boolean) => {
				this.isDownloadingCostEstimatePdf = false;
				if (!fileDownloadSuccess) {
					this.notificationService.showErrorMessage(
						this.translationService.instant("CostEstimateDownloadError"),
						null,
						5000
					);
				}
			}
		);
    }

	determinePackageVisibiilty(treatment: TreatmentModel): boolean {
		if (treatment.type !== TreatmentTypes.TmAnamnese ){
			return false;
		}
		if (!treatment.packages){
			return false;
		}
		if ( treatment.status === TreatmentStatus.NewRequestReceived ||
			 treatment.status === TreatmentStatus.CorrectionRequestReceived ){
			return false;
		}
		if ( treatment.status === TreatmentStatus.DataReceived ||
			 treatment.status === TreatmentStatus.Planning ){
			  return treatment.packages.next !== 0; //only if not ordered a single package yet
	    }
		//if not ordered a single package yet, only show on ReleaseRequested
		if ( treatment.packages.next === 0 ){
			return treatment.status === TreatmentStatus.ReleaseRequested;
		}
		return true;
	}

    ngAfterViewInit() {
		/** ca webviewer **/
        // if there is a json file and & status is not Release requested => show ca webviewer button
        this.showCaViewerButtonSubscription =  this.store.pipe(
			select(selectMediaSorted),
			map(media => media.filter(medium => medium.filetype.split('.')[1] === 'json')), //XXX seems to be broken since filetype is 'json'
            filter(jsonMedia => !!jsonMedia.length),
            switchMap(() => this.treatment$),
            filter(treatment => treatment.status !== TreatmentStatus.ReleaseRequested)
        ).subscribe(() => {
			this.showCaViewerButton = true;
		});
    }

    ngOnDestroy() {
		this.selectedTreatmentIdSubscription.unsubscribe();
		this.treamentSubscription.unsubscribe();
		this.showCaViewerButtonSubscription.unsubscribe();
		this.fileEventSubscription.unsubscribe();
		this.showUpsButton = false;
		this.showCaViewerButton = false;
		this.store.dispatch(new ClearMedias());
    }

	onAction(action: { type: FollowupPackageType, dataTransferMethod: DataTransferMethod, uploadedMediaIds?: string[] }) {
		if (action.type === FollowupPackageType.NEXT) {
			this.handleNextWithoutImprintAction();
		} else if (action.type === FollowupPackageType.NEXT_WITH_IMPRINT_DATA) {
			this.handleNextWithImprintAction(action.uploadedMediaIds);
		} else if (action.type === FollowupPackageType.CORRECTION) {
			this.handleCorrectionAction(action.uploadedMediaIds);
		}
	}

	private handleNextWithoutImprintAction() {
		if (!this.nextOpportunities.includes(CaOpportunity.Package)) {
			throw (new Error('got next in action follower order, but no package opportunity'));
		}

		this.hideNext = true;
		this.store.dispatch(new OrderTreatmentFollower({ treatmentId: this.treatment._id, packageType: CaOpportunity.Package, interimScanOption: InterimScanOption.WITHOUT_DATA }));
	}

	private handleNextWithImprintAction(uploadedMediaIds?: string[]) {
		if (!this.nextOpportunities.includes(CaOpportunity.Package) && !this.nextOpportunities.includes(CaOpportunity.FreeCorrection)) {
			throw (new Error('next follower order, but neither package nor free correction opportunity'));
		}

		this.hideNext = true;
		const orderPackageType = this.nextOpportunities.includes(CaOpportunity.Package) ? CaOpportunity.Package : CaOpportunity.FreeCorrection;
		this.store.dispatch(new OrderTreatmentFollower({ treatmentId: this.treatment._id, packageType: orderPackageType, interimScanOption: InterimScanOption.WITH_DATA }));
		this.updateMediaFiles(uploadedMediaIds);
	}

	private handleCorrectionAction(uploadedMediaIds?: string[]) {
		this.hideNext = true;
		this.store.dispatch(new OrderTreatmentFollower({ treatmentId: this.treatment._id, packageType: CaOpportunity.ChargeableCorrection, interimScanOption: InterimScanOption.WITH_DATA }));
		this.updateMediaFiles(uploadedMediaIds);
	}

	updateMediaFiles(uploadedMediaIds: string[]) {
		if (uploadedMediaIds?.length === 0){
			return;
		}

		const requestBody = {
			treatment: this.treatment._id,
			expiresAt: null
		}

		// update uploaded files with treatmentId and reset 'expiresAt' date
		uploadedMediaIds.forEach(mediaId => {
			if ( mediaId ){
				this.mediaService.updateMedium(mediaId, requestBody);
			}
		});
	}

    onCostEstimate() {
		this.isDownloadingCostEstimatePdf = true;
		const docNum = this.treatment.quotation.docNum;
		this.sapService.getCostEstimatePdf(this.treatment._id, docNum);
    }

	onNotificationsButtonClicked() {
		this.showDialog(TreatmentMessagesComponent, { isInputEnabled: this.isMessageInputEnabled(this.treatment) });
	}

	onDataExchangeButtonClicked() {
		this.showDialog(TreatmentMediaComponent, { isInputEnabled: this.isInputEnabled(this.treatment) });
	}

	onUpsButtonClicked()
	{
		this.showDialog(UpsPickupContainerComponent);
	}

	onCaViewerButtonClicked()
	{
		this.showDialog(CaWebviewerComponent);
	}

	private showDialog(component, data: any = {}) {
		this.dialog.open(component, { width: '75vw', maxHeight: '95vh', data });
	}

	getAlignerProductName() : string
	{
		return ProductInfoForKey[this.treatment.product.info.urlKey as ProductUrlKey]?.name;
	}

	onBackButtonClicked(){
		this.location.back();
	}

	onComplaintButtonClicked() {
		const dialogData = {
			title: "ComplaintConfirmDialogTitle",
			msg: "ComplaintConfirmDialogMessage",
			no: "No",
			yes: "Yes",
		};
		const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });
		dialogRef.afterClosed().pipe(filter(result => (result === "yes"))).subscribe(() => {
			const oldStatus = this.treatment.status;
			this.actions$.pipe(
				ofType<SubmitComplaintSuccess>(TreatmentActionTypes.SubmitComplaintSuccess),
				first()
			).subscribe(({ payload }) => {
				// XXX Complaints could be submitted for legacy treatments w/o quotation.
				const orderRefNumber = (payload.treatment.schema_version >= 2)
					? payload.treatment.quotation.docNum
					: payload.treatment.orders.at(-1).realId;
				this.complaintsMailToService.openComplaintMail(
					orderRefNumber,
					payload.treatment.patient.patientId
				);
				// Add status change message to event log
				const newStatus = TreatmentStatus.ComplaintReceived;
				const content = `Treatment-Status von
					[${this.treatmentStatusPipe.transform(oldStatus)}] auf
					[${this.treatmentStatusPipe.transform(newStatus)}] geändert.`;
				const message: MessageModel = {
					message: content,
					scope: MessageScopes.Intern,
					sendersType: SendersTypes.System,
					type: MessageTypes.StatusChange,
					context: {
						id: this.treatment._id,
						status: newStatus
					}
				};
				this.store.dispatch(new AddMessageOnDb({ message }));
			});
			this.store.dispatch(new SubmitComplaint({ treatment: this.treatment }));
		});
	}

	private isModelTransferMethod(transferMethod: DataTransferMethod): boolean {
		return [DataTransferMethod.UPS, DataTransferMethod.OTHER].includes(transferMethod);
	}

	private isUploadTransferMethod(transferMethod: DataTransferMethod): boolean {
		return transferMethod === DataTransferMethod.UPLOAD;
	}

	private isInputEnabled(treatment: TreatmentModel): boolean{
		return !(INPUT_DISABLED_STATUS_LIST.includes(treatment.status as TreatmentStatus));
	}

	private isMessageInputEnabled(treatment: TreatmentModel): boolean {
		return this.isInputEnabled(treatment)
			&& treatment.status !== TreatmentStatus.ComplaintReceived; // XXX Complaints are handled entirely within CRM workflows.
	}

	getTreatmentWebviewers() : Webviewer[] {
		return this.treatment.webviewers?.filter((webviewer) => webviewer.visibilityScope ? webviewer.visibilityScope === WebviewerVisibilityScope.PUBLIC : true);
	}
}
