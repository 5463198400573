import {Component, EventEmitter, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';

import {ACTION, AppState, BackAction, LoggerService, PatientModel} from '@suite/tm-common';
import {selectPatients, PatientsService} from '@suite/patients';
import {DashboardState, DashboardStates} from '@suite/treatments';


@Component({
	selector: 'tm2-anamnese-patient',
	templateUrl: './anamnese-patient.component.html',
	styleUrls: ['./anamnese-patient.component.scss']
})
export class AnamnesePatientComponent {

	@Output() next = new EventEmitter<any>();

	patient$:  Observable<PatientModel>;
	patients$: Observable<PatientModel[]>;

ACTION = ACTION;
	action = ACTION.INITIAL;

	dashboardStates: DashboardStates;

	defaultState: DashboardState = {
		id: '',
		filter: '',
		direction: '',
		active: '',
		pageSize: 6,
		pageIndex: 0
	};
	dashboardState: DashboardState = this.defaultState;

	constructor(private patientsService: PatientsService, private store: Store<AppState>, private logger: LoggerService) {

	}

	onAction(action): void {
		switch (action.type) {
			case ACTION.BACK:
				if (this.action === ACTION.INITIAL) {
					this.store.dispatch( new BackAction() );
				} else {
					this.action = ACTION.INITIAL;
				}
				break;
		}
	}
	onNewState(action): void {

		switch (action.type) {
			case ACTION.CREATE:
				this.patient$ = of(<PatientModel>{});
				this.action = ACTION.CREATE;
				this.next.emit(action);
				break;
			case ACTION.EDIT:
				this.action = ACTION.EDIT;
				break;
			case ACTION.SELECT:
				// get patients from store
				this.patients$ = this.store.pipe( select(selectPatients) );
				this.action = ACTION.SELECT;
				break;

			case ACTION.UPLOAD:
				// potential enhancement: upload for patient picture, not needed for Ver. 1
				action.type = ACTION.CREATE;
				break;

			case ACTION.SUBMIT:

				this.patientsService.savePatient(action.payload)
					.subscribe( patient => {
						action.payload = patient;
						this.next.emit(action);
						this.action = ACTION.EDIT;
					});
				break;

			case ACTION.SELECTED:
				this.next.emit(action);
				break;
			default:
		}

	}
	onStateChange(tab, newState) {
	}

	setFilter(filter) {
		this.dashboardState = {...this.dashboardState, filter};
	}
}
