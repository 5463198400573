import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromAnamnese from './store/anamnese.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AnamneseEffects} from './store';
import {AnamneseQuestionaireService} from './services/anamnese-questionaire.service';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('anamnese', fromAnamnese.reducer),
		EffectsModule.forFeature([AnamneseEffects])
	],
	providers: [
		AnamneseQuestionaireService,
	]
})
export class AnamneseModule {
}
