/**
 * patient.component
 * smartcomponent to provide patient data via route param
 *
 */
import {Component, OnInit} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {ACTION, AppState, BackAction, GoAction, PatientModel, TreatmentModel } from '@suite/tm-common';

import {selectPatientWithTreatments, selectPatientAction, PatientsService} from '@suite/patients';
import { selectPatientById } from '@suite/patients';

@Component({
	selector: 'tm2-patient',
	templateUrl: './patient.component.html',
	styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {
	patientTuple$: Observable<(PatientModel | (TreatmentModel[]))[]>;
	patient$;
	action;
	ACTION = ACTION;

	constructor(private patientsService: PatientsService,
				 public store: Store<AppState>) {

	}


	ngOnInit() {

		this.store.pipe (
			select(selectPatientAction),
		).subscribe( data => this.handleAction({type: data.action, payload: data.patientId}) 	);
	}

	handleAction(action) {
		this.action = action.type;
		switch (action.type) {
			case ACTION.EDIT:
				this.patient$ = this.store.pipe(select(selectPatientById(action.payload)));
				break;
			case ACTION.BACK:
				this.store.dispatch( new BackAction() );
				break;
			default:
				// treat action.type as payload if action not specified (shortcut url: patient/PATIENT_ID instead of patient/detail/PATIENT_ID)
				this.patientTuple$ = this.store.pipe( select(selectPatientWithTreatments) );
					 // this.patientsService.resolvePatientWithTreatments(action.payload ? action.payload : action.type);

		}
	}

	onPatientAction(action) {

		if (action.type === ACTION.EDIT_TREATMENT) {
			this.store.dispatch(
				new GoAction( { path: [`treatments/edit/${action.payload}`] }));
		}

		if (action.type === ACTION.EDIT_PATIENT) {
			this.store.dispatch(
				new GoAction( { path: [`patient/edit/${action.payload}`]}));
		}

		if (action.type === ACTION.SUBMIT) {
			this.patientsService.updatePatient(action.payload).subscribe();
			this.store.dispatch(
				new GoAction( { path: ['dashboard/patients']}));
		}
	}

}
