import { Pipe, PipeTransform } from '@angular/core';
import { TreatmentStatus } from 'libs/treatments/src/lib/models/treatment.model';

@Pipe({
  name: 'statusToString'
})
export class TreatmentStatusToStringPipe implements PipeTransform {
  transform(value: TreatmentStatus, isCustomerFrontend: boolean = false): string {
    switch (value) {
      case TreatmentStatus.NewRequestReceived:
		return 'Anfrage eingegangen'
      case TreatmentStatus.Planning:
        return 'Planung';
      case TreatmentStatus.DataReceived:
        return 'Daten vorhanden';
      case TreatmentStatus.RescanImprintsRequired:
        return isCustomerFrontend ? 'Modelle angekommen': 'Modelle erneut scannen';
      case TreatmentStatus.NewImprintsRequested:
        return 'Modelle erwartet';
      case TreatmentStatus.NewDataRequested:
        return 'Neue Daten benötigt';
      case TreatmentStatus.ConsultationRequired:
        return isCustomerFrontend ? "In Bearbeitung" : 'Rücksprache erforderlich';
      case TreatmentStatus.Shipped:
        return 'Auftrag versendet';
      case TreatmentStatus.CanceledByCustomer:
        return 'Storniert';
      case TreatmentStatus.CreditMemoIssued:
        return 'Gutschrift';
      case TreatmentStatus.Postponed:
        return 'Zurückgestellt';
      case TreatmentStatus.ConsultationComplete:
        return 'Rücksprache erledigt';
      case TreatmentStatus.InProduction:
        return 'Produktion';
      case TreatmentStatus.ReleaseRequested:
        return 'Freigabe angefordert';
      case TreatmentStatus.CorrectionRequestReceived:
        return 'Korrektur angefordert';
      case TreatmentStatus.Closed:
        return 'Closed';
	  case TreatmentStatus.RequestRejected:
        return 'Absage';
      case TreatmentStatus.ComplaintReceived:
        return 'Reklamation eingereicht';
	  case TreatmentStatus.ComplaintResolved:
        return 'Reklamation geklärt';
      default:
        return '';
    }
  }
}
