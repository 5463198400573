import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ACTION } from "@suite/tm-common";
import {
	DATEN_VARIANTE,
	PROFESSIONAL_PRODUCT_KEYS,
	ProductInfo,
	ProductInfoForKey,
	ProductInfoMagento,
	ProductUrlKey,
	SCHIENEN_VARIANTE
} from "libs/product/src/lib/models/product.model";
import {
	CATEGORY_DATA_VERSION_ASSET_URL,
	CATEGORY_SPLINT_VERSION_ASSET_URL,
	PRO_LOGO_ASSET_URL,
	ProductAssetsService,
	ProductInfoService
} from "libs/product/src/lib/services";
import { OverlayComponent } from "libs/ux-components/src/lib/components/overlay/overlay.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Subscription } from 'rxjs';
import { CASE_ASSESSMENT_MAIL_BODY } from './case-assessment-mail-body';

enum ALIGNER_VARIANT {
	DATEN_VARIANTE,
	SCHIENEN_VARIANTE
}

type LevelItem =
	| {
			title: string;
			description: string;
			image: string;
			variantKey?: ALIGNER_VARIANT;
	  }
	| {
			productKey: ProductUrlKey;
	  };

@Component({
	selector: "tm2-anamnese-product-selection",
	templateUrl: "./anamnese-product-selection.component.html",
	styleUrls: ["./_anamnese-product-selection.component.scss"]
})
export class AnamneseProductSelectionComponent implements OnInit, OnDestroy{
	@Input() isUserProfessionalCertified: boolean = false;
	@Output() next = new EventEmitter<any>();

	ACTION = ACTION;
	protected isProductSelected = false;
	protected isAllowedToOrderMemotain: boolean;
	protected isAllowedToOrderMemotain$: Observable<boolean>;

	private loadingSubject = new BehaviorSubject<boolean>(false);
	protected loading$: Observable<boolean> = this.loadingSubject.asObservable();

	private productSelectionTree: {
		level0: LevelItem[];
		level1: LevelItem[];
		level2_daten: LevelItem[];
		level2_schiene: LevelItem[];
	} = {
		level0: [
			{
				title: "ALIGNER",
				description: "AlignerDescription",
				image: PRO_LOGO_ASSET_URL
			},
			{
				productKey: ProductUrlKey.MEMOTAIN
			},
			{
				productKey: ProductUrlKey.RETENTIONSSCHIENE
			}
		],
		level1: [
			{
				title: DATEN_VARIANTE,
				description: "DatenVarianteDescription",
				image: CATEGORY_DATA_VERSION_ASSET_URL,
				variantKey: ALIGNER_VARIANT.DATEN_VARIANTE
			},
			{
				title: SCHIENEN_VARIANTE,
				description: "SchienenVarianteDescription",
				image: CATEGORY_SPLINT_VERSION_ASSET_URL,
				variantKey: ALIGNER_VARIANT.SCHIENEN_VARIANTE
			}
		],
		level2_daten: [
			{
				productKey: ProductUrlKey.PRO_SMILE_DATEN
			},
			{
				productKey: ProductUrlKey.PRO_LITE_DATEN
			},
			{
				productKey: ProductUrlKey.PRO_FULL_DATEN
			}
		],
		level2_schiene: [
			{
				productKey: ProductUrlKey.PRO_SMILE_SCHIENE
			},
			{
				productKey: ProductUrlKey.PRO_LITE_SCHIENE
			},
			{
				productKey: ProductUrlKey.PRO_FULL_SCHIENE
			}
		]
	};

	protected currentOrderLevel = this.productSelectionTree.level0;

	private translateSubscription: Subscription;
	protected caseAssessmentBodyUriEncoded: string;

	constructor(
		private productAssetsService: ProductAssetsService,
		private productInfoService: ProductInfoService,
		public dialog: MatDialog,
		private translate: TranslateService
	) {
		this.caseAssessmentBodyUriEncoded = encodeURIComponent(CASE_ASSESSMENT_MAIL_BODY[this.translate.currentLang] || CASE_ASSESSMENT_MAIL_BODY.en)
		this.translateSubscription = this.translate.onLangChange.subscribe(e =>
			this.caseAssessmentBodyUriEncoded = encodeURIComponent(CASE_ASSESSMENT_MAIL_BODY[e.lang] || CASE_ASSESSMENT_MAIL_BODY.en)
		);
	}

	ngOnDestroy(): void {
		this.translateSubscription.unsubscribe();
		this.loadingSubject.complete();
	}

	ngOnInit(): void {
		this.loadingSubject.next(true);
		this.isProductSelected = false;
		this.isAllowedToOrderMemotain$ =
			this.productInfoService.isUserAllowedForProduct(
				ProductUrlKey.MEMOTAIN
			);
		this.isAllowedToOrderMemotain$.subscribe({
			next: (value: boolean) => (this.isAllowedToOrderMemotain = value),
			error: () => {
				this.isAllowedToOrderMemotain = false;
				this.loadingSubject.next(false);
			},
			complete: () => this.loadingSubject.next(false)
		});
	}

	done(productKey: ProductUrlKey) {
		this.isProductSelected = true;
		this.next.emit({
			type: ACTION.PRODUCT_SELECTED,
			payload: this.getProductData(productKey)
		});
	}

	getProductData(productKey: ProductUrlKey) {
		let productInfo: Partial<ProductInfoMagento> = {
			urlKey: productKey
		};
		if (PROFESSIONAL_PRODUCT_KEYS.includes(productKey)) {
			productInfo = {
				...productInfo,
				steps: ProductInfoForKey[productKey].steps,
				corrections: ProductInfoForKey[productKey].corrections,
				toothArea: ProductInfoForKey[productKey].toothArea
			};
		}
		return productInfo;
	}

	isProductWithImageDescription(productKey: ProductUrlKey): boolean {
		return PROFESSIONAL_PRODUCT_KEYS.includes(productKey);
	}

	isAlignerCategory() {
		return (
			this.currentOrderLevel === this.productSelectionTree.level2_daten ||
			this.currentOrderLevel === this.productSelectionTree.level2_schiene
		);
	}

	getToothAreaImageUrl(productKey: ProductUrlKey) {
		return this.productAssetsService.getToothAreaImageUrl(productKey);
	}

	getProFullProductImageUrl() {
		return this.productAssetsService.getProductImageUrl(ProductUrlKey.PRO_FULL_DATEN);
	}

	getProductSelectionImageUrl(productKey: ProductUrlKey) {
		return this.productAssetsService.getProductSelectionImageUrl(productKey);
	}

	getProductNameHtml(productKey: ProductUrlKey) {
		const productInfo: ProductInfo = ProductInfoForKey[productKey];
		let nameHtml = this.translate.instant(productInfo?.name);
		if (PROFESSIONAL_PRODUCT_KEYS.includes(productKey)) {
			nameHtml += "<br>";
			nameHtml += this.translate.instant(productInfo.variantName);
		}
		return nameHtml;
	}

	getDescriptionForProduct(productKey: ProductUrlKey) {
		return this.translate.instant(ProductInfoForKey[productKey].descriptionKey);
	}

	onShowProductIndication(productKey: ProductUrlKey) {
		this.dialog.open(OverlayComponent, {
			panelClass: "blue-dialog",
			data: this.productAssetsService.getProductIndicationImageUrl(productKey)
		});
	}

	onNext(action) {
		switch (action.type) {
			case ACTION.SELECT:
				//product was selected
				if (action.payload.productKey) {
					this.done(action.payload.productKey);
					return;
				}

				//category was selected
				if (this.currentOrderLevel === this.productSelectionTree.level0) {
					this.currentOrderLevel = this.productSelectionTree.level1;
					return;
				}

				if (this.currentOrderLevel === this.productSelectionTree.level1) {
					if (action.payload.variantKey === ALIGNER_VARIANT.DATEN_VARIANTE) {
						this.currentOrderLevel = this.productSelectionTree.level2_daten;
					}
					if (action.payload.variantKey === ALIGNER_VARIANT.SCHIENEN_VARIANTE) {
						this.currentOrderLevel = this.productSelectionTree.level2_schiene;
					}
					return;
				}
				break;

			case ACTION.BACK:
				if (this.currentOrderLevel === this.productSelectionTree.level0) {
					this.next.emit(action);
					return;
				}

				if (this.currentOrderLevel === this.productSelectionTree.level1) {
					this.currentOrderLevel = this.productSelectionTree.level0;
					return;
				}

				if (
					this.currentOrderLevel === this.productSelectionTree.level2_daten ||
					this.currentOrderLevel === this.productSelectionTree.level2_schiene
				) {
					this.currentOrderLevel = this.productSelectionTree.level1;
					return;
				}
				break;
		}
	}

	isProductAllowed(productKey: ProductUrlKey) {
		if (productKey === ProductUrlKey.PRO_FULL_DATEN || productKey === ProductUrlKey.PRO_FULL_SCHIENE ){
			return this.isUserProfessionalCertified;
		}
		if (productKey !== ProductUrlKey.MEMOTAIN) {
			return true;
		}
		return this.isAllowedToOrderMemotain;
	}
}
