<section>

	<h1>{{'Help' | translate}}</h1>
	<p> {{'Help.Subtitle' | translate}} </p>

	<div *ngIf="helpData" class="grid">
		<mat-card>
			<mat-card-header>
				<mat-card-title>
					CA® PROFESSIONAL
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<img [src]="locale === 'de' ?
					helpData.order_professional.thumbnail_de :
					helpData.order_professional.thumbnail_en | safeUrl"
					alt="PROFESSIONAL Ordering">
			</mat-card-content>
			<mat-card-actions>
				<a mat-raised-button color="primary" target="blank" [href]="helpData.order_professional.de | safeUrl">PDF (de)</a>
				<a mat-raised-button color="primary" target="blank" [href]="helpData.order_professional.en | safeUrl">PDF (en)</a>
			</mat-card-actions>
		</mat-card>
		<mat-card>
			<mat-card-header>
				<mat-card-title>
					MEMOTAIN®
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<img [src]="locale === 'de' ?
					helpData.order_memotain.thumbnail_de :
					helpData.order_memotain.thumbnail_en | safeUrl"
					alt="MEMOTAIN Ordering">
			</mat-card-content>
			<mat-card-actions>
				<a mat-raised-button color="primary" target="blank" [href]="helpData.order_memotain.de | safeUrl">PDF (de)</a>
				<a mat-raised-button color="primary" target="blank" [href]="helpData.order_memotain.en | safeUrl">PDF (en)</a>
			</mat-card-actions>
		</mat-card>
	</div>

	<ux-pricelist></ux-pricelist>

	<div *ngIf="faq">
		<h1>{{'Frequently Asked Questions' | translate }}</h1>
		<ux-accordion *ngIf="faq"
		[items]="faq">
	</ux-accordion>

	</div>
	<p><i class="fal fa-info-circle"></i>&nbsp;<span [innerHTML]="'HelpFooterText'  | translate"></span></p>

</section>
