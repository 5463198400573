import {createSelector} from '@ngrx/store';
import {selectIdFromUrl} from 'libs/tm-common/src/lib/store/selectors';
import {selectAnamneseState} from 'libs/anamnese/src/lib/store/anamnese.reducer';
import {selectTreatmentState, selectAllTreatments, DashboardStates} from './treatment.reducer';
import {TreatmentModel, TreatmentStatus} from 'libs/treatments/src/lib/models';

export const selectAnamneseTreatment = createSelector(
	selectTreatmentState,
	selectAnamneseState,
	(treatmentState, anamneseState) => anamneseState.treatmentId ? treatmentState.entities[anamneseState.treatmentId]
																			: null
);

export const selectCurrentTreatment = createSelector(
	selectTreatmentState,
	selectIdFromUrl,
	(treatmentState, currentId) => {
		if (treatmentState.selectedTreatmentId && treatmentState.entities[treatmentState.selectedTreatmentId]) {
			return treatmentState.entities[treatmentState.selectedTreatmentId];
		} else {
			return treatmentState.entities[currentId];
		}
	}
);

export const selectTreatmentFromUrl = createSelector(
	selectTreatmentState,
	selectIdFromUrl,
	(treatmentState, treatmentId) => {
		return treatmentId && treatmentState ?  treatmentState.entities[treatmentId] : null;
	}
);

export const selectDashboardTreatments = () => createSelector(
	selectAllTreatments,
	(treatmentEntities) => {
		let data = structuredClone(Object.values(treatmentEntities));
		data.forEach((treatment: TreatmentModel) => {
			[treatment.needForAction, treatment.neededAction] = determineNeedForAction(treatment);
		});
		return data;
	}
);
export const selectDashboardStates = createSelector(
	selectTreatmentState,
	state => {
		return state.dashboardStates as DashboardStates;
	}
);

function determineNeedForAction(treatment: TreatmentModel): [number, string] {

	if (treatment.status === TreatmentStatus.ReleaseRequested) {
		return [3, "release"];
	}
	if (treatment.status === TreatmentStatus.NewDataRequested) {
		return [2, "send data"];
	}
	if (treatment.status === TreatmentStatus.NewImprintsRequested) {
		return [1, "send model"];
	}

	// None of above: No need for action by user.
	return [0, ""];
}
