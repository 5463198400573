import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ACTION} from '@suite/tm-common';
import {Store} from '@ngrx/store';

import {AppState} from '@suite/tm-common';
import {ClearAnamneseAction, ProductSelectedAction, QuestionaireAnswersUpdatedAction} from '@suite/anamnese';
import {QuestionaireService} from '@suite/questionaire';
import { AnamneseProcessStep } from '../anamnese.component';
import { PROFESSIONAL_PRODUCT_KEYS, ProductInfoForKey, QuestionnaireScopeLevel } from 'libs/product/src/lib/models';

@Component({
	selector: 'tm2-anamnese-treatment',
	templateUrl: './anamnese-treatment.component.html',
	styleUrls: ['./anamnese-treatment.component.scss']
})
export class AnamneseTreatmentComponent implements OnInit {

	@Input() anamnese;
	@Input() patient;
	@Input() isUserProfessionalCertified: boolean;
	@Input() previousStep: AnamneseProcessStep;


	@Output() next = new EventEmitter<any>();

	// questionaire level
	questionnaireScopeLevel:QuestionnaireScopeLevel;
	ACTION = ACTION;
	actions = [ACTION.INITIAL, ACTION.OPTIONS, ACTION.QUESTIONAIRE];
	action = this.actions[0];

	constructor(private store: Store<AppState>,
				private questionaireService: QuestionaireService,
	) {

	}

	ngOnInit() {
		this.questionnaireScopeLevel = ProductInfoForKey[this.anamnese?.product?.info?.urlKey]?.questionnaireScopeLevel;
		if(this.previousStep === AnamneseProcessStep.SETUP_CHECKOUT){
			this.action = this.isAlignerProduct() ? ACTION.QUESTIONAIRE : ACTION.OPTIONS;
		}
	}
	isAlignerProduct() : boolean {
		return PROFESSIONAL_PRODUCT_KEYS.includes(this.anamnese?.product?.info?.urlKey);
	}

	done() {
		this.next.emit({type: ACTION.NEXT});
	}

	onNext(action) {
		switch (action.type) {

			case ACTION.PRODUCT_SELECTED:
				// store product info in redux store
				this.store.dispatch(new ProductSelectedAction(action.payload));
				this.action = ACTION.OPTIONS;
				break;

			case ACTION.OPTIONS:
				if ( this.isAlignerProduct() ) {
					this.questionnaireScopeLevel = ProductInfoForKey[this.anamnese.product.info.urlKey].questionnaireScopeLevel;
					this.action = ACTION.QUESTIONAIRE;
				} else {
					this.done();
				}
				break;

			case ACTION.QUESTIONAIRE:
				this.store.dispatch(new QuestionaireAnswersUpdatedAction(action.payload));
				this.questionaireService.getQuestionaireData();
				this.done();
				break;

			case ACTION.BACK:
				const idx = this.actions.findIndex(el => el === this.action);

				if (idx > 0) {
					this.action = this.actions[idx - 1];
				} else {
					this.store.dispatch(new ClearAnamneseAction());
					this.next.emit({type: ACTION.BACK});
				}
		}


	}

}
