import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {PatientEffects} from './store';
import * as fromPatient from './store/patient.reducer';
import {PatientsService} from './services/patients.service';
import {PatientsResolver} from './services/patients.resolver';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('patient', fromPatient.reducer),
		EffectsModule.forFeature([PatientEffects])
	],
	providers: [
		PatientsService,
		PatientsResolver
	]
})
export class PatientsModule {
}
