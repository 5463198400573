import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {concat, of, throwError} from 'rxjs';
import {select, Store} from '@ngrx/store';

import { CaseWorker, GoAction, NotificationService, TreatmentModel, TreatmentStatus } from '@suite/tm-common';
import { OrderService } from 'libs/order/src/lib/services';

import { TreatmentsService } from '../services/treatments.service';
import { selectCurrentTreatment } from './treatment.selectors';
import { TreatmentState } from './treatment.reducer';
import {
	AssignAndGoToTreatment,
	LoadTreatments,
	NewPickUpReference,
	OrderTreatmentFollower,
	SaveDashboardState,
	SubmitComplaint,
	SubmitComplaintSuccess,
	SubmitReleaseRequest,
	TreatmentActionTypes,
	TreatmentRequested,
	TreatmentsRequested,
	UpsertTreatment,
	UpsertTreatmentOnDb
} from './treatment.actions';
import { UpsertMessage } from 'libs/messages/src/lib/store/messages.actions';

@Injectable()
export class TreatmentEffects {

	treatment$ = createEffect(() => this.actions$.pipe(
		ofType<TreatmentRequested>(TreatmentActionTypes.TreatmentRequested),
		mergeMap(({ payload }) => this.treatmentsService.getTreatment(payload.id)),
		map((treatment: TreatmentModel) => new UpsertTreatment({ treatment })),
		catchError(err => {
			console.error('error loading treatment');
			return throwError(() => new Error(err));
		})
	));

	treatments$ = createEffect(() => this.actions$.pipe(
		ofType<TreatmentsRequested>(TreatmentActionTypes.TreatmentsRequested),
		mergeMap(() => this.treatmentsService.getTreatments()),
		map((treatments: TreatmentModel[]) => new LoadTreatments({treatments})),
		catchError(err => {
			console.error('error loading treatments');
			return throwError( () => new Error(err));
		})
	));

	treatmentUpdateDb$ = createEffect(() => this.actions$.pipe(
		ofType<UpsertTreatmentOnDb>(TreatmentActionTypes.UpsertTreatmentOnDb),
		mergeMap(( {payload} ) => this.treatmentsService.updateTreatment(payload.treatmentId, payload.diff)),
		map((treatment: TreatmentModel) => new UpsertTreatment({treatment})),
		catchError(err => {
		 	console.error('error while updating treatment');
		 	return throwError( () => new Error(err));
		})
	));

	upsPickUpReference$ = createEffect(() => this.actions$.pipe(
		ofType<NewPickUpReference>(TreatmentActionTypes.NewPickUpReference),
		withLatestFrom(this.store$.pipe(select(selectCurrentTreatment))),
		mergeMap(([{payload}, treatment]) => this.treatmentsService.updateTreatment(treatment._id, {quotation: treatment.quotation})),
		catchError(err => {
			console.error('error while updating treatment');
			return throwError( () => new Error(err));
		})
	), {dispatch: false});


	orderTreatmentFollower$ = createEffect(() => this.actions$.pipe(
		ofType<OrderTreatmentFollower>(TreatmentActionTypes.OrderTreatmentFollower),
		map( action => action.payload),
		switchMap( ({treatmentId, packageType, interimScanOption}) => this.orderService.alignerFollowupOrder(treatmentId, packageType, interimScanOption)),
		map( treatment => new UpsertTreatment({treatment})),
		catchError(err => {
			console.error('error while updating treatment');
			return throwError( () => new Error(err));
		})
	));

	assignAndGoToTreatment$ = createEffect(() => this.actions$.pipe(
		ofType<AssignAndGoToTreatment>(TreatmentActionTypes.AssignAndGoToTreatment),
		switchMap(({ payload }) => {
			return concat(
				of(new SaveDashboardState({ tab: payload.tab, state: payload.state })),
				of(new UpsertTreatmentOnDb({ treatmentId: payload.treatment._id, diff: { caseworker: payload.username } })),
				of(new GoAction({ path: [`/treatment/edit/${payload.treatment._id}`] })),
			);
		}),
		catchError(err => {
			console.error('error while updating treatment');
			return throwError( () => new Error(err));
		})
	));

	submitComplaint$ = createEffect(() => this.actions$.pipe(
		ofType<SubmitComplaint>(TreatmentActionTypes.SubmitComplaint),
		mergeMap(({ payload }) => this.treatmentsService.updateTreatment(payload.treatment._id, { status: TreatmentStatus.ComplaintReceived, caseworker: CaseWorker.REKLAMATION })),
		switchMap((treatment: TreatmentModel) => [
			new UpsertTreatment({ treatment }),
			new SubmitComplaintSuccess({ treatment })
		]),

		catchError(err => {
			console.error('error while updating treatment');
			return throwError(() => new Error(err));
		})
	));

	submitReleaseRequest$ = createEffect(() => this.actions$.pipe(
		ofType<SubmitReleaseRequest>(TreatmentActionTypes.SubmitReleaseRequest),
		mergeMap(({ payload }) => this.treatmentsService.submitReleaseRequest(payload.treatmentId, payload.releaseRequest)),
		switchMap(({ treatment, message }) => [
			new UpsertMessage({ message }),
			new UpsertTreatment({ treatment })
		]),
		catchError(err => {
			this.notificationService.showErrorMessage(
				'ADMIN.requestRelease-error',
				(err.status && err.statusText) ? `${err.status} ${err.statusText}: ${err.error?.message ?? err.message}` : err.message
			);
			return throwError(() => new Error(err));
		})
	));

	constructor(
		private actions$: Actions,
		private store$: Store<TreatmentState>,
		private orderService: OrderService,
		private treatmentsService: TreatmentsService,
		private readonly notificationService: NotificationService
	) {
	}
}
