<ng-container>

	<tm2-tooth-area-image-selection
		(action)="action.emit($event)"
		[hasUpperJaw]="hasUpperJaw"
		[hasLowerJaw]="hasLowerJaw"
		>
	</tm2-tooth-area-image-selection>

</ng-container>



