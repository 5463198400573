<div class="flex-container">
	<div class="upload-container">

		<media-file-upload-box
			id="ok"
			[params]="{treatment: treatment?._id, patientId: patientId, type: MediaType.User}"
			[allowed_extensions]="['stl', 'ply']"
			[allowed_number]="1"
			[allowed_max_size]="1024*1024*75"
			[attachedFile]="uploadedFiles.uj"
			[isAllowedToRemoveFile]="true"
			[uploadRequired]="bothJawStlRequired ? true : false"
			(fileEvent)="onFileEvent($event, true)">
			<p class="description">{{'Digitaler Datensatz' | translate}}</p>
			<p class="description strong">{{'Ok' | translate}}</p>
		</media-file-upload-box>

		<media-file-upload-box
			id="uk"
			[params]="{treatment: treatment?._id, patientId: patientId, type: MediaType.User}"
			[allowed_extensions]="['stl', 'ply']"
			[allowed_number]="1"
			[allowed_max_size]="1024*1024*75"
			[attachedFile]="uploadedFiles.lj"
			[isAllowedToRemoveFile]="true"
			[uploadRequired]="true"
			(fileEvent)="onFileEvent($event, false)">
			<p class="description">{{'Digitaler Datensatz' | translate}}</p>
			<p class="description strong">{{'Uk' | translate}}</p>
		</media-file-upload-box>
	</div>

	<p class="call-to-action">
		{{'Upload Jaw Files' | translate}} <br>
		{{'OK Scan Occlusion' | translate}}
	</p>


	<p class="info">
		<i class="fas fa-info-circle"></i> {{'Erlaubte Dateiformate' | translate}} STL, PLY (max. 75 MB)
	</p>

</div>
