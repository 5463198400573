<div class="treatment-media" *ngIf="(media$ | async) as media">


		<media-file-list
			[media]="media"
			(refreshAction)="onFilesRefreshClicked()"
		></media-file-list>

		<div class="upload" *ngIf="params && isInputEnabled">
			<h3>
				<i class="fal fa-upload"></i>
				{{'Upload' | translate}}
			</h3>
			<media-file-upload-box
				[allowed_extensions]="allowedExtensions"
				[allowed_number]="5"
				[allowed_max_size]="allowedMaxFileSize ? allowedMaxFileSize : 1024*1024*75"
				[params]="params"
				[reset_after]="2000"
				(fileEvent)="onFileEvent($event)">
			</media-file-upload-box>
		</div>


</div>
