import {Action, ActionReducer, MetaReducer} from '@ngrx/store';
import {environment} from 'environments/environment';

import {AppState} from './reducers';
import {StoreActionTypes} from '../actions/actions';

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
	return function (state: AppState, action: Action): AppState {
		if (action.type === StoreActionTypes.ClearState) {
			state = undefined;
		}
		return reducer(state, action);
	};
}

export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
	return function (state: AppState, action: Action): AppState {   // reducer
		const nextState = reducer(state, action);

		action['payload']
			? console.log(`%c${action.type},`, 'color: #03A9F4; font-weight: bold', 'Payload:', action['payload'] )
			: console.log(`%c${action.type},`, 'color: #03A9F4; font-weight: bold');

		return nextState;
	};
}

export const metaReducers: MetaReducer<AppState>[] =
	!environment.production ? [clearState, logger]
							: [clearState];
// log used meta reducers
metaReducers.forEach( metaReducer => console.log(`%c!metaReducer`, `color: #03A9F4; font-weight: bold`, metaReducer.name));
