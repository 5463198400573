<div class="web-viewer" *ngIf="file">
	<h3>{{'WebViewer' | translate}}<span> - {{productName}}</span></h3>
	<iframe [src]="caWebViewerUrl"
			#caWebViewer>
	</iframe>
	<button mat-button color="default" ngxClipboard
		*ngIf="!webViewerJsonFileId"
		title="{{webViewerUrl}}"
		[cbContent]="webViewerUrl">
		<i class="fal fa-sign-in-alt"></i> {{'Zwischenablage' | translate}}
	</button>
</div>
<div class="web-viewer" *ngIf="upgradeFile">
	<h3>{{'WebViewer' | translate}}	<span> - {{'Upgrade Option' | translate}}</span> - {{upgradeProductName}}</h3>
	<iframe [src]="caWebViewerUrl"
			#caWebViewerUpgrade>
	</iframe>
	<button mat-button color="default" ngxClipboard
		*ngIf="!webViewerJsonFileId"
		title="{{webViewerUrlUpgrade}}"
		[cbContent]="webViewerUrlUpgrade">
		<i class="fal fa-sign-in-alt"></i> {{'Zwischenablage' | translate}}
	</button>
</div>
