<section class="notes-on-customer-container">
	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<i class="fal fa-clipboard">&nbsp;</i>
				{{"CustomerNotes" | translate}} ({{customerRelevantNotes.length}}/{{orderRelevantNotes ? 1 : 0}})
			</mat-expansion-panel-header>
			<div *ngIf="customerRelevantNotes.length">
				<h3>
					{{'CustomerRelevantNotes' | translate }}
				</h3>
				<div class="notes-list-table">
					<div class="Table-row" *ngFor="let note of customerRelevantNotes">
						<div class="Table-row-item date">{{ (note.updated_at ? note.updated_at : note.created_at) | date:
							'mediumDate' : 'EUROPE/BERLIN' : locale }}</div>
						<div class="Table-row-item name">{{ note.firstname }} {{ note.lastname }}</div>
						<div class="Table-row-item text">{{ note.note }}</div>
					</div>
				</div>
			</div>
			<div *ngIf="orderRelevantNotes">
				<h3>
					{{'OrderRelevantNotes' | translate }}
				</h3>
				<p>{{orderRelevantNotes}}</p>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</section>
