import {CustomerActions, CustomerActionTypes} from './customer.actions';
import { SapAddress } from 'libs/sap/src/lib/models/sap.model';


export interface CustomerState {
	billingAddress: SapAddress,
	shippingAddresses: SapAddress[]
}

export const initialCustomerState: CustomerState = {
	billingAddress: null,
	shippingAddresses: [],
};

export function reducer(state = initialCustomerState, action: CustomerActions): CustomerState {
	switch (action.type) {
		case CustomerActionTypes.AddressesLoaded:
			return {
				...state,
				billingAddress: action.payload.billingAddress,
				shippingAddresses: action.payload.shippingAddresses
			};

		default:
			return state;
	}
}
