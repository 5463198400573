import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '@suite/tm-common';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpsService {


	constructor(private http: HttpClient,
				 private notification: NotificationService,
				 @Inject('CONFIG') private config) {}

	pickupRequest(address, dateTimeInfo, context, instruction = '') {
		return this.http.post(
			`${this.config.apiUrl}/pickupRequest`,  {address, dateTimeInfo, context, instruction}
		).pipe(
			map( res => {
				// handle fault response
				if (res['Fault']) {
					this.notification.showErrorMessage(res['Fault'].detail.Errors.ErrorDetail.PrimaryErrorCode.Description);
					// enrich response with error info
					return {...res, error: res['Fault'].detail.Errors.ErrorDetail.PrimaryErrorCode.Description};

				// handle valid response (code 1)
				} else if (res['PickupCreationResponse'].Response.ResponseStatus.Code === '1' ) {
					return res;

				// handle other responses (unkonwon errors)
				} else {
					this.notification.showErrorMessage('Fehler im UPS Interface');
					// enrich response with error info
					return {...res, error: 'Unknown Error from ups'};
				}
			})
		);
	}
}
