import {ActionReducerMap, createFeatureSelector} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import {ActivatedRouteSnapshot, Params, RouterStateSnapshot} from '@angular/router';
import {AuthState} from '@suite/auth';
import {CustomerState} from '@suite/customer';
import {AnamneseState} from '@suite/anamnese';
import {MagentoState} from '@suite/magento';
import {PatientState} from '@suite/patients';
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
	url: string;
	params: Params;
	queryParams: Params;
}

export interface AppState {
	anamnese?: AnamneseState;
	auth?: AuthState;
	customer?: CustomerState;
	magento?: MagentoState;
	patient?: PatientState;
	router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
	router: fromRouter.routerReducer
};

export const selectRouterState = createFeatureSelector<
	fromRouter.RouterReducerState<RouterStateUrl>
	>('router');

@Injectable()
export class CustomSerializer
	implements fromRouter.RouterStateSerializer<RouterStateUrl> {

	serialize(routerState: RouterStateSnapshot): RouterStateUrl {

		const { url } = routerState;
	    const { queryParams } = routerState.root;

		let state: ActivatedRouteSnapshot = routerState.root;
		while (state.firstChild) {
			state = state.firstChild;
		}
		const { params } = state;

		return { url, params, queryParams };
	}

}
