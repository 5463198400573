import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output
} from "@angular/core";
import { TreatmentStatus } from "@suite/tm-common";

@Component({
	selector: "ux-message-status",
	templateUrl: "./message-status.component.html",
	styleUrls: ["./message-status.component.scss"]
})
export class MessageStatusComponent implements OnChanges {
	@Input() treatment;
	@Output() statusChange = new EventEmitter();

	treatmentStatus: TreatmentStatus;

	TreatmentStatus = TreatmentStatus;

	constructor() {}

	ngOnChanges() {
		if (this.treatment) {
			this.treatmentStatus = this.treatment.status;
		}
		}

	onSelection() {
		this.statusChange.emit({
			status: this.treatmentStatus
		});
	}

	/**
	 * Instead of defining an array with a subset of TreatmentStatus,
	 * it is easier to exclude unwanted status
	 * This way, we won't forget any status, if new ones are added later.
	 * TODO: check which status should not be selectable
	**/
	shouldIncludeStatus(status: any): boolean {
		return status !== TreatmentStatus.ReleaseRequested
			&& status !== TreatmentStatus.CanceledByCustomer
			&& status !== TreatmentStatus.CreditMemoIssued;
		;
	}
}
