<mat-form-field>
	<mat-label>{{'Treatment Status' | translate}}</mat-label>
	<mat-select
		(selectionChange)="onSelection()"
		[(value)]="treatmentStatus"	>
		<ng-container *ngFor="let status of (TreatmentStatus | keyvalue)">
				<mat-option *ngIf="shouldIncludeStatus(status.value)"
				[value]="status.value">{{status.value | statusToString | translate}}</mat-option>
		</ng-container>
	</mat-select>
</mat-form-field>
