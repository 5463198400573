import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {HandlingUpdatedAction} from '@suite/anamnese';
import {ACTION, AppState} from '@suite/tm-common';
import { SapShippingMethod, SapAddress, SapShippingCostItem, DOWNLOAD_SHIPPING_ITEM, EUCountryCode } from 'libs/sap/src/lib';
import { CurrencyPipe } from '@angular/common';
import { ProductUrlKey } from '@suite/product';

type FormFieldTypes = {
	shippingAddress: string,
	shippingType: string
}

@Component({
	selector: 'tm2-anamnese-addresses',
	templateUrl: './anamnese-addresses.component.html',
	styleUrls: ['./_anamnese-addresses.component.scss']
})
export class AnamneseAddressesComponent implements OnInit, OnChanges {
	@Input() billingAddress: SapAddress;
	@Input() shippingAddresses: SapAddress[];
	@Input() shippingCosts: SapShippingCostItem[];
	@Input() productKey: ProductUrlKey;
	@Output() next = new EventEmitter<any>();
	ACTION = ACTION;
	form: UntypedFormGroup;
	formFields: FormFieldTypes = {shippingAddress: '', shippingType: ''};
	disableNext: boolean = false;

	hasUniqueShippingAddress: boolean;
	shippingAddress: SapAddress = null;

	shippingTypes = [SapShippingMethod.STANDARD, SapShippingMethod.EXPRESS];
	isDataVariant: boolean = false;

	constructor(private fb: UntypedFormBuilder,
				private store: Store<AppState>,
				private currencyPipe: CurrencyPipe,
				@Inject('CONFIG') private config) {
	}

	ngOnInit() {
		this.form = this.fb.group(this.formFields);
	}

	ngOnChanges() {
		this.isDataVariant = [
			ProductUrlKey.PRO_SMILE_DATEN,
			ProductUrlKey.PRO_LITE_DATEN,
			ProductUrlKey.PRO_FULL_DATEN
		].includes(this.productKey);

		this.hasUniqueShippingAddress = this.shippingAddresses.length === 1;
		if ( this.hasUniqueShippingAddress ){
			this.shippingAddress = this.shippingAddresses.at(0);
		}else{
			this.formFields.shippingAddress = this.shippingAddresses.at(0).AddressName;
			this.shippingAddress = this.shippingAddresses.at(0);
		}
		this.formFields.shippingType = SapShippingMethod.STANDARD;

		this.store.dispatch(
			new HandlingUpdatedAction({
				billing: this.billingAddress,
				shipping: this.shippingAddress,
				shipment: this.getShipmentObject(),
				hasUniqueShippingAddress: this.hasUniqueShippingAddress
			})
		);
	}

	onAction(action) {
		switch (action.type) {
			case ACTION.ENABLE_NEXT:
				this.disableNext = !action.payload;
				break;
			case ACTION.BACK:
				this.next.emit({type: ACTION.BACK});
				break;
			case ACTION.NEXT:
				const handling = {
					billing: this.billingAddress,
					shipping: this.shippingAddress,
					shipment: this.getShipmentObject()
				};

				this.next.emit({
					type: ACTION.INITIAL,
					payload: handling
				});
				break;
		}

	}

	onShippingAddressSelection() {
		this.shippingAddress = this.shippingAddresses.find(el => el.AddressName === this.formFields.shippingAddress);
		this.store.dispatch(new HandlingUpdatedAction({shipping: this.shippingAddress}));
	}

	onShippingTypeSelection() {
		this.store.dispatch(new HandlingUpdatedAction({shipment: this.getShipmentObject()}));
	}

	getShipmentObject() : SapShippingCostItem {
		return this.isDataVariant
			? DOWNLOAD_SHIPPING_ITEM
			: {
					Method: this.formFields.shippingType as SapShippingMethod,
					Code: this.shippingCosts.find(
						(item) => item.Method === this.formFields.shippingType
					).Code,
					Amount: this.shippingCosts.find(
						(item) => item.Method === this.formFields.shippingType
					).Amount
			  };
	}

	getTextForShippingType( method: SapShippingMethod): string {
		const item: SapShippingCostItem = this.shippingCosts.find(item => item.Method === method);
		return `UPS ${item?.Method} - ${this.currencyPipe.transform(item?.Amount, 'EUR', 'symbol', '', this.config.locale)}`;
	}

	getTextForDownloadShipment(): string {
		return `Download - ${this.currencyPipe.transform(0, 'EUR', 'symbol', '', this.config.locale)}`;
	}

	protected getTextForCountryCode(Country: string) {
		return Country === EUCountryCode.Greece_EL ? EUCountryCode.Greece : Country;
	}
}
