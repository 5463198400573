import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CaTranslateModule} from '@suite/translate';
import * as fromMagento from './store/magento.reducer';
import {MagentoEffects} from './store/magento.effects';
import {CmsModule, CmsService} from '@suite/cms';

@NgModule({
    imports: [
        CommonModule,
        CaTranslateModule,
		CmsModule,
        StoreModule.forFeature('magento', fromMagento.reducer),
        EffectsModule.forFeature([MagentoEffects])
    ],
    declarations: [],
    exports: [],
    providers: [
		CmsService
    ]
})
export class MagentoModule {
}

