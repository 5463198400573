import {Component, OnInit, OnDestroy} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';
import { CmsService } from 'libs/cms/src/lib';


@Component({
	selector: 'tm2-help',
	templateUrl: './help.component.html',
	styleUrls: ['./_help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {

	translateSubscription$: Subscription;
	cmsSubscription$: Subscription

	helpData: {title: string, pdfURL: string}[] = null;

	faq = null;
	faq_de = null;
	faq_en = null;
	locale: string = "de";

	constructor( private cmsService: CmsService,
				 private translateService: TranslateService,
				) {}
	ngOnInit(): void {
		this.cmsSubscription$ = forkJoin({
				req1: this.cmsService.getCmsBlock('tm_help_main'),
				req2: this.cmsService.getCmsBlock('tm_faq_de'),
				req3: this.cmsService.getCmsBlock('tm_faq_en'),
			})
		.subscribe(({req1, req2, req3}) => {
			this.helpData = req1.activated && req1.content ? req1.content : null;
			this.faq_de = req2.activated && req2.content ? req2.content : null;
			this.faq_en = req3.activated && req3.content ? req3.content : null;
			this.faq = this.translateService.currentLang === 'de' ? this.faq_de : this.faq_en;
		});

		this.translateSubscription$ = this.translateService.onLangChange
			.subscribe(e => {
				this.faq = e.lang === 'de' ? this.faq_de : this.faq_en;
				this.locale = e.lang;
			});
	}

	ngOnDestroy() :void {
		this.translateSubscription$.unsubscribe();
		this.cmsSubscription$.unsubscribe();
	}
}
