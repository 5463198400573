import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Store } from '@ngrx/store';
import { OptionsUpdatedAction, SettingsUpdatedAction } from '@suite/anamnese';
import {ACTION, AppState} from '@suite/tm-common';
import {DataTransferMethod} from '@suite/treatments/models'

@Component({
  selector: 'tm2-anamnese-additional-settings',
  templateUrl: './anamnese-additional-settings.component.html',
  styleUrls: ['./_anamnese-additional-settings.component.scss']
})
export class AnamneseAdditionalSettingsComponent implements OnInit  {

	@Input() anamnese;
	@Output() next = new EventEmitter<any>();
	ACTION = ACTION;
	DataTransferMethod = DataTransferMethod;
    dataTransfer: DataTransferMethod;
	orderComment = '';
	maxLength = 1024;
	upsPickUpPossible: boolean = false;
	disposalConfirmationChecked: boolean = false;

	constructor(private store: Store<AppState>) {
	}

	ngOnInit() {
		//Note: this.anamese.comment is empty on first entrance of the state,
		//		they will be relevant, when the user returns back
		//		from the checkout cart to this state (this way he does not have to fill them again)
		this.orderComment = this.anamnese.comment;
		//check if shipping address is in germany and a ups pickup request is feasible
		if (this.anamnese?.product?.handling?.shipping) {
			this.upsPickUpPossible = this.anamnese.product.handling.shipping.Country === 'DE';
		}
	}

	onAction(action) {
		switch (action.type) {
			case ACTION.BACK:
				this.next.emit({ type: ACTION.BACK });
				break;
			case ACTION.NEXT:
				this.store.dispatch(
					new SettingsUpdatedAction({
						comment: this.orderComment,
					})
				);
				this.store.dispatch(
					new OptionsUpdatedAction({
						dataTransfer: this.dataTransfer
					})
				);

				if (this.dataTransfer === DataTransferMethod.UPLOAD) {
					this.next.emit({ type: ACTION.UPLOAD });
				} else {
					this.next.emit({ type: ACTION.OPTIONS });
				}
				break;
		}
	}

	onRadioButtonChange(){
		this.disposalConfirmationChecked = false;
	}

	isNextButtonDisabled() : boolean{
		if (this.dataTransfer === DataTransferMethod.UPLOAD){
			return false;
		}
		if( [DataTransferMethod.UPS, DataTransferMethod.OTHER].includes(this.dataTransfer)
			&& this.disposalConfirmationChecked ){
			return false;
		}
		return true;
	}
}
