import { Component, Input } from '@angular/core';

/**
 * For more documentation see: https://material.angular.io/components/expansion/overview
 */
@Component({
  selector: 'ux-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {

  @Input() items;

  constructor() { }

}
