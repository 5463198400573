export interface MessageModel {
	type: MessageTypes;
	scope: MessageScopes;
	message: string;
	sendersUserId?: string;
	sendersType?: SendersTypes;
	context: MessageContext;
	created?: Date;

	//exclusive mongodb fields
	_id?: string; 					//ObjectID of mongodb entry
}

export interface MessageContext
{
	id: string; 				 		// depending on type treatmentId | orderId | patientId
	ownerId?: string;			 		// user id of context owner: XXX actually this should be of type 'number'
	status?: number | string;			// status of treatment

	/** @deprecated Legacy since 2.0.0, do not use. */
	dontInformMage?: boolean;    		// XXX avoids infinite event loop between magneto observer and nodeserver
	/** @deprecated Legacy since 2.0.0, do not use. */
	orderId?: string;			 		// related order if any
	/** @deprecated Legacy since 2.0.0, do not use. */
	orderStatus?: number | string;		// status of order
	/** @deprecated Legacy since 2.0.0, do not use. */
	newOrderStatus?: number | string;	// new status of order
	/** @deprecated Legacy since 2.0.0, do not use. */
	newStatus?: number | string;	 	// new status of treatment
}

export enum MessageTypes {
	Note = 0,
	Info = 1,
	Question = 2,
	Warning = 3,
	Problem = 4,
	Coupon = 5, //legacy since 2.0.0
	StatusChange = 6,
	CaseWorker = 7,
	CompletionDate = 8,
	MessagesReset = 9
}

export enum MessageScopes {
	Public = 0,
	Intern = 1,
	PublicWithEmailNotification = 2
}

export enum SendersTypes {
	Customer = 0,
	Technican = 1,
	Support = 2,
	System = 3
}

export const MessageTypeIcons = [
	{type: MessageTypes.Note, icon: 'fal fa-comment'},
	{type: MessageTypes.Info, icon: 'fal fa-info-circle'},
	{type: MessageTypes.Question, icon: 'fal fa-question-circle'},
	{type: MessageTypes.Warning, icon: 'fal fa-exclamation-triangle'},
	{type: MessageTypes.Problem, icon: 'fas fa-exclamation-circle'},
	{type: MessageTypes.Coupon, icon: 'fal fa-gift-card', adminOnly: true},
	{type: MessageTypes.StatusChange, icon: 'fal fa-retweet', adminOnly: true},
	{type: MessageTypes.CaseWorker, icon: 'fal fa-user', adminOnly: true},
	{type: MessageTypes.CompletionDate, icon: 'fal fa-check-square', adminOnly: true},
	{type: MessageTypes.MessagesReset, icon: 'fal fa-broom', adminOnly: true}
];
