import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { OrderService } from "@suite/order";
import { ProductInfoForKey, ProductUrlKey } from "libs/product/src/lib/models/product.model";
import { TreatmentModel } from "@suite/treatments";
import { TranslateService } from '@ngx-translate/core';
import { Observable, take } from 'rxjs';
import { SapCostEstimate } from '@suite/sap';

export type ReleaseDialogActionData = {
	comment: string;
};

export enum ReleaseDialogType{
	RELEASE,
	RELEASE_UPGRADE,
	CORRECTION,
	CANCEL
}

@Component({
	selector: 'tm2-treatment-release-dialog',
	templateUrl: './treatment-release-dialog.component.html',
	styleUrls: ['./_treatment-release-dialog.component.scss']
})
export class TreatmentReleaseDialogComponent {

	ReleaseDialogType = ReleaseDialogType;
	step2 = false;
	price$: Observable<SapCostEstimate>;
	target = 0;
	constructor(private orderService: OrderService,
				private translate: TranslateService,
				@Inject(MAT_DIALOG_DATA) public data: {type: ReleaseDialogType, treatment: TreatmentModel},
				@Inject('CONFIG') public config) {

		// try to get pricebut only if we have a treatment
		if (data.treatment) {

			if (
				[
					ReleaseDialogType.RELEASE,
					ReleaseDialogType.RELEASE_UPGRADE,
					ReleaseDialogType.CANCEL
				].includes(data.type)
			) {
				this.getPrice(data.treatment._id);
			}
		}
	}

	getPrice(treatmentId: string) {
		let price$;
		if (this.data.type === ReleaseDialogType.RELEASE) {
			price$ = this.orderService.getPriceForQuotation(treatmentId);
			this.price$ = price$.pipe(take(1));
		}
		if (this.data.type === ReleaseDialogType.CANCEL) {
			price$ = this.orderService.getPriceForCancellation();
			this.price$ = price$.pipe(take(1));
		}
	}

	getProductNameHtml() : string
	{
		const urlKey: ProductUrlKey = this.data.type === ReleaseDialogType.RELEASE_UPGRADE ?
			this.data.treatment.upgrade.product.info.urlKey as ProductUrlKey :
			this.data.treatment.product.info.urlKey as ProductUrlKey ;
		return ProductInfoForKey[urlKey].name
		+ " - "
		+ this.translate.instant(ProductInfoForKey[urlKey].variantName);
	}

	protected calculateDiscountInPercent(discountFactor: number){
		return ((1.0 - discountFactor) * 100).toFixed(2);
	}

}
