import { Component, Inject, Input, OnChanges, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject, Subscription, delay, map, tap } from "rxjs";
import { Store, select } from "@ngrx/store";

import { AppState } from "@suite/tm-common";
import { ConfirmDialogComponent } from "libs/ux-components/src/lib/components/confirm-dialog";
import { MagentoUserRole, selectAuthState } from "@suite/auth";

import { MediaService } from "../../services";
import { DeleteMedia, MediaModel, MediaType, UpdateMedia, MediaVisibilityScope } from "../../store";
import { Update } from "@ngrx/entity";

@Component({
	selector: "media-file-list",
	templateUrl: "./file-list.component.html",
	styleUrls: ["./_file-list.component.scss"]
})
export class FileListComponent implements OnInit, OnChanges, OnDestroy {
	@Input() media: MediaModel[];
	@Output() refreshAction = new EventEmitter();
	MediaTypes = MediaType;

	selectedMediaFiles : string[] = [];
	allFilesChecked : boolean = false;

	isUserAllowedToHideFile$: Observable<boolean>;
	isUserAllowedToDelete$: Observable<boolean>;

	VisibilityScope = MediaVisibilityScope;

	protected isRefreshButtonDisabled = false;
	protected refreshButtonClicked = new Subject<void>();
	private refreshButtonSubscription: Subscription;

	constructor(
		private store: Store<AppState>,
		private dialog: MatDialog,
		private mediaService: MediaService,
		@Inject("CONFIG") protected config
	) {
		this.refreshButtonSubscription = this.refreshButtonClicked.pipe(
			tap(() => this.isRefreshButtonDisabled = true),
			delay(500),
			tap(() => this.isRefreshButtonDisabled = false),
		).subscribe();
	}
	ngOnDestroy(): void {
		this.refreshButtonSubscription.unsubscribe();
	}

	ngOnInit(): void {
		this.isUserAllowedToHideFile$ = this.store.pipe(
			select(selectAuthState),
			map((state) => { return state.user?.isLoggedInAsAdmin } )
		);
		this.isUserAllowedToDelete$ = this.store.pipe(
			select(selectAuthState),
			map((state) => state.user?.adminRole?.role_id),
			map((roleId) => this.checkDeletionPermission(roleId as MagentoUserRole))
		);
	}

	ngOnChanges() {
		this.selectedMediaFiles = [];
		this.allFilesChecked = false;
	}

	getMediaList() : MediaModel[]{
		if ( this.config.app !== "tm2" ){
			return this.media;
		}
		//filter out hidden files for customer frontend
		return this.media.filter(value => value.visibilityScope === MediaVisibilityScope.PUBLIC);
	}

	openDeleteConfirmationDialog(mediaId: string) {
		const dialogData = {
			title: "Confirm deletion",
			msg: "Are you sure you want to delete the selected file?",
			no: "No",
			yes: "Yes"
		};
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result == "yes") {
				this.deleteMediaFile(mediaId);
			}
		});
	}
	downloadMediaFile(id) {
		this.mediaService.getFile(id);
	}

	deleteMediaFile(id: string) {
		this.store.dispatch(new DeleteMedia({ id }));
	}

	checkDeletionPermission(role: MagentoUserRole): boolean {
		if (this.config.app === "tm2") {
			return false;
		}
		if ( role === undefined ) {
			return false;
		}
		//allow only users with role 'ADMIN' to delete files
		return role === MagentoUserRole.ADMIN;
	}

	hideFile( file: MediaModel ){
		const newVisiblity: MediaVisibilityScope =
			file.visibilityScope === MediaVisibilityScope.PUBLIC
				? MediaVisibilityScope.INTERN
				: MediaVisibilityScope.PUBLIC;
		const updatedModel: Update<MediaModel> = {
			id: file._id,
			changes: {
				visibilityScope: newVisiblity
			}
		};
		this.store.dispatch(new UpdateMedia({ media: updatedModel }));
	}

	getLocale(){
		return this.config.locale;
	}

	getFileSizeKb(bytes: number) {
		return Math.ceil(bytes / 1024).toLocaleString(this.config.locale);
	}

	onFileSelectionChange(isChecked: boolean, file: MediaModel){
		if( isChecked ){
			this.selectedMediaFiles.push(file._id);
		}else{
			this.selectedMediaFiles = this.selectedMediaFiles.filter((id) => id !== file._id);
		}
		this.allFilesChecked = this.selectedMediaFiles.length === this.getMediaList().length;
	}

	onAllFileSelectionChange(isChecked: boolean){
		this.selectedMediaFiles = [];
		if(isChecked){
			this.getMediaList().forEach(element => {
				this.selectedMediaFiles.push(element._id);
			});
		}
		this.allFilesChecked = isChecked;
	}

	onDownloadSelectedFilesClicked(){
		if(this.selectedMediaFiles.length > 0){
			this.selectedMediaFiles.forEach((id) => {
				this.downloadMediaFile(id);
			})
		}
	}

	isFileSelected(file: MediaModel){
		return this.selectedMediaFiles.includes(file._id)
	}

	areSomeFilesSelected(){
		return this.selectedMediaFiles.length > 0 && !this.allFilesChecked;
	}

	onRefreshClicked(){
		this.refreshAction.emit();
		this.refreshButtonClicked.next();
	}
}
