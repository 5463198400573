import {Injectable, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState, GoAction, User} from '@suite/tm-common';
import { IoApiProxyService } from '@suite/ioApi';

//XXX see Magento -> Berechtigungen -> Gruppenberechtigungen
export enum MagentoUserRole
{
	UNDEFINED = "0",
	ADMIN = "1",
    CA_DIGITAL_ADMIN = "3",
	GUEST = "302",
	//... to be continued ...
	// there are many more, but we honestly don't need any more currently
}

@Injectable()
export class AuthService {

	url;

	constructor(private http: HttpClient,
				private store: Store<AppState>,
				private ioApiService: IoApiProxyService,
				@Inject('CONFIG') private config) {
	}

	setRedirectUrl(url) {
		this.url = url;
	}

	onSignin() {
		if (this.url) {
			this.store.dispatch(new GoAction({path: [this.url]}));
			this.url = undefined;
		} else {
			this.store.dispatch(new GoAction({path: ['/']}));
		}
	}

	login(email: string, password): Observable<User> {
		const endPoint = '/auth/customer';
		return this.http
			 .post<User>(`${this.config.apiUrl}${endPoint}`,
						{username: email, password: password}
			);
	}

	logout(): Observable<User> {
		return this.ioApiService.deleteSession();

	}

	logoutAdmin(): Observable<User> {
		return this.ioApiService.deleteAdminSession();
	}

	adminLogin(userName: string, password) {
		const endPoint = '/auth/admin';
		return this.http
			.post<User>(`${this.config.apiUrl}${endPoint}`,
				{userName: userName, password: password}
			);
	}
	isAdmin() {
		return this.ioApiService.isLoggedInAsAdmin();
	}
}
