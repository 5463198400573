import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {TreatmentModel} from '@suite/tm-common';
import {UpsPickupReference} from '@suite/anamnese';
import {CaOpportunity, DashboardState, InterimScanOption} from '@suite/treatments';

export enum TreatmentActionTypes {
  TreatmentRequested = '[Treatment Resolver] Treatment Requested',
  TreatmentsRequested = '[Treatments Resolver] Treatments Requested',
  TreatmentsLoaded = '[Treatments API] Treatments Loaded',
  TreatmentSelected = '[Treatments Component] Treatment Selected',
  LoadTreatments = '[Treatment] Load Treatments',
  AddTreatment = '[Treatment] Add Treatment',
  UpsertTreatment = '[Treatment] Upsert Treatment',
  UpsertTreatmentOnDb = '[Treatment] Upsert Treatment on DB',
  AddTreatments = '[Treatment] Add Treatments',
  UpsertTreatments = '[Treatment] Upsert Treatments',
  UpdateTreatment = '[Treatment] Update Treatment',
  UpdateTreatments = '[Treatment] Update Treatments',
  DeleteTreatment = '[Treatment] Delete Treatment',
  DeleteTreatments = '[Treatment] Delete Treatments',
  ClearTreatments = '[Treatment] Clear Treatments',
  NewPickUpReference = '[UPS PickUp] New PickUp Reference',
  OrderTreatmentFollower = '[Treatment] Order Treatment Follower',
  SaveDashboardState = '[Treatment Dashboard] Save Dashboard State',
  TreatmentHandlingUpdated = '[Treatment Release] Update Product Handling',
  AssignAndGoToTreatment = '[Treatment Dashboard] Assign and Go to Treatment',
  SubmitComplaint = '[Treatment] Submit Complaint',
  SubmitComplaintSuccess = '[Treatment] Submit Complaint Success',
  SubmitReleaseRequest = '[Treatment] Submit Release Request',
}

export class TreatmentRequested implements Action {
		readonly type = TreatmentActionTypes.TreatmentRequested;
		constructor(public payload: { id: string }) { }
}
export class TreatmentsRequested implements Action {
	readonly type = TreatmentActionTypes.TreatmentsRequested;
}
export class TreatmentsLoaded implements Action {
	readonly type = TreatmentActionTypes.TreatmentsLoaded;
	constructor( public payload: TreatmentModel[]) {}
}
export class TreatmentSelected implements Action {
	readonly type = TreatmentActionTypes.TreatmentSelected;
	constructor( public payload: string) {}
}
export class TreatmentHandlingUpdated implements Action {
	readonly type = TreatmentActionTypes.TreatmentHandlingUpdated;
	constructor( public payload: {}) {}
}
export class LoadTreatments implements Action {
  readonly type = TreatmentActionTypes.LoadTreatments;

  constructor(public payload: { treatments: TreatmentModel[] }) {}
}

export class AddTreatment implements Action {
  readonly type = TreatmentActionTypes.AddTreatment;

  constructor(public payload: { treatment: TreatmentModel }) {}
}

export class UpsertTreatment implements Action {
  readonly type = TreatmentActionTypes.UpsertTreatment;

  constructor(public payload: { treatment: TreatmentModel }) {}
}
export class UpsertTreatmentOnDb implements Action {
	readonly type = TreatmentActionTypes.UpsertTreatmentOnDb;

	constructor(public payload: { treatmentId: string, diff: any }) {}
}
export class AddTreatments implements Action {
  readonly type = TreatmentActionTypes.AddTreatments;

  constructor(public payload: { treatments: TreatmentModel[] }) {}
}

export class UpsertTreatments implements Action {
  readonly type = TreatmentActionTypes.UpsertTreatments;

  constructor(public payload: { treatments: TreatmentModel[] }) {}
}

export class UpdateTreatment implements Action {
  readonly type = TreatmentActionTypes.UpdateTreatment;

  constructor(public payload: { treatment: Update<TreatmentModel> }) {}
}

export class UpdateTreatments implements Action {
  readonly type = TreatmentActionTypes.UpdateTreatments;

  constructor(public payload: { treatments: Update<TreatmentModel>[] }) {}
}

export class DeleteTreatment implements Action {
  readonly type = TreatmentActionTypes.DeleteTreatment;

  constructor(public payload: { id: string }) {}
}

export class DeleteTreatments implements Action {
  readonly type = TreatmentActionTypes.DeleteTreatments;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTreatments implements Action {
  readonly type = TreatmentActionTypes.ClearTreatments;
}
export class NewPickUpReference implements Action {
	readonly type = TreatmentActionTypes.NewPickUpReference;
	constructor( public payload: UpsPickupReference) {}
}
export class OrderTreatmentFollower implements Action {
	readonly type = TreatmentActionTypes.OrderTreatmentFollower;
	constructor(public payload: {treatmentId: string, packageType: CaOpportunity, interimScanOption: InterimScanOption}) {}
}

export class SaveDashboardState implements Action {
	readonly type = TreatmentActionTypes.SaveDashboardState;
	constructor( public payload: {tab: number, state: DashboardState}) {}
}

export class AssignAndGoToTreatment implements Action {
	readonly type = TreatmentActionTypes.AssignAndGoToTreatment;
	constructor(public payload: {
		treatment: TreatmentModel,
		username: string,
		tab: number,
		state: DashboardState,
	}) { }
}

export class SubmitComplaint implements Action {
	readonly type = TreatmentActionTypes.SubmitComplaint;
	constructor(public payload: { treatment: TreatmentModel }) { }
}

export class SubmitComplaintSuccess implements Action {
	readonly type = TreatmentActionTypes.SubmitComplaintSuccess;
	constructor(public payload: { treatment: TreatmentModel }) { }
}


export class SubmitReleaseRequest implements Action {
	readonly type = TreatmentActionTypes.SubmitReleaseRequest;
	constructor(public payload: { treatmentId, releaseRequest: {releaseData, totalPlannedSteps, upgrade?} }) { }
}

export type TreatmentActions =
 | TreatmentRequested
 | TreatmentsRequested
 | TreatmentSelected
 | TreatmentsLoaded
 | LoadTreatments
 | AddTreatment
 | UpsertTreatment
 | UpsertTreatmentOnDb
 | AddTreatments
 | UpsertTreatments
 | UpdateTreatment
 | UpdateTreatments
 | DeleteTreatment
 | DeleteTreatments
 | ClearTreatments
 | NewPickUpReference
 | OrderTreatmentFollower
 | SaveDashboardState
 | TreatmentHandlingUpdated
 | AssignAndGoToTreatment
 | SubmitComplaint
 | SubmitComplaintSuccess
 | SubmitReleaseRequest;
