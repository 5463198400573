<mat-card *ngIf="productInfo">

    <img mat-card-image
         src="{{getProductImageUrl()}}"
	/>
    <img class="cat-flag"
		*ngIf="productKey === ProductUrlKey.MEMOTAIN"
         src="{{getProductCategoryImageUrl()}}"
	/>

    <mat-card-content>
        <h3 [innerHTML]="getProductNameHtml()"></h3>

        <p *ngIf="!isProfessionalProduct()">
            {{getProductDescription()}}
        </p>

        <!-- image out of magento for toothArea visualization -->
        <img *ngIf="isProfessionalProduct() && !options"
             src="{{getToothAreaImageUrl()}}"
        >
        <!-- tootharea images-->
        <div class="toothAreas" *ngIf="options" >
			<div class="jaws" [ngClass]="{'two-jaws': options.uj && options.lj}">
				<div class="jaw" *ngIf="options.uj && getToothAreaForProduct(true) as toothArea">
					<img src="assets/img/options/uj_{{toothArea}}.png"/>
                    <div>{{toothArea}}</div>
                </div>
                <div class="jaw" *ngIf="options.lj && getToothAreaForProduct(false) as toothArea">
                    <img  src="assets/img/options/lj_{{toothArea}}.png"/>
                    <div>{{toothArea}}</div>
                </div>
            </div>
        </div>
    </mat-card-content>

</mat-card>
