import {Component, Input} from '@angular/core';
import {UpsPickupPrintOverlayComponent} from '../ups-pickup-print-overlay/ups-pickup-print-overlay.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'ux-ups-pickup-print',
	templateUrl: './ups-pickup-print.component.html',
	styleUrls: ['./ups-pickup-print.component.scss']
})

export class UpsPickupPrintComponent {

	@Input() treatment;
	@Input() user;

	constructor(public dialog: MatDialog) {}

	openDialog() {
		const dialogData = {treatment: this.treatment, user: this.user};
		this.dialog.open(UpsPickupPrintOverlayComponent,
			{data: dialogData, maxWidth: '100vw', width: '100%', height: '100%'});
	}
}
