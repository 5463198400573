import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CmsBlockModel } from "../models";

@Injectable()
export class CmsService {
	baseUrl: string = "";

	constructor(private http: HttpClient, @Inject("CONFIG") private config) {
		this.baseUrl = this.config.apiUrl;
	}

	getCmsBlocks(): Observable<CmsBlockModel[]> {
		return this.http.get<CmsBlockModel[]>(`${this.baseUrl}/cms`);
	}

	getCmsBlock(key: string): Observable<CmsBlockModel> {
		return this.http.get<CmsBlockModel>(`${this.baseUrl}/cms/${key}`);
	}

	createCmsBlock(cmsBlock: CmsBlockModel) {
		const content = cmsBlock.content;
		const body = cmsBlock;
		body.content = JSON.parse(content);
		return this.http.post(`${this.baseUrl}/cms`, body);
	}

	updateCmsBlock(key: string, cmsBlock: CmsBlockModel) {
		const content = cmsBlock.content;
		const body = cmsBlock;
		body.content = JSON.parse(content);
		return this.http.patch(`${this.baseUrl}/cms/${key}`, body);
	}

	deleteCmsBlock(key: string) {
		return this.http.delete(`${this.baseUrl}/cms/${key}`);
	}
}
