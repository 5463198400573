import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {AdminsActions, AdminActionTypes} from './admins.actions';
import {createFeatureSelector} from '@ngrx/store';
import {Admin} from '../models/Admin';

export interface AdminState extends EntityState<Admin> {
	// additional entities state properties
}
export function sortByFirstName(a1: Admin, a2: Admin): number {
	return a1.firstname.localeCompare(a2.firstname);
}
export const adapter: EntityAdapter<Admin> = createEntityAdapter<Admin>(
	{
		sortComparer: sortByFirstName
	}
);

export const initialState: AdminState = adapter.getInitialState({
	// additional entity state properties
});

export const getAdminState = createFeatureSelector<AdminState>('admin');
export const {
	selectIds: selectAdminIds,
	selectEntities: selectAdminEntities,
	selectAll: selectAllAdmins,
	selectTotal: selectAdminTotal,
} = adapter.getSelectors(getAdminState);

export function reducer(
	state = initialState,
	action: AdminsActions
): AdminState {
	switch (action.type) {

		case AdminActionTypes.LoadAdmins: {
			return adapter.setAll(action.payload.admins, state);
		}

		default: {
			return state;
		}
	}
}


