import { Inject, Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { DataSource} from '@angular/cdk/collections';
import { Observable, BehaviorSubject, merge, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { AppState, TreatmentModel, TreatmentStatus } from '@suite/tm-common';
import { selectDashboardTreatments } from '../store';
import { formatDate } from '@angular/common';

//** TreatmentStatus that describe states that will not be going anywhere, anymore
const FinalizedTreatmentStatus: TreatmentStatus[] = [
	TreatmentStatus.CreditMemoIssued,
	TreatmentStatus.CanceledByCustomer,
	TreatmentStatus.Closed,
	TreatmentStatus.Shipped,
	TreatmentStatus.Postponed,
	TreatmentStatus.ComplaintResolved,
	TreatmentStatus.RequestRejected,

];

@Injectable()
export class DashboardEntriesDatasource extends DataSource<TreatmentModel> {

	private _sort: MatSort;
	private _paginator: MatPaginator;
	private _filter: string = "";
	private _data: TreatmentModel[] = null;

	get sort(): MatSort {
		return this._sort;
	}

	set sort(sort: MatSort) {
		this._sort = sort;
	}

	get filter(): string {
		return this.filterChange$$.value;
	}

	set filter(filter: string) {
		this._filter = filter.trim().toLowerCase();
		this.filterChange$$.next(filter);
	}

	set paginator(paginator: MatPaginator) {
		this._paginator = paginator;
	}

	filterChange$$ = new BehaviorSubject<string>('');

	private _dataUpdated$$ = new Subject();
	treatmentSubscription:Subscription;

	constructor(private store: Store<AppState>,
				 @Inject('CONFIG') private config ) {
		super();
	}

	connect(): Observable<TreatmentModel[]> {

		this.treatmentSubscription = this.store
			.pipe( select(selectDashboardTreatments()) )
				.subscribe((treatments: TreatmentModel[]) => {
				this._data = treatments;
				this._dataUpdated$$.next(true);
			});

		// create Observable which acts on treatmentChange, filterChange, pageChange, sortChange
		const mergedObservables = merge(
			this._paginator.page,
			this._sort.sortChange,
			this._dataUpdated$$ as Observable<any>,
			this.filterChange$$ as Observable<string>
		);

		return mergedObservables.pipe(
			// filter
			map(() => {
				if (this._filter !== '') {
					return this.filterData();
				}
				return this._data;
			}),

			// sort
			map(data => {
				if (this._sort.direction !== '') {
					data.sort((a,b) => this.compareTreatmentsFn(a,b)); // asc
					if (this._sort.direction === 'desc') {
						data.reverse();
					}
				} else { // Default, natural order of treatments: creation date.
					data.sort(
						(a, b) =>
							new Date(b.created).getTime() -
							new Date(a.created).getTime()
					);
				}
				return data;
			}),

			// pagination
			map((data) => {
				if (this._paginator.length !== data.length) {
					this._paginator.pageIndex = 0;
					this._paginator.length = data.length;
				}
				return data.slice(this._paginator.pageIndex * this._paginator.pageSize, (this._paginator.pageIndex + 1) * this._paginator.pageSize);
			}),
		);
	}



	filterData(): TreatmentModel[] {
		return this._data.filter((data) => {
			if (this._filter === '*' ) {
				return data.neededAction;
			}
			if (!data || !data.patient) {
				return false;
			}
			return (
				data.patient.lastName.toLowerCase().indexOf(this._filter) !== -1 ||
				data.patient.firstName.toLowerCase().indexOf(this._filter) !== -1 ||
				data.patient.patientId.toLowerCase().indexOf(this._filter) !== -1 ||
				(data.schema_version >= 2 && data.quotation.docNum.indexOf(this._filter) !== -1) ||
				(data.schema_version < 2 && data.orders.some((order) => order.realId?.toLocaleLowerCase().indexOf(this._filter) >= 0)) ||
				formatDate(new Date(data.statusChanged), 'medium', this.config.locale).indexOf(this._filter) !== -1);
		});
	}

	compareTreatmentsFn (a: TreatmentModel, b: TreatmentModel): number {
		switch (this._sort.active) {
			case "needForAction":
				return this.compareByNeedForAction(a, b);
			default:
				const aCompVal = this.mapToCompareValueForProperty(a, this._sort.active);
				const bCompVal = this.mapToCompareValueForProperty(b, this._sort.active);

				if (typeof aCompVal === "number") {
					return aCompVal - bCompVal;
				} else if (typeof aCompVal === "string") {
					return aCompVal.localeCompare(bCompVal);
				} else {
					return 0;
				}
		}
	}

	mapToCompareValueForProperty(data: TreatmentModel, sortByPropertyKey: string) : string | number | any {
		// return data to sort for combined cells
		switch (sortByPropertyKey) {
			case 'patient':
				let compareString = "";
				if (data.patient) {
					compareString =
						data.patient.lastName +
						data.patient.firstName +
						data.patient.patientId;
				}
				return compareString;
			case 'product':
				return data.name;
			case 'status':
				return new Date(data.statusChanged).getTime();
			default:
				return data[sortByPropertyKey];
		}
	}

	// ImplNote: An Array.sort compareFunc should return negative values to indicate that "'a' should have smaller index than 'b'".
	// This compare function is meant to establish an order such that the "highest priority" will receive the smallest index.
	// I.e. the result must always be negative, if 'a' has a higher priority than 'b'.
	compareByNeedForAction(a: TreatmentModel, b: TreatmentModel){
		const actionPriorityComp = b.needForAction - a.needForAction; // => negative, if 'a' is larger.
		if (actionPriorityComp != 0) {
			// Need for action is higher for one of the two.
			return actionPriorityComp;
		}

		if (a.needForAction != 0) {
			// Need for action is equal and pressing, so we sort by recency of the call to action,
			// from oldest, i.e. most time-critical, to newest.
			return (
				new Date(a.statusChanged).getTime() -
				new Date(b.statusChanged).getTime() // => negative, if 'a' timestamp is smaller, i.e. less recent.
			);
		}

		const aStateIsFinal = FinalizedTreatmentStatus.includes(a.status as TreatmentStatus);
		const bStateIsFinal = FinalizedTreatmentStatus.includes(b.status as TreatmentStatus);
		if (aStateIsFinal != bStateIsFinal) {
			// One of the treatments is done and the other is being processed.
			return aStateIsFinal ? 1 : -1; // => negative, if 'a' order is still running, but 'b' is done.
		}

		// If none of the above holds, we sort by the date of last modification.
		return (
			new Date(b.lastModified).getTime() -
			new Date(a.lastModified).getTime() // => negative, if 'a' timestamp is larger, i.e. more recent.
		);
	}

	disconnect() {
		this.treatmentSubscription.unsubscribe();
	}
}
