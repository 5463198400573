<div class="setup">
	<h3>{{'Addresses' | translate}}</h3>
	<h4>{{'Billing Address' | translate}}</h4>
	<span>{{billingAddress.AddressName2}}</span><br>
	<ng-container *ngIf="billingAddress.AddressName3"><span>{{billingAddress.AddressName3}}</span><br></ng-container>
	<span>{{billingAddress.Street}}</span><br>
	<span>{{billingAddress.ZipCode}} {{billingAddress.City}}</span><br>
	<span>{{getTextForCountryCode(billingAddress.Country)}}</span>

	<ng-container *ngIf="hasUniqueShippingAddress">
		<h4>{{'Shipping Address' | translate}}</h4>
		<span>{{shippingAddress.AddressName2}}</span><br>
		<ng-container *ngIf="shippingAddress.AddressName3"><span>{{shippingAddress.AddressName3}}</span><br></ng-container>
		<span>{{shippingAddress.Street}}</span><br>
		<span>{{shippingAddress.ZipCode}} {{shippingAddress.City}}</span><br>
		<span>{{getTextForCountryCode(shippingAddress.Country)}}</span>
	</ng-container>

	<form [formGroup]="form">
		<ng-container *ngIf="!hasUniqueShippingAddress">
			<h4>{{'Shipping Address' | translate}}</h4>
			<mat-form-field class="large">
				<mat-label>{{'Your Shipping Address' | translate}}</mat-label>
				<mat-select
				[(value)]="formFields.shippingAddress"
				formControlName="shippingAddress"
				(selectionChange)="onShippingAddressSelection()"
				placeholder="{{'Your Shipping Address' | translate}}">
				<mat-option *ngFor="let address of shippingAddresses" [value]="address.AddressName">
					{{ address.AddressName2}}, {{address.AddressName3 ? address.AddressName3 + ", " : ''}}{{address.Street}}, {{address.ZipCode}} {{address.City}}, {{getTextForCountryCode(address.Country)}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		</ng-container>

		<h4>{{'Shipment' | translate}}</h4>
		<ng-container *ngIf="!isDataVariant else download">
			<mat-form-field class="large">
				<mat-label>{{'Your Shipment' | translate}}</mat-label>
				<mat-select
					[(value)]="formFields.shippingType"
					(selectionChange)="onShippingTypeSelection()"
					formControlName="shippingType"
					placeholder="{{'Your Shipment' | translate}}">
					<mat-option *ngFor="let shippingType of shippingTypes" [value]="shippingType">
						{{getTextForShippingType(shippingType)}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-container>
		<ng-template #download>
			<span>{{getTextForDownloadShipment()}}</span>
		</ng-template>
	</form>

	<h4>{{'Your Payment' | translate}}</h4>
	<span>{{'on account' | translate  }}</span>

</div>
<div class="flex-spacer"></div>
<ux-next-back-actions
	(action)="onAction($event)"
	[disabledNext]="disableNext">
</ux-next-back-actions>
