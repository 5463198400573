import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';


@Injectable()
export class NotificationService {

	public notify: Subject<{}> = new Subject();

	constructor(private translate: TranslateService) {}

	showErrorMessage(message, errorDetail = null, duration?: number) {
		let tr;
		try {
			tr = this.translate.instant(message);
		} catch (e) {
			tr = message;
		} finally {
			this.notify.next({type: 'error', message: tr + (errorDetail ? ` (${errorDetail})` : ''), duration: duration});
		}
	}

	showSuccessMessage(message, duration?: number) {
		let tr;
		try {
			tr = this.translate.instant(message);
		} catch (e) {
			tr = message;
		} finally {
			this.notify.next({type: 'success', message: tr, duration: duration});
		}
	}

}
