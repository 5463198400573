<h3>{{translationKeyPrefix + '.title' | translate}}</h3>

<p [innerHTML]="translationKeyPrefix + '.headerText' | translate"></p>

<ng-container *ngIf="hasUpperJaw">
	<ng-container
	 [ngTemplateOutlet]="toothAreaOptions"
	 [ngTemplateOutletContext]="{jawType: JAW_TYPE.UPPER_JAW}"
	 >
	</ng-container>
</ng-container>

<ng-container *ngIf="hasLowerJaw">
	<ng-container
	[ngTemplateOutlet]="toothAreaOptions"
	[ngTemplateOutletContext]="{jawType: JAW_TYPE.LOWER_JAW}"
	>
   </ng-container>
</ng-container>

<!-- <pre>{{(storedSelections$ | async) | json}}</pre> -->

<ng-template #toothAreaOptions let-jawType="jawType">
	<h4>{{jawType | translate}}</h4>
    <div class="wrapper">
        <div *ngFor="let toothArea of TOOTH_AREA_OPTIONS"
		[ngClass]="{selected: (storedSelections$ | async)[jawType] === toothArea }"
		(click)="onSelect(jawType, toothArea)"
        >
		<img class="normal" src="./assets/img/options/{{jawType}}_{{toothArea}}.png"/>
            <img class="hover" src="./assets/img/options/{{jawType}}_ht_{{toothArea}}.png"/>
            <div class="caption">{{toothArea}}</div>
        </div>
    </div>
</ng-template>
