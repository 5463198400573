import {Component, Inject} from '@angular/core';

@Component({
	selector: 'ux-page-footer',
	templateUrl: './page-footer.component.html',
	styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent {

	hasContrast = true;
	visible = false;
	currentYear = new Date().getFullYear();

	constructor(  @Inject('CONFIG') public config) {}

	toggle() {
		this.visible = !this.visible;
	}
}
