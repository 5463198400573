import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import {
	MessageModel,
	MessagesState,
	selectMessagesOfCurrentTreatment,
	sortByCreated
} from '@suite/messages';

import { MessagesViewAdminComponent } from "../../components/messages-view-admin/messages-view-admin.component";
import { selectCurrentTreatment } from '@suite/treatments';

@Component({
	selector: 'ux-treatment-messages-admin',
	standalone: true,
	imports: [CommonModule, MessagesViewAdminComponent],
	templateUrl: './treatment-messages-admin.component.html',
	styleUrls: ['./treatment-messages-admin.component.scss']
})
export class TreatmentMessagesAdminComponent {
	private store = inject(Store<MessagesState>);

	protected messages$ = this.store.pipe(
		select(selectMessagesOfCurrentTreatment),
		map((data) => data.map((msg) => ({ ...msg, message: this.getMessageHtml(msg) }))),
		map((data) => data.sort(sortByCreated))
	);

	protected currentTreatment$ = this.store.pipe(
		select(selectCurrentTreatment),
		filter(treatment => !!treatment)
	);

	private getMessageHtml(messageModel: MessageModel) {
		if (messageModel.message.includes("/ca-viewer/#/view")) {
			// The message appears to contain a web-viewer url (possibly plain text),
			// so to make sure they are clickable, we wrap them in <a> HTML-Tags if needed.

			// Regex: Matches web-viewer URLs that are NOT already part of an <a> tag or attribute value (e.g. href="...")
			const urlRegex: RegExp =
				/(?<!<\/a>)(https?:\/\/[^\s<>"']+[a-z0-9]{24})(?![^<>]*<\/a>)(?![^<>]*href=)(?![^<>]*['"])/g;
			return messageModel.message.replace(
				urlRegex,
				`<a href="$1" target="_blank">$1</a>`
			);
		} else {
			return messageModel.message;
		}
	}
}
