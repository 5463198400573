import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@suite/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PatientsListComponent} from './patients-list/patients-list.component';
import {PatientDetailsComponent} from './patient/patient-details/patient-details.component';
import {PatientEditComponent} from './patient/patient-edit/patient-edit.component';
import {PatientsInitialComponent} from './patients-initial/patients-initial.component';
import { PatientsComponent } from './patients.component';
import { PatientTestComponent } from './patient/patient-test/patient-test.component';
import { PatientComponent } from './patient/patient.component';
import {UxComponentsModule} from '@suite/ux-components';
import { CaTranslateModule } from '@suite/translate';
import { TmCommonModule } from '@suite/tm-common';

@NgModule({
	imports: [
		CommonModule,
		UxComponentsModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		CaTranslateModule,
		TmCommonModule
	],

	declarations: [
		PatientsComponent,
		PatientsListComponent,
		PatientsInitialComponent,
		PatientComponent,
		PatientDetailsComponent,
		PatientEditComponent,
		PatientTestComponent


	],
	exports: [
		PatientsInitialComponent,
		PatientsListComponent,
		PatientsComponent,
		PatientDetailsComponent,
		PatientEditComponent
	]
})
export class AppPatientsModule {
}

