import { MagentoPayment, MagentoProduct } from '@suite/magento';
import { DataTransferMethod } from '@suite/treatments/models';
import { SapAddress, SapJaws, SapShippingCostItem } from 'libs/sap/src/lib/models/sap.model';

export interface ProductModel {
	name?: string;
	key: string;
	productConfigs: ProductConfig[];
	isSetupProduct: boolean;
	description?: string;
	disallowedCountries?: string[];
	disallowedWms?: string[];
	lastModified?: Date;
	created?: Date;

	/** Flag indicating whether the product has explict configs for upper+lower bundle configs. */
	hasBundleConfigs: boolean;
}

export interface ProductConfig {
	jaws: string,
	method: string,
	range?: ToothArea,
	items: ProductItem[]
}

export interface ProductItem {
	itemCode: string,
	quantity: number,
	jaws: SapJaws,
	steps?: string,
	range?: ToothArea
}

export interface ProductInfoMagento {
	urlKey: string; 		// mage url key of category

	// Only present or at all relevant in Aligner products:
	steps?: number[];
	corrections?: number[];

	// DEPRECATED or/and may be removed in future:
	/** @deprecated */
	brand?: string; // al
	/** @deprecated */
	line?: string;  // product url key part before first dash eg.: alca2
	/** @deprecated */
	level?: string;  // product url key without first part eg.: professionallite
	/** @deprecated */
	title?: string;
	/** @deprecated */
	description?: string;
	/** @deprecated */
	toothArea?: string;
	/** @deprecated Unused */
	alignerPerStep?: number;
	/** @deprecated Only relevant order process. To be removed in #186 */
	categoryId?: number;	    // mage category id
	/** @deprecated Unused since #386 (v1.10.0). */
	image?: string;
	/** @deprecated Only used as a flag since #386 (v1.10.0).  */
	mainCategoryLabel?: string;
	/** @deprecated  Only relevant order process. Should not be accessed later.*/
	price?: number;
}
export interface ProductOptions {
	dataTransfer: DataTransferMethod;
	uj: ToothArea;
	lj: ToothArea;
	/**@deprecated */
	custom?: CustomProductOptions
	/**@deprecated */
	dataUpload?: boolean;
}

/**@deprecated */
export interface CustomProductOptions {
	zahnbereich?: {
		uj?: {
			value: string // e.g. "2-2", "3-3", "4-4"
		}
		lj?: {
			value: string // e.g. "2-2", "3-3", "4-4"
		}
	}

	/** @deprecated LEGACY (individua). Don't use. */
	specification?: any;
}

/** Related to DataTransferMethod, but describes whether the models are physical or digital */
export enum DataTransferType {
	DIGITAL_UPLOAD = "Upload",
	PHYSICAL_MODELS = "Modelle"
}

export const DataTypeForTransferMethod: Record<DataTransferMethod, DataTransferType> = {
	[DataTransferMethod.UPLOAD]: DataTransferType.DIGITAL_UPLOAD,
	[DataTransferMethod.UPS]: DataTransferType.PHYSICAL_MODELS,
	[DataTransferMethod.OTHER]: DataTransferType.PHYSICAL_MODELS
};

export interface Product {
	info: ProductInfoMagento;
	options: Partial<ProductOptions>;
	handling?: {
		shipment?: SapShippingCostItem;
		shippingAddressCode?: string;
		shipping?: SapAddress; //only used in redux store in TM
		billing?: SapAddress;  //only used in redux store in TM
		/**@deprecated */
		payment?: MagentoPayment;
	};

	/**@deprecated */
	mainArticles?: MagentoProduct[];
	/**@deprecated */
	stepArticles?: MagentoProduct[];
	/**@deprecated */
	corrArticles?: MagentoProduct[];
}

export type ToothAreaCombined = {
	UJ: ToothArea,
	LJ: ToothArea
}

export enum ToothArea {
	NONE = "",
	TWO_TWO = "2-2",
	THREE_THREE = "3-3",
	FOUR_FOUR = "4-4",
	FIVE_FIVE = "5-5",
	SIX_SIX = "6-6",
	SEVEN_SEVEN = "7-7"
}

/**@deprecated */
export enum ProductBrand {
	ALIGNER = "ca",
	MEMOTAIN = "mt",
	RETENTIONSSCHIENE = "retentionsschiene",
	LEGACY_ALIGNER_PRO = "cap" // LEGACY (never seen in database)
}

/**@deprecated */
export enum ProductLine {
	DATEN = "daten",
	SCHIENE = "schiene",
	CAM = "cam", // MEMOTAIN
	LEGACY_PROFESSIONAL = "professional" // LEGACY (last modified treatment on 2020-06-02)
}

/**@deprecated */
export enum ProductLevel {
	PRO_SMILE = "professionalx",
	PRO_LITE = "professionallite",
	PRO_FULL = "professionalfull",
	CAM = "cam", // MEMOTAIN
	LEGACY_CA_AESTHETIC = "aesthetic", // LEGACY
	LEGACY_CA_ESSENTIAL = "essential", // LEGACY
	LEGACY_PROFESSIONAL = "professional" // LEGACY (last modified treatment in 2019)
}

export const ProductStepLimit = {
	PRO_SMILE_DEFAULT: 10,
	PRO_SMILE_CORRECTION: 5,
	PRO_SMILE_TOTAL: 15,
	PRO_LITE_DEFAULT: 20,
	PRO_LITE_CORRECTION: 10,
	PRO_LITE_TOTAL: 30,
	PRO_FULL_DEFAULT: 60,
	PRO_FULL_CORRECTION: 20,
	PRO_FULL_TOTAL: 260, //actually this is "infinite"
}

export enum ProductUrlKey {
	PRO_SMILE_SCHIENE = "ca-schiene-professionalx",
	PRO_LITE_SCHIENE = "ca-schiene-professionallite",
	PRO_FULL_SCHIENE = "ca-schiene-professionalfull",
	PRO_SMILE_DATEN = "ca-daten-professionalx",
	PRO_LITE_DATEN = "ca-daten-professionallite",
	PRO_FULL_DATEN = "ca-daten-professionalfull",
	MEMOTAIN = "cad-cam",
	RETENTIONSSCHIENE = "retentionsschiene",
	ARTICLES = "articles",
	CATEGORY_ALIGNER = "al",
	CATEGORY_ALIGNER_DATEN = "cap-daten",
	CATEGORY_ALIGNER_SCHIENE = "cap-schiene",
	CATEGORY_PROFESSIONAL = "cap",
	CATEGORY_MEMOTAIN = "mt",
	CATEGORY_TM_PRODUCTS = "tm-products",
	LEGACY_CATEGORY_CLEAR_ALIGNER = "ca",
	LEGACY_CATEGORY_CLEAR_ALIGNER_DATEN = "ca-daten",
	LEGACY_CATEGORY_CLEAR_ALIGNER_SCHIENE = "ca-schiene",
	LEGACY_CATEGORY_CLEAR_ALIGNER_TIEFZIEH = "ca-tiefzieh",
	LEGACY_ESSENTIAL_DATEN = "ca-daten-essential",
	LEGACY_AESTHETIC_DATEN = "ca-daten-aesthetic",
	LEGACY_ESSENTIAL_TIEFZIEH = "ca-tiefzieh-essential",
	LEGACY_AESTHETIC_TIEFZIEH = "ca-tiefzieh-aesthetic",
	LEGACY_ESSENTIAL_SCHIENE = "ca-schiene-essential",
	LEGACY_AESTHETIC_SCHIENE = "ca-schiene-aesthetic",
	LEGACY_CATEGORY_INDIVIDUA = "id"
}

export const PROFESSIONAL_PRODUCT_KEYS = [
		ProductUrlKey.PRO_FULL_SCHIENE,
		ProductUrlKey.PRO_FULL_DATEN,
		ProductUrlKey.PRO_LITE_SCHIENE,
		ProductUrlKey.PRO_LITE_DATEN,
		ProductUrlKey.PRO_SMILE_SCHIENE,
		ProductUrlKey.PRO_SMILE_DATEN,
]

export const LEGACY_PRODUCT_KEYS = [
	ProductUrlKey.LEGACY_ESSENTIAL_DATEN,
	ProductUrlKey.LEGACY_AESTHETIC_DATEN,
	ProductUrlKey.LEGACY_ESSENTIAL_TIEFZIEH,
	ProductUrlKey.LEGACY_AESTHETIC_TIEFZIEH,
	ProductUrlKey.LEGACY_ESSENTIAL_SCHIENE,
	ProductUrlKey.LEGACY_AESTHETIC_SCHIENE,
]

export const PROFESSIONAL_PRODUCT_SCHIENE_KEYS = [
	ProductUrlKey.PRO_FULL_SCHIENE,
	ProductUrlKey.PRO_LITE_SCHIENE,
	ProductUrlKey.PRO_SMILE_SCHIENE,
]

export const PROFESSIONAL_PRODUCT_DATA_KEYS = [
		ProductUrlKey.PRO_FULL_DATEN,
		ProductUrlKey.PRO_LITE_DATEN,
		ProductUrlKey.PRO_SMILE_DATEN,
]

export enum QuestionnaireScopeLevel {
	SHORT,		//former 'level1'
	COMPLETE,	//former 'level2'
	ABBREVIATED	//former 'level3'
}

/** The default treatment name of the API-variant of the MEMOTAIN product. */
export const MEMOTAIN_PARTNER_API_TREATMENT_NAME = "Service API Memotain Preparation";

/** The 'virtual' product info title of the API-variant of the MEMOTAIN product. */
export const MEMOTAIN_DISTRIBUTION_PRODUCT_TITLE = "MEMOTAIN (DISTRIBUTION)";

/** The default treatment name of an API-variant of a MEMOTAIN bulk order (multiorder). */
export const MEMOTAIN_BULK_ORDER_PARTNER_API_TREATMENT_NAME = "Service API Distribution Memotain Bulk Order";

/** The 'virtual' product info title of the API-variant of the MEMOTAIN bulk order. */
export const MEMOTAIN_BULK_ORDER_DISTRIBUTION_PRODUCT_TITLE = "MEMOTAIN BULK ORDER (DISTRIBUTION)";

/** The 'virtual' product info of the API-variant of the MEMOTAIN product. */
export const MEMOTAIN_DISTRIBUTION_PRODUCT_INFO: ProductInfoMagento = {
	brand: ProductBrand.MEMOTAIN,
	level: ProductLevel.CAM,
	line: ProductLine.CAM,
	title: MEMOTAIN_DISTRIBUTION_PRODUCT_TITLE,
	urlKey: ProductUrlKey.MEMOTAIN
};

export const DATEN_VARIANTE = "Daten-Variante";
export const SCHIENEN_VARIANTE = "Schienen-Variante";

export interface ProductInfo {
	name: string;
	variantName?: string;
	descriptionKey?: string;
	steps?: number[],
	corrections?: number[],
	toothArea?: string,
	questionnaireScopeLevel?: QuestionnaireScopeLevel
}

export const ProductInfoForKey: Record<ProductUrlKey, ProductInfo> = {
	[ProductUrlKey.PRO_SMILE_SCHIENE]: {
		name: "PROFESSIONAL SMILE",
		variantName: SCHIENEN_VARIANTE,
		steps: [10],
		corrections: [5],
		toothArea: ToothArea.FIVE_FIVE,
		questionnaireScopeLevel:QuestionnaireScopeLevel.SHORT
	},
	[ProductUrlKey.PRO_LITE_SCHIENE]: {
		name: "PROFESSIONAL LITE",
		variantName: SCHIENEN_VARIANTE,
		steps: [20],
		corrections: [10],
		toothArea: ToothArea.SEVEN_SEVEN,
		questionnaireScopeLevel:QuestionnaireScopeLevel.COMPLETE
	},
	[ProductUrlKey.PRO_FULL_SCHIENE]: {
		name: "PROFESSIONAL FULL",
		variantName: SCHIENEN_VARIANTE,
		steps: [20, 20, 20],
		corrections: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
		toothArea: ToothArea.SEVEN_SEVEN,
		questionnaireScopeLevel:QuestionnaireScopeLevel.COMPLETE
	},
	[ProductUrlKey.PRO_SMILE_DATEN]: {
		name: "PROFESSIONAL SMILE",
		variantName: DATEN_VARIANTE,
		steps: [10],
		corrections: [5],
		toothArea: ToothArea.FIVE_FIVE,
		questionnaireScopeLevel:QuestionnaireScopeLevel.SHORT
	},
	[ProductUrlKey.PRO_LITE_DATEN]: {
		name: "PROFESSIONAL LITE",
		variantName: DATEN_VARIANTE,
		steps: [20],
		corrections: [10],
		toothArea: ToothArea.SEVEN_SEVEN,
		questionnaireScopeLevel:QuestionnaireScopeLevel.COMPLETE
	},
	[ProductUrlKey.PRO_FULL_DATEN]: {
		name: "PROFESSIONAL FULL",
		variantName: DATEN_VARIANTE,
		steps: [20, 20, 20],
		corrections: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
		toothArea: ToothArea.SEVEN_SEVEN,
		questionnaireScopeLevel:QuestionnaireScopeLevel.COMPLETE
	},
	[ProductUrlKey.MEMOTAIN]: {
		name: "MEMOTAIN®",
		variantName: "Lingual Retainer",
		descriptionKey: "MemotainDescription", //needs to be translated by component
	},
	[ProductUrlKey.RETENTIONSSCHIENE]: {
		name: "Retentionsschiene",
		toothArea: ToothArea.SEVEN_SEVEN,
		descriptionKey: "RetentionsschieneDescription", //needs to be translated by component
	},
	[ProductUrlKey.LEGACY_ESSENTIAL_DATEN]: {
		name: "ESSENTIAL",
		variantName: "Daten-Variante",
	},
	[ProductUrlKey.LEGACY_AESTHETIC_DATEN]: {
		name: "AESTHETIC",
		variantName: "Daten-Variante",
	},
	[ProductUrlKey.LEGACY_ESSENTIAL_TIEFZIEH]: {
		name: "ESSENTIAL",
		variantName: "Tiefzieh-Variante",
	},
	[ProductUrlKey.LEGACY_AESTHETIC_TIEFZIEH]: {
		name: "AESTHETIC",
		variantName: "Tiefzieh-Variante",
	},
	[ProductUrlKey.LEGACY_ESSENTIAL_SCHIENE]: {
		name: "ESSENTIAL",
		variantName: "Schienen-Variante",
	},
	[ProductUrlKey.LEGACY_AESTHETIC_SCHIENE]: {
		name: "AESTHETIC",
		variantName: "Schienen-Variante",
	},
	[ProductUrlKey.ARTICLES]: undefined,
	[ProductUrlKey.CATEGORY_ALIGNER]: undefined,
	[ProductUrlKey.CATEGORY_ALIGNER_DATEN]: undefined,
	[ProductUrlKey.CATEGORY_ALIGNER_SCHIENE]: undefined,
	[ProductUrlKey.CATEGORY_PROFESSIONAL]: undefined,
	[ProductUrlKey.CATEGORY_MEMOTAIN]: undefined,
	[ProductUrlKey.CATEGORY_TM_PRODUCTS]: undefined,
	[ProductUrlKey.LEGACY_CATEGORY_CLEAR_ALIGNER]: undefined,
	[ProductUrlKey.LEGACY_CATEGORY_CLEAR_ALIGNER_DATEN]: undefined,
	[ProductUrlKey.LEGACY_CATEGORY_CLEAR_ALIGNER_SCHIENE]: undefined,
	[ProductUrlKey.LEGACY_CATEGORY_CLEAR_ALIGNER_TIEFZIEH]: undefined,
	[ProductUrlKey.LEGACY_CATEGORY_INDIVIDUA]: undefined
};
