import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@suite/material';

import {SigninComponent} from './signin';

import {AuthTokenGuard, AuthGuard, AuthService} from './services';
import {ForgotPasswordService} from './services/forgot-password.service';
import {StoreModule} from '@ngrx/store';
import * as fromAuth from './store/auth.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store';
import { CookieService } from 'ngx-cookie-service';
import {CaTranslateModule} from '@suite/translate';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthHttpInterceptor} from './services/auth.http.interceptor';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MaterialModule,
		ReactiveFormsModule,
		CaTranslateModule,
		StoreModule.forFeature('auth', fromAuth.reducer),
		EffectsModule.forFeature([AuthEffects])
	],
	declarations: [
		SigninComponent
	],
	exports: [
		SigninComponent,
	],
	providers: [
		AuthService,
		AuthGuard,
		AuthTokenGuard,
		CookieService,
		ForgotPasswordService,
		{provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true}
	]
})
export class AuthModule {

	constructor() {
	}
}

