import {Component, EventEmitter, Input, Output} from '@angular/core';

import {ACTION, AppState} from '@suite/tm-common';

import { Store } from '@ngrx/store';
import { AnamneseUploadType, MediaModel, MediaType } from 'libs/media/src/lib/models/media.model';
import { FileEventType } from 'libs/media/src/lib/components/file-upload';
import { MediaFileUploadedAction } from 'libs/anamnese/src/lib/store/anamnese.actions';

@Component({
	selector: 'ux-upload-stl',
	templateUrl: './upload-stl.component.html',
	styleUrls: ['./_upload-stl.component.scss']
})
export class UploadStlComponent {
	@Input() treatment;
	@Input() patientId: string;
	//pass through variable for file upload box
	@Input() uploadedFiles: { uj: Partial<MediaModel>, lj: Partial<MediaModel> } = {uj: {}, lj: {}};
	@Input() bothJawStlRequired: true;

	@Output() action = new EventEmitter<any>();

	MediaType = MediaType;

	constructor(private store: Store<AppState>) {
	}

	onFileEvent($event, isUpperJaw: boolean) {

		const mediaId = $event.payload?.id
		const orgFilename = $event.payload?.orgFilename;
		const uploadedJawType = isUpperJaw ? AnamneseUploadType.UJ : AnamneseUploadType.LJ;

		switch ($event.type) {

			// better handling see in order.component.ts from directMemotain
			case FileEventType.UPLOADED:
				//check if file was uploaded after treatment creation or during anamnese
				if( !$event.payload.treatment ){
					//store objectId of uploaded media file in reduxstore
					this.store.dispatch(new MediaFileUploadedAction(uploadedJawType, mediaId, orgFilename) )
				}
				// inform parent about uploaded file
				this.action.emit({type: ACTION.UPLOADED, payload: {mediaId: mediaId, uploadType: uploadedJawType} });
				break;

			case FileEventType.ERROR:
				console.error('File Upload Error', $event.payload);
				break;
			case FileEventType.DELETED:
				this.action.emit({type: ACTION.DELETED, payload: {uploadType: uploadedJawType} });
				break;
		}


	}
}
