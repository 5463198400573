import {Action} from '@ngrx/store';
import {NavigationExtras} from '@angular/router';

export enum StoreActionTypes {
	ClearState = '[Logout] ClearState'
}

export class ClearStateAction implements Action {
	readonly type = StoreActionTypes.ClearState;
}

export enum RouterActionTypes {
	GoAction = '[Router] Go',
	GoSignInFromNoHttpAuthAction = '[Http Interceptor via Epic] Go to signin',
	BackAction = '[Router] Back',
	ForwardAction = '[Router] Forward',
}

export class GoAction implements Action {
	readonly type = RouterActionTypes.GoAction;

	constructor(
		public payload: { path: any[]; query?: object; extras?: NavigationExtras; }
	) {}
}
export class GoSignInFromNoHttpAuthAction implements Action {
	readonly type = RouterActionTypes.GoSignInFromNoHttpAuthAction;
	public payload;
	constructor() {
		this.payload = {path: ['/signin']};
	}
}

export class BackAction implements Action {
	readonly type = RouterActionTypes.BackAction;
}

export class ForwardAction implements Action {
	readonly type = RouterActionTypes.ForwardAction;
}

export type RouterActions =
	GoAction
    | GoSignInFromNoHttpAuthAction
	| BackAction
	| ForwardAction;
