<mat-accordion>
	<mat-expansion-panel *ngFor="let item of items.items;">
	  <mat-expansion-panel-header>
		<mat-panel-title>
		  {{item.question}}
		</mat-panel-title>
	  </mat-expansion-panel-header>
	  <p>{{item.answer}}</p>
	</mat-expansion-panel>
</mat-accordion>
