import { createFeatureSelector } from "@ngrx/store";

import { User } from "@suite/tm-common/models";
import { AuthActions, AuthActionTypes } from "./auth.actions";

export interface AuthState {
	signedIn: boolean;
	isAdmin: boolean;
	user: User;
	redirectUrl: string;
}

export const initialAuthState: AuthState = {
	signedIn: false,
	isAdmin: false,
	user: undefined,
	redirectUrl: undefined,
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export function reducer(state = initialAuthState, action: AuthActions): AuthState {
	switch (action.type) {
		case AuthActionTypes.LoginAction:
			return {
				...state,
				signedIn: true,
				user: action.payload as User
			};
		case AuthActionTypes.LoginAdminAction:
			return {
				...state,
				signedIn: true,
				isAdmin: true,
				user: {...action.payload, tm_admin: '1'}
			};

		case AuthActionTypes.LogoutAction:
		case AuthActionTypes.NoHttpAuthAction:
			return {
				...state,
				signedIn: false,
				isAdmin: false,
				user: undefined
			};

		case AuthActionTypes.SaveRedirectUrlAction:
			return {
				...state,
				redirectUrl: action.payload
			};

		default:
			return state;
	}
}
