import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {PatientModel} from '@suite/tm-common';
import {PatientActions, PatientActionTypes} from './patient.actions';
import {createFeatureSelector} from '@ngrx/store';

export interface PatientState extends EntityState<PatientModel> {
	// additional entities state properties
}

export const adapter: EntityAdapter<PatientModel> = createEntityAdapter<PatientModel>();

export const initialState: PatientState = adapter.getInitialState({
	// additional entity state properties
});

export const getPatientState = createFeatureSelector<PatientState>('patient');
export const {
	selectIds: selectPatientEntitiesIds,
	selectEntities: selectPatientEntities,
	selectAll: selectAllPatients,
	selectTotal: selectPatientTotal,
} = adapter.getSelectors(getPatientState);

export function reducer(
	state = initialState,
	action: PatientActions
): PatientState {
	switch (action.type) {

		case PatientActionTypes.AddPatient: {
			return adapter.addOne(action.payload.patient, state);
		}

		case PatientActionTypes.UpsertPatient: {
			return adapter.upsertOne(action.payload.patient, state);
		}

		case PatientActionTypes.AddPatients: {
			return state;
			// return adapter.addMany(action.payload.patients, state);
		}

		case PatientActionTypes.UpsertPatients: {
			return adapter.upsertMany(action.payload.patients, state);
		}

		case PatientActionTypes.UpdatePatient: {
			return adapter.updateOne(action.payload.patient, state);
		}

		case PatientActionTypes.UpdatePatients: {
			return adapter.updateMany(action.payload.patients, state);
		}

		case PatientActionTypes.DeletePatient: {
			return adapter.removeOne(action.payload.id, state);
		}

		case PatientActionTypes.DeletePatients: {
			return adapter.removeMany(action.payload.ids, state);
		}

		case PatientActionTypes.LoadPatients: {
			return adapter.setAll(action.payload.patients, state);
		}
		case PatientActionTypes.NewPatientTreatment: {
			const patient = structuredClone(state.entities[action.payload.patientId]);
			return {
				...state,
				entities: {...state.entities, [action.payload.patientId]: patient}
			};
		}
		case PatientActionTypes.ClearPatients: {
			return adapter.removeAll(state);
		}

		default: {
			return state;
		}
	}
}


