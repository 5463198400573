import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {CustomSerializer} from '@suite/tm-common';
import {AuthGuard, SigninComponent} from '@suite/auth';
import {QuestionairePrintDialogComponent} from '@suite/questionaire';
import {TreatmentsResolver} from '@suite/treatments';

import {CustomerAddressesResolver} from '@suite/customer';
import {PatientsResolver} from '@suite/patients';
import {AnamneseComponent} from './anamnese/anamnese.component';
import {TreatmentsComponent} from './treatments/treatments.component';
import {PatientsComponent} from './patients/patients.component';
import {PatientComponent} from './patients/patient/patient.component';
import {PatientsInitialComponent} from './patients/patients-initial/patients-initial.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {HelpComponent} from './help/help.component';
import {MediaResolver} from '@suite/media';
import {MessagesResolver} from '../../../../libs/messages/src/lib/services/messages.resolver';
import { ShippingCostResolver } from 'libs/sap/src/lib/services/shipping-cost.resolver';

const routes: Routes = [


	{path: '', redirectTo: '/dashboard', pathMatch: 'full'},
	// auth routes
	{path: 'signin', component: SigninComponent},
	// anamnese routes
	{
		path: 'anamnese',
		resolve: {
			patients: PatientsResolver,
			treatments: TreatmentsResolver,
			addresses: CustomerAddressesResolver,
			shippingCosts: ShippingCostResolver,
		},
		canActivate: [AuthGuard],
		children: [
			{path: '', component: AnamneseComponent, pathMatch: 'full'},
			{path: ':treatmentId/upload', component: AnamneseComponent},
		]
	},

	// dashboard routes
	{
		path: 'dashboard',
		resolve: {
			patients: PatientsResolver,
			treatments: TreatmentsResolver
		},
		canActivate: [AuthGuard],
		children: [
			{path: '', component: DashboardComponent},
			{path: 'patients', component: DashboardComponent}
		]
	},
	// help routes
	{
		path: 'help',
		component: HelpComponent,
	},

	// patients routes
	{
		path: 'patients',
		component: PatientsComponent,
		canActivate: [AuthGuard],
		resolve: {
			patients: PatientsResolver,
			treatments: TreatmentsResolver
		}
	},
	{
		path: 'patient',
		canActivate: [AuthGuard],
		resolve: {
			patients: PatientsResolver,
			treatments: TreatmentsResolver
		},
		children: [
			{path: '', component: PatientsInitialComponent, pathMatch: 'full'},
			{path: ':action', component: PatientComponent},
			{path: ':action/:patientId', component: PatientComponent}
		]
	},

	{
		path: 'treatments/edit/:id',
		component: TreatmentsComponent,
		canActivate: [AuthGuard],
		resolve: {
			patients: PatientsResolver,
			treatments: TreatmentsResolver,
			addresses: CustomerAddressesResolver,
			messages: MessagesResolver,
			medias: MediaResolver
		}
	},

	{path: 'print', component: QuestionairePrintDialogComponent},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { useHash: true }),
		StoreRouterConnectingModule.forRoot({stateKey: 'router'})
	],
	exports: [RouterModule],
	providers: [{provide: RouterStateSerializer, useClass: CustomSerializer}]
})
export class AppRoutingModule {
}
