import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Store, select } from "@ngrx/store";
import { Dictionary } from "@ngrx/entity";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { MediaService } from "../../../services";
import { MediaModel, UPL_ERROR, UPL_STATE } from "../../../models";
import { MediaState, selectMediaEntities } from "../../../store/media.reducer";
import { AddMedia, DeleteMedia } from "../../../store/media.actions";

import { EvalFileService } from "../eval-file.service";
import { ConfirmDialogComponent } from "libs/ux-components/src/lib/components";
import { MatDialog } from "@angular/material/dialog";

export enum FileEventType {
	UPLOADED = "uploaded",
	ADDED = "added",
	DELETED = "deleted",
	ERROR = "error",
	FILE_NOT_FOUND = "filenotfound"
}

export interface FileEvent {
	type: FileEventType;
	payload: any;
}

export interface UploadErrorEvent {
	err: UPL_ERROR;
	invalid: any;
	allowed?: any;
}

export enum FileUploadBoxState{
	DEFAULT = "",
	UPLOADING = "uploading",
	UPLOADED = "uploaded"
}

enum SnackBarType {
	INFO = "sb-info",
	ERROR = "sb-error",
	SUCCESS = "sb-success"
}

@Component({
	selector: "media-file-upload-box",
	templateUrl: "./file-upload-box.component.html",
	styleUrls: ["./_file-upload-box.component.scss"]
})
export class FileUploadBoxComponent implements OnInit, OnChanges, OnDestroy {
	FileUploadBoxState = FileUploadBoxState;

	@Input() allowed_extensions;
	@Input() allowed_number = 1;
	@Input() allowed_max_size = 1024 * 1024 * 1024;

	// The reference to use for uploading.
	// e.g. {treatment: '5a159407c3b6c8ad1dd282d2'}
	// if treatment is empty, the patientId is needed instead
	@Input() params?: { treatment: string, patientId?: string };

	// flag indicating whether files uploaded by this component trigger
	// the order status to 'daten_angekommen'
	@Input() triggerOrderStatus = true;
	@Input() reset_after = 0; // delay in ms before resetting the button state after an upload
	// field can be set to state, that a file was already attached to this component
	@Input() attachedFile: Partial<MediaModel> = null;
	@Input() isAllowedToRemoveFile = false;
	@Input() uploadRequired = false;
	@Output() fileEvent = new EventEmitter<FileEvent>();

	@HostBinding("class.uploading") isUploading = false;
	@HostBinding("class.uploaded") isUploaded = false;
	@HostBinding("class.alt-bg") hasAltBg = false;
	@HostBinding("class.show-desc") showDesc = false;

	fileQueue: File[] = [];
	file = {};
	filename = "";
	mediaId: string;

	transferStatus = new EventEmitter<any>();

	snackBarConfig: MatSnackBarConfig = {
		verticalPosition: "top",
		duration: 10000 //close snackbar after 10 seconds
	};

	state: FileUploadBoxState = FileUploadBoxState.DEFAULT;
	completion: number = 0; // Completion percentage of running upload (if any).

	mediaFiles: { orgFilename: string; filesize: number }[];
	mediaFiles$: Observable<{ orgFilename: string; filesize: number }[]>;
	subscription: Subscription;

	constructor(
		private translate: TranslateService,
		public snackBar: MatSnackBar,
		private mediaService: MediaService,
		private evalService: EvalFileService,
		private store: Store<MediaState>,
		private elRef: ElementRef,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.transferStatus.subscribe((event) =>
			this.onFileTransferStatusChange(event)
		);
		this.mediaService.fileNotFoundEvent.subscribe((event: FileEvent) =>
				this.showSnackBar(
					this.translate.instant(event.payload),
					SnackBarType.ERROR
				)
		);

		this.mediaFiles$ = this.store.pipe(
			select(selectMediaEntities),
			map((mediaList: Dictionary<MediaModel>) =>
				Object.values(mediaList).map((m) => ({
					orgFilename: m.orgFilename,
					filesize: m.filesize
				}))
			)
		);
		this.subscription = this.mediaFiles$.subscribe((mediaFiles) => {
			this.mediaFiles = mediaFiles;
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();

		// Check if there are uploads in progress when the component was destroyed.
		// If so, we attach an file event handler that uses the snack bar to keep the user informed of the upload results.
		if (
			this.fileQueue.length ||
			this.state === FileUploadBoxState.UPLOADING
		) {
			this.attachBackgroundSnackBarHandler();
		}
	}

	private attachBackgroundSnackBarHandler() {
		// Show hint to inform user that uploads are still in progress in background.
		this.showSnackBar(
			this.translate.instant("UploadInProgressHint"),
			SnackBarType.INFO
		);

		const subscription = this.fileEvent.subscribe((event) => {
			// Show upload result in snackbar.
			if (event.type === FileEventType.UPLOADED) {
				const mediaModel: MediaModel = event.payload;
				this.showSnackBar(
					this.translate.instant("UploadCompleteHint") +
						mediaModel.orgFilename,
					SnackBarType.SUCCESS
				);
			}

			// Check if last file upload has completed, then unsubscribe itself.
			if (
				!this.fileQueue.length &&
				(event.type === FileEventType.UPLOADED ||
					event.type === FileEventType.ERROR)
			) {
				subscription.unsubscribe();
			}
		});
	}

	ngOnChanges() {
		if (this.attachedFile?._id && this.attachedFile?.orgFilename){
			this.mediaId = this.attachedFile._id;
			this.state = FileUploadBoxState.UPLOADED;
			this.filename = this.attachedFile.orgFilename;
			this.isUploading = false;
			this.isUploaded = true;
			this.hasAltBg = true;
			this.showDesc = false;
		}
	}

	onClick(event, fileInputElement: HTMLInputElement) {
		if (this.state === FileUploadBoxState.DEFAULT) {
			fileInputElement.click();
		}
	}

	/**
	 * onChange - handles file selection change via button
	 * @param event
	 */
	onChange(event) {
		this.evalService.evaluate(
			Object.values(event.srcElement.files),
			{
				max: this.allowed_number,
				max_size: this.allowed_max_size,
				extensions: this.allowed_extensions,
				is_duplicate: (file) => this.isDuplicate(file)
			},
			{ emit: (e) => this.onFilesChange(e) }, // NOTE: we have to fake an event emitter object here which provides fnc emit
			{ emit: (e) => this.onUploadError(e) } // NOTE: we have to fake an event emitter object here which provides fnc emit
		);
	}

	/**
	 * onFilesChange - handles new valid files from evalService (which ist triggered by directive or onChange above)
	 * @param files
	 */
	onFilesChange(files: File[]) {
		if (files.length) {
			this.fileQueue = [...files];
			this.onNextFile(this.fileQueue.pop());
		}
	}

	onNextFile(file: File) {
		this.file = file;
		this.filename = file.name;
		this.completion = 0;

		this.isUploading = true;
		this.hasAltBg = true;
		this.fileEvent.emit({ type: FileEventType.ADDED, payload: file.name });

		this.state = FileUploadBoxState.UPLOADING;

		if ( this.params.treatment ){
			this.mediaService
				.uploadFile(file, this.params, this.triggerOrderStatus, this.transferStatus)
				.subscribe( {
					error: error => console.error("Error caught while uploading file", error)
				});
		} else {
			//if no treatment exists, upload is made from anamnese or from follow up order
			this.mediaService
				.uploadFileAnamnese(file, this.params.patientId, this.transferStatus)
				.subscribe( {
					error: error => console.error("Error caught while uploading file", error)
				});
		}
	}

	/**
	 * onFileTransferStatusChange - handles all stati during file transfer
	 * @param event
	 */
	onFileTransferStatusChange(
		event:
			| { event: UPL_STATE.PERCENT; payload: number }
			| { event: UPL_STATE.COMPLETED; payload: MediaModel }
			| { event: UPL_STATE.ERROR; payload: any }
	) {
		switch (event.event) {
			case UPL_STATE.PERCENT:
				this.completion = event.payload;
				break;
			case UPL_STATE.COMPLETED:
				this.isUploading = false;
				this.isUploaded = true;
				this.mediaId = event.payload._id;
				this.state = FileUploadBoxState.UPLOADED;

				// Add the new media to the redux store.
				this.store.dispatch(new AddMedia({ media: event.payload }));

				this.fileEvent.emit({
					type: FileEventType.UPLOADED,
					payload: event.payload
				});

				if (this.fileQueue.length) {
					const nextFile = this.fileQueue.pop();
					this.onNextFile(nextFile);
				} else if (this.reset_after !== 0) {
					this.reset(this.reset_after);
				}
				break;
			case UPL_STATE.ERROR:
				console.error("Error caught while uploading file", event, this);
				this.fileEvent.emit({
					type: FileEventType.ERROR,
					payload: this.file["name"]
				});
				//XXX The following user feedback (translation) is stupid, but we take it as it is
				const uploadErrorEvent: UploadErrorEvent = {
					err: UPL_ERROR.TRANSFER,
					invalid: "Filetransfer Error"
				};
				this.showSnackBar(
					this.translate.instant(
						"UPL_ERROR_" + uploadErrorEvent.err,
						{ invalid: uploadErrorEvent.invalid }
					),
					SnackBarType.ERROR
				);

				if (this.fileQueue.length) {
					const nextFile = this.fileQueue.pop();
					this.onNextFile(nextFile);
				}

				break;
		}
	}

	/**
	 * handles file selection errors (either from drop (directive) or from fileselection dialog via onChange above)
	 * @param event
	 */
	onUploadError(event: UploadErrorEvent) {
		switch (event.err) {
			case UPL_ERROR.EMPTY:
			case UPL_ERROR.TOO_MANY:
			case UPL_ERROR.TOO_LARGE:
			case UPL_ERROR.INVALID:
			case UPL_ERROR.DUPLICATE:
				this.showSnackBar(
					this.translate.instant("UPL_ERROR_" + event.err, {
						invalid: event.invalid,
						allowed: event.allowed
					}),
					SnackBarType.ERROR
				);
				break;
			default:
				console.error(
					"Unknown error caught while uploading file",
					event
				);
		}
	}

	private reset(timeoutMs = 0) {
		setTimeout(() => {
			this.isUploading = false;
			this.isUploaded = false;
			this.hasAltBg = false;
			this.showDesc = false;
			this.state = FileUploadBoxState.DEFAULT;
			this.file = {};
			this.filename = "";
			this.mediaId = "";
			this.attachedFile = null;
		}, timeoutMs);
	}

	/**
	 * showSnackBar - handles Snackbar
	 * @param event
	 * @param snackBarType of the snackBar
	 */
	private showSnackBar(message: string, snackBarType: SnackBarType) {
		this.snackBar.open(message, "X", {
			...this.snackBarConfig,
			panelClass: snackBarType
		});
	}

	private isDuplicate(file: File) {
		return this.mediaFiles?.some((mediaFile) => {
			return (
				mediaFile.filesize === file.size &&
				mediaFile.orgFilename === file.name
			);
		});
	}

	isHovered(): boolean{
		return this.elRef.nativeElement.classList.contains('uploaded-hovered');
	}

	removeAttachedFile(){
		if (!this.isAllowedToRemoveFile || !this.mediaId){
			return;
		}
		const dialogData = {
			title: "Confirm deletion",
			msg: "Are you sure you want to delete the uploaded file?",
			no: "No",
			yes: "Yes"
		};
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: dialogData
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result == "yes") {
				//delete file from store and from the database
				//any other logic must be handled by the parent component
				this.store.dispatch(new DeleteMedia({id: this.mediaId}));
				this.reset();
				this.fileEvent.emit({
					type: FileEventType.DELETED,
					payload: null
				});
			}
		});
	}
}
