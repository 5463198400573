import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import {MaterialModule} from '@suite/material';
import {NextBackActionsComponent} from './components/next-back-actions/next-back-actions.component';
import {MediaModule} from '@suite/media';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PageFooterComponent} from './components/page-footer/page-footer.component';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {PageHeaderComponent} from './container/page-header/page-header.component';
import {
	ConfirmDialogComponent,
	CommentComponent,
	TreatmentDetailHeaderComponent,
	GridHeaderComponent,
	NotesOnCustomerComponent,
	ChangeAddressDialogComponent
} from './components';
import {
	TreatmentMediaComponent,
	TreatmentMessagesComponent,
	UploadAdditionalsComponent,
	UploadStlComponent,
	UpsPickupContainerComponent
} from './container';

import {TreatmentPackagesComponent} from './components/treatment-packages/treatment-packages.component';
import {UpsPickupComponent} from './components/ups-pickup/ups-pickup.component';
import {UpsPickupPrintComponent} from './components/ups-pickup-print/ups-pickup-print.component';
import {UpsPickupPrintOverlayComponent} from './components/ups-pickup-print-overlay/ups-pickup-print-overlay.component';
import {FormlyModule} from '@ngx-formly/core';
import {FormlyMaterialModule} from '@ngx-formly/material';
import {FormlyMatDatepickerModule} from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MessageTypesComponent} from './components/message-types/message-types.component';
import {MessageStatusComponent} from './components/message-status/message-status.component';
import {ProductVisualComponent} from './components/product-visual/product-visual.component';
import {TmCommonModule} from '@suite/tm-common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CaWebviewerComponent } from './components/ca-webviewer';
import { OverlayComponent } from './components/overlay/overlay.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FileuploadComponent } from './container/data-transfer/fileupload.component';
import { TreatmentMessagesAdminComponent } from "./container/treatment-messages-admin/treatment-messages-admin.component";
import { WebviewerPreviewComponent } from './components/webviewer-preview/webviewer-preview.component';
import { MondayFirstOfWeekDateAdapter } from './adapters';

@NgModule({
    imports: [
        MatExpansionModule,
        CommonModule,
        ClipboardModule,
        FormlyMatDatepickerModule,
        FormlyMaterialModule,
        FormlyModule.forRoot(),
        FormsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        MaterialModule,
        MatNativeDateModule,
        MediaModule,
        ReactiveFormsModule,
        TmCommonModule,
        TranslateModule,
        AngularEditorModule,
        TreatmentMessagesAdminComponent,
		NotesOnCustomerComponent,
		WebviewerPreviewComponent
    ],
    declarations: [
        AccordionComponent,
        CaWebviewerComponent,
        CommentComponent,
        ChangeAddressDialogComponent,
        ConfirmDialogComponent,
        GridHeaderComponent,
        NextBackActionsComponent,
        PageFooterComponent,
        PageHeaderComponent,
        ProductVisualComponent,
        UpsPickupContainerComponent,
        TreatmentDetailHeaderComponent,
        TreatmentMediaComponent,
        TreatmentMessagesComponent,
        TreatmentPackagesComponent,
        UploadAdditionalsComponent,
        UploadStlComponent,
        UpsPickupComponent,
        UpsPickupPrintComponent,
        UpsPickupPrintOverlayComponent,
        MessageTypesComponent,
        MessageStatusComponent,
        OverlayComponent,
        FileuploadComponent,
    ],
    exports: [
        AccordionComponent,
        CaWebviewerComponent,
        CommentComponent,
        FileuploadComponent,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        NextBackActionsComponent,
        OverlayComponent,
        PageFooterComponent,
        PageHeaderComponent,
        ProductVisualComponent,
        UpsPickupContainerComponent,
        TreatmentDetailHeaderComponent,
        TreatmentMediaComponent,
        TreatmentMessagesComponent,
        TreatmentPackagesComponent,
        UploadAdditionalsComponent,
        UploadStlComponent,
        UpsPickupComponent,
        UpsPickupPrintComponent,
        UpsPickupPrintOverlayComponent,
        GridHeaderComponent,
		WebviewerPreviewComponent
    ]
})
export class UxComponentsModule {
}
