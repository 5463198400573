import {Action} from '@ngrx/store';

export enum MagentoActionTypes {
	StoererSeen = '[Dashboard] Stoerer Seen'

}
export class StoererSeenAction implements Action {
    readonly type = MagentoActionTypes.StoererSeen;
}

export type MagentoActions =
	| StoererSeenAction;

