<div class="flex-container">
	<div class="upload-container">
		<media-file-upload-box
			*ngFor="let additional of additionals; let i = index"
			id="{{additional.id}}"
			[ngClass]="{required: additional.required}"
			[params]="{treatment: treatment?._id, patientId: patientId, type: MediaType.User}"
			[triggerOrderStatus]="false"
			[allowed_extensions]="additional.allowed_extensions"
			[allowed_number]="additional.allowed_number"
			[allowed_max_size]="additional.allowed_max_size"
			[attachedFile]="getMediaModelForIndex(i)"
			[isAllowedToRemoveFile]="true"
			(fileEvent)="onFileEvent($event, additional.id, i)"
		>
			<p class="description">{{additional.description | translate}}</p>
			<p class="description">{{additional.description2 | translate}}</p>
		</media-file-upload-box>
	</div>
	<p class="info"><i class="fas fa-info-circle"></i> {{'Erlaubte Dateiformate' | translate}} jpg, jpeg, png /
		{{'Röntgenbild' | translate}}: jpg, jpeg, png, dcm, dcm30
	</p>
</div>
