import { Injectable } from "@angular/core";

import { UploadErrorEvent } from "./file-upload-box";
import { UPL_ERROR } from "../../models";

export type EvalOptions = {
	max: number;
	max_size: number;
	extensions: string[];
	allow_empty?: boolean;
	is_duplicate?: Function;
};

@Injectable()
export class EvalFileService {
	constructor() {}

	/**
	 * @returns true, if an error occurs.
	 */
	evaluate(
		files: File[],
		options: EvalOptions,
		onSuccess: { emit(value?: File[]): void },
		onUploadError: { emit(value?: UploadErrorEvent): void }
	): boolean {
		const valid_files: Array<File> = [];
		const invalid_files: Array<String> = [];
		const empty_files: string[] = [];
		const duplicate_files: string[] = [];
		const large_files: Array<String> = [];

		// check allowed number of files
		if (files.length > options.max) {
			const uploadErrorEvent: UploadErrorEvent = {
				err: UPL_ERROR.TOO_MANY,
				invalid: files.length,
				allowed: options.max
			};
			onUploadError.emit(uploadErrorEvent);
			return true;
		}

		// check each file
		files.forEach((file: File) => {
			// get extension
			const parts = file.name.split(".");
			const ext = parts.at(-1).toLowerCase();

			// check on invalid extensions
			if (options.extensions.lastIndexOf(ext) === -1) {
				invalid_files.push(ext);
			} else if (file.size == 0 && !options.allow_empty) {
				empty_files.push(file.name);
			} else if (file.size > options.max_size) {
				// check on invalid sizes
				large_files.push(
					Number(
						// round filesize to two decimal places (5,007 -> 5,01)
						Math.ceil((file.size / (1024 * 1024)) * 100) / 100
					).toFixed(2) + " MB"
				);
			} else if (options.is_duplicate && options.is_duplicate(file)) {
				duplicate_files.push(file.name);
			} else {
				// is valid
				valid_files.push(file);
			}
		});

		if (invalid_files.length) {
			const uploadErrorEvent: UploadErrorEvent = {
				err: UPL_ERROR.INVALID,
				invalid: invalid_files.join(", "),
				allowed: options.extensions.join(", ")
			};
			onUploadError.emit(uploadErrorEvent);
			return true;
		}

		if (empty_files.length) {
			const uploadErrorEvent: UploadErrorEvent = {
				err: UPL_ERROR.EMPTY,
				invalid: empty_files.join(", "),
			};
			onUploadError.emit(uploadErrorEvent);
			return true;
		}

		if (large_files.length) {
			const uploadErrorEvent: UploadErrorEvent = {
				err: UPL_ERROR.TOO_LARGE,
				invalid: large_files.join(", "),
				allowed: options.max_size / (1024 * 1024) + " MB"
			};
			onUploadError.emit(uploadErrorEvent);
			return true;
		}

		if (duplicate_files.length) {
			const uploadErrorEvent: UploadErrorEvent = {
				err: UPL_ERROR.DUPLICATE,
				invalid: duplicate_files.join(", ")
			};
			onUploadError.emit(uploadErrorEvent);
			return true;
		}

		if (valid_files.length) {
			onSuccess.emit(valid_files);
		}

		return false;
	}
}
