<section>
	<div *ngIf="data.type === ReleaseDialogType.CORRECTION">
		<h1 mat-dialog-title>{{'Setup-Korrektur' | translate}}</h1>
		<div>
			<mat-radio-group *ngIf="data.treatment?.upgrade"
							 [(ngModel)]="target"  aria-label="Select an option">
				<mat-label>{{'bezogen auf' | translate }}: </mat-label>
				<mat-radio-button value="{{'Angefragte Option' | translate }}">{{'Angefragte Option' | translate }} </mat-radio-button>
				<mat-radio-button value="Upgrade Option">{{'Upgrade Option' | translate}}</mat-radio-button>
			</mat-radio-group>

			<mat-form-field appearance="fill">
				<textarea matInput #comment cdkFocusInitial
						  placeholder="{{'Behandlungs-/Korrekturhinweise...' | translate}}"></textarea>
			</mat-form-field>
		</div>
		<div mat-dialog-actions align="end">
			<button class="back-button" mat-raised-button color="default" [mat-dialog-close]="false">
				<i class="fal fa-chevron-double-left"></i> {{"Back" | translate}}
			</button>
			<ng-container *ngIf="data.treatment?.upgrade; else noUpgrade" >
				<button disabled="{{!comment.value || !target }}" mat-raised-button color="primary"
						[mat-dialog-close]="target + ': ' + comment.value">
					<i class="fal fa-sync"></i> {{'Korrektur anfordern' | translate}}
				</button>
			</ng-container>
			<ng-template #noUpgrade>
				<button disabled="{{!comment.value}}" mat-raised-button color="primary"
						[mat-dialog-close]="comment.value">
					<i class="fal fa-sync"></i> {{'Korrektur anfordern' | translate}}
				</button>
			</ng-template>
		</div>
	</div>

	<div *ngIf="data.type === ReleaseDialogType.CANCEL">
		<h1 mat-dialog-title>{{'Behandlung stornieren' | translate}}</h1>

		<ng-container *ngTemplateOutlet="priceTable"></ng-container>

		<div *ngIf="step2">
			<p >
				{{'Hiermit storniere ich die von mir' | translate }} <br> {{'bestellte Behandlung kostenpflichtig!' | translate}}
			</p>
			<div mat-dialog-actions align="end">
				<button class="back-button" mat-button mat-raised-button color="default" (click)="step2=false">
					<i class="fal fa-chevron-double-left"></i> {{"Back" | translate}}
				</button>
				<button *ngIf="step2" disabled="{{!comment.value}}" mat-raised-button color="primary"
						[mat-dialog-close]="{comment: comment.value}">
					<i class="fal fa-times"></i> {{"Stornierung absenden" | translate}}
				</button>
			</div>
		</div>

		<div>
			<mat-form-field appearance="fill">
				<mat-label>{{'Grund der Stornierung' | translate}}</mat-label>
				<textarea disabled="{{step2}}" matInput #comment cdkFocusInitial placeholder="{{'Ihr Stornierungsgrund...' | translate}}"></textarea>
			</mat-form-field>
		</div>

		<div mat-dialog-actions align="end" *ngIf="!step2">
			<button class="back-button" mat-button mat-raised-button color="default"
					[mat-dialog-close]="false">
				<i class="fal fa-chevron-double-left"></i> {{"Back" | translate}}
			</button>
			<button (click)="step2=true;" disabled="{{!comment.value}}" mat-raised-button color="primary" >
				{{"Next" | translate}}
			</button>
		</div>
	</div>
	<div *ngIf="data.type === ReleaseDialogType.RELEASE">
			<h1 mat-dialog-title>{{'Freigabe erteilen' | translate}}</h1>

			<ng-container *ngTemplateOutlet="priceTable"></ng-container>

			<div>
				<mat-form-field appearance="fill">
					<textarea matInput #comment cdkFocusInitial placeholder="{{'Hinweise zur Freigabe...' | translate}}"></textarea>
				</mat-form-field>
			</div>
			<div mat-dialog-actions align="end">
				<button class="back-button" mat-raised-button color="default" [mat-dialog-close]="false">
					<i class="fal fa-chevron-double-left"></i> {{"Back" | translate}}
				</button>
				<button mat-raised-button color="primary"
						[mat-dialog-close]="{comment: comment.value}"
				>
					<i class="fal fa-sync"></i> {{'Freigabe erteilen' | translate}}
				</button>
			</div>
	</div>
	<div *ngIf="data.type === ReleaseDialogType.RELEASE_UPGRADE">
			<h1 mat-dialog-title>{{'Freigabe erteilen' | translate}} (Upgrade)</h1>
			<div class="center">
				<h3 [innerHTML]="getProductNameHtml()"></h3>
				<h3> {{"ForPatient" | translate}} {{data.treatment.patient.displayName}}</h3>
			</div>
			<div>
				<ux-pricelist></ux-pricelist>
				<mat-form-field appearance="fill">
					<textarea matInput #comment cdkFocusInitial placeholder="{{'Hinweise zur Freigabe...' | translate}}"></textarea>
				</mat-form-field>
			</div>
			<div mat-dialog-actions align="end">
				<button class="back-button" mat-raised-button color="default" [mat-dialog-close]="false">
					<i class="fal fa-chevron-double-left"></i> {{"Back" | translate}}
				</button>
				<button mat-raised-button color="primary"
						[mat-dialog-close]="{comment: comment.value}"
				>
					<i class="fal fa-sync"></i> {{'Freigabe erteilen' | translate}}
				</button>
			</div>
	</div>
</section>

<!-- PRICE TABLE -->
<ng-template #priceTable>
	<div *ngIf="(price$ | async) as price; else loading">
		<h3 [innerHTML]="getProductNameHtml()"></h3>
		<h3> {{"ForPatient" | translate}} {{data.treatment.patient.displayName}}</h3>
		<table class="table">
			<tr>
				<td>{{ 'Product(s) price' | translate }}:</td>
				<td class="text-right">{{price.ItemPriceSum | currency:'EUR':'symbol':'': config.locale}}</td>
			</tr>
			<tr *ngIf="price.DiscountFactor < 1">
					<td>{{ 'Your discount' | translate }}:</td>
					<td class="text-right">{{calculateDiscountInPercent(price.DiscountFactor)}}%</td>
			</tr>
			<tr *ngIf="price.DiscountFactor < 1">
				<td>{{ 'Subtotal' | translate }}:</td>
				<td class="text-right">{{(price.DiscountedItemPriceSum) | currency:'EUR':'symbol':'':
					config.locale}}
				</td>
			</tr>
			<tr *ngIf="data.type !== ReleaseDialogType.CANCEL && price.ShippingCost !== null">
				<td>{{ 'Shipping' | translate }}:</td>
				<td class="text-right">{{price.ShippingCost | currency:'EUR':'symbol':'': config.locale}}</td>
			</tr>
			<tr *ngIf="price.Vat">
				<td>{{ 'Tax' | translate }}:</td>
				<td class="text-right">{{price.Vat | currency:'EUR':'symbol':'': config.locale}}</td>
			</tr>
			<tr class="spacer"></tr>
			<tr class="total">
				<td>{{'Total' | translate}}:</td>
				<td class="text-right"> {{price.Total | currency:'EUR':'symbol':'': config.locale}}</td>
			</tr>
		</table>
	</div>
</ng-template>
<ng-template #loading>
	<div class="loading">
		<i class="fal fa-spinner fa-spin"></i>
		<div>{{'calculateCosts' | translate}}</div>
	</div>
</ng-template>
