import {createSelector} from '@ngrx/store';
import {selectMagentoState} from './magento.reducer';
import * as moment from 'moment/moment';

export const selectStoererSeen = createSelector(
	selectMagentoState,
	state => {

		if (state.stoererSeen) { return true; }

		// check if we have a date in localStorage...
		const lastStoererSeenDate = localStorage.getItem('lastStoererSeen');
		if (lastStoererSeenDate) {
			const lastSeenDateTime = moment(lastStoererSeenDate);
			// which shows lastStoererSeen is less than 12 hours ago
			if (moment().diff(lastSeenDateTime, 'hours') <= 12) {
				return true;
			}
		}

		return false;
	}
);
