import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PatientModel} from '@suite/tm-common';

export enum PatientActionTypes {
  PatientsRequested = '[Patients Resolver] Patients Requested',
  NewPatient = '[Patients Service] New Patient',
  NewPatientTreatment = '[Anamnese] New Patient Treatment',
  LoadPatients = '[Patient Effects] Load Patients',
  AddPatient = '[Patient] Add Patient',
  UpsertPatient = '[Patient] Upsert Patient',
  AddPatients = '[Patient] Add Patients',
  UpsertPatients = '[Patient] Upsert Patients',
  UpdatePatient = '[Patient] Update Patient',
  UpdatePatients = '[Patient] Update Patients',
  DeletePatient = '[Patient] Delete Patient',
  DeletePatients = '[Patient] Delete Patients',
  ClearPatients = '[Patient] Clear Patients'
}

export class PatientsRequested implements Action {
	readonly type = PatientActionTypes.PatientsRequested;
}

export class LoadPatients implements Action {
  readonly type = PatientActionTypes.LoadPatients;

  constructor(public payload: { patients: PatientModel[] }) {}
}

export class NewPatient implements Action {
	readonly type = PatientActionTypes.NewPatient;

	constructor(public payload: { patient: PatientModel } ) {}
}
export class NewPatientTreatment implements Action {
	readonly type = PatientActionTypes.NewPatientTreatment;
	constructor(public payload: { patientId: string, treatmentId: string } ) {}
}
export class AddPatient implements Action {
  readonly type = PatientActionTypes.AddPatient;

  constructor(public payload: { patient: PatientModel }) {}
}

export class UpsertPatient implements Action {
  readonly type = PatientActionTypes.UpsertPatient;

  constructor(public payload: { patient: PatientModel }) {}
}

export class AddPatients implements Action {
  readonly type = PatientActionTypes.AddPatients;

  constructor(public payload: { patients: PatientModel[] }) {}
}

export class UpsertPatients implements Action {
  readonly type = PatientActionTypes.UpsertPatients;

  constructor(public payload: { patients: PatientModel[] }) {}
}

export class UpdatePatient implements Action {
  readonly type = PatientActionTypes.UpdatePatient;

  constructor(public payload: { patient: Update<PatientModel> }) {}
}

export class UpdatePatients implements Action {
  readonly type = PatientActionTypes.UpdatePatients;

  constructor(public payload: { patients: Update<PatientModel>[] }) {}
}

export class DeletePatient implements Action {
  readonly type = PatientActionTypes.DeletePatient;

  constructor(public payload: { id: string }) {}
}

export class DeletePatients implements Action {
  readonly type = PatientActionTypes.DeletePatients;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearPatients implements Action {
  readonly type = PatientActionTypes.ClearPatients;
}


export type PatientActions =
 PatientsRequested
 | NewPatient
 | NewPatientTreatment
 | LoadPatients
 | AddPatient
 | UpsertPatient
 | AddPatients
 | UpsertPatients
 | UpdatePatient
 | UpdatePatients
 | DeletePatient
 | DeletePatients
 | ClearPatients;
