import {Component, Inject, Input, OnChanges} from '@angular/core';
import {TreatmentModel, TreatmentStatus} from 'libs/treatments/src/lib/models';
import {OrderModel} from '@suite/tm-common';
import {TreatmentsService} from '@suite/treatments';
import { ProductInfoService } from 'libs/product/src/lib/services';

@Component({
	selector: 'ux-treatment-detail-header',
	templateUrl: './treatment-detail-header.component.html',
	styleUrls: ['./_treatment-detail-header.component.scss']
})
export class TreatmentDetailHeaderComponent implements OnChanges {
	@Input() user;
	@Input() patient;
	@Input() treatment: TreatmentModel = null;
	TreatmentStatus = TreatmentStatus;
	pickUpInfo = null;

	constructor(private treatmentService: TreatmentsService,
				private productInfoService: ProductInfoService,
				@Inject('CONFIG') public config) {}

	ngOnChanges() {
		if (this.treatment.quotation?.upsPickUpReference) {
			this.pickUpInfo = this.treatmentService.checkPickUpInfo(this.treatment.quotation.upsPickUpReference);
		}
	}
	getToothAreaForProduct(isUpperJaw: boolean) : string {
		return this.productInfoService.getToothAreaForProduct(this.treatment.product?.info, this.treatment.product?.options, isUpperJaw);
	}

	getCreatedDate() : Date{
		if( this.treatment.schema_version >= 2){
			return this.treatment.created;
		}else {
			return this.treatment.orders.at(-1).created;
		}
	}

	getQuotationOrOrderId() : string{
		if( this.treatment.schema_version >= 2 ){
			return this.treatment.quotation.docNum;
		}else {
			return this.treatment.orders.at(-1).realId;
		}
	}
}
