import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {filter, first, tap} from 'rxjs/operators';

import {AppState} from '@suite/tm-common';

import {selectCustomerState} from '../store/customer.selectors';
import {AddressesRequested} from '../store/customer.actions';
import { SapAddress } from '@suite/sap';
import { CustomerState } from '../store/customer.reducer';

@Injectable()
export class CustomerAddressesResolver  {

	constructor(
		private store: Store<AppState>) {

	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{billingAddress: SapAddress, shippingAddresses: SapAddress[]}> {
		return this.store
			.pipe(
				select(selectCustomerState),
				tap((state: CustomerState) => {
					if (state.billingAddress === null || state.shippingAddresses.length === 0) {
						this.store.dispatch(new AddressesRequested());
					}
				}),
				filter(state => state.billingAddress && state.shippingAddresses.length > 0),
				first()
			);
	}

}

