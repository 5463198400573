<div>

	<button mat-raised-button
			(click)="onBack()">
		<i class="fal fa-chevron-double-left"></i> {{'Back' | translate}}
	</button>

	<button mat-raised-button color="primary"
			*ngIf="!hideNext"
			(click)="onNext()">
		<i class="fal fa-shopping-cart"></i> {{'Order next package' | translate}}
	</button>
</div>
