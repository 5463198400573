import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';

import {ACTION, AppState, DataTransferMethod} from '@suite/tm-common';

import {selectBillingAddress, selectShippingAddresses} from '@suite/customer';
import {HandlingUpdatedAction, selectAnamneseDataTransfer, selectShippingCosts } from '@suite/anamnese';
import { first } from 'rxjs';
import { ClearMedias } from '@suite/media/store';

enum SetupSubstate{
	BILLING_SHIPMENT = 0,
	TRANSFER_COMMENTS = 1,
	UPLOAD = 2,
	CART = 3
}
@Component({
	selector: 'tm2-anamnese-setup',
	templateUrl: './anamnese-setup.component.html',
	styleUrls: ['./_anamnese-setup.component.scss']
})
export class AnamneseSetupComponent implements OnDestroy {
	@Input() anamnese;
	@Input() isSetupProduct;

	@Output() next = new EventEmitter<any>();

	billingAddress$ = this.store.pipe(select(selectBillingAddress));
	shippingAddresses$ = this.store.pipe(select(selectShippingAddresses));
	shippingCosts$ = this.store.pipe(select(selectShippingCosts));

	ACTION = ACTION;
	actions = [ACTION.INITIAL, ACTION.OPTIONS, ACTION.UPLOAD, ACTION.SELECT];

	actionIdx = SetupSubstate.BILLING_SHIPMENT;
	action = this.actions[this.actionIdx];

	constructor(private store: Store<AppState>) {
	}

	ngOnDestroy(): void {
		//prevent incorrect duplicate check on next fileupload
		this.store.dispatch(new ClearMedias());
	}

	done() {
		this.next.emit({type: ACTION.NEXT});
	}

	onNext(action) {
		switch (action.type) {
			case ACTION.INITIAL: // handling
				this.actionIdx++;
				this.store.dispatch(new HandlingUpdatedAction(action.payload));
				break;
			case ACTION.UPLOAD: // file upload before going to cart
				this.actionIdx = SetupSubstate.UPLOAD;
				break;
			case ACTION.OPTIONS: // select DataTransferMethod, enter comment
				this.actionIdx = SetupSubstate.CART;
				break;
			case ACTION.NEXT:
				this.done();
				break;
			case ACTION.BACK:
				if( this.actionIdx === SetupSubstate.BILLING_SHIPMENT ){
					this.actionIdx = SetupSubstate.BILLING_SHIPMENT; // reset actionIdx for later use
					this.next.emit(action);
					break;
				}
				if( [SetupSubstate.TRANSFER_COMMENTS, SetupSubstate.UPLOAD].includes(this.actionIdx) ){
					this.actionIdx--;
					break;
				}

				this.store.pipe( select(selectAnamneseDataTransfer)).pipe(first()).subscribe((value: any) => {
					if ( this.actionIdx === SetupSubstate.CART && value.dataTransfer === DataTransferMethod.UPLOAD ){
							this.actionIdx--;
					} else {
							this.actionIdx = SetupSubstate.TRANSFER_COMMENTS;
					}
				});
				break;
			default:
				// unknown event - should be disablePrevious - so tell parent
				this.next.emit(action);
				break;
		}

		this.action = this.actions[this.actionIdx];
	}

}
