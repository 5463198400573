
<div mediaFileDragDrop
	 class="drop-zone"
	 [class.default]="state === FileUploadBoxState.DEFAULT"

	 (filesChange)="onFilesChange($event)"
	 (uploadErrorEvent)="onUploadError($event)"
	 (click)="onClick($event, fileInputElement)"
	 [state]="state"
	 [allowed_extensions]="allowed_extensions"
	 [allowed_number]="allowed_number"
	 [allowed_max_size]="allowed_max_size"
	 [mediaFiles]="mediaFiles$ | async"
>
	<!-- description from host -->
	<ng-content></ng-content>
	<!-- hover -->
	<i *ngIf="state === FileUploadBoxState.DEFAULT" class="fileselect fal fa-plus-circle"></i>
	<!-- dragover -->
	<i class="filedrop fal fa-arrow-alt-circle-down"></i>
	<!-- progress spinner -->
	<!-- Note: we need two spinners here
			    one with value 100 to have the background circle
			    and the other one to show processing
	-->
	<div class="spinner" *ngIf="state === FileUploadBoxState.UPLOADING">
		<mat-progress-spinner
			class="bg-spinner"
			[diameter]="60"
			mode="determinate"
			[value]="100"
		></mat-progress-spinner>
		<mat-progress-spinner
			class="top-spinner"
			[diameter]="60"
			style="margin-top: -60px"
			mode="determinate"
			[value]="completion" ></mat-progress-spinner>
		<p class="state">{{"Uploading" | translate}}
			<span *ngIf="fileQueue.length">
				({{"QueuedFileUploads" | translate}}: {{fileQueue.length}})
			</span>
		</p>

	</div>

	<ng-container *ngIf="state === FileUploadBoxState.UPLOADED">
		<i [ngClass]="isAllowedToRemoveFile && isHovered() ?
			'fileuploaded-hovered fal fa-circle-xmark' :
			'fileuploaded fal fa-check-circle'"
			(click)="removeAttachedFile()"></i>
		<p class="filename">{{filename}}</p>
	</ng-container>

	<input
		#fileInputElement
		(change)="onChange($event)"
		(click)="$event.target.value=null"
		style="display:none"
		type="file"
		[multiple]="allowed_number > 1" />

	<label *ngIf="state === FileUploadBoxState.DEFAULT && uploadRequired">*{{"uploadRequired" | translate}}</label>
</div>
