<div class="treatment-messages"
	 *ngIf="(messages$ | async) as messages"
>
	<h3>
		<i class="fal fa-copy"></i>
		{{'Nachrichtenverlauf' | translate }}
		<span
			*ngIf="messages?.length"
			[ngClass]="{red: config.app === 'tmadmin' && treatment.newMessagesCount}"
			matBadgeSize="large"
			matBadge="{{messages?.length}}{{config.app === 'tmadmin' && treatment.newMessagesCount ? '/' + treatment.newMessagesCount : ''}}"
			matBadgeOverlap="false">
		</span>
	</h3>

	<div *ngIf="config.app === 'tmadmin'; then tmadminMessagesList else tmMessagesList"></div>

	<ng-template #tmadminMessagesList>
		<ux-treatment-messages-admin />

		<button class="btn-reset-messages" *ngIf="treatment?.newMessagesCount" mat-raised-button
			color="primary" (click)="resetMessagesCount()">
			<i class="fal fa-broom"></i> neue Nachrichten({{treatment.newMessagesCount}}) als gelesen markieren!
		</button>
	</ng-template>

	<ng-template #tmMessagesList>
		<cdk-virtual-scroll-viewport itemSize="autosize"
									*ngIf="messages?.length; else noNotifications"
									[style.height.px]="messages?.length > 5 ? 5 * 72 + 20 : messages?.length * 72 + 20"
									class="messages-list">

			<div class="Table" >
				<div class="Table-row" *cdkVirtualFor="let message of messages" [ngClass]="{intern: message.scope === MessageScopes.Intern}">
					<div class="Table-row-item xs icon">
						<i class="{{MessageTypeIcons[message.type].icon}}"></i>
						<i *ngIf="message.scope === MessageScopes.PublicWithEmailNotification" style="margin-left: 5px" class="fa fa-envelope"></i>
					</div>
					<div class="Table-row-item">{{message.created | date: 'medium' : 'EUROPE/BERLIN' : config.locale }}
					</div>
					<div class="Table-row-item">
						{{message.sendersType === SendersTypes.Technican
							? 'TEC'
							: message.sendersType === SendersTypes.Customer
								? 'DOC'
								:  message.sendersType === SendersTypes.Support
									? 'SUP'
									:  message.sendersType === SendersTypes.System
										? 'SYS'
										: '???'
						}}<br>
					</div>
					<div class="Table-row-item xl text" [innerHTML]="message.message"></div>
				</div>
			</div>
		</cdk-virtual-scroll-viewport>

		<ng-template #noNotifications>
			<p>{{'no notifications' | translate}}</p>
		</ng-template>
	</ng-template>
</div>
<div class="treatment-message" *ngIf="isInputEnabled">
	<h3>
		<i class="fal fa-copy"></i>
		{{'Neue Nachricht' | translate }}
	</h3>

	<ux-message-status
		*ngIf="messageType === MessageTypes.StatusChange"
		[treatment]="treatment"
		(statusChange)="onStatusChange($event)"
	>
	</ux-message-status>

	<ux-message-types
		[messageType]="messageType"
		(newMessageType)="onNewMessageType($event)">
	</ux-message-types>

	<ng-container *ngIf="messageType !== MessageTypes.CompletionDate && messageType !== MessageTypes.CaseWorker">
		<ux-comment #commentComponent
					[type]="messageType"
					[options]="messageInputOptions"
					[htmlContent]="message"
					(valueChanges)="onMessageChange($event)"
		></ux-comment>

		<mat-checkbox *ngIf="config.app === 'tmadmin'"
					  [checked]="internOnly"
					  [disabled]="isInternOnlyCheckboxDisabled"
					  (change)="onInternOnlyChange()">
			{{'ADMIN.onlyInternal' | translate}}
		</mat-checkbox>

		<mat-checkbox class="spacer-before" *ngIf="config.app === 'tmadmin' && !internOnly"
			[checked]="sendMailToCustomer"
			(change)="onSendMailToCustomerChange()">
			{{'ADMIN.sendCustomerMail' | translate}}
		</mat-checkbox>

		<button mat-raised-button
				[disabled]="(!message || message.length < 2) && !newTreatmentStatus"
				(click)="onClick()"
		>{{ 'Absenden' | translate}}
		</button>
	</ng-container>

</div>
