<section class="container">
	<header class="top-header">
		<h1>{{'Order Summary' | translate }} - {{'Patient' | translate}}: {{patient?.displayName}}
			[{{patient?.patientId}}]</h1>
		<img src="./assets/img/logo_scheu.svg" alt="SCHEU-DENTAL" class="logo">
	</header>

	<div class="product-info">
		<img src="{{getProductLogoUrl(productInfo)}}" class="product-logo">
		<h3 [innerHTML]="getProductNameHtml()"></h3>
	</div>

	<!-- TABLE HEADERS -->
	<section class="anamnese-table">

		<div class="first"></div>
		<div class="header uj">{{'uj' | translate | uppercase }}</div>
		<div class="header lj">{{'lj' | translate | uppercase }}</div>

		<div class="first"></div>
		<div class="subheader ist" *ngIf="hasAnswersWithActualDataEntries">{{'ist' | translate | uppercase }}</div>
		<div class="subheader soll">{{'soll' | translate | uppercase }}</div>
		<div class="subheader details" *ngIf="!hasAnswersWithActualDataEntries">{{'details' | translate | uppercase }}</div>
		<div class="subheader therapie">{{'therapie' | translate | uppercase }}</div>
		<div class="subheader ist" *ngIf="hasAnswersWithActualDataEntries">{{'ist' | translate | uppercase }}</div>
		<div class="subheader soll">{{'soll' | translate | uppercase }}</div>
		<div class="subheader details" *ngIf="!hasAnswersWithActualDataEntries">{{'details' | translate | uppercase }}</div>
		<div class="subheader therapie">{{'therapie' | translate | uppercase }}</div>
	</section>

	<!-- TABLE ROWS / CONTENT -->
	<section *ngIf="answers.data" class="anamnese-table-rows"
		[ngStyle]="{'grid-template-rows': 'repeat(' + answers.data.length + ', 1fr);'}">
		<ng-container *ngFor="let theme of answers.data; let i = index">

			<!-- First Column: Theme labels/titles/names -->
			<div class="first">{{ i + 1 }}. {{theme.label | translate}}</div>

			<ng-container *ngFor="let jaw of ['ok', 'uk']">
				<!-- IST / ACTUAL COLUMN -->
				<div class="data" *ngIf="hasAnswersWithActualDataEntries" [innerHTML]="translate.instant(theme[jaw].actual)"></div>
				<!-- TARGET COLUMN -->

				<div *ngIf="!isConcatenated(theme[jaw].target)" class="data" [innerHTML]="theme[jaw].target | translate">
				</div>
				<div *ngIf="isConcatenated(theme[jaw].target)" class="data">
					<ng-container *ngFor="let value of concatenatedToArray(theme[jaw].target)">{{value | translate}}
					</ng-container>
				</div>

				<!-- DETAILS COLUMN -->
				<div *ngIf="!hasAnswersWithActualDataEntries" class="data details">{{theme[jaw].details}}</div>

				<!-- THERAPY COLUMN -->
				<!-- Read-Write view as technician from TM-Admin -->
				<ng-container *ngIf="isTechnician; else noTechnician">
					<div class="data input" (click)="changeStatus(theme[jaw])">
						<i *ngIf="theme[jaw].status === JawStatus.PLANNED" class="material-icons green">done</i>
						<i *ngIf="theme[jaw].status === JawStatus.NOT_PLANNED" class="material-icons red">close</i>
						<i *ngIf="theme[jaw].status === JawStatus.NOT_PLANNED_WITH_COMMENT"
							class="material-icons red">close</i>
						<input *ngIf="theme[jaw].status === JawStatus.NOT_PLANNED_WITH_COMMENT"
							(click)="$event.stopPropagation();" type="text" class="input" maxlength="16"
							[(ngModel)]="theme[jaw].answerTechnician">
					</div>
				</ng-container>

				<!-- Read-Only view as customer from Treatment-Manager -->
				<ng-template #noTechnician>
					<div class="data">
						<i *ngIf="theme[jaw].status === JawStatus.PLANNED" class="material-icons green">done</i>
						<i *ngIf="theme[jaw].status === JawStatus.NOT_PLANNED || theme[jaw].status === JawStatus.NOT_PLANNED_WITH_COMMENT"
							class="material-icons red">close</i>
						<span *ngIf="theme[jaw].answerTechnician">{{theme[jaw].answerTechnician}}</span>
					</div>
				</ng-template>
			</ng-container>
		</ng-container>
	</section>


	<!-- COMMENT -->
	<h3>{{'Comment' | translate }}:</h3>
	<p class="comment"> {{answers.comment}} </p>


	<!-- ACTION BUTTONS -->
	<div class="action-buttons">
		<button mat-raised-button color="primary" (click)="printthis()">{{'Print' | translate}}</button>
		<button *ngIf="isTechnician" mat-raised-button color="default" mat-dialog-close data-cy="cancel-print-dialog">
			{{"Cancel" | translate}}
		</button>
		<button *ngIf="isTechnician" mat-raised-button color="primary" mat-dialog-close (click)="save()"
			data-cy="save-and-close-print-dialog">
			{{"SaveAndClose" | translate}}
		</button>
		<button *ngIf="!isTechnician" mat-raised-button color="primary" mat-dialog-close>{{'Close' | translate}}</button>
	</div>

</section>
