import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppState, TreatmentModel} from '@suite/tm-common';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import { SapService } from 'libs/sap/src/lib/services/sap.service';
import { CaOpportunity, InterimScanOption } from '@suite/treatments';

@Injectable()
export class OrderService {

	anamnese;

	constructor(private http: HttpClient,
				private store: Store<AppState>,
				private sapService: SapService,
				@Inject('CONFIG') private config) {

		this.store
			.pipe(
				tap(state => this.anamnese = state.anamnese),
			).subscribe();
	}

	getPriceForCancellation() {
		return this.sapService.getPriceForCancellation();
	}

	getPriceForQuotation(treatmentId: string) {
		return this.sapService.getPriceForQuotation(treatmentId);
	}

	/* Storno */
	cancelOrder(treatmentId: string, cancellationComment: string = "") {
		let comment = this.anamnese.comment || "";

		// Expand order comment with cancellation comment from dialog.
		comment += `***Stornierungsgrund***: ${cancellationComment}`;

		const requestBody = {comment: comment};
		return this.http
			.post(`${this.config.apiUrl}/treatments/${treatmentId}/cancelTreatment`, requestBody,
				{withCredentials: true});
	}

	alignerReleaseOrder(treatmentId: string, comment: string, isUpgrade: boolean = false): Observable<TreatmentModel> {
		const payload = {
			comment: comment,
			isUpgrade: isUpgrade
		}
		return <Observable<TreatmentModel>>this.http
			.post(`${this.config.apiUrl}/treatments/${treatmentId}/quotation/release`, payload, { withCredentials: true });
	}

	alignerFollowupOrder(treatmentId: string, packageType: CaOpportunity, interimScanOption: InterimScanOption): Observable<TreatmentModel> {
		const payload = {
			packageType: packageType,
			interimScanOption: interimScanOption,
		}
		return <Observable<TreatmentModel>>this.http
			.post(`${this.config.apiUrl}/treatments/${treatmentId}/packages/next`, payload, { withCredentials: true });
	}
}
