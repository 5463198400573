import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {PatientModel} from '@suite/tm-common';

import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import {selectPatientIds} from '../store/patient.selectors';
import {PatientState } from '../store/patient.reducer';
import {AddPatient, UpsertPatient } from '../store/patient.actions';

@Injectable()
export class PatientsService {
	patientsIds: string[];

	constructor(private httpClient: HttpClient,
				private store: Store<PatientState>,
				@Inject('CONFIG') private config) {

		this.store.pipe(select(selectPatientIds))
			.subscribe(ids => this.patientsIds = ids.map(id => id.trim()));
	}

	getPatients(): Observable<PatientModel[]> {
		return this.httpClient.get<PatientModel[]>(
			`${this.config.apiUrl}/patients`,
			{
				withCredentials: true
			}
		);
	}

	uniquePatientId(patientId) {
		return this.patientsIds.findIndex(id => id === patientId) === -1;
	}

	savePatient(patient): Observable<PatientModel> {
		return this.httpClient
			.post<PatientModel>(`${this.config.apiUrl}/patients`, patient , {withCredentials: true})
			.pipe(
				tap( newPatient => this.store.dispatch( new AddPatient({patient: newPatient})))
			);
	}

	updatePatient(patient): Observable<PatientModel> {
		return this.httpClient
			.put<PatientModel>(`${this.config.apiUrl}/patients/${patient._id}`, patient , {withCredentials: true})
			.pipe(
				tap( updatedPatient => this.store.dispatch( new UpsertPatient({patient: updatedPatient})))
			);
	}


}
