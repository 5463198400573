import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthActionTypes, LoginAction, LogoutAction, LogoutAdminAction} from './auth.actions';

import {map, switchMap, tap} from 'rxjs/operators';
import {ClearStateAction, GoAction, GoSignInFromNoHttpAuthAction} from '@suite/tm-common';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthEffects {



	login$ = createEffect(() => this.actions$.pipe(
		ofType<LoginAction>(AuthActionTypes.LoginAction),
		tap(action => {
			localStorage.setItem('user', JSON.stringify(action.payload));
		})
	), {dispatch: false});


	logout$ = createEffect(() => this.actions$.pipe(
		ofType<LogoutAction>(AuthActionTypes.LogoutAction),
		tap(action => {
			localStorage.removeItem('user');
			// fire and forget logout
			this.authService.logout()
				.subscribe({
					error: (e) => console.error(e),
					complete: () => console.info('Logged out successfully')
				});
		}),
		switchMap(res => [
			new ClearStateAction(),
			new GoAction({path: ['/signin']})
		])

	));

	logoutAdmin$ = createEffect(() => this.actions$.pipe(
		ofType<LogoutAdminAction>(AuthActionTypes.LogoutAdminAction),
		tap(action => {
			localStorage.removeItem('user');
			// delete magento session via ioApi
			this.authService.logoutAdmin()
				.subscribe({
					error: (e) => console.error(e),
					complete: () => console.info('Logged out successfully')
			});
		}),
		switchMap(res => [
			new ClearStateAction(),
			new GoAction({path: ['/signin']})
		])

	));

	noHttpsAuth$ = createEffect(() => this.actions$.pipe(
		ofType<LogoutAction>(AuthActionTypes.NoHttpAuthAction),
		map(() => new GoSignInFromNoHttpAuthAction())
	));

	constructor( private actions$: Actions,
				 private authService: AuthService) {}
}
