<mat-card>
	<mat-card-header>
		<mat-card-title><h1><img matCardImage src="assets/img/upsLogo.gif" alt="ups"> {{'UPS.pickupService' | translate}}</h1></mat-card-title>
	</mat-card-header>

	<mat-card-content>
		<h3><i class="fal fa-address-card"></i> {{'UPS.addressSelection' | translate}}</h3>
		<mat-select
			[(ngModel)]="selectedAddressName"
			(selectionChange)="onAddressSelection()"
		>
			<mat-option
				*ngFor="let address of shippingAddresses" [value]="address.AddressName"
			>
			{{ address.AddressName2}}, {{address.AddressName3 ? address.AddressName3 + ", " : ""}}{{address.Street}}, {{address.ZipCode}} {{address.City}}, {{getTextForCountryCode(address.Country)}}
			</mat-option>
		</mat-select>
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<formly-form [ngClass]="{'hide-details': !showDetails}" [form]="form" [model]="model" [fields]="fields" [options]="options" >
			</formly-form>
		</form>
		<div *ngIf="loading" class="loading-indicator"><i class="fal fa-spinner fa-spin"></i></div>
		<div *ngIf="prn" class="success-indicator">
			<i class="fal fa-check-circle"></i><br/><br/>
			{{'UPS.label' | translate}}<br/><br/>
			{{'UPS.PRN' | translate}}: <strong>{{prn}}</strong><br><br>
			<ux-ups-pickup-print [treatment]="treatment" [user]="user"></ux-ups-pickup-print>
		</div>
	</mat-card-content>
	<mat-card-actions align="end">
		<button mat-raised-button color="primary"
		[disabled]="!form.valid || loading || prn"
		(click)="onSubmit()"
		data-cy="pickupRequest-button">{{'UPS.pickupRequest' | translate}}</button>
	</mat-card-actions>
</mat-card>
