import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {first, tap} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import {TreatmentsRequested} from '../store/treatment.actions';
import {selectTreatmentTotal, TreatmentState} from '../store/treatment.reducer';
@Injectable()

export class TreatmentsResolver  {
    constructor(
        private store: Store<TreatmentState>) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {

        // NOTE: we just want to trigger action TreatmentsRequested here if there are
        // no entities in store, so its enough to selector selectTotal
        // important:  observable must complete, otherwise no routing
        return this.store
            .pipe(
                select(selectTreatmentTotal),
                // tap(cnt =>  cnt ? null : this.store.dispatch(new TreatmentsRequested())),
				tap ( () => this.store.dispatch(new TreatmentsRequested()) ),
				first()
            );
    }

}

