import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaOpportunity, TreatmentModel } from '@suite/treatments';
import { DataTransferMethod } from '@suite/treatments/models';

export enum FollowupPackageType {
	NEXT = "next",
	NEXT_WITH_IMPRINT_DATA = "nextWithImprintData",
	CORRECTION = "correction"
}

enum UIState {
	OPTIONS,
	DATA_TRANSFER
}

@Component({
	selector: "tm2-next-steps",
	templateUrl: "./treatment-next-steps.component.html",
	styleUrls: ["./_treatment-next-steps.component.scss"]
})
export class TreatmentNextStepsComponent implements OnInit {
	// from dialog data
	nextOpportunities: CaOpportunity[];
	treatment: TreatmentModel;
	wmsId: string;

	// visibilty flags
	showCorrection: boolean;
	showNext: boolean;
	showNextWithData: boolean;
	isFileUploaded: boolean = false;

	DataTransferMethod = DataTransferMethod;
	UIState = UIState;
	uiState: UIState = UIState.OPTIONS;

	FollowupPackageType = FollowupPackageType;
	followupPackageType: FollowupPackageType;

	uploadedMediaIds: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		data: {
			wmsId: string;
			treatment: TreatmentModel;
			nextOpportunities: CaOpportunity[];
		},
		@Inject('CONFIG') public config
	) {
		this.wmsId = data.wmsId;
		this.treatment = data.treatment;
		this.nextOpportunities = data.nextOpportunities;
		this.showNext = this.nextOpportunities.includes(CaOpportunity.Package);
		this.showNextWithData =
			this.nextOpportunities.includes(CaOpportunity.Package) ||
			this.nextOpportunities.includes(CaOpportunity.FreeCorrection);
		this.showCorrection = this.nextOpportunities.includes(CaOpportunity.ChargeableCorrection);
	}

	ngOnInit() {
		this.uiState = UIState.OPTIONS;
		this.isFileUploaded = false;
		this.uploadedMediaIds = [];
	}

	showDataTransferState(type: FollowupPackageType) {
		this.uiState = UIState.DATA_TRANSFER;
		this.followupPackageType = type;
	}

	onFileChanged($event) {
		//sloppy test, whether at least one stl file was uploaded
		this.isFileUploaded = !!$event.payload.uploadedStlFiles.uj ||
							  !!$event.payload.uploadedStlFiles.lj;

		//persist all uploaded media ids
		const uploadedAdditionalMediaIds = $event.payload.uploadedAdditionalFiles.map(value => value.mediaId );
		this.uploadedMediaIds = [...uploadedAdditionalMediaIds, $event.payload.uploadedStlFiles.uj, $event.payload.uploadedStlFiles.lj].filter( value => value !== '');
	}

	onPickupRequested($event) {
		//not implemented since it's not needed yet
	}

	isOrderButtonDisabled(): boolean{
		if ( this.treatment.product.options.dataTransfer === DataTransferMethod.UPLOAD ){
			return !this.isFileUploaded;
		}
		return false;
	}

	onDialogClose(){
		return {
			type: this.followupPackageType,
			dataTransferMethod: this.treatment.product.options.dataTransfer,
			uploadedMediaIds: this.uploadedMediaIds
		};
	}
}
