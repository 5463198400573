import {Component, EventEmitter, Input, Output} from '@angular/core';

import {ACTION, AppState} from '@suite/tm-common';

import {Additionals} from './upload-additional.types';

import { Store } from '@ngrx/store';
import { AnamneseUploadType, MediaModel, MediaType } from 'libs/media/src/lib/models/media.model';
import { FileEventType } from 'libs/media/src/lib/components/file-upload/file-upload-box/file-upload-box.component';
import { MediaFileUploadedAction } from 'libs/anamnese/src/lib/store/anamnese.actions';

@Component({
	selector: 'ux-upload-additionals',
	templateUrl: './upload-additionals.component.html',
	styleUrls: ['./upload-additionals.component.scss']
})
export class UploadAdditionalsComponent  {

	@Input() treatment;
	@Input() patientId: string;
	@Input() required;
	//pass through variable for file upload box
	@Input() uploadedFiles: { index: number, mediaModel: Partial<MediaModel> }[] = [];
	additionals = Additionals;

	@Output() action = new EventEmitter<any>();

	MediaType = MediaType;

	constructor(private store: Store<AppState>) {}

	onFileEvent($event, id, index) {
		const mediaId = $event.payload?.id;
		const orgFilename = $event.payload?.orgFilename;

		switch ($event.type) {
			// better handling see in order.component.ts from directMemotain
			case FileEventType.UPLOADED:
				//check if file was uploaded during anamnese
				if ( !$event.payload.treatment ){
					//store objectId of uploaded media file in reduxstore
					this.store.dispatch(new MediaFileUploadedAction( AnamneseUploadType.ADDITIONAL, mediaId, orgFilename, index) )
				}
				// inform parent that files are uploaded
				this.action.emit({type: ACTION.UPLOADED, payload: {mediaId: mediaId, index: index, uploadType: AnamneseUploadType.ADDITIONAL} });
				break;
			case FileEventType.ERROR:
				console.error('File Upload Error', $event.payload);
				break;
			case FileEventType.DELETED:
				this.action.emit({type: ACTION.DELETED, payload: {index: index, uploadType: AnamneseUploadType.ADDITIONAL} });
				break;

		}
	}

	/**
	 * Returns the mediaModel of the related additional 'slot'
	 * Mainly important if the user goes back during ordering process.
	 * @param index Slot of the additional file (0-8)
	 * @returns
	 */
	getMediaModelForIndex(index: number): Partial<MediaModel>{
		if ( this.uploadedFiles.length === 0 )
		{
			return null;
		}
		return this.uploadedFiles.find( value => index === value.index )?.mediaModel;
	}

}
