<section>

	<tm2-anamnese-product-selection
		*ngIf="action === ACTION.INITIAL"
		[isUserProfessionalCertified]="isUserProfessionalCertified"
		(next)="onNext($event)"
	></tm2-anamnese-product-selection>

	<tm2-anamnese-options
		*ngIf="action === ACTION.OPTIONS"
		[anamnese]="anamnese"
		(next)="onNext($event)"
	></tm2-anamnese-options>

	<tm2-anamnese-questionaire
		*ngIf="action === ACTION.QUESTIONAIRE"
		[anamnese]="anamnese"
		[level]="questionnaireScopeLevel"
		(next)="onNext($event)"
	></tm2-anamnese-questionaire>

</section>
