<section>
		<div class="patientDetails">
			<ng-container *ngIf="patient">
				<div class="patient">
					<img *ngIf="patient.image" src="{{patient?.image}}"/>
					<img *ngIf="!patient.image"
						 src="assets/img/patient-placeholder-300x300.jpg"/>
				</div>

				<div class="displayName">{{patient.displayName}}</div>

				<div class="birthday">
					{{'born' | translate}}
					{{patient.birthday | date:'mediumDate'}}
				</div>

				<button mat-raised-button color="primary" class="edit-button"
								 (click)="editPatient(patient.id)">
					{{'Edit' | translate}}
				</button>
			</ng-container>

		</div>

		<div class="runningTreatments">
			<h1>{{'Running Treatments' | translate}}</h1>

			<mat-table
				[dataSource]="dataSource"
				matSort>

				<!-- Id Column -->
				<ng-container matColumnDef="orderNo">
					<mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
					<mat-cell *matCellDef="let row" class="id">{{row.orderNo}}</mat-cell>
				</ng-container>

				<!-- Treatment Name Column -->
				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name' | translate}}</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div [innerHTML]="getProductNameHtml(row)"></div>
					</mat-cell>
				</ng-container>

				<!-- Start Date Column -->
				<ng-container matColumnDef="created">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{'Treatment started' | translate}}
					</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div>{{row.created | date:'mediumDate' }}</div>
					</mat-cell>
				</ng-container>

				<!-- IoApiOrder ID Column -->
				<ng-container matColumnDef="realId">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{'Order ID' | translate}}</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div>{{row.realId}}</div>
					</mat-cell>
				</ng-container>

				<!-- Status Column -->
				<ng-container matColumnDef="status">
					<mat-header-cell *matHeaderCellDef mat-sort-header>{{'Status' | translate}}</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div>{{ row.status | statusToString:true | translate}}</div>
					</mat-cell>
				</ng-container>

				<!-- Button Column -->
				<ng-container matColumnDef="button">
					<mat-header-cell *matHeaderCellDef></mat-header-cell>
					<mat-cell *matCellDef="let row">
						<button mat-raised-button color="primary" class="button"
						(click)="selectEntry(row._id)">
		  				 {{'Show' | translate}}
	   				</button>
					</mat-cell>
				</ng-container>


				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

			</mat-table>

			<mat-paginator
				[length]="length"
				[pageSize]="pageSize"
				[pageSizeOptions]="pageSizeOptions"
				(page)="pageEvent = $event"
			>
			</mat-paginator>

		</div>

		<ng-template #noTreatments>
			<h3>{{'No running Treatments. Create one.' | translate}}</h3>
		</ng-template>
</section>
