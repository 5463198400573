import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ACTION} from '../../../../../tm-common/src/lib/constants/subcomponent.actions';
@Component({
	selector: 'ux-next-back-actions',
	templateUrl: './next-back-actions.component.html',
	styleUrls: ['./_next-back-actions.component.scss']
})
export class NextBackActionsComponent {
	@Output() action = new EventEmitter<any>();
	@Input() disabledBack = false;
	@Input() disabledNext = false;
	@Input() hideNext = false;
	@Input() nextText = 'Next';

	constructor() {
	}

	onNext() {
		this.action.emit( {type: ACTION.NEXT});
	}

	onBack() {
		this.action.emit( {type: ACTION.BACK});
	}
}
