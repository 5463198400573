<section>

<ng-container>
	<ng-container *ngIf="loading$ | async; else products">
		<div class="loading-indicator">
		<i class="fal fa-spinner fa-spin"></i><br><br>
		{{'Loading Products' | translate}}
		</div>
	</ng-container>
	<ng-template #products>

	<ng-container
		*ngFor="let item of currentOrderLevel">
		<ng-container *ngIf="item.productKey; else itemWithoutProductKey">
			<ng-container *ngIf="isProductAllowed(item.productKey)">
				<mat-card *ngIf="!isProductSelected"
					(click)="onNext({type: ACTION.SELECT, payload: item})">

				<img mat-card-image src="{{getProductSelectionImageUrl(item.productKey)}}"/>

				<mat-card-content>
					<h3 [innerHTML]="getProductNameHtml(item.productKey)"></h3>
					<p *ngIf="!isProductWithImageDescription(item.productKey)">
						{{getDescriptionForProduct(item.productKey)}}
					</p>
					<ng-container *ngIf="isProductWithImageDescription(item.productKey)">
						<img src="{{getToothAreaImageUrl(item.productKey)}}">
						<!-- It's necessary to use $event.stopPropagation() to prevent that the (click) event of the parent element is triggered here -->
						<i style="position: absolute;cursor:help" (click)="onShowProductIndication(item.productKey);$event.stopPropagation()" class="fas fa-circle-info"></i>
					</ng-container>
				</mat-card-content>
				<mat-card-actions>
					<button mat-raised-button
							color="primary"
							(click)="onNext({type: ACTION.SELECT, payload: item})">
							{{'Select' | translate}}
						</button>
				</mat-card-actions>

				</mat-card>
			</ng-container>
		</ng-container>

		<ng-template #itemWithoutProductKey>
			<mat-card *ngIf="!isProductSelected"
			(click)="onNext({type: ACTION.SELECT, payload: item})">
			<!-- <pre>{{item|json}}</pre> -->
			<img mat-card-image
				src="{{item.image}}"
				/>

			 <mat-card-content>
				<h3>{{item.title | translate}}</h3>
				<p>
					{{item.description | translate}}
				</p>
			</mat-card-content>
			<mat-card-actions>
				<button mat-raised-button
						color="primary"
						(click)="onNext({type: ACTION.SELECT, payload: item})">
					{{'Select' | translate}}
				</button>
			</mat-card-actions>

			 </mat-card>
		</ng-template>

	</ng-container>
	</ng-template>
</ng-container>

	<!-- The following container is only displayed, if the customer is not certified for Professional FULL -->
	<ng-container
		*ngIf="isAlignerCategory() && !isUserProfessionalCertified">

		<mat-card>

			<img mat-card-image
				src="{{getProFullProductImageUrl()}}"
				alt="no image"/>

			<mat-card-content data-cy="certification-required-hint">
				<p>{{'certificationRequired' | translate}}</p>
			</mat-card-content>

			<div style="height: 36px; margin-bottom: 1.5vw"></div>

		</mat-card>

	</ng-container>

	<!-- Fallbeurteilung "light" -->
	<ng-container *ngIf="isAlignerCategory()">
		<mat-card>
			<img mat-card-image
				src="assets/img/products/professional_blank.png"
				alt="{{'CaseAssessment' | translate}}"/>
			<mat-card-content>
				<h3 [innerHTML]="'CaseAssessmentText' | translate"></h3>
			</mat-card-content>
			<mat-card-actions>
				<a mat-raised-button
					color="primary"
					href="mailto:paketeinschaetzung@scheu-dental.com?subject={{'CaseAssessment' | translate}}&body={{caseAssessmentBodyUriEncoded}}">
					{{'Select' | translate}}
				</a>
			</mat-card-actions>
		</mat-card>
	</ng-container>

</section>

<ux-next-back-actions
	*ngIf="!isProductSelected"
	[hideNext]="true"
	(action)="onNext({type: ACTION.BACK})"
></ux-next-back-actions>
