<span class="message-types">

	<i *ngFor="let icon of MessageTypeIcons"
	   [ngClass]="{
			active: messageType === icon.type,
			hidden: (icon.adminOnly && config.app !== 'tmadmin')
				|| icon.type === MessageTypes.MessagesReset
				|| icon.type === MessageTypes.CompletionDate
				|| icon.type === MessageTypes.CaseWorker
		}"
	   (click)="newType = icon.type"
	   title="{{MessageTypes[icon.type] | translate}}"
	   class="{{icon.icon}}"
	></i>

</span>
