<section>
		<div class="section-wrapper" *ngIf="showUpsPickUpContainer">
			<h1>{{'Abholungsauftrag' | translate}}</h1>
			<ux-pickup-container
			*ngIf="showUpsPickUpContainer"
			[wmsId]="wmsId"
			[treatmentId]="treatment._id"
			(action)="onAction($event)">
			</ux-pickup-container>
		</div>

		<div [ngClass]="showUpsPickUpContainer ? 'section-wrapper' : 'section-wrapper-single'">
			<h1>{{'Zusätzliche diagnostische Unterlagen' | translate}}</h1>
			<ux-upload-additionals
			(action)="onAction($event)"
			[treatment]="treatment"
			></ux-upload-additionals>
		</div>

</section>
<div class="button-wrapper">
	<button mat-raised-button
		*ngIf="disableNext && showUpsPickUpContainer"
		(click)="onSkipUpsPickup()"
		data-cy="skip-button">
		{{'Skip UPS Pickup' | translate}}
	</button>

	<ux-next-back-actions
		(action)="onAction($event)"
		[disabledNext]="disableNext"
		[disabledBack]="true"
		[nextText]="nextText"
	></ux-next-back-actions>
</div>
