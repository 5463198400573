<ux-grid-header [gridState]="dashboardState"
				[dataSource]="dataSource"
				(stateChange)="onGridStateChange($event)"
></ux-grid-header>
<div class="dashboard-container">

	<mat-table [dataSource]="dataSource" matSort>

		<!-- Patient Column -->
		<ng-container matColumnDef="patient">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'Patient'| translate}}</mat-header-cell>
			<mat-cell *matCellDef="let row" class="patient">
				<img *ngIf="row.patient?.image" src="{{row.patient.image}}" />
				<img *ngIf="!row.patient?.image" src="assets/img/patient-placeholder-300x300.jpg" />
				<div>{{row.patient?.lastName}}, {{row.patient?.firstName}}<br>{{row.patient?.patientId}}</div>
			</mat-cell>
		</ng-container>

		<!-- Product Column -->
		<ng-container matColumnDef="product">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'Product' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let row" class="product">
				<img class="logo" src="{{getProductLogoUrl(row)}}"/>
				<div>
					<span [innerHTML]="getProductNameHtml(row)"></span><br>
				</div>
			</mat-cell>
		</ng-container>

		<!-- needForAction -->
		<ng-container matColumnDef="needForAction">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'NeedForAction' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="need-for-action">
					<i *ngIf="row.neededAction === 'send data'" class="fal fa-upload fa-2xl"></i>
					<i *ngIf="row.neededAction === 'send model'" class="{{getSendModelMethodIcon(row)}}"></i>
					<i *ngIf="row.neededAction === 'release'"
						class="fal fa-2xl {{checkOnOverdue(row) ? 'fa-triangle-exclamation' : 'fa-circle-exclamation'}}"></i>
					{{row.neededAction | translate}}
				</div>
			</mat-cell>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="status">
			<mat-header-cell *matHeaderCellDef mat-sort-header>{{'MediaState' | translate}}</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span class="status-wrapper">
					<span class="status {{getStatusCssClass(row)}}"></span>
				</span>
				<div class="status-info">
					<span class="status-text">
						{{ row.status  | statusToString:true | translate}}
					</span><br>
					<span class="status-date">
						{{row.statusChanged | date: 'medium' : '' : config.locale }}
					<br></span>
					<span *ngIf="row.schema_version >= 2">
						{{'OrderId' | translate}}: {{row.quotation.docNum}}
					</span>
					<span *ngIf="row.schema_version < 2 && orders?.at(-1)?.realId !== 'n/a'">
						{{'OrderId' | translate}}: {{row.orders.at(-1).realId}}
					</span>
				</div>
			</mat-cell>
		</ng-container>

		<!-- Action Column -->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-raised-button
						(click)="selectEntry(row)"
						[color]="row.needForAction ? 'primary' : 'default'" >
					{{'Show' | translate}}
				</button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"
				 [ngClass]="{hovered: row.hovered, highlighted: row._id === dashboardState.id, overdue: checkOnOverdue(row)}"
				 (mouseover)="row.hovered = true"
				 (mouseout)="row.hovered = false"></mat-row>

	</mat-table>

</div>

