import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MediaBaseService {

	constructor(private httpClient: HttpClient,
				@Inject('CONFIG') private config) { }

	getFile(mediaId:string) {
		return this.httpClient
			.get(`${this.config.apiUrl}/media/${mediaId}`, {withCredentials: true});
	}

}
