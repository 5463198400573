<div class="upload-wrapper">
	<div data-cy="upload-stl">
		<h1>{{'Digitale Kiefer Daten' | translate}}</h1>
		<ux-upload-stl
		[uploadedFiles]="uploadedStlFiles"
		[patientId]="patientId"
		[bothJawStlRequired]="jaws.uj ? true : false"
		(action)="onAction($event)"
		></ux-upload-stl>
	</div>
	<div data-cy="upload-additionals">
		<h1>{{'Zusätzliche diagnostische Unterlagen' | translate}}</h1>
		<ux-upload-additionals
		[uploadedFiles]="uploadedAdditionalFiles"
		[patientId]="patientId"
		(action)="onAction($event)"
		></ux-upload-additionals>
	</div>
</div>

<ux-next-back-actions
	(action)="onAction($event)"
	[disabledNext]="disableNext"
></ux-next-back-actions>

