export interface MediaModel {
	uid: number;
	type: MediaType;
	filename: string;
	filetype: string;
	filesize: number;
	orgFilename: string;
	patient: string; 		//ObjectID of mongoDB reference
	treatment: string; 		//ObjectID of mongoDB reference
	lastModified: Date;
	created: Date;
	expiresAt?: Date;
	visibilityScope: MediaVisibilityScope;

	/** @deprecated Legacy since 2.0.0, do not use. */
	order?: string;
	//exclusive mongodb fields
	_id?: string;			//ObjectID of mongodb entry
}

export enum MediaType
{
	User = 0,
	Admin = 1
}

export enum MediaVisibilityScope{
	PUBLIC = "public",
	INTERN = "intern",
	ADMIN = "admin"
}

export enum  UPL_ERROR {
	TOO_MANY = 0,
	INVALID = 1,
	TOO_LARGE = 2,
	TRANSFER = 3,
	EMPTY = 4,
	DUPLICATE = 5
}

export enum UPL_STATE {
	PERCENT = 0,
	COMPLETED = 1,
	ERROR = 2
}

export enum AnamneseUploadType {
	UJ = 'uj',
	LJ = 'lj',
	ADDITIONAL = 'additional'
}
