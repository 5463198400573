import {Action} from '@ngrx/store';

export enum AuthActionTypes {
	LoginAdminAction = '[Auth] Admin Login',
	LoginAction = '[Auth] Login',
	LogoutAction = '[Auth] Logout',
	LogoutAdminAction = '[Auth] Logout Admin',
	NoHttpAuthAction = '[AuthHttpInterceptor] No HTTP Auth',
	SaveRedirectUrlAction = '[Auth] Save RedirectUrl',
}
export class LoginAction implements Action {
	readonly type = AuthActionTypes.LoginAction;
	constructor( public payload) {}
}
export class LoginAdminAction implements Action {
	readonly type = AuthActionTypes.LoginAdminAction;
	constructor( public payload) {}
}
export class LogoutAction implements Action {
	readonly type = AuthActionTypes.LogoutAction;
}
export class LogoutAdminAction implements Action {
	readonly type = AuthActionTypes.LogoutAdminAction;
}
export class NoHttpAuthAction implements Action {
	readonly type = AuthActionTypes.NoHttpAuthAction;
}
export class SaveRedirectUrlAction implements Action {
	readonly type = AuthActionTypes.SaveRedirectUrlAction;
	constructor( public payload) {}
}

export type AuthActions =
    | LoginAction
	| LoginAdminAction
	| LogoutAction
	| LogoutAdminAction
	| NoHttpAuthAction
	| SaveRedirectUrlAction;
