<section>
	<h1>
		{{'Welcome' | translate}} {{displayName$ | async}}
		&nbsp;
		<span class="customer-id">{{'CustomerId' | translate}}: {{userWmsId$ | async}}</span>
	</h1>

    <div class="dashboard-ctrls">
        <button mat-raised-button color="primary"
                (click)="onNewCase()">
            <i class="fal fa-smile-plus"></i> {{'new Case' | translate}}
        </button>
    </div>

    <mat-tab-group mat-stretch-tabs="false" #tabs
                   (selectedIndexChange)="onIndexChange($event)"
                   [selectedIndex]="selectedTab"
    >

        <mat-tab label="{{'Dashboard' | translate}}">
            <!-- ng-template for lazy-loading of tab content to correct sort icons error -->
            <ng-template matTabContent>
                <tm2-dashboard-entries [dashboardState]="dashboardState"
                                       (stateChange)="onStateChange(0,$event)"
                ></tm2-dashboard-entries>
            </ng-template>
        </mat-tab>

        <mat-tab label="{{'Patientlist' | translate}}">
            <!-- ng-template for lazy-loading of tab content to correct sort icons error -->
            <ng-template matTabContent>
                <tm2-patients-list
                        (next)="onPatientSelected($event)"
                        (stateChange)="onStateChange(1,$event)"
                        [dashboardState]="dashboardState"
                        [patients]="patients$ | async">
                </tm2-patients-list>
            </ng-template>
        </mat-tab>

    </mat-tab-group>

</section>

<div *ngIf="(stoerer$ | async) as stoererContent"
     (click)="onStoererSeen()"
     class="stoerer-wrapper"
     title="{{'click to close' | translate}}">
    <div class="stoerer">
        <i class="fal fa-times-circle fa-3x"></i>
        <div innerHTML="{{stoererContent}}"></div>
    </div>
</div>

