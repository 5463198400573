
<div class="jawbones">
	<h3>{{'Please select jawbone(s)' | translate}}</h3>
	<div (click)="onToggleJawbone('uj')"
		 [ngClass]="{'selected': options.uj}"
		 class="jawbone uj">
		<img src="assets/img/ca_ok.png"><br>
	</div>
	<div (click)="onToggleJawbone('lj')"
		 [ngClass]="{'selected': options.lj}"
		 class="jawbone lj">
		<img src="assets/img/ca_uk.png">
	</div>
</div>
<!--pre>{{options | json}}</pre-->
