import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnamneseUploadType } from '@suite/media';
import { ACTION, TreatmentModel } from '@suite/tm-common';

@Component({
  selector: 'ux-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./_fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {

  @Input() treatment: TreatmentModel = null;
  @Input() patientId: string;
  @Input() showStl: boolean = true;
  @Input() showAdditionals: boolean = true;
  @Output() informParent = new EventEmitter<any>();

  uploadedStlFiles = { uj: "", lj: "" };  // stores the mediaId for each jaw
  uploadedAdditionalFiles : { index: number, mediaId: string }[]= [] // stores the mediaId for each index

  constructor() {}

  ngOnInit(): void {
	this.uploadedStlFiles = { uj: "", lj: "" };
	this.uploadedAdditionalFiles = [];
  }

  onAction(action){
	const payload = action.payload;

	if([ACTION.UPLOADED, ACTION.DELETED].includes(action.type)){
		const type: AnamneseUploadType = payload.uploadType;
			switch (type) {
				case AnamneseUploadType.UJ:
					this.uploadedStlFiles.uj = action.type === ACTION.UPLOADED ? payload.mediaId : "";
					break;
				case AnamneseUploadType.LJ:
					this.uploadedStlFiles.lj = action.type === ACTION.UPLOADED ? payload.mediaId : "";
					break;
				case AnamneseUploadType.ADDITIONAL:
					if (action.type === ACTION.UPLOADED){
						this.uploadedAdditionalFiles.push({
							index: payload.index,
							mediaId: payload.mediaId
						});
					}
					if (action.type === ACTION.DELETED){
						this.uploadedAdditionalFiles = this.uploadedAdditionalFiles.filter(elem => elem.index !== payload.index);
					}
					break;
			}
	}

	this.informParent.emit({payload: {
			uploadedStlFiles: this.uploadedStlFiles,
			uploadedAdditionalFiles: this.uploadedAdditionalFiles
		} });
	}
}
