import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { Subscription, of } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { select, Store } from "@ngrx/store";

import { AppState, GoAction, selectCurrentUrl } from '@suite/tm-common';
import { selectDisplayName, selectWmsId } from '@suite/auth';
import { selectAllPatients } from '@suite/patients';
import { selectStoererSeen, StoererSeenAction } from '@suite/magento';
import { DashboardState, DashboardStates, selectDashboardStates, SaveDashboardState } from '@suite/treatments';
import { CmsService } from 'libs/cms/src/lib';

@Component({
	selector: 'tm2-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./_dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

	@ViewChild('tabs', {static: true}) tabs: MatTabGroup;
	displayName$;
	userWmsId$;
	patients$;
	stoerer$;
	selectedTab;

	dashboardStates: DashboardStates;
	dashboardState: DashboardState;
	defaultState: DashboardState = {
		id: '',
		filter: '',
		direction: 'asc',
		active: 'needForAction',
		pageSize: 6,
		pageIndex: 0
	};
	dashboadStatesSubscription: Subscription;
	userSubscription: Subscription;
	urlSubscription: Subscription;

	constructor(private store: Store<AppState>,
				private cmsService: CmsService,
				@Inject("CONFIG") private config
				) {
	}

	ngOnInit() {
		// set dashboardState to default
		this.dashboardState = {...this.defaultState};

		//lifecycle handled by angular async pipe
		this.displayName$ = this.store.pipe(select(selectDisplayName));
		this.userWmsId$ = this.store.pipe(select(selectWmsId));
		this.patients$ =  this.store.pipe( select(selectAllPatients), map(data => Object.values(data)) );
		this.stoerer$ = this.store.pipe(
			select(selectStoererSeen),
			switchMap((stoererSeen) => {
				if (stoererSeen) {
					return of("");
				} else {
					const cmsKey = "tm_startpage_stoerer_".concat(this.config.locale);
					return this.cmsService
						.getCmsBlock(cmsKey)
						.pipe(
							take(1),
							filter((cmsBlock) => cmsBlock.activated), //return empty object if cms block is deactivated
							map((cmsBlock) => cmsBlock.content)
						);
				}
			})
		);

		// set tab depending on url
		this.urlSubscription = this.store.pipe( select(selectCurrentUrl) )
			.subscribe(url => {
				const parts = url.split('?');
				url === '/dashboard/patients'
					? this.selectedTab = 1
					: this.selectedTab = 0;
			});

		this.dashboadStatesSubscription = this.store.pipe(
			select(selectDashboardStates),
			filter(data=>!!data))
				.subscribe(data => {
						this.dashboardStates = data;
						this.selectedTab = data.tab || 0;
						this.dashboardState = {...this.defaultState, ...data.tabs[this.selectedTab]};
				});
	}

	ngOnDestroy(): void {
		this.dashboadStatesSubscription.unsubscribe();
		this.urlSubscription.unsubscribe();
	}

	onNewCase() {
		this.store.dispatch( new GoAction({path: ['/anamnese']}) );
	}

	onStoererSeen() {
		this.store.dispatch(new StoererSeenAction());
	}

	onStateChange(tab, newState) {
		this.store.dispatch(new SaveDashboardState({tab: tab, state: {...this.dashboardState, ...newState}}));
	}
	onPatientSelected(action) {
		this.store.dispatch(new GoAction({path: [`/patient/details/${action.payload._id}`]}));
	}

	onIndexChange(index) {
		// if there is a saved state, use it!
		if (this.dashboardStates && this.dashboardStates.tabs && this.dashboardStates.tabs[index]) {
			this.dashboardState = this.dashboardStates.tabs[index];
			// otherwise use the default state
		} else {
			this.dashboardState = {...this.defaultState};
		}
	}

}
