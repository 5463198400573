<!-- https://v16.material.angular.io/components/tabs/overview -->
<div class="messages-view-filters">
	<div class="filter-option">
		<span class="filter-list-label">
			<mat-icon>filter_list</mat-icon>
			{{"SenderFilter" | translate }}
		</span>
		<mat-button-toggle-group
			name="senderFilter"
			class="sender-filter"
			aria-label="Sender Filter"
			[value]="SenderFilter.NONE"
			#senderFilter="matButtonToggleGroup"
		>
			<mat-button-toggle value={{SenderFilter.NONE}}>{{"All" | translate}}</mat-button-toggle>
			<mat-button-toggle value={{SenderFilter.EXTERN_ONLY}}>{{"ExternOnly" | translate}}</mat-button-toggle>
			<mat-button-toggle value={{SenderFilter.CUSTOMER_ONLY}}>{{"CustomerOnly" | translate}}</mat-button-toggle>
			<mat-button-toggle value={{SenderFilter.INTERN_ONLY}}>{{"InternOnly" | translate}}</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
	<div class="filter-option">
		<span class="filter-list-label">
			<mat-icon>filter_list</mat-icon>
			{{"ContextFilter" | translate }}
		</span>
		<mat-button-toggle-group
			class="context-filter"
			name="contextFilter"
			aria-label="Context Filter"
			[value]="ContextFilter.NONE"
			#contextFilter="matButtonToggleGroup"
		>
			<mat-button-toggle value={{ContextFilter.NONE}}>{{"All" | translate}}</mat-button-toggle>
			<mat-button-toggle value={{ContextFilter.CURRENT_STATUS}}>{{"CurrentStatusOnly" | translate}}</mat-button-toggle>
			<mat-button-toggle value={{ContextFilter.CONSULTATION_STATUS}}>{{"ConsultationOnly" | translate}}</mat-button-toggle>
		</mat-button-toggle-group>
	</div>
</div>

<mat-tab-group class="messages-view-tab-group">
	<mat-tab label="{{'MessagesTab' | translate}}">
		<section class="table-container" tabindex="0">
			<table mat-table [dataSource]="communicationMessages(messages)">
				<!-- Icons Column -->
				<ng-container matColumnDef="icons">
					<td mat-cell *matCellDef="let msg"
						[ngStyle]="{'text-align': isStaffMemberMessage(msg) ? 'right' : 'left'}"
						>
						<i *ngIf="!isStaffMemberMessage(msg)" class="{{MessageTypeIcons[msg.type].icon}}"></i>
						<i *ngIf="hasMailNotification(msg)" style="margin-right: 2px" class="fa fa-envelope"></i>
						<i *ngIf="isStaffMemberMessage(msg)"
							class="{{MessageTypeIcons[msg.type].icon}}"
							[ngClass]="{'fa-flip-horizontal': msg.type === MessageTypes.Note}"
							></i>
					</td>
				</ng-container>

				<!-- Sender Column -->
				<ng-container matColumnDef="sender">
					<td mat-cell *matCellDef="let msg">
						<span matTooltip="{{msg.sendersUserId}}">{{sendersTypeLabel(msg.sendersType)}}</span>
					</td>
				</ng-container>

				<!-- Date Column -->
				<ng-container matColumnDef="date">
					<td mat-cell *matCellDef="let msg" class="break-line">
						{{msg.created | date:dateFormat}}
					</td>
				</ng-container>

				<!-- Status Column -->
				<ng-container matColumnDef="status">
					<td mat-cell *matCellDef="let msg">
						<p>
							<span matTooltip="{{(msg.context?.status) | statusToString |  translate}}">
								{{formatTreatmentStatusText(msg.context?.status)}}
							</span>
							<br>
							<span matTooltip="{{('TREATMENT.STATUS.' + msg.context?.orderStatus) | translate}}">
								{{formatOrderStatusText(msg.context?.orderStatus)}}
							</span>
						</p>
					</td>
				</ng-container>

				<!-- Message Column -->
				<ng-container matColumnDef="message">
					<td mat-cell *matCellDef="let msg" [innerHtml]="msg.message"
						[ngClass]="{'staff-message': isStaffMemberMessage(msg)}"
					></td>
				</ng-container>

				<!-- Rows Definition -->
				<tr mat-row *matRowDef="let row; columns: messageTabTableColumns;"
					[class.internal-message-row]="isInternalMessage(row)"
					[class.collapsed-row]="!isRowVisible(row, senderFilter.value, contextFilter.value)"
					[@rowExpand]="isRowVisible(row, senderFilter.value, contextFilter.value) ? 'expanded' : 'collapsed'"
				>
				</tr>

				<!-- No Data Row -->
				<tr class="mat-row no-data-row" *matNoDataRow>
					<td class="mat-cell" [attr.colspan]="messageTabTableColumns.length">
						{{'NoMessages' | translate}}
					</td>
				</tr>
			</table>
		</section>
	</mat-tab>

	<mat-tab label="{{'EventLogTab' | translate}}">
		<section class="table-container" tabindex="1">
			<table mat-table [dataSource]="eventLogMessages(messages)" multiTemplateDataRows>
				<!-- Date Column -->
				<ng-container matColumnDef="date">
					<td mat-cell *matCellDef="let msg"
						class="single-line"
					>
						{{msg.created | date:dateFormat}}
					</td>
				</ng-container>

				<!-- Icons Column -->
				<ng-container matColumnDef="icons">
					<td mat-cell *matCellDef="let msg"
						[ngStyle]="{'text-align': isStaffMemberMessage(msg) ? 'right' : 'left'}"
					>
						<i *ngIf="hasMailNotification(msg)" style="margin-right: 2px" class="fa fa-envelope"></i>
						<i class="{{MessageTypeIcons[msg.type].icon}}"
							[ngClass]="{'fa-flip-horizontal': (isStaffMemberMessage(msg) && msg.type === MessageTypes.Note)}"
						></i>
					</td>
				</ng-container>

				<!-- Sender Column -->
				<ng-container matColumnDef="sender">
					<td mat-cell *matCellDef="let msg">
						<span matTooltip="{{msg.sendersUserId}}">{{sendersTypeLabel(msg.sendersType)}}</span>
					</td>
				</ng-container>

				<!-- Message Column -->
				<ng-container matColumnDef="message">
					<td mat-cell *matCellDef="let msg" [innerHtml]="systemLogMessageHtml(msg)"></td>
				</ng-container>

				<!-- Expand Button Column -->
				<ng-container matColumnDef="expand">
					<td mat-cell *matCellDef="let msg">
						<button mat-icon-button aria-label="expand row"
							*ngIf="msg.message.length >= 100"
							(click)="toggleExpandMessage(msg); $event.stopPropagation()">
							<mat-icon>{{(expandedMessage !== msg) ? "keyboard_arrow_down" : "keyboard_arrow_up"}}</mat-icon>
						</button>
					</td>
				</ng-container>

				<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let msg" [attr.colspan]="systemTabTableColumns.length">
					<div class="message-detail"
						[@detailExpand]="(msg === expandedMessage) ? 'expanded' : 'collapsed'"
					>
						<div class="message-detail-message" [innerHTML]="msg.message"></div>
					</div>
					</td>
				</ng-container>

				<!-- Rows Definition -->
				<tr mat-row *matRowDef="let row; columns: systemTabTableColumns;"
					[class.internal-message-row]="isInternalMessage(row)"
					[class.collapsed-row]="!isRowVisible(row, senderFilter.value, contextFilter.value)"
					[@rowExpand]="isRowVisible(row, senderFilter.value, contextFilter.value) ? 'expanded' : 'collapsed'"
				>
				</tr>
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
					class="message-detail-row"
					[class.internal-message-row]="isInternalMessage(row)"
				>
				</tr>

				<!-- No Data Row -->
				<tr class="mat-row no-data-row" *matNoDataRow>
					<td class="mat-cell" [attr.colspan]="messageTabTableColumns.length">
						{{'NoEntries' | translate}}
					</td>
				</tr>
			</table>
		</section>
	</mat-tab>
</mat-tab-group>
