import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { User } from "@suite/tm-common/models";

@Injectable()
export class IoApiProxyService {
	baseUrl: string;

	constructor(private http: HttpClient, @Inject("CONFIG") private config) {
		this.baseUrl = this.config.ioApiUrl;
	}

	//////////////////////////////////////////////////////////
	// ADMIN
	//////////////////////////////////////////////////////////

	getAdmins(): Observable<Object> {
		return this.http.get(`${this.baseUrl}/user/admin`);
	}

	//////////////////////////////////////////////////////////
	// USER
	//////////////////////////////////////////////////////////

	isLoggedIn(): Observable<Object> {
		return this.http.get(`${this.baseUrl}/user/isLoggedIn`);
	}

	isLoggedInAsAdmin(): Observable<Object> {
		return this.http.get(`${this.baseUrl}/user/isLoggedInAsAdmin`);
	}

	deleteSession(): Observable<User> {
		return this.http.delete<User>(`${this.baseUrl}/user/logout`);
	}

	deleteAdminSession(): Observable<User> {
		return this.http.delete<User>(`${this.baseUrl}/user/logoutAdmin`);
	}

	forgotPassword(email: string): Observable<Object> {
		return this.http.get(
			`${this.baseUrl}/user/forgotPassword/?email=${email}`
		);
	}

	getUser(id: string): Observable<User> {
		return this.http.get<User>(`${this.baseUrl}/user/${id}`);
	}

	//////////////////////////////////////////////////////////
	// CUSTOMER
	//////////////////////////////////////////////////////////

	getCustomerNotes(customerId: any): Observable<Object> {
		return this.http.get(
			`${this.baseUrl}/customer/notes/messages?id=${customerId}`
		);
	}
}
