import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import {
	HttpClient,
	HttpErrorResponse,
	HttpEvent,
	HttpEventType,
	HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, last, map } from "rxjs/operators";

import { MediaModel, UPL_STATE } from "../models";
import { saveAs } from "file-saver";
import { FileEvent, FileEventType } from "../components";

@Injectable()
export class MediaService {
	@Output() fileNotFoundEvent = new EventEmitter<FileEvent>();

	constructor(
		private httpClient: HttpClient,
		@Inject("CONFIG") private config
	) {
	}

	listFiles(params: {
		treatmentId: string;
	}): Observable<MediaModel[]> {
		return this.httpClient.get<MediaModel[]>(`${this.config.apiUrl}/media`, {
			params,
			withCredentials: true
		});
	}

	deleteFile(id) {
		return this.httpClient.delete(`${this.config.apiUrl}/media/${id}`, {
			withCredentials: true
		});
	}

	uploadFile(
		file: File,
		params: { treatment: string },
		triggerOrderStatus: boolean = true,
		transferStatus: EventEmitter<any>
	) {
		const formData: FormData = new FormData();
		// submit each param as form field
		Object.keys(params).forEach((prop) => {
			formData.append(prop, params[prop]);
		});

		formData.append("file", file, file.name);
		// unfortunately FormData is not capable of transmitting 'boolean' values
		// therefore we transfer '0' or '1' representing the boolean pendant
		formData.append("triggerOrderStatus", triggerOrderStatus ? '1' : '0' );

		const url = this.config.apiUrl;

		const req = new HttpRequest("POST", `${url}/upload`, formData, {
			withCredentials: true,
			reportProgress: true
		});

		return this.httpClient.request(req).pipe(
			map((httpEvent: HttpEvent<any>) => this.getEventMessage(httpEvent, file, transferStatus)),
			last(), // return last (completed) message to caller
			catchError((error) => this.handleError(error, transferStatus))
		);
	}

	uploadFileAnamnese(
		file: File,
		patientId: string,
		transferStatus: EventEmitter<any>
	) {
		const formData: FormData = new FormData();
		formData.append("patientId", patientId);
		formData.append("file", file, file.name);

		const url = this.config.apiUrl;
		const req = new HttpRequest("POST", `${url}/anamnese/media`, formData, {
			withCredentials: true,
			reportProgress: true
		});

		return this.httpClient.request(req).pipe(
			map((httpEvent: HttpEvent<any>) => this.getEventMessage(httpEvent, file, transferStatus)),
			last(), // return last (completed) message to caller
			catchError((error) => this.handleError(error, transferStatus))
		);
	}

	updateMedium(mediaId: string, requestBody){
		const url = this.config.apiUrl;
		const req = new HttpRequest("PATCH", `${url}/media/${mediaId}`, requestBody, {
			withCredentials: true,
		});
		return this.httpClient.request(req).subscribe({
			error: error => console.error("Error caught while updating medium", error)
		});
	}

	getFile(id) {
		const httpOptions: Object = {
			responseType: "blob",
			observe: "response",
			withCredentials: true
		};
		return this.httpClient
			.get(`${this.config.apiUrl}/media/${id}`, httpOptions)
			.subscribe({
				next: (response: any) => {
					const blob: Blob = new Blob([response.body]);

					// To handle UTF-8 in the Content-Disposition header, browsers have added the 'filename*' directive.
					// As we're handling the request manually, we need to check for it manually, too.
					// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition#directives
					// https://www.rfc-editor.org/rfc/rfc6266#section-4.3
					const cDispHeader: string = response.headers.get(
						"content-disposition"
					);
					// Regex: capture group behind `filename*=UTF8''` (case-insensitive)
					const filenameUtf8Matches = cDispHeader.match(
						/filename\*?=UTF8''([^'";]+)/i
					);
					// Regex: capture the group XXX in `filename="XXX"`
					const filenameMatches = cDispHeader.match(
						/filename=\"?([^'";]+)/
					);

					let filename = filenameUtf8Matches?.length > 1
						? decodeURI(filenameUtf8Matches[1])
						: filenameMatches[1];

					saveAs(blob, filename);
				},
				error: (error) => {
					console.error("Error caught while retrieving file", error);
					const notFoundEvent: FileEvent = {
						type: FileEventType.FILE_NOT_FOUND,
						payload: "fileNotFound"
					};
					this.fileNotFoundEvent.emit(notFoundEvent);
				}
			});
	}

	private getEventMessage(httpEvent: HttpEvent<any>, file: File, transferStatus) {
		switch (httpEvent.type) {
			case HttpEventType.Sent:
				return `Uploading file "${file.name}" of size ${file.size}.`;

			case HttpEventType.UploadProgress:
				// Compute and show the % done:
				const percentDone = Math.round(
					(100 * httpEvent.loaded) / httpEvent.total
				);
				transferStatus.emit({
					event: UPL_STATE.PERCENT,
					payload: percentDone
				});
				return `File "${file.name}" is ${percentDone}% uploaded.`;

			case HttpEventType.Response:
				transferStatus.emit({
					event: UPL_STATE.COMPLETED,
					payload: httpEvent.body
				});
				return `File "${file.name}" was completely uploaded!`;

			case HttpEventType.DownloadProgress:
				return `DownloadProgress file "${file.name}" of size ${file.size}.`;

			case HttpEventType.ResponseHeader:
				return `File "${file.name}" ${httpEvent.status} ${httpEvent.statusText}`;

			default:
				console.error("getEventMessage UNKNOWN", httpEvent);
				// this.transferStatus.emit({event: UPL_STATE.ERROR, payload: `${HttpEventType[event.type]}`});
				return `File "${file.name}" surprising upload event: ${
					HttpEventType[httpEvent.type]
				}.`;
		}
	}

	private handleError(
		error: HttpErrorResponse,
		transferStatus: EventEmitter<any>
	) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` +
					`body was: ${error.error}`,
				error
			);
		}
		transferStatus.emit({ err: UPL_STATE.ERROR, payload: error });
		return throwError(
			() => new Error("Something bad happened; please try again later.")
		);
	}
}
