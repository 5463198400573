import {Component, Inject, OnDestroy } from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {TranslateService} from '@ngx-translate/core';
import {AppState, NotificationService} from '@suite/tm-common';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import { CHANGE_ADDRESS_TARGET } from 'libs/ux-components/src/lib/container/page-header/page-header.component';

@Component({
	selector: 'tm2-root',
	templateUrl: './app.component.html',
	styleUrls: ['./_app.component.scss']
})

export class AppComponent implements OnDestroy {

	translateSubscription$;

	// for notification service - central snackbar
	notificationSubscription$;
	sbConfig = new MatSnackBarConfig();
	snackBarRef;

	//SEPA constants
	sepaFormTitle = 'SEPA Form';
	sepaPdfDE = '/cat/media/pdf/SCHEU_SEPA_de.pdf';
	sepaPdfEN = '/cat/media/pdf/SCHEU_SEPA_en.pdf';

	// menu definitions for page-header
	menuEntries = [
		{title: 'Dashboard', route: '/dashboard'},
		{title: 'Help', route: '/help'},
		{title: 'Contact', url: 'mailto:info@scheu-dental.com'},
		];

	subMenuEntries = [
		{title: this.sepaFormTitle, url: this.sepaPdfDE},
		{title: 'CustomerMasterDataChange', route: CHANGE_ADDRESS_TARGET}
	];

	constructor(private translate: TranslateService,
				private notification: NotificationService,
				public snackBar: MatSnackBar,
				private activatedRoute: ActivatedRoute,
				private store: Store<AppState>,
				@Inject('CONFIG') private config) {

		console.log('TM2 AppComponent constructed');

		// register locale and set default language
		registerLocaleData(localeDe, 'de');
		this.translate.setDefaultLang(this.config.locale);
		this.translate.use(this.config.locale);

		this.activatedRoute.queryParams.subscribe(params => {
			if (params['lang']) {
				this.config.locale = params['lang'];
				this.translate.use(params['lang']);
			}
		});

		// subscribe language changes
		this.translateSubscription$ = this.translate.onLangChange
			.subscribe(e => {
				if (this.config.locale !== e.lang) {
					console.log('Language Change act/new', this.config.locale, e.lang);
					this.config.locale = e.lang;
					this.subMenuEntries.find((entry) => entry.title === this.sepaFormTitle).url =
						this.config.locale === 'de' ? this.sepaPdfDE : this.sepaPdfEN;
				}
			});

		// subscribe notification service
		this.notificationSubscription$ =
			this.notification.notify.subscribe(data => this.handleNotification(data));

	}

	ngOnDestroy() {
		this.translateSubscription$.unsubscribe();
		this.notificationSubscription$.unsubscribe();
	}


	handleNotification(data) {
		this.sbConfig.verticalPosition = 'top';
		this.sbConfig.panelClass = [`sb-${data.type}`];
		this.sbConfig.duration = data.duration ? data.duration : this.sbConfig.duration;
		this.snackBarRef = this.snackBar.open(data.message, this.translate.instant('close'), this.sbConfig);
	}

}
