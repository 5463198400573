import {Component, AfterViewInit, ViewChild, EventEmitter, Input, Output, OnChanges} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {ACTION, PatientModel} from '@suite/tm-common';
import {LoggerService} from '@suite/tm-common';
import {DashboardState} from '@suite/treatments';

@Component({
	selector: 'tm2-patients-list',
	templateUrl: './patients-list.component.html',
	styleUrls: ['./patients-list.component.scss']
})

export class PatientsListComponent implements AfterViewInit, OnChanges {
	@Input() patients;
	@Input() actionLabel = 'Edit';
	@Input() dashboardState: DashboardState;

	@Output() stateChange = new EventEmitter<any>();
	@Output() next = new EventEmitter<any>();

	@ViewChild(MatSort, {static: true}) sort: MatSort;

	// table properties
	displayedColumns = ['visual', 'patient', 'birthday', 'action'];
	dataSource = new MatTableDataSource<PatientModel>();

	// pagination properties
	length = 100;
	pageSize = 6;
	pageEvent: PageEvent;
	pageIndex;
	pageSizeOptions = [6, 12, 18, 24, 36];
	state = {};

	constructor(private logger: LoggerService) {
	}

	ngOnChanges() {
		this.dataSource.data = structuredClone(this.patients);
		if (this.dashboardState) {
			this.applyFilter(this.dashboardState.filter || '');
			this.sort.active = this.dashboardState.active;
			this.sort.direction = this.dashboardState.direction;
		}
	}

	selectEntry(id: string) {
		this.stateChange.emit({...this.state, id});
		this.next.emit({type: ACTION.SELECTED, payload: { _id: id}});
	}

	/**
	 * helpers for material data table
	 */

	ngAfterViewInit() {
		// on after init view we have to wire our data source together, even if there is no data
		this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
		this.dataSource.filterPredicate = this.filterPredicate;
		this.dataSource.sort = this.sort;               // watch out: sort & paginator
		this.sort.sortChange.subscribe( x => {
			this.state = {...this.state, ...x};
			this.stateChange.emit(this.state);
		});
	}


	/**
	 * sortingDataAccessor - defines special sorting behavior
	 * @param {PatientModel} data
	 * @param {string} property
	 * @returns {string}
	 */
	sortingDataAccessor(data: PatientModel, property: string) {
		// return data to sort for combined cells
		switch (property) {
			case 'patient':
				return data.lastName + data.firstName + data.patientId;
			default:
				return data[property] ? data[property] : '';
		}
	}

	/**
	 * filterPredicate - defines special filter behavior
	 * @param {Patient} data
	 * @param {string} filter
	 * @returns {boolean}
	 */
	filterPredicate(data: PatientModel, filter: string) {
		if ((data.lastName.toLowerCase().indexOf(filter) !== -1) ||
			(data.firstName.toLowerCase().indexOf(filter) !== -1) ||
			(data.patientId.toLowerCase().indexOf(filter) !== -1) ||
			(data.birthday && data.birthday.toString().indexOf(filter) !== -1)
		) {
			return true;
		}
		return false;

	}
	onGridStateChange(state) {
		if (state.filter !== undefined) {
			this.applyFilter(state.filter);
		}
		this.stateChange.emit({...this.state, ...state});
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
		this.dataSource.filter = filterValue;
	}

}


