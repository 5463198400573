import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProductInfoMagento } from "libs/product/src/lib/models/product.model";
import {
	AnswerOption,
	RadioQuestion,
} from "@suite/questionaire";

export type AnamneseQuestionnaireAnswer = {
	value: AnswerOption;
	details: string;
};

export type AnamneseQuestionnaireFormData = {
	radioItems: (RadioQuestion & AnamneseQuestionnaireAnswer)[];
	comment: string;
};

@Component({
	selector: 'tm2-anamnese-questionaire-simple',
	templateUrl: './anamnese-questionaire-simple.component.html',
	styleUrls: ['./_anamnese-questionaire-simple.component.scss']
})
export class AnamneseQuestionaireSimpleComponent {
	AnswerOption = AnswerOption;

	@Output() action = new EventEmitter<any>();
	@Input() formData: AnamneseQuestionnaireFormData;
	@Input() productInfo: ProductInfoMagento;

	constructor() {}

	checkValid() {
		const isValid: boolean =
			this.formData.comment !== "" &&
			this.formData.radioItems.every((item) => item.value);
		return isValid;
	}
}
