import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { CmsService } from "./cms.service";
import { CmsBlockModel } from "../models";
import { Observable } from "rxjs";

@Injectable()
export class CmsBlockResolver {

	constructor( private cmsService: CmsService)
	{}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CmsBlockModel[]>{
		return this.cmsService.getCmsBlocks();
	}
}
