import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {DataTransferMethod, IoApiOrder} from '@suite/tm-common';
import { ProductUrlKey, ToothArea } from 'libs/product/src/lib';
import { SapShippingMethod } from '@suite/sap';

export enum OrderActionTypes {
	EstimatedCostsRequested = '[Anamnese Order] Estimated Costs Requested',
	EstimatedCostsUpdated = '[Order Effects] Estimated Costs Updated',
	ClearEstimatedCosts = '[Anamnese] Estimated Costs Prices',
	IoApiOrdersRequested = '[DirectMemotain] IoApiOrders Requested',
	IoApiOrdersLoaded = '[Order Effects] IoApiOrders Loaded',
	AddOrder = '[Order] Add Order',
	UpsertOrder = '[Order] Upsert Order',
	AddOrders = '[Order] Add Orders',
	UpsertOrders = '[Order] Upsert Orders',
	UpdateOrder = '[Order] Update Order',
	UpdateOrders = '[Order] Update Orders',
	DeleteOrder = '[Order] Delete Order',
	DeleteOrders = '[Order] Delete Orders',
	ClearOrders = '[Order] Clear Orders'
}
export class EstimatedCostsRequested implements Action {
	readonly type = OrderActionTypes.EstimatedCostsRequested;
	constructor(public payload: {
		productKey: ProductUrlKey,
		shippingMethod: SapShippingMethod,
		country: string,
		transferMethod: DataTransferMethod,
		uj: ToothArea,
		lj: ToothArea}) {
	}
}
export class EstimatedCostsUpdated implements Action {
	readonly type = OrderActionTypes.EstimatedCostsUpdated;

	constructor(public payload) {
	}
}
export class ClearEstimatedCosts implements Action {
	readonly type = OrderActionTypes.ClearEstimatedCosts;
}
export class IoApiOrdersRequested implements Action {
	readonly type = OrderActionTypes.IoApiOrdersRequested;

	constructor(public payload?: { fromDate: Date }) {}
}

export class IoApiOrdersLoaded implements Action {
	readonly type = OrderActionTypes.IoApiOrdersLoaded;

	constructor(public payload: { orders: IoApiOrder[] }) {
	}
}

export class AddOrder implements Action {
	readonly type = OrderActionTypes.AddOrder;

	constructor(public payload: { order: IoApiOrder }) {
	}
}

export class UpsertOrder implements Action {
	readonly type = OrderActionTypes.UpsertOrder;

	constructor(public payload: { order: IoApiOrder }) {
	}
}

export class AddOrders implements Action {
	readonly type = OrderActionTypes.AddOrders;

	constructor(public payload: { orders: IoApiOrder[] }) {
	}
}

export class UpsertOrders implements Action {
	readonly type = OrderActionTypes.UpsertOrders;

	constructor(public payload: { orders: IoApiOrder[] }) {
	}
}

export class UpdateOrder implements Action {
	readonly type = OrderActionTypes.UpdateOrder;

	constructor(public payload: { order: Update<IoApiOrder> }) {
	}
}

export class UpdateOrders implements Action {
	readonly type = OrderActionTypes.UpdateOrders;

	constructor(public payload: { orders: Update<IoApiOrder>[] }) {
	}
}

export class DeleteOrder implements Action {
	readonly type = OrderActionTypes.DeleteOrder;

	constructor(public payload: { id: string }) {
	}
}

export class DeleteOrders implements Action {
	readonly type = OrderActionTypes.DeleteOrders;

	constructor(public payload: { ids: string[] }) {
	}
}

export class ClearOrders implements Action {
	readonly type = OrderActionTypes.ClearOrders;
}

export type OrderActions =
	EstimatedCostsRequested
	| EstimatedCostsUpdated
	| ClearEstimatedCosts
	| IoApiOrdersRequested
	| IoApiOrdersLoaded
	| AddOrder
	| UpsertOrder
	| AddOrders
	| UpsertOrders
	| UpdateOrder
	| UpdateOrders
	| DeleteOrder
	| DeleteOrders
	| ClearOrders;
