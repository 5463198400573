export enum AnswerOption {
	YES = "1",
	NO = "2",
	WHEN_NEEDED = "3",
};

export type RadioQuestion = {
	name: string;
	label: string;
	isObligatoryDecision?: boolean;
	pill?: "UK" | "OK";
	double?: boolean;
	hideLabel?: boolean;
};

export type SimpleQuestionnaire = {
	radios: RadioQuestion[];
	comment: string;
};

export const SIMPLE_QUESTIONAIRE: SimpleQuestionnaire = {
	radios: [
		{name: 'stripping', label: 'Stripping Request', isObligatoryDecision: true},
		{name: 'attachments', label: 'Attachments Request', isObligatoryDecision: true},
		{name: 'buttons', label: 'Buttons Request'},
		{name: 'biteramps', label: 'Bite Ramps Request'},
		{name: 'biteblocks', label: 'Bite Blocks Request'},
		{name: 'elastics', label: 'Elastics Request'},
		{name: 'proclination', label: 'Proklination Request'},
		{name: 'distalisation_ok', label: 'Distalisation Request', pill: 'OK', double: true},
		{name: 'distalisation_uk', label: 'Distalisation Request', pill: 'UK', hideLabel: true},
		{name: 'classcorrection', label: 'Klassenkorrektur Request'},
		{name: 'middleline_ok', label: 'Mittellinie korrigieren Request', pill: 'OK', double: true},
		{name: 'middleline_uk', label: 'Mittellinie korrigieren Request', pill: 'UK', hideLabel: true}
	],
	comment: ''
};
