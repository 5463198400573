import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrderEffects } from './store/order.effects';
import * as fromOrder from './store/order.reducer';
import { OrderService, OrdersResolver } from './services';
import {CustomerModule} from '@suite/customer';

@NgModule({
    imports: [
        CommonModule,
        CustomerModule,
        StoreModule.forFeature('order', fromOrder.reducer),
        EffectsModule.forFeature([OrderEffects])
    ],
    declarations: [],
    exports: [],
    providers: [OrderService, OrdersResolver, CurrencyPipe]
})
export class OrderModule {}
