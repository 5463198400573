<div class="title" mat-dialog-title>
	{{data.title | translate}}
</div>

<div class="msg" mat-dialog-content>
	{{data.msg | translate}}
</div>

<mat-dialog-actions class="buttons">
	<button mat-raised-button [mat-dialog-close]="'no'">
		{{data.no | translate}}
	</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="'yes'">
		{{data.yes | translate}}
	</button>
</mat-dialog-actions>
