<ng-container>
	<div class="app">
		<header>
			<ux-page-header [menuEntries]="menuEntries"
							[subMenuEntries]="subMenuEntries"
							[showDiscount]="true"
							[hotline]="'+49 2104 80041-00'"
			></ux-page-header>
		</header>
		<main>
			<router-outlet></router-outlet>
		</main>
	</div>
	<footer>
		<ux-page-footer></ux-page-footer>
	</footer>
</ng-container>





