<tm2-patients-initial
	*ngIf="action === ACTION.INITIAL"
	(next)="onNewState($event)">
</tm2-patients-initial>

<tm2-patient-edit
	*ngIf="action === ACTION.EDIT || action === ACTION.CREATE"
	[patient]="patient$ | async"
	(next)="onNewState($event)">
</tm2-patient-edit>

<tm2-patients-list
	*ngIf="action === ACTION.SELECT"
	[patients]="patients$ | async"
	actionLabel="Select"
	(stateChange)="onStateChange(1,$event)"
	[dashboardState]="dashboardState"
	(next)="onNewState($event)">
</tm2-patients-list>

<ux-next-back-actions
	[hideNext]="true"
	(action)="onAction({type: ACTION.BACK})"
></ux-next-back-actions>
<!--{{patient | json}}-->
