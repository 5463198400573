interface AdditionalsOptions {
	id: string;
	allowed_extensions: string[];
	allowed_number: number;
	allowed_max_size: number;
	description: string;
	description2: string;
	required?: boolean;
}

const MB = 1024 * 1024;
const MB_5 = MB * 5;
const MB_100 = MB * 100;
const ALLOWED_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'dcm', 'dcm30'];

export const Additionals: AdditionalsOptions[] = [
	{
		id: 'add-1-1',
		description: 'Extra-Oral',
		description2: 'Profil',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	},
	{
		id: 'add-1-2',
		description: 'Extra-Oral',
		description2: 'Frontal entspannt',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	},
	{
		id: 'add-1-3',
		description: 'Extra-Oral',
		description2: 'Frontal lächelnd',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	},
	{
		id: 'add-2-1',
		description: 'Intra-Oral',
		description2: 'Okklusal OK',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	},
	{
		id: 'add-2-2',
		description: 'Röntgenbild',
		description2: 'DVT',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_100
	},
	{
		id: 'add-2-3',
		description: 'Intra-Oral',
		description2: 'Okklusal UK',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	},
	{
		id: 'add-3-1',
		description: 'Intra-Oral',
		description2: 'Biss Links',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	},
	{
		id: 'add-3-2',
		description: 'Intra-Oral',
		description2: 'Biss Mitte',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	},
	{
		id: 'add-3-3',
		description: 'Intra-Oral',
		description2: 'Biss Rechts',
		allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
		allowed_number: 1,
		allowed_max_size: MB_5
	}
];


