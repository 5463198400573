import {Injectable} from '@angular/core';
import { IoApiProxyService } from '@suite/ioApi';

@Injectable()
export class CustomerService {

	constructor( private ioApiService: IoApiProxyService) {
	}

	public getCustomer(customerId: string) {
		return this.ioApiService.getUser(customerId);
	}

	public getCustomerNotes(customerId) {
		return this.ioApiService.getCustomerNotes(customerId);
	}

	public getMe() {
		return this.ioApiService.isLoggedIn();
	}
}
