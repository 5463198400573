import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@suite/material';
import {FormsModule} from '@angular/forms';

import {UxComponentsModule} from '@suite/ux-components';
import {QuestionaireService} from './services/questionaire.service';
import {QuestionairePrintDialogComponent} from './questionaire-print/questionaire-print-dialog.component';
import {CssPrintIconDirective} from './questionaire-print/css-print-icon.directive';
import { CaTranslateModule } from '@suite/translate';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CaTranslateModule,
        MaterialModule,
        UxComponentsModule
    ],
    declarations: [
        QuestionairePrintDialogComponent,
        CssPrintIconDirective
    ],
    providers: [
        QuestionaireService
    ],
    exports: [
    ]
})
export class QuestionaireModule {
}

