import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ACTION, AppState} from '@suite/tm-common';
import {select, Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {ToothAreaUpdatedAction, selectAnamneseProductOptions} from '@suite/anamnese';
import { ToothArea } from 'libs/product/src/lib/models/product.model';

enum JAW_TYPE{
	UPPER_JAW = "uj",
	LOWER_JAW = "lj"
}

@Component({
	selector: 'tm2-tooth-area-image-selection',
	templateUrl: './tooth-area-image-selection.component.html',
	styleUrls: ['./tooth-area-image-selection.component.scss']
})
export class ToothAreaSelectionComponent implements OnChanges {
	@Input({required: true}) hasUpperJaw;
	@Input({required: true}) hasLowerJaw;

	@Output() action = new EventEmitter<any>();

	TOOTH_AREA_OPTIONS = [ToothArea.TWO_TWO, ToothArea.THREE_THREE, ToothArea.FOUR_FOUR];

	storedSelections$;
	isUpperJawSelected = false;
	isLowerJawSelected = false;
	translationKeyPrefix = 'mt.zahnbereich'

	JAW_TYPE = JAW_TYPE;

	constructor( private store: Store<AppState>) {
	}

	ngOnChanges() {
		if (!this.storedSelections$) {
			this.storedSelections$ = this.store.pipe(
				select(selectAnamneseProductOptions),
				// test if already all selection (from store), to enable next button if so
				tap((data: {uj: ToothArea, lj: ToothArea}) => {
					if(this.hasUpperJaw ){
						this.isUpperJawSelected = this.TOOTH_AREA_OPTIONS.includes(data.uj);
					}
					if(this.hasLowerJaw ){
						this.isLowerJawSelected = this.TOOTH_AREA_OPTIONS.includes(data.lj);
					}
					if (this.isAllSelected()) {
						setTimeout(() => this.action.emit({type: ACTION.ENABLE_NEXT}), 0);
					}
				})
			);
		}
	}

	onSelect(jaw: JAW_TYPE, value: ToothArea.TWO_TWO | ToothArea.THREE_THREE | ToothArea.FOUR_FOUR) {
		// selection to store
		this.store.dispatch(
			new ToothAreaUpdatedAction({
				jaw: jaw,
				value: value
			}
		));

		// inform parent if all selection
		if (this.isAllSelected()) {
			this.action.emit({type: ACTION.SELECTED});
		}
	}

	isAllSelected() {
		if ( this.hasUpperJaw && this.hasLowerJaw ){
			return this.isUpperJawSelected && this.isLowerJawSelected;
		}else if (this.hasUpperJaw){
			return this.isUpperJawSelected;
		}else{
			return this.isLowerJawSelected;
		}
	}
}
