import {AnamneseActions, AnamneseActionTypes} from './anamnese.actions';
import {createFeatureSelector} from '@ngrx/store';
import {Product, ProductInfoForKey, ProductUrlKey, ToothArea} from "libs/product/src/lib/models/product.model";
import { AnamneseUploadType } from '@suite/media';
import { SapShippingCostItem } from '@suite/sap';

export interface Options {
	uj: ToothArea;
	lj: ToothArea;
}

const initialOptions = {
	uj: undefined,
	lj: undefined,
};

interface UploadedAnamneseFileInfo {
	mediaId: string,
	orgFilename: string
	index?: number //only necessary for additional files
}

export interface AnamneseState {
	patientId: string;
	treatmentId: string;
	product: Product;
	questionaire;
	questionaireAnswers;
	questionairePreparedAnswers;
	uploadedFiles: {uj: UploadedAnamneseFileInfo,
					lj: UploadedAnamneseFileInfo,
					additionals: UploadedAnamneseFileInfo[]
				};
	shippingCosts: SapShippingCostItem[]
}

export const initialAnamneseState: AnamneseState = {
	patientId: undefined,
	treatmentId: undefined,
	product: null,
	questionaire: null,
	questionaireAnswers: null,
	questionairePreparedAnswers: null,
	uploadedFiles: {uj: null, lj: null, additionals: [] },
	shippingCosts: []
};

export const selectAnamneseState = createFeatureSelector<AnamneseState>('anamnese');

export function reducer(state = initialAnamneseState, action: AnamneseActions): AnamneseState {
	switch (action.type) {
		case AnamneseActionTypes.ClearAnamneseRequested:
			return {...initialAnamneseState, patientId: state.patientId, shippingCosts: state.shippingCosts};

			case AnamneseActionTypes.ClearQuestionaireRequested:
			return {
					...state,
					questionaire: null,
					questionaireAnswers: null,
					questionairePreparedAnswers: null
					};

		case AnamneseActionTypes.ToothAreaUpdated:
			let jaw = action.payload.jaw === "uj" ?
				{uj: action.payload.value} :
				{lj: action.payload.value};
			return {
				...state,
				product: {
					...state.product,
					options: {
						...state.product.options,
						...jaw
					}
				}
			};

		case AnamneseActionTypes.JawToggled: {
			const prod = structuredClone(state.product);
			if(prod.options[action.payload]){
				prod.options[action.payload] = undefined;
			}else{
				prod.options[action.payload] =
					prod.info.urlKey === ProductUrlKey.MEMOTAIN
						? "-" //placeholder since tooth range is set later
						: ProductInfoForKey[prod.info.urlKey].toothArea;
			}
			return {
				...state,
				product: prod
			};
		}
		case AnamneseActionTypes.PatientSelected:
			return {
				...state,
				patientId: action.payload
			};

		case AnamneseActionTypes.ProductSelected:
			const product: Partial<Product> = {info: action.payload, options: initialOptions};

				return {
					...state,
					product: {
						...state.product,
						...product
					}
			}


		case AnamneseActionTypes.PriceUpdated:
			return {
				...state,
				product: {
					...state.product,
					info: {
						...state.product.info,
						price: action.payload
					}
				}
			};

		case AnamneseActionTypes.OptionsUpdated:
			return {
				...state,
				product: {
					...state.product,
					options: {
						...state.product.options,
						...action.payload,
					}
				}
			};

		case AnamneseActionTypes.QuestionaireAnswersUpdated:

		 	// delete all other answers because we use simpleAnswers now
			const answers = { ...action.payload };
			return {
				...state,
				questionaireAnswers: answers
			};

		case AnamneseActionTypes.QuestionairePreparedAnswersUpdated:
			return {
				...state,
				questionairePreparedAnswers: action.payload
			};

		case AnamneseActionTypes.HandlingUpdated:
			const handling = state.product && state.product.handling
							? {...state.product.handling, ...action.payload}
							: {...action.payload};
			return {
				...state,
				product: {
					...state.product,
					handling
				}
			};

		case AnamneseActionTypes.SettingsUpdated:
			return {
				...state,
				...action.payload,
			};

		case AnamneseActionTypes.TreatmentIdUpdated:
			return {
				...state,
				treatmentId: action.payload.treatmentId
			};

		case AnamneseActionTypes.MediaFileUploaded:
			const updatedAnamneseFiles = structuredClone(state.uploadedFiles);
			const uploadType = action.uploadType;
			const mediaId = action.mediaId;
			const orgFilename = action.orgFilename;
			const index = action.index;

			switch(uploadType){
				case AnamneseUploadType.UJ:
					updatedAnamneseFiles.uj = { mediaId: mediaId, orgFilename: orgFilename }
					break;
				case AnamneseUploadType.LJ:
					updatedAnamneseFiles.lj = { mediaId: mediaId, orgFilename: orgFilename }
					break;
				case AnamneseUploadType.ADDITIONAL:
					updatedAnamneseFiles.additionals.push({ mediaId: mediaId, orgFilename: orgFilename, index: index });
					break;
			}

			return {
				...state,
				uploadedFiles: updatedAnamneseFiles,
				}

		case AnamneseActionTypes.MediaFileDeleted:
			const anamneseFiles = structuredClone(state.uploadedFiles);

			switch(action.uploadType){
				case AnamneseUploadType.UJ:
					anamneseFiles.uj = null;
					break;
				case AnamneseUploadType.LJ:
					anamneseFiles.lj = null;
					break;
				case AnamneseUploadType.ADDITIONAL:
					anamneseFiles.additionals = anamneseFiles.additionals.filter(elem => elem.index !== action.index)
					break;
			}

			return {
				...state,
				uploadedFiles: anamneseFiles,
				}

		case AnamneseActionTypes.ShippingCostsLoaded:
			return {
				...state,
				shippingCosts: action.payload.shippingCosts
			};

		default:
			return state;
	}
}


