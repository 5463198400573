import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "@suite/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppPatientsModule } from "../patients/patients.module";
import { MediaModule } from "@suite/media";
import { QuestionaireModule } from "@suite/questionaire";
import { AnamneseComponent } from "./anamnese.component";
import { AnamnesePatientComponent } from "./anamnese-patient/anamnese-patient.component";
import { AnamneseSetupComponent } from "./anamnese-setup/anamnese-setup.component";
import { AnamneseTreatmentComponent } from "./anamnese-treatment/anamnese-treatment.component";
import { AnamneseProductSelectionComponent } from "./anamnese-treatment/anamnese-product-selection/anamnese-product-selection.component";
import { AnamneseOptionsComponent } from "./anamnese-treatment/anamnese-options/anamnese-options.component";
import { AnamneseAddressesComponent } from "./anamnese-setup/anamnese-addresses/anamnese-addresses.component";
import { AnamneseAdditionalSettingsComponent } from "./anamnese-setup/anamnese-additional-settings/anamnese-additional-settings.component";
import { AnamneseFileuploadComponent } from "./anamnese-setup/anamnese-fileupload/anamnese-fileupload.component";
import { AnamneseOrderComponent } from "./anamnese-setup/anamnese-order/anamnese-order.component";
import { AnamneseQuestionaireComponent } from "./anamnese-treatment/anamnese-questionaire/anamnese-questionaire.component";
import { AnamneseQuestionaireSimpleComponent } from "./anamnese-treatment/anamnese-questionaire/anamnese-questionaire-simple/anamnese-questionaire-simple.component";
import { UxComponentsModule } from "@suite/ux-components";
import { TreatmentsModule } from "../treatments/treatments.module";
import { AnamneseOptionsJawsComponent } from "./anamnese-treatment/anamnese-options/jaws/jaws.component";
import { CustomOptionsComponent } from "./anamnese-treatment/anamnese-options/custom-options/custom-options.component";
import { ToothAreaSelectionComponent } from "./anamnese-treatment/anamnese-options/custom-options/tooth-area-image-selection/tooth-area-image-selectioncomponent";
import { TmCommonModule } from "@suite/tm-common";
import { CaTreatmentsModule } from "@suite/treatments";
import { CaTranslateModule } from "@suite/translate";
import { AnamneseTransferComponent } from './anamnese-setup/anamnese-transfer/anamnese-transfer.component';

@NgModule({
	imports: [
		CommonModule,
		CaTranslateModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		AppPatientsModule,
		MediaModule,
		QuestionaireModule,
		CaTreatmentsModule,
		TreatmentsModule,
		TmCommonModule,
		UxComponentsModule
	],
	declarations: [
		AnamneseComponent,
		AnamnesePatientComponent,
		AnamneseSetupComponent,
		AnamneseTreatmentComponent,
		AnamneseOptionsComponent,
		AnamneseProductSelectionComponent,
		AnamneseAddressesComponent,
		AnamneseAdditionalSettingsComponent,
		AnamneseOrderComponent,
		AnamneseQuestionaireComponent,
		AnamneseQuestionaireSimpleComponent,
		AnamneseOptionsJawsComponent,
		CustomOptionsComponent,
		ToothAreaSelectionComponent,
		AnamneseFileuploadComponent
	, AnamneseTransferComponent]
})
export class AnamneseTmModule {}
