import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpsFormFieldsService} from './services/ups.form-fields.service';
import {UpsService} from './services/ups.service';

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		UpsFormFieldsService
	]
})
export class UpsModule {
}
