import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, first, map, catchError, EMPTY} from 'rxjs';
import {Store} from '@ngrx/store';
import {LoadMedias, MediaModel, MediaState} from '../store';
import { MediaService } from './media.service';


@Injectable()

export class MediaResolver  {
	constructor(
		private store: Store<MediaState>,
		private mediaService: MediaService	) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void | MediaModel[]> {
		return this.mediaService.listFiles({treatmentId: route.params.id}).pipe(
			first(),
			catchError(() => {
				return EMPTY;
			})
		);
	}

}

