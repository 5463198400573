import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import {GoAction, RouterActionTypes} from '../actions/actions';

@Injectable()
export class RouterEffects {

	navigate$ = createEffect(() => this.actions$.pipe(
		ofType(RouterActionTypes.GoAction),
		map((action: GoAction) => action.payload),
		tap(({ path, query: queryParams, extras }) =>
			this.router.navigate(path, { queryParams, ...extras })
		)
	), { dispatch: false });

	navigate2$ = createEffect(() => this.actions$.pipe(
		ofType(RouterActionTypes.GoSignInFromNoHttpAuthAction),
		map((action: GoAction) => action.payload),
		tap(({ path }) =>
			this.router.navigate(path)
		)
	), { dispatch: false });


	navigateBack$ = createEffect(() => this.actions$.pipe(
		ofType(RouterActionTypes.BackAction),
		tap(() => this.location.back())
	), { dispatch: false });


	navigateForward$ = createEffect(() => this.actions$.pipe(
		ofType(RouterActionTypes.ForwardAction),
		tap(() => this.location.forward())
	), { dispatch: false });

	constructor(
		private actions$: Actions,
		private router: Router,
		private location: Location
	) {}
}
