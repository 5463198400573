import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AppState } from "@suite/tm-common";
import { select, Store } from "@ngrx/store";
import { first, tap } from "rxjs/operators";
import { IoApiOrdersRequested } from "../store/order.actions";
import * as fromOrders from "../store";

// XXX Keep this value equal to the value in dasbhoard.component.ts (tmormco).
const DEFAULT_DATE_RANGE_MONTHS = 3;

@Injectable()
export class OrdersResolver  {
	constructor(private store: Store<AppState>) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<number> {

		// NOTE: we just want to trigger action IoApiOrdersRequested here if there are
		// no entities in store, so its enough to selector selectTotal
		// important:  observable must complete, otherwise no routing
		return this.store.pipe(
			select(fromOrders.selectTotal),
			tap((cnt) => {
				if (cnt) {
					return null;
				} else {
					const fromDate = new Date();
					fromDate.setMonth(
						fromDate.getMonth() - DEFAULT_DATE_RANGE_MONTHS
					);

					return this.store.dispatch(
						new IoApiOrdersRequested({
							fromDate
						})
					);
				}
			}),
			first()
		);
	}
}
