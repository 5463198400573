import { PatientModel } from "libs/tm-common/src/lib/models/patient.model";
import { OrderModel } from "libs/tm-common/src/lib/models/order.model";
import { Product, ProductLevel } from "libs/product/src/lib/models/product.model";
import { Anamnese, PlanningData, UpsPickupReference } from "libs/anamnese/src/lib/models/anamnese.model";
import { SapJaws } from "libs/sap/src/lib/models/sap.model";

export enum TreatmentTypes {
	Prognose = 1,
	Vorbehandlung = 2,
	/** Formerly known as 'Behandlung'*/
	PartnerApiOrder = 3,
	Legacy = 91,
	TmOrder = 40,
	TmAnamnese = 41,
}

export interface TreatmentModel {
	/** Internal Magento User-Entity-ID of the owning (customer) user. */
	uid: number;

	/** Customer WMS-ID of the owning customer. */
	customerWmsId: string;

	type: TreatmentTypes;
	status: TreatmentStatus;
	patient: PatientModel;
	product: Product;
	quotation: QuotationModel;
	/**
	 * @deprecated
	 * legacy with 2.0.0
	 * code still reads attributes from treatments pre 2.0.0
	*/
	orders?: OrderModel[];
	packages?: TreatmentPackages;

	newMessagesCount?: number | 0;
	timeOuts?: TimeOut[];
	created?: Date;
	lastModified?: Date;
	statusChanged?: Date;
	upgrade?: UpgradeModel;
	webviewers?: Webviewer[];
	caseworker?: string;
	completionDate?: Date;
	readonly schema_version?: number; //will be set by the schema in tm-server

	//exclusive mongodb fields
	_id?: string; 					//ObjectID of mongodb entry

	//non mongodb fields (relevant for redux store)
	needForAction?: number;			//TODO make enum
	neededAction?: string;			//TODO make enum

	/** @deprecated legacy with 2.0.0 */
	name?: string;
	/** @deprecated legacy with 2.0.0 */
	couponCodeForNextOrder?: string;
	// LEGACY fields that may exist on ancient cases (< 2019)
	/** @deprecated last seen on a case: 2019-12-05 */
	anamnese?: any;
}

export interface QuotationModel {
	docEntry: number, //internal used SAP reference
	docNum: string,   //external used SAP reference
	anamnese?: Anamnese
	upsPickUpReference?: UpsPickupReference
	ormcoDoctorId?: string,
	ormcoSn?: string,
	bulkOrderItems?: BulkOrderItem[],
	salesOrders?: SalesOrderModel[]
}

export interface SalesOrderModel {
	docEntry: number,
	docNum: string,
	created: Date,
}

export interface BulkOrderItem{
	patient: string,
	toothRange: string,
	comment: string,
	jaw?: SapJaws
}

export interface UpgradeModel {
	planing: PlanningData,
	packages: TreatmentPackages,
	product: Product

	/**@deprecated */
	level?: ProductLevel
}

export class TimeOut{
	type: TimeOutTypes;
	dateTime: Date;
	text?: string;
}

export enum TimeOutTypes {
    FinalTimeout = 'final-timeout',
    DataTransfer = 'data-transfer',
	Reminder = 'reminder',
	MemotainReminder = 'memotain-reminder',
	ReleaseMail = 'release-email',
	ReleaseDashboard = 'release-dashboard',
	AnamneseTimeout = 'anamnese-timeout'
}


export interface TreatmentPackages {
	steps: number[];
	freeCorrections: number[];
	addCorrections: number[];
	next: number;
}

export enum TreatmentStatus{
	NewRequestReceived = 'new_request_received',
	Planning = 'planning',
	DataReceived = 'data_received',
	RescanImprintsRequired = 'rescan_imprints_required',
	NewImprintsRequested = 'new_imprints_requested',
	NewDataRequested = 'new_data_requested',
	ConsultationRequired = 'consultation_required',
	Shipped = 'shipped',
	CanceledByCustomer = 'canceled_by_customer',
	CreditMemoIssued = 'credit_memo_issued',
	Postponed = 'postponed',
	ConsultationComplete = 'consultation_complete',
	InProduction = 'in_production',
	ReleaseRequested = 'release_requested',
	CorrectionRequestReceived = 'correction_request_received',
	Closed = 'closed',
	RequestRejected = 'request_rejected', // "Absage".
	ComplaintReceived = 'complaint_received',
	ComplaintResolved = 'complaint_resolved'
}

export enum InterimScanOption {
	WITH_DATA,
	WITHOUT_DATA
}


/** @deprecated*/
export enum TreatmentStati {
	AnamneseRequested = 20,			//XXX Anamnese angefordert
	AnamneseReleaseRequested = 21,  //XXX Warten auf Freigabe
	AnamneseRejected = 23,			//XXX Anamnese zurückgewiesen //wird bei Setup Korrektur gesetzt
	AnamneseCanceld = 29,			//XXX Anamnese abgebrochen
	TreatmentOrdered = 33,			//XXX Behandlung bestellt
	TreatmentRunning = 34,			//XXX Behandlung läuft

	/**
	 * by code unused status
	 * legacy treatments still might have theses status,
	 * but only for display
	 */
	AnamneseReleased = 22,
	AnamneseNotFeasible = 28,
	AnamnseCanceldLegacy = 24,
	PreparationOrdered = 30,
	PreparationRunning = 31,
	ReleaseGranted = 32,
	AwaitingRelease = 40,
	CorrectionRequired = 41,
	Archived= 99,
}

export enum CaOpportunity {
	Package = 'package',
	ChargeableCorrection = 'correction',
	FreeCorrection = 'freeCorrection'
}

export enum DataTransferMethod{
	UPLOAD = 0,
	UPS = 1,
	OTHER = 2
}

export interface Webviewer{
	name: string;
	url: string;
	visibilityScope: WebviewerVisibilityScope;
}

export enum WebviewerVisibilityScope {
	PUBLIC = "public",
	INTERN = "intern",
	ADMIN = "admin"
}

/**
 * This enum only covers a part of the magento "system caseworkers".
 * A caseworker assigned to a treatment can also be a tm admin user (e.g. technician),
 * but in some cases, a "system" caseworker is used for usability.
 */
export enum CaseWorker {
	EXPORT = "Export",
	OHNE_PLANUNG = "ohne Planung",
	STORNO = "Storno",
	REKLAMATION = "REKLAMATION"
}
