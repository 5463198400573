import {Component, EventEmitter, Output} from '@angular/core';
import {ACTION} from '@suite/tm-common';

@Component({
	selector: 'tm2-patients-initial',
	templateUrl: './patients-initial.component.html',
	styleUrls: ['./patients-initial.component.css']
})
export class PatientsInitialComponent {

	@Output() next = new EventEmitter<any>();
	ACTION = ACTION;

	constructor() {	}

	onNext(state) {
		this.next.emit({type: state });
	}

}
