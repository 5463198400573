import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {ACTION, AppState} from '@suite/tm-common';
import {Anamnese, ClearAnamneseAction} from '@suite/anamnese';
import { ProductUrlKey } from "libs/product/src/lib/models/product.model";

enum OptionsSubstate{
	JAW_SELECTION = 0,
	TOOTH_AREA_SELECTION = 1,
}

@Component({
	selector: 'tm2-anamnese-options',
	templateUrl: './anamnese-options.component.html',
	styleUrls: ['./_anamnese-options.component.scss']
})

/**
 * @description
 * This component has two sub states.
 * The first state represents the subcomponent @see `AnamneseOptionsJawsComponent` jaw selection
 * The second state the subcomponent @see `CustomOptionsComponent` for the Zahnbereichsauswahl
 * (currently only used by Memotain)
 * Compared to other states, these two states aren't represented bei ngrx, but have their own logic
 */
export class AnamneseOptionsComponent implements OnInit {
	@Input() anamnese: Anamnese;
	@Output() next = new EventEmitter<any>();

	ACTION = ACTION;
	disableNext = false;

	OptionsSubstate = OptionsSubstate;
	currentSubState: OptionsSubstate = OptionsSubstate.JAW_SELECTION;


	constructor( private store: Store<AppState> ) {
	}

	ngOnInit() {
		this.currentSubState = OptionsSubstate.JAW_SELECTION;
	}

	done() {
		this.next.emit({type: ACTION.OPTIONS});
	}

	onAction(action) {
		switch (action.type) {
			case ACTION.BACK:
				if ( this.currentSubState === OptionsSubstate.JAW_SELECTION ){
					//go back to productselection and reset anamnese and product object in redux store
					this.store.dispatch(new ClearAnamneseAction());
					this.next.emit(action);
					return;
				}
				if ( this.currentSubState === OptionsSubstate.TOOTH_AREA_SELECTION ){
					this.currentSubState = OptionsSubstate.JAW_SELECTION;
					this.disableNext = false;
				}
				break;
			case ACTION.NEXT:
				if ( this.currentSubState === OptionsSubstate.JAW_SELECTION ){
					if ( this.anamnese.product.info.urlKey === ProductUrlKey.MEMOTAIN ){
						this.disableNext = true;
						this.currentSubState = OptionsSubstate.TOOTH_AREA_SELECTION;
						return;
					}
					this.done();
					return;
				}

				if ( this.currentSubState === OptionsSubstate.TOOTH_AREA_SELECTION ){
					this.done();
					return;
				}
				break;
			case ACTION.SELECTED:
			case ACTION.ENABLE_NEXT:
				this.disableNext = false;
				break;
		}
	}
}
