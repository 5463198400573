<ng-container *ngIf="(url$ | async) as url">

	<mat-toolbar [ngClass]="{'contrast': url !== '/anamnese', 'admin': config.app === 'tmadmin'}">

		<div>
			<h1 *ngIf="url !== '/signin'"
				class="title">
				{{config.appTitle1}} {{config.appTitle2}}
				<span *ngIf="config.app === 'tmadmin'">{{adminDisplayName$ | async}} ({{adminUsername$ | async}})</span>
			</h1>
		</div>

		<div class="right">
			<div class="language-flags">
				<span (click)="switchLanguage('de')" class="fi fi-de fis flag-icon flag-icon-de"></span>
				<span (click)="switchLanguage('en')" class="fi fi-gb fis flag-icon flag-icon-gb"></span>
			</div>
			<div class="main-menu" *ngIf="url !== '/signin'">
				<!-- Admin menu, only visible in TM Admin -->
				<ng-container *ngIf="(currentAdminRole$ | async) === MagentoUserRole.ADMIN">
					<button mat-button [matMenuTriggerFor]="adminMenu">Admin</button>
					<mat-menu #adminMenu="matMenu">
						<ng-container *ngFor="let entry of adminMenuEntries">
							<a *ngIf="entry['route']" mat-button
							   (click)="navigateTo(entry['route'])"
							   [ngClass]="{active: url.startsWith(entry['route'])}"
							>
								{{entry['title'] | translate}}
							</a>
						</ng-container>
					</mat-menu>
				</ng-container>

				<ng-container *ngFor="let entry of menuEntries">

					<a *ngIf="entry['route'] && !entry['url']" mat-button
					   (click)="navigateTo(entry['route'])"
					   [ngClass]="{active: url.startsWith(entry['route'])}"
					>
						{{entry['title'] | translate}}
					</a>
					<a *ngIf="!entry['route'] && entry['url']" mat-button
					   href="{{entry['url']}}">
						{{entry['title'] | translate}}
					</a>
				</ng-container>

				<button *ngIf="isSignedOut$ | async" mat-button
						(click)="navigateTo('/signin');">
					{{'Signin' | translate}}
				</button>

				<button *ngIf="isSignedIn$ | async" mat-button
						(click)="signout();">
					{{'Signout' | translate}}
				</button>
				<ng-container *ngIf="subMenuEntries.length">
					<button mat-button [matMenuTriggerFor]="submenu"><i class="fal fa-cog"></i></button>
					<mat-menu #submenu="matMenu">
						<ng-container *ngFor="let entry of subMenuEntries">
							<a *ngIf="entry['route'] && !entry['url']" mat-button
							   (click)="navigateTo(entry['route'])"
							   [ngClass]="{active: url.startsWith(entry['route'])}"
							>
								{{entry['title'] | translate}}
							</a>
							<a *ngIf="!entry['route'] && entry['url']" mat-button
							   href="{{entry['url']}}">
								{{entry['title'] | translate}}
							</a>
						</ng-container>
					</mat-menu>
				</ng-container>
				<div>
					<div *ngIf="hotline" class="hotline">
						<i class="fal fa-phone"></i>  {{hotline}}
					</div>
					<div *ngIf="timeToMaintenance$" class="maintenance">
						<i class="fal fa-user-helmet-safety"></i>
						{{'Maintenance in:' | translate}} {{ timeToMaintenance$ }}
					</div>
				</div>

			</div>
			<div class="logo">
				<img src="assets/img/logo_scheu.svg"/>
			</div>

		</div>


	</mat-toolbar>

</ng-container>

<ngx-loading-bar [height]="'5px'" [color]="'#be0e11'" [includeSpinner]="false"></ngx-loading-bar>




