import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ClearEstimatedCosts,  EstimatedCostsRequested,  selectEstimatedCosts} from '@suite/order';
import {AppState, NotificationService, PatientModel} from '@suite/tm-common';
import {select, Store} from '@ngrx/store';

import {first, takeUntil} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {QuestionairePrintDialogComponent, QuestionairePrintDialogData} from '@suite/questionaire';
import {
	Anamnese,
	selectAnamneseDataTransfer,
	selectAnamnesePatient,
	selectAnamneseProductInfo,
	selectAnamneseProductOptions,
	selectAnamneseQuestionairePreparedAnswers,
	selectAnamneseUploadedFiles,
	TreatmentIdUpdatedAction
} from '@suite/anamnese';
import {AddTreatment, DataTransferMethod, TreatmentSelected, TreatmentStatus, TreatmentsService, UpsertTreatmentOnDb} from '@suite/treatments';
import {ACTION, TreatmentModel} from '@suite/tm-common';
import {NewPatientTreatment} from '@suite/patients';
import {Subject, forkJoin} from 'rxjs';
import { MediaService } from '@suite/media';
import { TranslateService } from '@ngx-translate/core';
import { PROFESSIONAL_PRODUCT_KEYS, ProductInfo, ProductInfoForKey, ProductOptions, ProductUrlKey, ToothArea } from 'libs/product/src/lib/models';
import { EUCountryCode, SapAddress, SapCostEstimate } from '@suite/sap';


enum OrderProcessStatus{
	NOT_ORDERED = 0,
	PROCESSING = 1,
	PROCESSED = 2,
	ERROR = 3
}

@Component({
	selector: 'tm2-anamnese-order',
	templateUrl: './anamnese-order.component.html',
	styleUrls: ['./_anamnese-order.component.scss']
})
export class AnamneseOrderComponent implements OnInit, OnChanges, OnDestroy {

	@Input() anamnese: Anamnese;
	@Input() isSetupProduct: boolean;
	@Output() next = new EventEmitter<any>();

	OrderProcessStatus = OrderProcessStatus;
	ProductUrlKey = ProductUrlKey;
	DataTransferMethod = DataTransferMethod;

	estimatedCosts: SapCostEstimate;
	hasValidCostEstimate = false;
	options: ProductOptions;
	disabledBack = false;
	processed;
	orderProcessStatus = OrderProcessStatus.NOT_ORDERED;
	buttonText;
	ACTION = ACTION;
	billingAddr: SapAddress;
	shippingAddr: SapAddress;
	destroy$ = new Subject<void>();

	constructor(
		private store: Store<AppState>,
		private treatmentsService: TreatmentsService,
		private mediaService: MediaService,
		private notificationService: NotificationService,
		private translate: TranslateService,
		@Inject('CONFIG') public config,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.processed = false;
		this.store.pipe(select(selectAnamneseProductOptions), takeUntil(this.destroy$))
			.subscribe((options: ProductOptions) => {
				this.options = options;
			});

		// final prices
		this.store.pipe(select(selectEstimatedCosts), takeUntil(this.destroy$))
		.subscribe(estimatedCosts => {
			this.estimatedCosts = estimatedCosts;
			this.hasValidCostEstimate = this.estimatedCosts?.Total >= 0;
		});
	}

	ngOnChanges() {
		// initialize as long as anamnese is not been processed
		if (!this.processed) {
			this.billingAddr = this.anamnese.product.handling.billing;
			this.shippingAddr = this.anamnese.product.handling.shipping;
			this.buttonText = this.isSetupProduct ? 'ORDER.setup' : 'ORDER.order';
			this.store.dispatch(
				new EstimatedCostsRequested({
					productKey: this.anamnese.product.info.urlKey as ProductUrlKey,
					shippingMethod: this.anamnese.product.handling.shipment.Method,
					country: this.shippingAddr.Country,
					transferMethod: this.anamnese.product.options.dataTransfer,
					uj: this.anamnese.product.options.uj ?? ToothArea.NONE,
					lj: this.anamnese.product.options.lj ?? ToothArea.NONE,
				})
			);
		}else{
			this.buttonText = this.anamnese.product.options.dataTransfer === DataTransferMethod.UPLOAD ? 'gotoTreatment' : 'Next';
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
	}

	onAction(action) {
		switch (action.type) {
			case ACTION.BACK:
				this.store.dispatch(new ClearEstimatedCosts());
				this.next.emit({type: ACTION.BACK});
				break;
			case ACTION.NEXT:
				if (!this.processed) {
					this.orderProcessStatus = OrderProcessStatus.PROCESSING;
					this.processAnamnese();
					// notify anamnese.component about ordered treatment
					this.next.emit({type: ACTION.SUBMIT, payload: undefined});
				} else {
					this.next.emit({type: ACTION.NEXT, payload: this.anamnese});
				}
				break;
		}
	}

	processAnamnese() {
		this.next.emit({type: ACTION.DISABLE_PREVIOUS_STEPS});
		this.disabledBack = true;
		// ALIGNER
		const isSetupProduct = PROFESSIONAL_PRODUCT_KEYS.includes(this.anamnese.product.info.urlKey as ProductUrlKey);
		this.treatmentsService.createTreatment()
			.subscribe({
				next: (resp: TreatmentModel) => this.onAnamneseSuccess(resp, isSetupProduct),
				error: error => this.onAnamneseError(error)
			});
	}

	onAnamneseSuccess(treatment, sendMail = false) {
		forkJoin({
			anamneseDataTransfer: this.store.pipe(select(selectAnamneseDataTransfer), first()),
			anamneseUploadedFiles: this.store.pipe(select(selectAnamneseUploadedFiles), first()),
			anamnesePatient: this.store.pipe(select(selectAnamnesePatient), first())
		}).subscribe((value) => {
			const isTransferMethodDataUpload: boolean = value.anamneseDataTransfer.dataTransfer === DataTransferMethod.UPLOAD;
			const uploadedFiles = value.anamneseUploadedFiles;
			const patient: PatientModel = value.anamnesePatient;

			// if treatment was ordered with transfer method 'dataupload'
			// update the uploaded media with the created 'treatmentId'
			if (isTransferMethodDataUpload) {
				//collect mediaIds that need to be updated
				const mediaIdsToUpdate = [uploadedFiles.uj?.mediaId, uploadedFiles.lj?.mediaId];

				uploadedFiles.additionals.forEach(additional => {
					mediaIdsToUpdate.push(additional.mediaId);
				});

				const requestBody = {
					treatment: treatment._id,
					expiresAt: null
				}

				// update uploaded files with treatmentId and orderId and reset 'expiresAt' date
				mediaIdsToUpdate.forEach(mediaId => {
					if ( mediaId ){
						this.mediaService.updateMedium(mediaId, requestBody);
					}
				});

				this.store.dispatch(new UpsertTreatmentOnDb({
					treatmentId: treatment._id,
					diff: {status: TreatmentStatus.DataReceived }
				}));
			}

			// Second: Handle UI, Reduxstore and send mail
			this.processed = true;
			this.orderProcessStatus = OrderProcessStatus.PROCESSED;
			// ...and enrich treatment with patient object
			treatment['patient'] = patient;

			// store treatment
			this.store.dispatch(new AddTreatment({treatment: treatment as TreatmentModel}));
			this.store.dispatch(new TreatmentIdUpdatedAction({treatmentId: treatment._id}));
			this.store.dispatch(new TreatmentSelected(treatment._id));
			this.store.dispatch(new NewPatientTreatment({patientId: patient._id || '', treatmentId: treatment._id}));
			});
	}

	onAnamneseError(error) {
		this.orderProcessStatus = OrderProcessStatus.ERROR;
		this.notificationService.showErrorMessage(
			"ErrorSubmitOrder"
		);
		console.error(error);
	}


	openDialog() {
		let questionaireAnswers = null;
		let productInfo = null;
		let patient = null;

		this.store.pipe(select(selectAnamneseQuestionairePreparedAnswers), first()).subscribe((result) => questionaireAnswers = result );
		this.store.pipe(select(selectAnamneseProductInfo), first()).subscribe((result) => productInfo = result );
		this.store.pipe(select(selectAnamnesePatient), first()).subscribe((result) => patient = result );

		this.prepareDataAndOpenPrintDialog(questionaireAnswers, productInfo, patient);
	}

	prepareDataAndOpenPrintDialog(questionaireAnswers, productInfo, patient) {
		const dialogData: QuestionairePrintDialogData = {
			answers: questionaireAnswers,
			info: {
				product: productInfo,
				patient: patient
			}
		};

		this.dialog.open(QuestionairePrintDialogComponent,
			{data: dialogData, maxWidth: '100vw', width: '100%', height: '100%'});
	}

	// Currently, this function is only relevant for aligner products, since there is no
	// e-mail notification for other products after ordering.
	getProductName( treatment: TreatmentModel ) : string
	{
		const productInfo: ProductInfo = ProductInfoForKey[treatment.product.info.urlKey as ProductUrlKey];
		let nameHtml = productInfo?.name;
		if(productInfo?.variantName){
			nameHtml += " ";
			nameHtml += this.translate.instant(productInfo.variantName);
		}
		return nameHtml;
	}

	protected getTextForCountryCode(Country: string) {
		return Country === EUCountryCode.Greece_EL ? EUCountryCode.Greece : Country;
	}

	protected calculateDiscountInPercent(){
		return ((1.0 - this.estimatedCosts.DiscountFactor) * 100).toFixed(2);
	}
}
