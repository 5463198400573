export const CASE_ASSESSMENT_MAIL_BODY = {
	de: `Sehr geehrtes Praxisteam,

vielen Dank für Ihre Anfrage.

Für einen optimale Paketeinschätzung benötigen wir jetzt ihre Mithilfe.

Wir möchten Sie bitten, uns folgende Informationen/Daten antwortend auf diese bereits an uns adressierte Mail zukommen zu lassen.

Senden Sie uns ihre Daten & Informationen zu: Patientenbilder und/oder Bildaufnahmen von Modellen, selbst STL-Daten und Röntgenbilder zur Beurteilung können Sie uns zukommen zu lassen, überlegen Sie jetzt schon ob ASR und die Verwendung von Attachment vom Patienten akzeptiert wird, da dies Einfluss auf die Paketeinschätzung haben kann.

Bitte gehen Sie wie folgt vor:
Bitte beschriften Sie die Dateien wie im Beispiel:
Vor- und Nachname des Behandlers, PatientenID, Dateityp
Beispiel: Dr.Max_Mustermann_1234.stl

Laden Sie nun bitte Ihre Daten in unserem Upload-Portal hoch
Link: https://cloud.ca-digit.com/fallbeurteilung
Passwort: sa6mqrA2€

Wann erhalten Sie Ihre Rückmeldung?
Innerhalb weniger Tage erhalten Sie von uns eine Rückmeldung über die Machbarkeit und den Umfang der Behandlung, über die in Ihrem Kundenkonto hinterlegte Mailadresse.

Bei weiteren Fragen stehen wir Ihnen gerne zur Verfügung.

Ihr Kundenservice-Team in Hilden:
Tel.: +49(0)2104-80041-00
E-Mail: info@scheu-dental.com
(Mo-Do von 7:45-16:30 Uhr, Fr. 7:45-14:00 Uhr)

Wir freuen uns auf die Zusammenarbeit mit Ihnen.

Es grüßt Sie aus Hilden,
Ihr SCHEU DENTAL Team`,

	en: `Dear Practice Team,

Thank you for your inquiry.

We now need your help to ensure an optimal package assessment.

We would like to ask you to send us the following information/data in response to this email that has already been addressed to us.

Send us your data & information: Patient images and/or images of models, you can even send us STL data and X-ray images for assessment.
Consider now whether ASR and the use of attachment are accepted by the patient, because this can have an influence on the package assessment.

Instructions:
Please label the files as in the example:
First and last name of the practitioner, patient ID, file type
Example: Dr.Max_John_1234.stl

Upload your data to our upload-portal:
Link: https://cloud.ca-digit.com/fallbeurteilung
Password: sa6mqrA2€

When will you receive your feedback?
Within a few days you will receive feedback from us about the feasibility and scope of the treatment via the email address stored in your customer account.

For further questions please do not hesitate to contact us.

Your customer service team in Hilden:
Tel.: +49(0)2104-80041-00
Email: info@scheu-dental.com
(Mon-Thurs from 7:45 a.m. to 4:30 p.m., Fri. 7:45 a.m. to 2:00 p.m.)

We look forward to working with you.

Greetings from Hilden,
Your SCHEU DENTAL Team`
};
