import {Component, OnInit} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {AppState, LoggerService} from '@suite/tm-common';
import {selectAllPatients} from '@suite/patients';

@Component({
	selector: 'tm2-patients',
	templateUrl: './patients.component.html',
	styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {

	patients$ = this.store.pipe(
		select(selectAllPatients),
		map(data => Object.values(data)),
	);


	constructor(private store: Store<AppState>, private logger: LoggerService) {
	}

	ngOnInit() {}

	onSubmit(result) {
	}
}
