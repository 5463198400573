import {createSelector} from '@ngrx/store';
import {selectPatientEntities} from './patient.reducer';
import {selectTreatmentEntities} from 'libs/treatments/src/lib/store';
import {ACTION, TreatmentModel, selectRouterState} from '@suite/tm-common';

export const selectPatientId = createSelector(
	selectRouterState,
	({state}) => state.params.patientId
);
export const selectPatientAction = createSelector(
	selectRouterState,
	selectPatientId,
	({state}, patientId) => {
		if (!state.params) {
			return null;
		}
		return { action: state.params.action === 'edit' ? ACTION.EDIT : state.params.action,
				 patientId: state.params.patientId
		};
	}
);

export const selectPatients = createSelector(
	selectPatientEntities,
	patientEntities => Object.values(patientEntities)
);

export const selectPatientById = (id: string) => createSelector(
	selectPatientEntities,
	patientEntities => patientEntities[id]
);

export const selectPatientIds = createSelector(
	selectPatientEntities,
	patientEntities => Object.values(patientEntities).map( patient => patient.patientId )
);

export const selectPatientWithTreatments = createSelector(
	selectPatientEntities,
	selectTreatmentEntities,
	selectPatientId,
	(patients, treatments, id) => {
		// don't handle empty objects
		if (!Object.keys(patients).length || !Object.keys(treatments).length || !id) {
			return null;
		}
		// get patient
		const patient = patients[id];

		// get treatments of patient (not only the ids)
		const patientTreatments =  Object.values(treatments).filter(
			(treatment) => treatment.patient?._id === patient._id);

		// clone patients treatments (immutability)
		// XXX since we are adding 'orderNo' to the 'TreatmentModel', we need to define
		// 'patientTreatmentsClone' as 'any', because 'orderNo' is not a field of the model
		// Note: 'orderNo' states 'row' of the treatment in the patient treatments table
		const patientTreatmentsClone: any = structuredClone(patientTreatments);
		patientTreatmentsClone.sort((o1: TreatmentModel, o2: TreatmentModel) => {
			return +new Date(o1.created) - +new Date(o2.created);
		});
		// enrich treatment data with the id of the last order
		patientTreatmentsClone.forEach( (tm, index) => {
			tm.orderNo = index + 1;
			if (tm.schema_version >= 2) {
				tm.realId = tm.quotation.docNum;
			}
			if (tm.schema_version < 2 ) {
				tm.realId = tm.orders.at(-1)['realId'];
			}

		});

		// return a patient/patientTreatments tuple
		return [patient, patientTreatmentsClone];
	}
);
