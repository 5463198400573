import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {AppState, TreatmentModel} from '@suite/tm-common';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {UpsertTreatmentOnDb} from '@suite/treatments';
import { ACTUAL_NOT_PROVIDED } from '../services';
import { ProductInfoMagento, ProductUrlKey } from "libs/product/src/lib/models/product.model";
import { ProductAssetsService, ProductInfoService } from "libs/product/src/lib/services";
import { Anamnese } from 'libs/anamnese/src/lib/models/anamnese.model';

enum JawStatus {
	NONE = 0,
	PLANNED = 1,
	NOT_PLANNED = 2,
	NOT_PLANNED_WITH_COMMENT = 3,
};

export interface QuestionairePrintDialogData {
	treatment?: TreatmentModel;
	info?; 	//contains a 'product' (see 'ProductInfoMagento') and a 'patient' object
	answers?;
}

@Component({
	selector: 'suite-questionaire-print',
	templateUrl: './questionaire-print-dialog.component.html',
	styleUrls: ['./_questionaire-print-dialog.component.scss']
})

export class QuestionairePrintDialogComponent implements OnInit {
	JawStatus=JawStatus;
	isTechnician = false;
	hasAnswersWithActualDataEntries = false; // XXX Only true in legacy datasets of full questionnaire anamneses.
	Object = Object;

	anamnese: Anamnese;
	treatment: TreatmentModel;
	patient;
	productInfo;

	answers;

	constructor(
		private store: Store<AppState>,
		public dialog: MatDialog,
		public translate: TranslateService,
		private productAssetsService: ProductAssetsService,
		private productInfoService: ProductInfoService,
		@Inject(MAT_DIALOG_DATA) data: QuestionairePrintDialogData,
		@Inject('CONFIG') private config) {

		//determine whether pdf is opened before or after ordering
		if (data.treatment) {
			this.treatment = data.treatment;

			// Shortcuts
			this.patient = this.treatment.patient;
			this.productInfo = this.treatment.product.info;
			// differentiate between "new"/migrated and legacy treatments
			this.anamnese = this.treatment.quotation?.anamnese ?? this.treatment.orders.at(0).anamnese;
			this.answers = structuredClone(this.anamnese.questionaireAnswers);
		} else if (data.info && data.answers) {
			this.patient = data.info.patient;
			this.productInfo = data.info.product;
			this.answers = data.answers;
		} else {
			throw new Error('No treatment/order nor info/questionaire data');
		}

		if (this.answers.data.some((d) => d.ok?.actual !== ACTUAL_NOT_PROVIDED)) {
			this.hasAnswersWithActualDataEntries = true;
		}
	}


	ngOnInit() {
		if (this.config.app === 'tmadmin') {
			this.isTechnician = true;
			this.enrichDialogData();
		}
	}

	enrichDialogData() {
		// enrich data with default technician status and answer
		this.answers["data"].forEach((el) => {
			if (el.ok && !el.ok.status) {
				el.ok = {...el.ok, status: JawStatus.PLANNED, answerTechnician: ''};
			}
			if (el.uk && !el.uk.status) {
				el.uk = {...el.uk, status: JawStatus.PLANNED, answerTechnician: ''};
			}
		});
	}


	changeStatus(jaw) {
		jaw.status++;
		if (jaw.status > 3) {
			jaw.status = JawStatus.NONE;
		}
	}

	isConcatenated(target: string) {
		return target.indexOf(';') !== -1;
	}

	concatenatedToArray(target: string) {
		const arr = target.split(';');
		return arr.map(el => {
			const e = this.translate.instant(el.trim());
			return e;
		});
	}

	printthis() {
		window.print();
	}

	save() {
		let updatedQuotation = {
				...this.treatment.quotation,
				anamnese: {
					...this.treatment.quotation?.anamnese,
					questionaireAnswers: this.answers
				}
		};
		this.store.dispatch(
			new UpsertTreatmentOnDb({
				treatmentId: this.treatment._id,
				diff: { quotation: updatedQuotation }
			})
		);
	}

	getProductLogoUrl(productInfo: ProductInfoMagento): string {
		return this.productAssetsService.getProductCategoryImageUrl(productInfo.urlKey as ProductUrlKey);
	}

	getProductNameHtml()
	{
		const nameHtml = this.productInfoService.getProductNameHtml(this.productInfo.urlKey as ProductUrlKey);
		//if product info could not be found, its probably a legacy treatment
		return nameHtml ? nameHtml : this.treatment.name;
	}

}

