<mat-card *ngIf="treatment.upgrade">
	<mat-card-content>
		<div class="treatment-title">
			<h3>
				<i class="fas fa-arrow-alt-circle-up"></i>
				{{'Upgrade Option' | translate}} - {{getUpgradeProductName()}}
			</h3>
		</div>
			<ux-treatment-packages class="upgrade-packages"
								[treatment]="treatment"
								[packages]="treatment.upgrade.packages"
			></ux-treatment-packages>
	</mat-card-content>
</mat-card>

<mat-card *ngIf="anamnese.releaseData as releaseData">
	<mat-card-content>
		<h2>{{'PleaseRelease' | translate}}</h2>
		<div class="info">
			<h3>{{'ReleaseFiles' | translate}}</h3>
			<table>

				<tr>
					<td>{{'Anamnestische Checkliste PDF' | translate}}</td>
					<td></td>
					<td>
						<a (click)="openQuestionaireDialog()"
						   mat-button
						   color="default">
							<i class="fal fa-arrow-to-bottom"></i> {{'Download' | translate}}
						</a>
					</td>
				</tr>

				<tr *ngFor="let file of releaseData.releaseFiles">
					<td>{{file.name}}</td>
					<td></td>
					<td>
						<a (click)="downloadMediaFile(file.id)"
						   mat-button
						   color="default"
						>
							<i class="fal fa-arrow-to-bottom"></i> {{'Download' | translate}}
						</a>
					</td>
				</tr>

				<tr *ngIf="releaseData.release?.webViewerUrl">
					<td>WebViewer Link</td>
					<td></td>
					<td>
						<button mat-button color="default" ngxClipboard
								title="{{releaseData.release.webViewerUrl}}"
								[cbContent]="releaseData.release.webViewerUrl">
							<i class="fal fa-sign-in-alt"></i> {{'Zwischenablage' | translate}}
						</button>
					</td>
				</tr>
				<tr *ngIf="releaseData.release?.webViewerUrlUpgrade">
					<td>Upgrade WebViewer Link</td>
					<td></td>
					<td>
						<button mat-button color="default" ngxClipboard
								title="{{releaseData.release.webViewerUrlUpgrade}}"
								[cbContent]="releaseData.release.webViewerUrlUpgrade">
							<i class="fal fa-sign-in-alt"></i> {{'Zwischenablage' | translate}}
						</button>
					</td>
				</tr>
			</table>
			<div *ngIf="releaseData.release?.technicianComment?.comment">
				<h3>{{'Anmerkung des Technikers' | translate}}</h3>
				<p class="technician-comment">{{releaseData.release?.technicianComment.date| date: 'mediumDate' : 'EUROPE/BERLIN' : config.locale}}
					:
					{{releaseData.release?.technicianComment.comment}}</p>
			</div>
		</div>
	</mat-card-content>
</mat-card>

<ux-ca-webviewer></ux-ca-webviewer>

<div class="controls">
	<button mat-raised-button (click)="onBackButtonClicked()" [disabled]="processing">
		<i class="fal fa-chevron-double-left"></i> {{'Back' | translate}}
	</button>
	<button *ngIf="hasRequestedOptionForRelease(treatment)" (click)="onRelease()" [disabled]="processing" mat-raised-button
		color="primary">
		{{'Setup Freigabe' | translate}}
	</button>
	<button *ngIf="hasUpgradeOptionForRelease(treatment)" (click)="onUpgrade()" [disabled]="processing" mat-raised-button
		color="primary">
		{{'Upgrade Freigabe' | translate}}
	</button>
	<button (click)="onCorrection()" mat-raised-button [disabled]="processing">
		{{'Setup Korrektur' | translate}}
	</button>
	<button (click)="onCancel()" [disabled]="processing" mat-raised-button>
		{{'Storno' | translate}}
	</button>
</div>
