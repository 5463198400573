import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'tm2-custom-options',
	templateUrl: './custom-options.component.html',
	styleUrls: ['./custom-options.component.css']
})
export class CustomOptionsComponent {
	@Input({required: true}) hasUpperJaw;
	@Input({required: true}) hasLowerJaw;

	@Output() action = new EventEmitter<any>();

	constructor() {}
}
