import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaBaseService} from '@suite/media';
import {TreatmentBaseService} from 'libs/treatments/src/lib/services';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {selectTreatmentFromUrl, TreatmentModel, TreatmentState} from '@suite/treatments';
import {takeWhile, tap} from 'rxjs/operators';
import {AnamneseOrder} from '@suite/anamnese';
import { Subscription } from 'rxjs';
import { ProductInfoForKey, ProductUrlKey } from 'libs/product/src/lib/models/product.model';

const TARGET_ORIGIN = 'https://' + window.location.host + '/caWebViewer/index.html';

@Component({
	selector: 'ux-ca-webviewer',
	templateUrl: './ca-webviewer.component.html',
	styleUrls: ['./ca-webviewer.component.scss']
})
export class CaWebviewerComponent implements OnInit, OnDestroy {

	@Input() webViewerJsonFileId = ''; //is currently only set by the ca-viewer app (and empty if component is called by tm2 (embedded))
	@ViewChild('caWebViewer') caWebViewer: ElementRef;
	@ViewChild('caWebViewerUpgrade') caWebViewerUpgrade: ElementRef;

	caWebViewerUrl; // sanitized web viewer url
	productName: string;
	file;
	upgradeFile;
	upgradeProductName: string;
	translateSubscription$: Subscription;
	filled = false;

	webViewerUrl;
	webViewerUrlUpgrade;

	constructor(private mediaBaseService: MediaBaseService,
				private translate: TranslateService,
				private treatmentBaseService: TreatmentBaseService,
				private store: Store<TreatmentState>,
				@Inject('CONFIG') private config) {
	}

	ngOnInit() {
		// bypass security for this url
		this.caWebViewerUrl = this.treatmentBaseService.getCaWebViewerSource(TARGET_ORIGIN);
		if (this.webViewerJsonFileId) {
			this.file = this.webViewerJsonFileId;
		}
		// subscribe language changes
		this.translateSubscription$ = this.translate.onLangChange
			.subscribe(e => {
				// normal webviewer
				if (this.caWebViewer?.nativeElement) {
					this.caWebViewer.nativeElement.contentWindow
							.postMessage({language: e.lang}, TARGET_ORIGIN);
				}
				// upgrade viewer
				if (this.caWebViewerUpgrade?.nativeElement) {
					this.caWebViewerUpgrade.nativeElement.contentWindow
						.postMessage({language: e.lang}, TARGET_ORIGIN);
				}
			});

		if (this.file) {
			//this path is reached, if the webviewer url is called as app via browser
			this.getFile(this.file);
		} else {
			//this path is reached in the embedded version inside the tm treatment
			this.store.pipe(select(selectTreatmentFromUrl), takeWhile(() => !this.filled),
				tap((treatment: TreatmentModel) => {
					if (treatment) {
						const anamnese = treatment.quotation?.anamnese;
						this.productName =  ProductInfoForKey[treatment.product.info.urlKey as ProductUrlKey].name;
						this.webViewerUrl = null;
						this.webViewerUrlUpgrade = null;
						if (treatment.upgrade?.product.info.urlKey) {
							this.upgradeProductName = ProductInfoForKey[treatment.upgrade.product.info.urlKey as ProductUrlKey].name;
						}
						if (anamnese?.releaseData?.release) {
							const release = anamnese.releaseData.release;
							if (release.webViewerUrl) { // Requested option.
								this.webViewerUrl = release.webViewerUrl;
								const urlParts = this.webViewerUrl.split('/');
								const isCaViewerLink = (urlParts.findIndex(part => part === 'ca-viewer') !== -1);
								if (isCaViewerLink) {
									this.file = urlParts[urlParts.length - 1]; // The media id of the .json file.
								}
							}
							if (release.webViewerUrlUpgrade) {
								// XXX Ignore "overruled" original planning backup link.
								// See the 'onUpgrade()' function in treatment-release.component.ts for context.
								const isBackupLinkAfterUpgradeRelease = release.webViewerUrlUpgrade.startsWith('(org)');
								if (!isBackupLinkAfterUpgradeRelease) {
									this.webViewerUrlUpgrade = release.webViewerUrlUpgrade;
									const urlParts = this.webViewerUrlUpgrade.split('/');
									const isCaViewerLink = (urlParts.findIndex(part => part === 'ca-viewer') !== -1);
									if (isCaViewerLink) {
										this.upgradeFile = urlParts[urlParts.length - 1];  // The media id of the .json file.
									}
								}
							}
							if (!this.filled) {
								if (this.file) {
									this.filled = true;
									this.getFile(this.file);
								}
								if (this.upgradeFile) {
									this.filled = true;
									this.getFile(this.upgradeFile, true);
								}
							}
						}
					}
				}))
				.subscribe();
			}
		}

	ngOnDestroy(): void {
		this.translateSubscription$.unsubscribe();
	}

	getFile(fileId:string, upgrade = false) {
		this.mediaBaseService.getFile(fileId)
			.subscribe(webViewerInput => {
				// post data to iframe
				if ((this.caWebViewer?.nativeElement || this.caWebViewerUpgrade?.nativeElement) && webViewerInput) {
					setTimeout(() => {
						const contentWindow = !upgrade ?
							this.caWebViewer.nativeElement.contentWindow :
							this.caWebViewerUpgrade.nativeElement.contentWindow;
						contentWindow.postMessage(webViewerInput, TARGET_ORIGIN);
						contentWindow.postMessage({language: this.config.locale}, TARGET_ORIGIN);
					}, 2000);
				}
			});
	}
}
