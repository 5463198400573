<div class="setup">
	<h3>{{'Weitere Angaben zu Ihrer Bestellung' | translate}}</h3>

	<h4>{{'Wie möchten Sie uns die Modelle zukommen lassen?' | translate}}</h4>
	<mat-radio-group [(ngModel)]="dataTransfer" (change)="onRadioButtonChange()">
		<mat-radio-button *ngIf="upsPickUpPossible"
			[value]="DataTransferMethod.UPS">{{'I would like to have my models picked up via UPS' | translate}}
		</mat-radio-button><br>
		<mat-radio-button
				[value]="DataTransferMethod.OTHER">{{'I would like to send my models differently' | translate}}
		</mat-radio-button><br>
		<mat-radio-button
			[value]="DataTransferMethod.UPLOAD">{{'I would like to upload digital data (Upload)' | translate}}
		</mat-radio-button>
	</mat-radio-group>
	<mat-checkbox *ngIf="dataTransfer === DataTransferMethod.UPS
					  || dataTransfer === DataTransferMethod.OTHER"
		[(ngModel)]="disposalConfirmationChecked"
		[ngClass]="{'red-text': !disposalConfirmationChecked}"
		data-cy="disposalCheckbox"
		>
		{{"ConfirmPlasterModelDisposal" | translate}}
	</mat-checkbox>

	<div class="comments">
		<h4>{{'Hint' | translate}}</h4>

		<mat-form-field appearance="fill">
			<mat-label>{{'Order hint' | translate}}:</mat-label>
			<textarea matInput #hint id="hint"
					  [(ngModel)]="orderComment"
					  matTextareaAutosize
					  maxlength="{{maxLength}}"
					  matAutosizeMinRows="4"
					  matAutosizeMaxRows="12"
					  placeholder="{{'Bitte geben Sie hier Anmerkungen zur Bestellung ein' | translate}}"
			></textarea>
			<mat-hint align="end">{{hint.value.length}} / {{maxLength}}</mat-hint>

		</mat-form-field>

	</div>

</div>
<div class="flex-spacer"></div>
<ux-next-back-actions
	(action)="onAction($event)"
	[disabledNext]="isNextButtonDisabled()"
></ux-next-back-actions>
