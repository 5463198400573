import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AddMessage, AddMessageOnDb, LoadMessages, MessagesActionTypes, MessagesRequested} from './messages.actions';
import {MessageModel} from '../models';
import {MessagesService} from '../services';
import {Store} from '@ngrx/store';
import {AppState} from '@suite/tm-common';


@Injectable()
export class MessagesEffects {

	messages$ = createEffect(() => this.actions$.pipe(
		ofType<MessagesRequested>(MessagesActionTypes.MessagesRequested),
		withLatestFrom(this.store$),
		mergeMap(([action, store]) => this.messagesService.getMessages({id: store.router.state.params.id})),
		map((messages: MessageModel[]) => new LoadMessages({messages})),
		catchError(err => {
			console.error('error loading messages');
			return throwError( () => new Error(err));
		})
	));


	addMessageOnDb$ = createEffect(() => this.actions$.pipe(
		ofType<AddMessageOnDb>(MessagesActionTypes.AddMessageOnDb),
		mergeMap(( {payload} ) => this.messagesService.createMessage(payload.message)),
		map((message: MessageModel) => new AddMessage({message})),
		catchError(err => {
			console.error('error while updating message');
			return throwError( () => new Error(err));
		})
	));

	constructor(private actions$: Actions,
				private store$: Store<AppState>,
				private messagesService: MessagesService) {
	}
}
