export type SapProductCategorization = {
	category: SapProductCategory;
	variant: SapProductVariant;
	dataTransfer: SapProductDataTransfer;
}

export enum SapProductCategory {
	MEMOTAIN = 'M',
	PROFESSIONAL = 'P',
	RETENTIONSSCHIENE = 'R',
	NONE = '-'
}

export enum SapProductVariant {
	SPLINT = 'S',
	DATA = 'D',
	NONE = '-'
}

export enum SapProductDataTransfer {
	DATA_UPLOAD = 'D',
	MODELS = 'M',
	NONE = '-'
}

export enum SapJaws {
	UJ = "OK",
	LJ = "UK",
	BOTH = "OK/UK",
	NONE = "-"
}

export enum SapShippingMethod {
	STANDARD = "Standard",
	EXPRESS = "Express",
	DOWNLOAD = "Download"
}

/**
 * Possible values for `TransportationCode` in a Quotation document.
 * Related to `SapShippingMethod`, but specifying the respective numerical code in SAP
 */
export enum SapTransportationCode {
	TC_STANDARD = 1,
	TC_EXPRESS = 2,
	TC_DOWNLOAD = 3
}

/** Maps the `SapShippingMethod` string enum to the respective numerical `SapTransportationCode` values */
export const SapTransportationCodeForShippingMethod: Record<SapShippingMethod, SapTransportationCode> = {
	[SapShippingMethod.STANDARD]: SapTransportationCode.TC_STANDARD,
	[SapShippingMethod.EXPRESS]: SapTransportationCode.TC_EXPRESS,
	[SapShippingMethod.DOWNLOAD]: SapTransportationCode.TC_DOWNLOAD
}

export interface SapShippingCostItem {
	Method: SapShippingMethod;
	Code: SapExpenseCode; 		// expenseCode for SAP shipping item
	Amount: number; 			// price of the composed shipping method
}

export interface SapCostEstimate {
	ItemPriceSum: number,
	DiscountedItemPriceSum: number,
	DiscountFactor: number,
	ShippingCost: number,
	Vat: number,
	Total: number
}

export interface SapAddress {
	AddressName: string;
	AddressName2: string;
	AddressName3?: string | null;
	Street: string,
	ZipCode: string,
	City: string,
	Country: string,
	isBillingAddress: boolean,
}

export enum SapExpenseCode {
	LIEFERANT_1 = 1,
	UPS_EXPRESS_INLAND_2 = 2,
	UPS_STANDARD_INLAND_3 = 3,
	UPS_STANDARD_AUSLAND_4 = 4,
	UPS_EXPRESS_AUSLAND_5 = 5,

	/** Fake expense code for "free" options, must NOT be sent to SAP as it does not exist. */
	NONE_OR_DOWNLOAD = -1,
}

/** Fake Shipping item for "free" download option, must NOT be sent to SAP as it does not exist. */
export const DOWNLOAD_SHIPPING_ITEM: SapShippingCostItem = {
	Method: SapShippingMethod.DOWNLOAD,
	Code: SapExpenseCode.NONE_OR_DOWNLOAD,
	Amount: 0
}

/** Fake Shipping items for customers that are freed from shipping costs, must NOT be sent to SAP as it does not exist. */
export const FREE_SHIPPING_ITEMS = [
	{
		Method: SapShippingMethod.EXPRESS,
		Code: SapExpenseCode.NONE_OR_DOWNLOAD,
		Amount: 0,
	},
	{
		Method: SapShippingMethod.STANDARD,
		Code: SapExpenseCode.NONE_OR_DOWNLOAD,
		Amount: 0,
	}
];

/** Properties on the BusinessPartner object. */
export enum SapBusinessPartnerProperty {
	/** Flag indicating whether or not the customer is freed from shipping costs. */
	FREE_SHIPPING_FLAG = "Properties1",
	/** List of billing and shipping addresses. */
	ADDRESSES_LIST = "BPAddresses",
	/** 'Production relevant notes' for the customer. */
	PRODUCTION_RELEVANT_NOTES_TEXT = "FreeText"
}

/** Possible values for `SapBusinessPartnerProperty.FREE_SHIPPING_FLAG` */
export enum SapBusinessPartnerFreeShipping {
	YES = "tYES",
	NO = "tNO"
}

export enum SapDocObjectId {
	QUOTATION = "23",
	ORDER = "17",
	DELIVERY_NOTE = "15",
	INVOICE = "13"
}

export enum SapPdfDocCode {
	QUOTATION = "QUT20010",
	ORDER = "RDR20007",
	INVOICE = "INV20015",
	DELIVERY_NOTE = "DLN20011",
	DECLARATION_OF_CONFORMITY = "DLN20012"
}

/** Country-Codes specifying european countries. */
export enum EUCountryCode {
	Austria = 'AT',
	Belgium = 'BE',
	Bulgaria = 'BG',
	Croatia = 'HR',
	Cyprus = 'CY',
	CzechRepublic = 'CZ',
	Denmark = 'DK',
	Estonia = 'EE',
	Finland = 'FI',
	France = 'FR',
	Germany = 'DE',
	Greece = 'GR',
	Greece_EL = 'EL', // XXX Alternative SAP country code for Greece
	Hungary = 'HU',
	Ireland = 'IE',
	Italy = 'IT',
	Latvia = 'LV',
	Lithuania = 'LT',
	Luxembourg = 'LU',
	Malta = 'MT',
	Netherlands = 'NL',
	Poland = 'PL',
	Portugal = 'PT',
	Romania = 'RO',
	Slovakia = 'SK',
	Slovenia = 'SI',
	Spain = 'ES',
	Sweden = 'SE',
}

export const EUCountryCodes = new Set(Object.values(EUCountryCode));

/** Possible values for `BusinessPartner.VatLiable` */
export enum SapVatLiable {
	YES = "Y",
	EUROPE = "E",
	NO = "N"
}

/** Possible values for `BusinessPartner.VatGroup` */
export enum SapVatGroup {
	GERMANY_A1 = "A1",
	EUROPE_A7 = "A7",
	REST_OF_WORLD_A0 = "A0"
}

export function mapCountryCodeToSapVatLiable(countryCode: string): SapVatLiable {
	if (countryCode === EUCountryCode.Germany) {
		return SapVatLiable.YES;
	} else if (EUCountryCodes.has(countryCode as EUCountryCode)) {
		return SapVatLiable.EUROPE;
	} else {
		return SapVatLiable.NO;
	}
}

export function mapCountryCodeToSapVatGroup(countryCode: string): SapVatGroup {
	if (countryCode === EUCountryCode.Germany) {
		return SapVatGroup.GERMANY_A1;
	} else if (EUCountryCodes.has(countryCode as EUCountryCode)) {
		return SapVatGroup.EUROPE_A7;
	} else {
		return SapVatGroup.REST_OF_WORLD_A0;
	}
}

export enum SapPdfDocNumPrefix {
	COST_ESTIMATE = "1",
	ORDER = "2",
	/**
	 * Prefix '3' is shared and can only be separated by DocCode (see `SapPdfDocCode`)
	 * Delivery Note: 				'DocCode = DLN20011'
	 * Declaration of conformity: 	'DocCode = DLN20012'
	 */
	DELIVERY_NOTE_CONFORMITY = "3",
	INVOICE = "4",
}

export enum SapPdfFilenamePrefix {
	COST_ESTIMATE = "Kostenvoranschlag",
	ORDER = "Auftrag",
	DELIVERY_NOTE = "Lieferschein",
	DECLARATION_OF_CONFORMITY = "Konformitätserklärung",
	INVOICE = "Rechnung",
}

/**
 * Maps the `SapPdfDocNumPrefix` string enum to the respective `SapPdfFilenamePrefix` values
 * Function is not on 'export' (aka. private) since the special handling is needed
*/
const SapPdfFilenameFromDocNumPrefix: Record<SapPdfDocNumPrefix, SapPdfFilenamePrefix> = {
	[SapPdfDocNumPrefix.COST_ESTIMATE]: SapPdfFilenamePrefix.COST_ESTIMATE,
	[SapPdfDocNumPrefix.ORDER]: SapPdfFilenamePrefix.ORDER,
	[SapPdfDocNumPrefix.DELIVERY_NOTE_CONFORMITY]: SapPdfFilenamePrefix.DELIVERY_NOTE, //needs a special handling
	[SapPdfDocNumPrefix.INVOICE]: SapPdfFilenamePrefix.INVOICE,
}

/**
 * Returns the composed pdf file name which is determined from the 'docNum'
 * Since 'Lieferschein' and 'Konf-Erklärung' share the same prefix, the
 * record `SapPdfFilenameFromDocNumPrefix` is just not sufficient and we need
 * a special handling.
 * @param docNum 'docNum' for the related file in SAP
 * @param docCode Only needed for 'Lieferschein' and 'Konformitätserklärung'
 * @returns the composed filename existing of 'filetype' and 'docNum'
 */
export function mapDocNumPrefixToPdfFilename(docNum: string, docCode?: SapPdfDocCode): string{
	let filename = "";
	filename =
		SapPdfFilenameFromDocNumPrefix[docNum.charAt(0) as SapPdfDocNumPrefix];

	if (docNum.charAt(0) === SapPdfDocNumPrefix.DELIVERY_NOTE_CONFORMITY) {
		filename =
			docCode === SapPdfDocCode.DELIVERY_NOTE
				? SapPdfFilenamePrefix.DELIVERY_NOTE
				: SapPdfFilenamePrefix.DECLARATION_OF_CONFORMITY;
	}
	return `${filename}_${docNum}.pdf`;
}


