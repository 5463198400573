<mat-card>
	<mat-card-content>
		<h3><i class="fal fa-arrow-up-right-from-square"></i>
			Webviewer ({{webviewers.length}})
		</h3>

		<mat-form-field>
			<mat-label>Name</mat-label>
				<mat-select data-cy="webviewer-selection"
					(selectionChange)="onSelectionChange($event.value)"
					[compareWith]="compareWebviewers"
					[(ngModel)]="selectedWebviewer"
					>
					<mat-option *ngFor="let webviewer of webviewers" [value]="webviewer">
						{{webviewer.name}}
 					</mat-option>
				</mat-select>
		</mat-form-field>
		<iframe [src]="sanitizedAppUrl" #webviewerIframe>
		</iframe>
		<div class="url-wrapper">
			<mat-label>URL: {{selectedWebviewer.url}}</mat-label>
			<button
				mat-raised-button
				color="primary"
				ngxClipboard
				[cbContent]="selectedWebviewer.url">
				<i class="fal fa-sign-in-alt"></i> {{'Zwischenablage' | translate}}
			</button>
		</div>

	</mat-card-content>
</mat-card>
