import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'cutFileName'
})
export class CutFileNamePipe implements PipeTransform {

	transform(value: string, length: number = 18): string {
		if (value.length <= length) {
			return value;
		}
		let [name, ext] = value.split('.');
		if (name.length > length - 4) {
			name = name.substr(0, length - 4) + '...' + name.substr(-1, 3);
		}
		if (ext.length > 3) {
			ext = ext.substr(-1, 3);
		}
		return `${name}.${ext}`;

	}

}
