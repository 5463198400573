import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {ACTION} from '@suite/tm-common';
import { EUCountryCode, SapAddress } from 'libs/sap/src/lib/models/sap.model';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment/moment';
@Component({
	selector: 'ux-ups-pickup',
	templateUrl: './ups-pickup.component.html',
	styleUrls: ['./ups-pickup.component.scss'],
	providers: [
		{provide: MAT_DATE_LOCALE, useValue: ''},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
	]
})
export class UpsPickupComponent implements OnInit, OnChanges {
	@Input({required: true}) shippingAddresses: SapAddress[];
	@Input() fields: FormlyFieldConfig[];
	@Input() user;
	@Input() treatment;
	@Input() loading = false;
	@Input() showDetails = true;
	@Input() prn = null;
	@Output() action = new EventEmitter();

	selectedAddressName: string;
	model = {
		address: {	//will be overwritten by the selected sap customer shipping address
			CompanyName: 'SCHEU-DENTAL custom made GmbH',
			ContactName: '', //will be set by the ups form
			AddressLine: 'Walder Str. 53',
			City: 'Hilden',
			StateProvince: '',
			PostalCode: '40724',
			CountryCode: 'DE',
			ResidentialIndicator: 'N',
			Phone: {
				Number: '' //will be set by the ups form
			}
		},
		dateTimeInfo: {	//will be set by the ups form
			CloseTime: '1700',
			ReadyTime: '1000',
			PickupDate: ''
		},
	};

	form: UntypedFormGroup = new UntypedFormGroup({});
	options: FormlyFormOptions = {};
	translateSubscription$: Subscription;

	constructor(@Inject('CONFIG') public config,
				private dateAdapter: DateAdapter<any>,
				private translate: TranslateService,
				public dialog: MatDialog) {
		this.model.dateTimeInfo.PickupDate =
			moment(new Date()).add(1, 'days').format('YYYYMMDD');
	}

	ngOnInit() {

		// set default locale...
		this.dateAdapter.setLocale(this.config.locale);
		// ... and subscribe language change
		this.translateSubscription$ = this.translate.onLangChange.subscribe(e => {
			this.dateAdapter.setLocale(e.lang);
		});

	}
	ngOnChanges() {
		this.selectedAddressName = this.shippingAddresses.at(0).AddressName;
		this.buildAddress();
	}
	onSubmit() {
		const form = this.form.value;
		if (typeof this.model.dateTimeInfo.PickupDate !== 'string') {
			this.model.dateTimeInfo.PickupDate = form.dateTimeInfo.PickupDate.format('YYYYMMDD');
		}
		this.action.emit({ type: ACTION.SUBMIT, payload: this.model});
	}

	onAddressSelection() {
		this.buildAddress();
		this.options.resetModel(this.model);
	}

	buildAddress() {
		const addr: SapAddress = this.shippingAddresses.find(addr => addr.AddressName === this.selectedAddressName);

		this.model.address.CompanyName = addr.AddressName2 + (addr.AddressName3 ? `, ${addr.AddressName3}` : "");
		this.model.address.AddressLine = addr.Street;
		this.model.address.PostalCode = addr.ZipCode;
		this.model.address.City = addr.City;
		this.model.address.CountryCode = this.getTextForCountryCode(addr.Country);

		this.model = structuredClone(this.model);
	}

	protected getTextForCountryCode(Country: string) {
		return Country === EUCountryCode.Greece_EL ? EUCountryCode.Greece : Country;
	}
}
