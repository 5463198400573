import {createSelector} from '@ngrx/store';
import {selectAuthState} from './auth.reducer';

export const selectIsAdmin = createSelector(
	selectAuthState,
	auth => auth.isAdmin
);

export const selectIsSignedIn = createSelector(
	selectAuthState,
	auth => auth.signedIn
);

export const selectIsSignedOut = createSelector(
	selectIsSignedIn,
	signedIn => !signedIn
);

export const selectUser = createSelector(
	selectAuthState,
	auth => auth ? auth.user : null
);

export const selectDisplayName = createSelector(
	selectAuthState,
	auth => auth && auth.user ?  auth.user.displayName : ''
);

export const selectCustomerEmail = createSelector(
	selectAuthState,
	auth => auth && auth.user ?  auth.user.email : ''
);

export const selectUserId = createSelector(
	selectAuthState,
	auth => auth && auth.user ?  auth.user.entity_id : ''
);

export const selectAdminRole = createSelector(
	selectAuthState,
	auth => auth?.user?.adminRole?.role_id
)

export const selectAdminUsername = createSelector(
	selectAuthState,
	auth => auth?.user?.adminData?.username ?? ''
)

export const selectWmsId = createSelector(
	selectAuthState,
	auth => auth?.user?.wmsId ?? ''
);

export const selectIsProfessionalCertified = createSelector(
	selectAuthState,
	auth => auth?.user?.ca_certified_professional === '1'
);
