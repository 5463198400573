import {Component, Input, OnInit} from '@angular/core';
import {TreatmentStatus, TreatmentTypes} from '@suite/treatments';

@Component({
	selector: 'tm2-treatment-special-status',
	templateUrl: './treatment-special-status.component.html',
	styleUrls: ['./_treatment-special-status.component.scss']
})
export class TreatmentSpecialStatusComponent implements OnInit {
	@Input() status;
	TS = TreatmentStatus;
	TT = TreatmentTypes;

	constructor() {
	}

	ngOnInit() {
	}

}
