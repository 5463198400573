 import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from './auth.service';
import {Store} from '@ngrx/store';
import {AppState} from '@suite/tm-common';
import {Observable} from 'rxjs';
import {first, tap} from 'rxjs/operators';
import {LoginAction, LogoutAction, SaveRedirectUrlAction} from '../store/auth.actions';
 import {CustomerService} from '@suite/customer';


@Injectable()
export class AuthGuard  {

	constructor(public router: Router,
				 private authService: AuthService,
				 private customerService: CustomerService,
				 private store: Store<AppState>) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

       return this.customerService.getMe().pipe(
       		tap( res => {
       			if (!res['isLoggedIn']) {
					this.store.dispatch(new LogoutAction());
					if (state.url) {
						this.store.dispatch(new SaveRedirectUrlAction(state.url));
						this.authService.setRedirectUrl(state.url);
					}
					this.router.navigate(['signin']);
				} else {
					this.store.dispatch(new LoginAction(res));
				}
			}),
		  first()
	   );
	}
}
