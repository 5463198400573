import {Anamnese, UpsPickupReference} from '@suite/anamnese';
import { MagentoProduct } from '@suite/magento';

export interface IoApiOrderItem {
	name: string;
	sku: string;
	price: string;
}

// Order Format when retrieving directly from Magento
export interface IoApiOrder {
	_id: string; // Magento Entity-ID, e.g. "189501"
	createdAt: string;
	id: string; // Magento "Order-Number", e.g. "100012345"
	items: IoApiOrderItem[];
	Okklusion?: string;
	ormcoDocId?: string;
	ormcoSn?: string;
	patientFirstName: string;
	patientId?: string;
	patientLastName: string;
	price: string;
	state: string;
	status: string;
	Stripping?: string;
	updatedAt: string;
	treatmentId: string;
	/** @deprecated */
	downloadCode?: string;
}

/**@deprecated */
export interface OrderModel
{
    anamnese?: Anamnese;

	id?: string;  // Magento Entity-ID (if applicable), e.g. "189501"
    realId: string; // "Order-Number", e.g. "100012345" (Magento) or "AE1700012345" (Anamnese)
    webViewerUrl?: string;
    products?: MagentoProduct[];
    status?: string;
    state?: string;
    comment?: string;
    created?: Date;
    lastStatusChange?: Date;
	upsPickUpReference?: UpsPickupReference;
	updatedAt?: string;	//TODO: Check if it is ever set - Date instead of string - check magento/ioApi
	/**@deprecated */
	tc_case_worker?: string;
	/**@deprecated */
	tc_completion_date?: string | Date;

	/** External doctor id (in distributor's system). I.e. in Ormco Partner-API orders. */
	doctorId?: string;
	/** External service number (in distributor's system). I.e. only in Ormco Partner-API orders. */
	ormcoSn?: string;

	/** @deprecated */
    downloadCode?: string;
	/** @deprecated */
    downloadLink?: string;
	/** @deprecated */
    downloadPassword?: string;
	/** @deprecated */
	zielmodel?: any;
}

export enum OrderStati {
	processing = 'processing',
	pending = 'pending',
	produktion = 'produktion',
	modell_angekommen = 'modell_angekommen',
	daten_angekommen = 'daten_angekommen',
	digitale_bearbeitung = 'digitale_bearbeitung',
	neues_modell_erwarten = 'neues_modell_erwarten',
	neue_daten_erwarten = 'neue_daten_erwarten',
	ruecksprache = 'ruecksprache',
	processed = 'processed',
	uebergabe_technik = 'uebergabe_technik',
	versendet = 'versendet',
	canceled = 'canceled',
	sonderfreigabe = 'sonderfreigabe',
	closed = 'closed',
	holded = 'holded',
	auftrag_vorbereitet = 'auftrag_vorbereitet',
	quality_check = 'quality_check',
	export = 'export',
	box_prepared = 'box_prepared',
	// special order statis
	release_requested = 'freigabe_angefordert',
	correction_requested = 'korrektur_angefordert',
	complaint_received = 'complaint_received',
	complaint_resolved = 'complaint_resolved'
}
