import {Action} from '@ngrx/store';

export enum CustomerActionTypes {
	AddressesRequested = '[Customer Addresses Resolver] Addresses Requested',
	AddressesLoaded = '[Customer Effects] Addresses Loaded',

}

export class AddressesRequested implements Action {
	readonly type = CustomerActionTypes.AddressesRequested;
}

export class AddressesLoaded implements Action {
	readonly type = CustomerActionTypes.AddressesLoaded;
	constructor( public payload) {}
}


export type CustomerActions = AddressesRequested
						     | AddressesLoaded ;
