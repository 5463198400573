export const environment = {
	production: true,
	isDebugMode: false,
	reduxDevTools: false,
	withProxy: false,
	version: require('../version.json').version,
	buildInfo: require('../version.json'),
	sentryDSN: 'https://e429b08db639414db8334afdb628e8c2@o4504361986424832.ingest.sentry.io/4504361993764867',
	useIoApiProxy: true
};
