<section *ngIf="anamnese">

	<ux-product-visual
		[productKey]="anamnese.product.info.urlKey"
		[productInfo]="anamnese.product.info"
	></ux-product-visual>

	<tm2-anamnese-addresses
		*ngIf="action === ACTION.INITIAL"
		[billingAddress]="billingAddress$ | async"
		[shippingAddresses]="shippingAddresses$ | async"
		[shippingCosts]="shippingCosts$ | async"
		[productKey]="anamnese.product.info.urlKey"
		(next)="onNext($event)"
	></tm2-anamnese-addresses>

	<tm2-anamnese-additional-settings
		*ngIf="action === ACTION.OPTIONS"
		[anamnese]="anamnese"
		(next)="onNext($event)"
	></tm2-anamnese-additional-settings>

	<tm2-anamnese-fileupload
		*ngIf="action === ACTION.UPLOAD"
		(next)="onNext($event)">
	</tm2-anamnese-fileupload>

	<tm2-anamnese-order
		*ngIf="action === ACTION.SELECT"
		[anamnese]="anamnese"
		[isSetupProduct]="isSetupProduct"
		(next)="onNext($event)"
	></tm2-anamnese-order>

</section>


