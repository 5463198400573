import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TreatmentsComponent} from './treatments.component';
import {MaterialModule} from '@suite/material';
import {TreatmentActionsComponent} from './treatment-actions/treatment-actions.component';
import {TreatmentReleaseComponent} from './treatment-release/treatment-release.component';
import {CaTreatmentsModule} from '@suite/treatments';
import {CaTranslateModule} from '@suite/translate';
import {TreatmentSpecialStatusComponent} from './treatment-special-status/treatment-special-status.component';
import {TreatmentReleaseDialogComponent} from './treatment-release/treatment-release-dialog/treatment-release-dialog.component';
import {MediaModule} from '@suite/media';
import {TreatmentNextStepsComponent} from './treatment-next-steps/treatment-next-steps.component';
import {UxComponentsModule} from '@suite/ux-components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { MessagesModule } from '@suite/messages';
import { PricelistComponent } from 'libs/ux-components/src/lib/components/pricelist/pricelist.component';

@NgModule({
    imports: [
        CommonModule,
        ClipboardModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CaTranslateModule,
        CaTreatmentsModule,
        MediaModule,
		MessagesModule,
        UxComponentsModule,
		PricelistComponent
    ],
    declarations: [
        TreatmentsComponent,
        TreatmentActionsComponent,
        TreatmentReleaseComponent,
        TreatmentSpecialStatusComponent,
        TreatmentReleaseDialogComponent,
        TreatmentNextStepsComponent
    ],
    exports: [
        TreatmentsComponent,
        TreatmentActionsComponent,
        TreatmentReleaseComponent,
    ],
    providers: []
})
export class TreatmentsModule {

	constructor() {
	}
}

