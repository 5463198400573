<div class="grid-header">
    <mat-form-field *ngIf="fWithSearch">
        <span matPrefix><i class="fal fa-search"></i> &nbsp;</span>
        <input matInput #filter placeholder="{{'Search' | translate}}" [(value)]="gridState.filter" />
        <i *ngIf="gridState.filter" (click)="onFilterChange('')" matSuffix class="fal fa-times-circle"></i>
    </mat-form-field>
	<ng-content></ng-content>
    <mat-paginator
            [length]="gridState.length"
            [pageSize]="gridState.pageSize"
            [pageSizeOptions]="paginationProps.pageSizeOptions"
            (page)="onPageEvent($event)"
    >
    </mat-paginator>
</div>
