import {createFeatureSelector} from '@ngrx/store';
import {MagentoActions, MagentoActionTypes} from './magento.actions';

export interface MagentoState {
	categoryProducts: [any]; //TODO: Remove as soon as SAP Articles are available
	stoererSeen: boolean;
}

export const initialMagentoState: MagentoState = {
	categoryProducts: null, //TODO: Remove as soon as SAP Articles are available
	stoererSeen: false
};

export const selectMagentoState = createFeatureSelector<MagentoState>('magento');

export function reducer(state = initialMagentoState, action: MagentoActions): MagentoState {
	switch (action.type) {

        case MagentoActionTypes.StoererSeen:
            return {
                ...state,
                stoererSeen: true
            };
		default:
			return state;
	}
}
