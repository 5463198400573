import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, switchMap} from 'rxjs/operators';
import {concat, of, throwError} from 'rxjs';
import {AnamneseActionTypes, AnamneseReleaseAction} from './anamnese.actions';
import {TreatmentRequested} from '@suite/treatments';
import {OrderService} from '@suite/order';
import {NotificationService} from '@suite/tm-common';
import { MessagesRequested } from '@suite/messages';

@Injectable()
export class AnamneseEffects {
	/**
	 * AnamneseReleaseAction
	 */

	 anamneseRelease$ = createEffect(() => this.actions$.pipe(
		ofType<AnamneseReleaseAction>(AnamneseActionTypes.AnamneseRelease),
		switchMap(action =>
			this.orderService.alignerReleaseOrder(action.payload.treatmentId, action.payload.comment, action.payload.isUpgrade)
			),
		switchMap(treatment => {
			return concat(
				of(new TreatmentRequested({id: treatment['_id']})),
				of(new MessagesRequested())
			)
		}),
		catchError(err => {
			console.error('Error during release Setup');
			this.notificationService.showErrorMessage('Error during release Setup');
			return throwError( () => new Error(err));
		})
	));

	constructor( private actions$: Actions,
	             private orderService: OrderService,
				 private notificationService: NotificationService) {}

}
