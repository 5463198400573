import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CmsBlockModel, CmsService } from "@suite/cms";
import { Observable, filter, tap } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "ux-pricelist",
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: "./pricelist.component.html",
})
export class PricelistComponent implements OnInit {
	priceListCmsBlock$: Observable<CmsBlockModel>;
	priceListUrlDE: string = null;
	priceListUrlEN: string = null;

	constructor(private cmsService: CmsService) {}

	ngOnInit(): void {
		this.priceListCmsBlock$ = this.cmsService
			.getCmsBlock("tm_pricelists")
			.pipe(
				filter((value) => value.activated),
				tap(value => {
					this.priceListUrlDE = value.content?.de ? `https://${window.location.host}/${value.content.de}` : null;
					this.priceListUrlEN = value.content?.en ? `https://${window.location.host}/${value.content.en}` : null;
				})
			);
	}
}
