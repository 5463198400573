import { EventEmitter, Inject, Injectable, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, delay, of } from "rxjs";
import { ProductUrlKey, ToothArea } from "libs/product/src/lib";
import { saveAs } from "file-saver";
import { DataTransferMethod } from "@suite/tm-common";
import { SapAddress, SapShippingCostItem, SapShippingMethod, SapCostEstimate, mapDocNumPrefixToPdfFilename } from "../models/sap.model";

export const CancellationCost: SapCostEstimate = {
	ItemPriceSum: 99,
	DiscountedItemPriceSum: 99,
	DiscountFactor: 1,
	ShippingCost: 0,
	Vat: 0,
	Total: 99
}

export type SapAddressesResponse = {
	billingAddress: SapAddress,
	shippingAddresses: SapAddress[]
}

@Injectable({
	providedIn: "root"
})
export class SapService {
	@Output() fileEvent = new EventEmitter<boolean>();
	baseUrl: string;

	constructor(private http: HttpClient, @Inject("CONFIG") private config) {
		this.baseUrl = this.config.apiUrl;
	}

	public getCustomerAddresses(): Observable<SapAddressesResponse>{
		return this.http.get<SapAddressesResponse>(`${this.baseUrl}/customer/addresses`);
	}

	public getShippingCosts(countryCode: string): Observable<SapShippingCostItem[]> {
		const queryParams = {
			country: countryCode.toUpperCase()
		}
		return this.http.get<SapShippingCostItem[]>(
			`${this.baseUrl}/ordering/shippingCost`,  {params: queryParams, withCredentials: true}
		);
	}
	public getEstimatedCosts(
		productKey: ProductUrlKey,
		shippingMethod: SapShippingMethod,
		countryCode: string,
		transferMethod: DataTransferMethod,
		uj: ToothArea,
		lj: ToothArea
	 ) : Observable<SapCostEstimate>{
			const queryParams = {
				country: countryCode.toUpperCase(),
				method: shippingMethod,
				productKey: productKey,
				transferMethod: transferMethod,
				uj: uj,
				lj: lj
			};

			return this.http.get<SapCostEstimate>(
				`${this.baseUrl}/ordering/costEstimate`,
				{ params: queryParams, withCredentials: true }
			);
		}

	public getPriceForCancellation()
		: Observable<SapCostEstimate> {
		return of(CancellationCost);
	}

	public getPriceForQuotation(
		treatmentId: string )
		: Observable<SapCostEstimate> {
		return this.http.get<SapCostEstimate>(
				`${this.baseUrl}/ordering/finalPrice/treatment/${treatmentId}`,
			);
	}

	/**
	 * Downloads and saves the cost estimate pdf in the default browser location.
	 * @param treatmentId
	 * @param docNum Id representing the pdf document in SAP
	 */
	public getCostEstimatePdf(treatmentId: string, docNum: string) {
		this.http
			.get<string>(`${this.baseUrl}/pdf/costEstimate/treatment/${treatmentId}`)
			.pipe()
			.subscribe({
				next: (response: string) => {
					const blob = this.convertBase64ToPdfBlob(response);
					let filename = mapDocNumPrefixToPdfFilename(docNum);
					saveAs(blob, filename);
					this.fileEvent.emit(true);
				},
				error: (error) => {
					console.error("Error caught while retrieving cost estimate pdf", error);
					this.fileEvent.emit(false);
				}
			});
	}

	/**
	 * See https://stackoverflow.com/a/16245768
	 * @param base64String
	 * @returns The base64 string as 'Blob'
	 */
	private convertBase64ToPdfBlob(base64String: string) {
		const byteCharacters = atob(base64String);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], { type: "application/pdf" });
	}

}
