<div class="order">
	<mat-card class="blue">
		<mat-card-content class="options">
			<h3>{{'Zu behandelnde Kiefer' | translate}}</h3>

			<div class="jawbones">
				<div *ngIf="anamnese.product.options.uj"
					 [ngClass]="{'selected': anamnese.product.options.uj}"
					 class="jawbone uj">
					<img src="assets/img/ca_ok.png" alt="uj"><br>
				</div>

				<div *ngIf="anamnese.product.options.lj"
					 [ngClass]="{'selected': anamnese.product.options.lj}"
					 class="jawbone lj">
					<img src="assets/img/ca_uk.png" alt="lj">
				</div>
			</div>

			<div class="custom-options">
				<div class="custom-option" *ngIf="anamnese.product.info.urlKey === ProductUrlKey.MEMOTAIN">
						<h3>{{"Zahnbereich" | translate}}</h3>
						<div *ngIf="options?.uj"><span>{{'uj' | translate}}:</span> {{options.uj}}</div>
						<div *ngIf="options?.lj"><span>{{'lj' | translate}}:</span> {{options.lj}}</div>
				</div>
			</div>

			<div class="hint">
				<h3>{{'Bestellhinweis' | translate}}</h3>
				<ng-container *ngIf="anamnese.comment; else noComment"><p style="word-break: break-word">
					{{anamnese.comment}}
				</p></ng-container>
				<ng-template #noComment>{{'no comments' | translate}}</ng-template>
			</div>

		</mat-card-content>

	</mat-card>

	<mat-card class="blue">
		<mat-card-content>

			<h3>{{'Billing Address' | translate}}</h3>
			<span>{{billingAddr.AddressName2}}</span><br>
			<ng-container *ngIf="billingAddr.AddressName3"><span>{{billingAddr.AddressName3}}</span><br></ng-container>
			<span>{{billingAddr.Street}}</span><br>
			<span>{{billingAddr.ZipCode}} {{billingAddr.City}}</span><br>
			<span>{{getTextForCountryCode(billingAddr.Country)}}</span>

			<h3>{{'Shipping Address' | translate}}</h3>
			<span>{{shippingAddr.AddressName2}}</span><br>
			<ng-container *ngIf="shippingAddr.AddressName3"><span>{{shippingAddr.AddressName3}}</span><br></ng-container>
			<span>{{shippingAddr.Street}}</span><br>
			<span>{{shippingAddr.ZipCode}} {{shippingAddr.City}}</span><br>
			<span>{{getTextForCountryCode(shippingAddr.Country)}}</span>

			<h3>{{'Versandart' | translate}}</h3>
			<span>{{anamnese.product?.handling?.shipment.Method}}</span>

			<h3>{{'Zahlungsart' | translate}}</h3>
			<span>{{ 'auf Rechnung' | translate }}</span>

			<h3>{{'Modelle' | translate}}</h3>
			<span>{{(anamnese.product?.options?.dataTransfer === DataTransferMethod.UPLOAD ? 'Upload' :
					(anamnese.product.options.dataTransfer === DataTransferMethod.UPS ? 'UPS Abholung' : 'Eigener Versand')) | translate}}
			</span>
		</mat-card-content>
	</mat-card>

	<mat-card class="blue cart-container">
		<mat-card-content>
			<div class="cart">
				<h3>{{ (isSetupProduct ? 'ORDER.interims' : 'ORDER.cart') | translate}}
					<i *ngIf="!isSetupProduct" class="fal fa-shopping-cart"></i></h3>
			</div>
			<div class="loading-spinner" *ngIf="!estimatedCosts"><i class="fal fa-spinner fa-spin"></i></div>
			<ng-container *ngIf="estimatedCosts">
				<table class="table" *ngIf="hasValidCostEstimate else invalidCostEstimate">
					<tr *ngIf="estimatedCosts.ItemPriceSum">
					<td>{{ 'Product(s) price' | translate }}:</td>
					<td class="text-right">{{estimatedCosts.ItemPriceSum| currency:'EUR':'symbol':'': config.locale}}</td>
				</tr>
				<tr *ngIf="estimatedCosts.DiscountFactor < 1">
					<td>{{ 'Your discount' | translate }}:</td>
					<td class="text-right">{{calculateDiscountInPercent()}}%</td>
				</tr>
				<tr *ngIf="estimatedCosts.DiscountFactor < 1">
					<td>{{ 'Subtotal' | translate }}:</td>
					<td class="text-right">{{(estimatedCosts.DiscountedItemPriceSum) | currency:'EUR':'symbol':'':
						config.locale}}
					</td>
				</tr>
				<tr>
					<td>{{ 'Shipping' | translate }}:</td>
					<td class="text-right">{{estimatedCosts.ShippingCost | currency:'EUR':'symbol':'': config.locale}}</td>
				</tr>
				<tr *ngIf="estimatedCosts.Vat">
					<td>{{ 'Tax' | translate }}:</td>
					<td class="text-right">{{estimatedCosts.Vat | currency:'EUR':'symbol':'': config.locale}}</td>
				</tr>

				<tr class="spacer"></tr>
				<tr class="total">
					<td>{{'Provisional total price' | translate}}:</td>
					<td class="text-right"> {{estimatedCosts.Total | currency:'EUR':'symbol':'': config.locale}}</td>
				</tr>
			</table>
				<ng-template #invalidCostEstimate>
						<div class="error-indicator"><i class="fal fa-exclamation-circle"></i></div>
						<div>{{'ErrorCostEstimateCalculation' | translate}}</div>
				</ng-template>
			</ng-container>
			<div class="loading-spinner" *ngIf="orderProcessStatus === OrderProcessStatus.PROCESSING"><i class="fal fa-spinner fa-spin"></i></div>
			<div class="success-indicator" *ngIf="orderProcessStatus === OrderProcessStatus.PROCESSED"><i class="fal fa-check-circle"></i></div>
			<div class="error-indicator" *ngIf="orderProcessStatus === OrderProcessStatus.ERROR"><i class="fal fa-exclamation-circle"></i></div>
		</mat-card-content>
		<mat-card-actions *ngIf="isSetupProduct">
			<button mat-raised-button color="primary" (click)="openDialog()">{{'AnamnesePDF' | translate}}</button>
		</mat-card-actions>
	</mat-card>

</div>
<div class="flex-spacer"></div>
<ux-next-back-actions
	(action)="onAction($event)"
	[disabledNext]="!hasValidCostEstimate || orderProcessStatus === OrderProcessStatus.PROCESSING"
	[disabledBack]="disabledBack"
	[nextText]="buttonText">
</ux-next-back-actions>
