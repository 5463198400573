import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {MediaModel } from '../models';
import {MediaActions, MediaActionTypes} from './media.actions';
import {createFeatureSelector} from '@ngrx/store';

export interface MediaState extends EntityState<MediaModel> {
  // additional entities state properties
}

export const mediaAdapter: EntityAdapter<MediaModel> = createEntityAdapter<MediaModel>(
	{
		selectId: (media: MediaModel) => media._id,
	}
);

export const initialMediaState: MediaState = mediaAdapter.getInitialState({
  // additional entity state properties
});

export const selectMediaState = createFeatureSelector<MediaState>('media');

export const {
	selectIds: selectMediaIds,
	selectEntities: selectMediaEntities,
	selectAll: selectAllMedia,
	selectTotal: selectMediaTotal,
} = mediaAdapter.getSelectors(selectMediaState);

export function reducer(
  state = initialMediaState,
  action: MediaActions
): MediaState {
  switch (action.type) {

    case MediaActionTypes.AddMedia: {
      return mediaAdapter.addOne(action.payload.media, state);
    }

    case MediaActionTypes.UpsertMedia: {
      return mediaAdapter.upsertOne(action.payload.media, state);
    }

    case MediaActionTypes.AddMedias: {
      return mediaAdapter.addMany(action.payload.medias, state);
    }

    case MediaActionTypes.UpsertMedias: {
      return mediaAdapter.upsertMany(action.payload.medias, state);
    }

    case MediaActionTypes.UpdateMedia: {
      return mediaAdapter.updateOne(action.payload.media, state);
    }

    case MediaActionTypes.UpdateMedias: {
      return mediaAdapter.updateMany(action.payload.medias, state);
    }

    case MediaActionTypes.DeleteMedia: {
      return mediaAdapter.removeOne(action.payload.id, state);
    }

    case MediaActionTypes.DeleteMedias: {
      return mediaAdapter.removeMany(action.payload.ids, state);
    }

    case MediaActionTypes.LoadMedias: {
      return mediaAdapter.setAll(action.payload.medias, state);
    }

    case MediaActionTypes.ClearMedias: {
      return mediaAdapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}


