import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular-ivy";
import {MaterialModule} from '@suite/material';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';

import {AuthModule} from '@suite/auth';
import {DashboardModule} from './dashboard/dashboard.module';
import {TreatmentsModule} from './treatments/treatments.module';
import {AppPatientsModule} from './patients/patients.module';
import {PatientsModule} from '@suite/patients';
import {AnamneseTmModule} from './anamnese/anamnese.module';
import {AnamneseModule} from '@suite/anamnese';
import {CustomerModule} from '@suite/customer';
import {OrderModule} from '@suite/order';
import {environment} from 'environments/environment';
import {StoreModule} from '@ngrx/store';
import {reducers, metaReducers} from '@suite/tm-common';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TmCommonModule} from '@suite/tm-common';
import {HelpComponent} from './help/help.component';
import {MagentoModule } from '@suite/magento';
import {CmsModule } from '@suite/cms';
import {RouterEffects} from '@suite/tm-common';
import {UxComponentsModule} from '@suite/ux-components';
import {APP_CONFIG} from './app.config';
import {CaTranslateModule, HttpLoaderFactory} from '@suite/translate';
import { IoApiModule, IoApiProxyService} from '@suite/ioApi';
import { UpsModule } from '@suite/ups';
import { SapModule } from '@suite/sap';
import { PricelistComponent } from 'libs/ux-components/src/lib/components/pricelist/pricelist.component';

@NgModule({
	declarations: [
		AppComponent,
		HelpComponent
	],
	imports: [
		AppRoutingModule,
		CaTranslateModule,
		AppPatientsModule,
		PatientsModule,
		AnamneseTmModule,
		AnamneseModule,
		AuthModule,
		BrowserAnimationsModule,
		BrowserModule,
		CustomerModule,
		DashboardModule,
		HttpClientModule,
		IoApiModule,
		MagentoModule,
		SapModule,
		CmsModule,
		MaterialModule,
		OrderModule,
		TmCommonModule,
		TreatmentsModule,
		UpsModule,
		UxComponentsModule,
		PricelistComponent,

		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		StoreModule.forRoot(reducers, {metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }}),
		environment.reduxDevTools ? StoreDevtoolsModule.instrument() : [],
		EffectsModule.forRoot([RouterEffects])
	],
	providers: [
		{ provide: 'CONFIG', useValue: APP_CONFIG },   // make it app wide injectable by name 'CONFIG'
		{ provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		IoApiProxyService
	],

	bootstrap: [AppComponent]
})

export class AppModule {
	constructor(trace: Sentry.TraceService){}
}

