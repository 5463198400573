import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FileDragDropDirective} from './components/file-upload/file-drag-drop.directive';
import {MaterialModule} from '@suite/material';
import {EvalFileService} from './components/file-upload/eval-file.service';
import {CaTranslateModule} from '@suite/translate';
import {StoreModule} from '@ngrx/store';
import * as fromMedia from './store/media.reducer';
import {EffectsModule} from '@ngrx/effects';
import {MediaEffects} from './store/media.effects';
import {FileUploadBoxComponent, FileListComponent} from './components';
import {MediaBaseService, MediaResolver, MediaService} from './services';
import {TmCommonModule} from '@suite/tm-common';

@NgModule({
    imports: [
        CommonModule,
        TmCommonModule,
        MaterialModule,
        CaTranslateModule,
        StoreModule.forFeature('media', fromMedia.reducer),
        EffectsModule.forFeature([MediaEffects])
    ],
    declarations: [
        FileUploadBoxComponent,
        FileDragDropDirective,
        FileListComponent
    ],
    providers: [
        MediaService,
        MediaBaseService,
        MediaResolver,
        EvalFileService
    ],
    exports: [
        FileUploadBoxComponent,
        FileListComponent
    ]
})
export class MediaModule {
}
