import {Injectable} from '@angular/core';
import {AnswerOption} from '@suite/questionaire';
import {first} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {AppState} from '@suite/tm-common';
import {selectAnamneseQuestionaireAnswers} from 'libs/anamnese/src/lib/store/index';
import {ClearQuestionaireAction, QuestionairePreparedAnswersUpdatedAction} from 'libs/anamnese/src/lib/store/anamnese.actions';

type QuestionnaireAnswersData = {
	comment: string;
	data: QuestionnaireThemeAnswers[];
};

type QuestionnaireThemeAnswers = {
	label: string;
	ok: QuestionnaireThemeJawAnswer;
	uk: QuestionnaireThemeJawAnswer;
};

type QuestionnaireThemeJawAnswer = {
	actual: string;
	target: string | SimpleQuestionnaireTargetLabel;
	details: string;
};

export const ACTUAL_NOT_PROVIDED: string = "-";

export enum SimpleQuestionnaireTargetLabel {
	EMPTY = "",
	JA = "Ja",
	NEIN = "Nein",
	BEI_BEDARF = "Bei Bedarf",
};

@Injectable()
export class QuestionaireService {

	constructor(private store: Store<AppState>) {}

	getQuestionaireData() {
		this.store.pipe(select(selectAnamneseQuestionaireAnswers), first())
			.subscribe(answers => {
				const preparedAnswers = this.handleSimpleQuestionaire(answers);
				this.store.dispatch(new QuestionairePreparedAnswersUpdatedAction(preparedAnswers));
			});
	}

	clearQuestionaire()
	{
		this.store.dispatch( new ClearQuestionaireAction());
	}

	// handle SimpleQuestionaire
	handleSimpleQuestionaire(answers): QuestionnaireAnswersData {

		// extract comment/Behandlungshinweis ...
		const comment = answers.comment;

		// start with an empty array
		const data: QuestionnaireThemeAnswers[] = [];

		// map values to Names
		const mapValues = {
			[AnswerOption.WHEN_NEEDED]: SimpleQuestionnaireTargetLabel.BEI_BEDARF,
			[AnswerOption.NO]: SimpleQuestionnaireTargetLabel.NEIN,
			[AnswerOption.YES]: SimpleQuestionnaireTargetLabel.JA,
		};

		let tmpJawAnswers = {};
		answers.radioItems.forEach(theme => {
			const [name, jaw] = theme.name.split('_');
			const answer = {
				actual: ACTUAL_NOT_PROVIDED,
				target: mapValues[theme.value],
				details: theme.details
			};

			if (jaw) { // This is a theme that has separate answers per jaw and requires buffering.
				tmpJawAnswers[jaw] = answer;
				if (jaw === "uk") { // XXX We assume that "ok" is first, and on "uk" we can complete the entry.
					const themeAnswers = {
						label: name,
						ok: tmpJawAnswers["ok"],
						uk: tmpJawAnswers["uk"],
					};
					data.push(themeAnswers);
					tmpJawAnswers = {};
				}
			} else { // This is a theme that shares the same answer for both jaws.
				const themeAnswers = {
					label: name,
					ok: answer,
					uk: answer,
				};
				data.push(themeAnswers);
			}
		});

		return {data, comment};
	}
}
