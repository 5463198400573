import {Component, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {FollowupPackageType, TreatmentNextStepsComponent} from '../treatment-next-steps/treatment-next-steps.component';
import {CaOpportunity, DataTransferMethod, TreatmentModel} from '@suite/tm-common';

@Component({
	selector: 'tm2-treatment-actions',
	templateUrl: './treatment-actions.component.html',
	styleUrls: ['./treatment-actions.component.css']
})
export class TreatmentActionsComponent {
	@Input() hideNext = false;
	@Input() nextOpportunities: CaOpportunity[];
	@Input() treatment: TreatmentModel;
	@Input() wmsId: string;
	@Output() action = new EventEmitter<{type:FollowupPackageType, transferType: DataTransferMethod, uploadedMediaIds?: string[]}>();
	@Output() backButtonClicked = new EventEmitter<any>();

	constructor(public dialog: MatDialog) {}

	onNext() {
		this.dialog
			.open<any, any, any>(TreatmentNextStepsComponent, {
				data: {
					wmsId: this.wmsId,
					treatment: this.treatment,
					nextOpportunities: this.nextOpportunities },
			})
			.afterClosed() // XXX afterClosed emits the value bound to 'mat-dialog-close' by the clicked button.
			.subscribe((payload: {type:FollowupPackageType, transferType: DataTransferMethod, uploadedMediaIds?: string[]}) => {
				if( payload ){
					this.action.emit(payload);
				}
			});
	}

	onBack() {
		this.backButtonClicked.emit();
	}
}
