import {Action} from '@ngrx/store';
import { AnamneseUploadType } from '@suite/media';
import { SapShippingCostItem } from '@suite/sap';
import {TreatmentModel} from '@suite/treatments';

export enum AnamneseActionTypes {
	JawToggled = '[Anamnese] Jaw Toggled',
	ToothAreaUpdated = '[ToothAreaUpdatedAction] Tootharea Updated',
	PatientSelected = '[Anamnese] Patient Selected',
	ProductSelected = '[Anamnese] Product Selected',
	PriceUpdated = '[Anamnese] Price Updated',
	OptionsUpdated = '[Anamnese] Options Updated',
	QuestionaireUpdated = '[Anamnese] Questionaire Updated',
	QuestionaireAnswersUpdated = '[Anamnese] Questionaire Answers Updated',
	QuestionairePreparedAnswersUpdated = '[Anamnese] Questionaire Prepared Answers Updated',
	ClearAnamneseRequested = '[Anamnese] Clear Anamnese',
	ClearQuestionaireRequested = '[Anamnese] Questionaire Clear',
	SettingsUpdated = '[Anamnese] Settings Updated',
	HandlingUpdated = '[Anamnese] Handling Updated',
	TreatmentIdUpdated = '[Anamnese] Treatment ID updated',
	AnamneseRelease = '[Treatment] Release Anamnese and Order main articles',
	MediaFileUploaded ="[Anamnese] Uploaded mediafile",
	MediaFileDeleted ="[Anamnese] Deleted mediafile",
	ShippingCostsLoaded = '[Anamnese] Shipping Costs Loaded',
}


export class ClearAnamneseAction implements Action {
	readonly type = AnamneseActionTypes.ClearAnamneseRequested;
}

export class ClearQuestionaireAction implements Action {
	readonly type = AnamneseActionTypes.ClearQuestionaireRequested;
}

export class ToothAreaUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.ToothAreaUpdated;
	constructor( public payload) {}
}
export class JawToggledAction implements Action {
	readonly type = AnamneseActionTypes.JawToggled;
	constructor( public payload) {}
}
export class PatientSelectedAction implements Action {
	readonly type = AnamneseActionTypes.PatientSelected;
	constructor( public payload) {}
}

export class ProductSelectedAction implements Action {
	readonly type = AnamneseActionTypes.ProductSelected;
	constructor( public payload) {}
}
export class PriceUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.PriceUpdated;
	constructor( public payload) {}
}
export class OptionsUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.OptionsUpdated;
	constructor( public payload) {}
}
export class QuestionairePreparedAnswersUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.QuestionairePreparedAnswersUpdated;
	constructor( public payload) {}
}
export class QuestionaireAnswersUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.QuestionaireAnswersUpdated;
	constructor( public payload) {}
}
export class HandlingUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.HandlingUpdated;
	constructor( public payload) {}
}
export class SettingsUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.SettingsUpdated;
	constructor( public payload) {}
}

export class MediaFileUploadedAction implements Action {
	readonly type = AnamneseActionTypes.MediaFileUploaded;
	constructor( public uploadType: AnamneseUploadType, public mediaId: string, public orgFilename: string, public index: number = 0 ) {}
}

export class MediaFileDeletedAction implements Action {
	readonly type = AnamneseActionTypes.MediaFileDeleted;
	constructor( public uploadType: AnamneseUploadType, public index: number = 0 ) {}
}
export class TreatmentIdUpdatedAction implements Action {
	readonly type = AnamneseActionTypes.TreatmentIdUpdated;
	constructor( public payload) {}
}
export class AnamneseReleaseAction implements Action {
	readonly type = AnamneseActionTypes.AnamneseRelease;
	constructor( public payload: {treatmentId: string, comment: string, isUpgrade: boolean}) {}
}

export class ShippingCostsLoaded implements Action {
	readonly type = AnamneseActionTypes.ShippingCostsLoaded;
	constructor( public payload: {shippingCosts: SapShippingCostItem[]}) {}
}

export type AnamneseActions =
	AnamneseReleaseAction
	| ClearAnamneseAction
	| ClearQuestionaireAction
	| ToothAreaUpdatedAction
	| JawToggledAction
	| PatientSelectedAction
	| ProductSelectedAction
	| PriceUpdatedAction
    | OptionsUpdatedAction
	| QuestionairePreparedAnswersUpdatedAction
	| QuestionaireAnswersUpdatedAction
	| HandlingUpdatedAction
	| SettingsUpdatedAction
	| MediaFileUploadedAction
	| MediaFileDeletedAction
	| TreatmentIdUpdatedAction
	| ShippingCostsLoaded;

