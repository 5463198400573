import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {first, tap} from 'rxjs/operators';

import {AppState} from '@suite/tm-common';

import {PatientsRequested, selectPatientTotal} from '../store';


@Injectable()

export class PatientsResolver  {
	constructor(
		private store: Store<AppState>) {

	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {

		// NOTE: we just want to trigger action PatientsRequested here if there are
		// no entities in store, so its enough to selector selectTotal
		// important:  observable must complete, otherwise no routing
		return this.store
			.pipe(
				select(selectPatientTotal),
				tap(cnt => cnt ? null : this.store.dispatch(new PatientsRequested())),
				first()
			);
	}

}

