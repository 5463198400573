export const COMPLAINTS_MAIL_BODY = {
	de: `Sehr geehrtes Praxisteam,

wir bedauern zutiefst, dass es Grund zur Beanstandung gibt, und möchten Ihnen schnellstmöglich helfen.

Um Ihr Anliegen zu klären, möchten wir Sie bitten die nächsten Schritte zu befolgen.

Bitte senden Sie uns:
Neue Scans/ Modelle ohne Retainer
Ein Foto der Beanstandung (Bruch, Passung …)
Detaillierten Sachverhalt

Die Scans und Fotos können Sie nach absenden der vorgefertigten Mail in der alten Bestellung hochladen.
Möchten Sie Modelle senden, bitten wir Sie eine UPS-Beauftragung telefonisch bei uns anzufragen.

Bitte beachten Sie, dass eine erneute Inanspruchnahme der Garantie anhand alter Daten entfällt.

Wir bitten um Ihr Verständnis und sind gerne bereit, Ihnen bei weiteren Fragen und Anliegen behilflich zu sein.

Mit freundlichen Grüßen

Ihr Kundenservice-Team in Hilden:
Tel.: +49(0)2104-80041-00
E-Mail: info@scheu-dental.com`,

	en: `Dear practice team,

If you want to report a complaint, we will take care of it immediately.

In order to clarify your concern, we would like to ask you to follow the next steps.

Please send us:
New scans/models without retainers
A photo of the complaint (breakage, fit, etc.)
Detailed facts

You can upload the scans and photos to the old order after sending the pre-prepared email.
If you would like to send models, please call us to request a UPS order.

Please note that the warranty does not need to be claimed again based on old data.

We ask for your understanding and are happy to help you with any further questions or concerns.

Best regards

Your customer service team in Hilden:
Tel.: +49(0)2104-80041-00
Email: info@scheu-dental.com`
};
