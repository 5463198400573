import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SapService } from "./sap.service";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { AppState } from "@suite/tm-common";
import { EMPTY, Observable, catchError, filter, of, switchMap, take, tap } from "rxjs";
import { selectShippingAddresses } from "@suite/customer";
import { ShippingCostsLoaded, selectShippingCosts } from "libs/anamnese/src/lib";
import { SapShippingCostItem, SapAddress } from "../models/sap.model";

@Injectable()
export class ShippingCostResolver {
	constructor(private sapService: SapService, private store: Store<AppState>) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SapShippingCostItem[]> {
		let shippingCosts: SapShippingCostItem[] = [];

		//check if shippingCosts are already available in the redux store
		this.store.pipe(select(selectShippingCosts), take(1)).subscribe((costs: SapShippingCostItem[]) => {
			shippingCosts = costs;
		});

		if (shippingCosts.length > 0) {
			return of(shippingCosts);
		}

		//shippingCosts needs to be fetched from SAP and saved in redux store
		return this.store.pipe(
			select(selectShippingAddresses),
			filter((shippingAddresses: SapAddress[]) => shippingAddresses.length > 0),
			switchMap((shippingAddresses: SapAddress[]) => {
				//XXX Note: It is not specified, what happens if a customer has shipping addresses from
				//different countries. So for now, we expect that all addresses are from the same country.
				const countryCodeShippingAddress
					= shippingAddresses.find((item: SapAddress) => item.isBillingAddress === false)?.Country;
				if (countryCodeShippingAddress) {
					return this.sapService.getShippingCosts(countryCodeShippingAddress);
				} else {
					return of([]);
				}
			}),
			filter((shippingCosts: SapShippingCostItem[]) => shippingCosts.length > 0),
			tap((shippingCosts: SapShippingCostItem[]) =>
				this.store.dispatch(new ShippingCostsLoaded({ shippingCosts: shippingCosts }))
			),
			catchError((err) => {
				console.error("Error loading shippingcosts", err);
				return EMPTY;
			})
		);
	}
}
