import {TranslateService} from '@ngx-translate/core';
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import {Subscription} from 'rxjs';

@Component({
	selector: 'ux-ups-pickup-print-overlay',
	templateUrl: './ups-pickup-print-overlay.component.html',
	styleUrls: ['./ups-pickup-print-overlay.component.scss']
})

export class UpsPickupPrintOverlayComponent implements OnInit {

	translateSubscription$$: Subscription;

	constructor(@Inject('CONFIG') public config,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data) {}

	ngOnInit() {
		// set default locale...
		moment.locale(this.config.locale);
		// ... and subscribe language change
		this.translateSubscription$$ = this.translate.onLangChange.subscribe(e => {
			moment.locale(e.lang);
		});
	}

	print() {
		window.print();
	}
}
