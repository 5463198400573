import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { COMPLAINTS_MAIL_BODY } from './complaints-mail-body';

const COMPLAINTS_MAILTO_ADDRESS = "tm-reklamation@scheu-dental.com";

@Injectable({
	providedIn: 'root'
})
export class ComplaintsMailToService {

	constructor(private translateService: TranslateService) { }

	private subject(orderId: string): string {
		const subjectKey = "ComplaintMailToSubject";
		const translatedSubject = this.translateService.instant(subjectKey);
		return `${translatedSubject} ${orderId}`;
	}

	private body(): string {
		return COMPLAINTS_MAIL_BODY[this.translateService.currentLang] || COMPLAINTS_MAIL_BODY.en;
	}

	private mailToUrl(orderId: string, patientId: string): string {
		const subject = encodeURIComponent(this.subject(orderId));
		const body = encodeURIComponent(this.body());
		return `mailto:${COMPLAINTS_MAILTO_ADDRESS}?subject=${subject}&body=${body}`;
	}

	public openComplaintMail(orderId: string, patientId: string) {
		const url = this.mailToUrl(orderId, patientId);
		window.location.href = url;
	}
}
