import {createSelector} from '@ngrx/store';
import {selectAnamneseState} from './anamnese.reducer';
import {selectPatientEntities} from '@suite/patients';
import {PatientModel} from '@suite/tm-common';

export const selectAnamneseProductOptions = createSelector(
	selectAnamneseState,
	anamnese => anamnese.product?.options
			? anamnese.product.options
			: {}
);

export const selectAnamneseCustomOptions = createSelector(
	selectAnamneseState,
	anamnese => anamnese.product?.options?.custom
			? anamnese.product.options.custom
			: {}
);

export const selectAnamneseJaws = createSelector(
	selectAnamneseState,
	anamnese => ({uj: anamnese.product.options.uj, lj: anamnese.product.options.lj }) );

export const selectAnamneseDataTransfer = createSelector(
	selectAnamneseState,
	anamnese => ({dataTransfer: anamnese.product.options.dataTransfer}) );

export const selectAnamneseUploadedFiles = createSelector(
	selectAnamneseState,
	anamnese => anamnese.uploadedFiles );

export const selectAnamnesePatientId = createSelector(
	selectAnamneseState,
	anamnese => anamnese.patientId
);

export const selectAnamnesePatient = createSelector(
	selectPatientEntities,
	selectAnamnesePatientId,
	(patients, id) => id  ? <PatientModel>patients[id] : null
);

export const selectAnamneseProductInfo = createSelector(
	selectAnamneseState,
	({product}) => product && product.info
		? product.info
		: {}
);

export const selectAnamneseQuestionaireAnswers = createSelector(
	selectAnamneseState,
	anamnese => anamnese.questionaireAnswers ? anamnese.questionaireAnswers : {}
);

export const selectAnamneseQuestionairePreparedAnswers = createSelector(
	selectAnamneseState,
	anamnese => anamnese.questionairePreparedAnswers ? anamnese.questionairePreparedAnswers : {}
);


export const selectShippingCosts = createSelector(
	selectAnamneseState,
	anamnese => anamnese.shippingCosts
);
