import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'ux-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
 })
export class OverlayComponent {

	baseUrl;

  constructor(private dialogRef: MatDialogRef<OverlayComponent>,
			  @Inject(MAT_DIALOG_DATA) public imageSource) { }

  onClick() {
	this.dialogRef.close();
  }

}
