import { Component, Inject, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { PROFESSIONAL_PRODUCT_KEYS, ProductInfo, ProductInfoForKey, ProductInfoMagento, ProductOptions, ProductUrlKey } from "libs/product/src/lib/models/product.model";
import { ProductAssetsService, ProductInfoService } from "libs/product/src/lib/services";

@Component({
	selector: 'ux-product-visual',
	templateUrl: './product-visual.component.html',
	styleUrls: ['./product-visual.component.scss']
})
export class ProductVisualComponent {
	@Input({required: true}) productKey: ProductUrlKey;
	@Input() productInfo: ProductInfoMagento;
	@Input() options: ProductOptions;

	ProductUrlKey = ProductUrlKey;

	constructor(
				public dialog: MatDialog,
				private translate: TranslateService,
				private productAssetsService: ProductAssetsService,
				private productInfoService: ProductInfoService,
				@Inject('CONFIG') public config) {
	}

	getProductImageUrl(): string {
		return this.productAssetsService.getProductImageUrl(this.productKey);
	}

	getProductCategoryImageUrl(): string {
		return this.productAssetsService.getProductCategoryImageUrl(this.productKey);
	}

	getProductNameHtml(): string {
		const nameHtml = this.productInfoService.getProductNameHtml(this.productKey as ProductUrlKey);
		//if product info could not be found, its probably a legacy treatment
		return nameHtml ? nameHtml : this.productInfo.title;
	}

	getProductDescription(): string {
		const productInfo: ProductInfo = ProductInfoForKey[this.productKey];
		return productInfo?.descriptionKey ? this.translate.instant(productInfo.descriptionKey) : '';
	}

	isProfessionalProduct(): boolean {
		return PROFESSIONAL_PRODUCT_KEYS.includes(this.productKey);
	}

	getToothAreaImageUrl(): string {
		return this.productAssetsService.getToothAreaImageUrl(this.productKey);
	}

	getToothAreaForProduct(isUpperJaw: boolean) : string {
		return this.productInfoService.getToothAreaForProduct(this.productInfo, this.options, isUpperJaw);
	}
}
