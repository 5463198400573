import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class TreatmentBaseService {


    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        @Inject('CONFIG') private config) {

    }
    getCaWebViewerSource(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            url);
    }

    getTreatmentViewer(treatmentId, mediumId, caseId) {
        mediumId = mediumId || 0;
        return this.http.get(`${this.config.apiUrl}/treatments/${treatmentId}/${mediumId}/viewer`, {params: { caseId }, withCredentials: true});
    }



}
