import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { TreatmentModel } from '@suite/treatments';
import { AddMessageOnDb } from '../store/messages.actions';
import { MessagesState } from '../store/messages.reducer';
import { MessageModel, MessageScopes, MessageTypes, SendersTypes } from '../models/message.model';


@Injectable({
	providedIn: 'root'
})
export class MessagesService {

	constructor(private http: HttpClient,
				private store: Store<MessagesState>,
				@Inject('CONFIG') public config) {
	}

	getMessages(params = {}): Observable<MessageModel[]> {
		return <Observable<MessageModel[]>>this.http.get(`${this.config.apiUrl}/messages`, {params, withCredentials: true});
	}

	createMessage(message: MessageModel): Observable<MessageModel> {
		return this.http.post<MessageModel>(
			`${this.config.apiUrl}/messages`,
			message,
			{withCredentials: true});
	}

	dispatchTreatmentRelatedMessage(
		comment: string,
		treatment: TreatmentModel,
		messageScope: MessageScopes = MessageScopes.Public,
		messageType: MessageTypes = MessageTypes.Note,
		sendersType: SendersTypes = null
	) {

		if (!comment) {
			return;
		}
		if (!treatment || !treatment.orders) {
			throw( new Error('There is something wrong with caOrder result'));
		}

		const message: MessageModel = {
			message: comment,
			sendersType: sendersType || (this.config.app === 'tmadmin' ? SendersTypes.Technican : SendersTypes.Customer),
			scope: messageScope,
			type: messageType,
			context: {
				id: treatment._id,
				status: treatment.status,
				ownerId: treatment.uid.toString()
			}
		};

		this.store.dispatch(new AddMessageOnDb({ message }));
	}

	resetMessageCount(treatmentId) {
		return this.http.post(`${this.config.apiUrl}/messages/resetCount`, {id: treatmentId}, {withCredentials: true});
	}
}
