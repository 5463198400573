<div class="comment">
	<h4><span *ngIf="!options.title" class="icon"><i class="{{MessageTypeIcons[type]?.icon}}"></i></span>
	   	{{(options.title || MessageTypes[type] + 'Title') | translate}}
		<span *ngIf="options.required">(<i>*</i> {{'Eingabe erforderlich, mind. 12 Zeichen' | translate}})</span>
	</h4>

	<!--mat-form-field>
		<mat-label>{{(options.title || 'Hint') | translate}}:</mat-label>
		<textarea matInput #hint id="hint" [formControl]="commentControl" [required]="options.required"
				  matTextareaAutosize
				  maxlength="{{maxLength}}"
				  matAutosizeMinRows="4"
				  matAutosizeMaxRows="12"
				  placeholder="{{'please insert your comment' | translate}}"
		></textarea>
		<mat-hint align="end">{{hint.value.length}} / {{maxLength}}</mat-hint>
		<mat-error *ngIf="options.required && commentControl.hasError('required')">{{'Bitte geben Sie einen Text ein' | translate}}</mat-error>
	</mat-form-field-->
	<angular-editor (ngModelChange)="htmlChange($event)" [ngModel]="htmlContent" [config]="editorConfig"></angular-editor>
	<!--div [innerHTML]="htmlContent" ></div-->
</div>
