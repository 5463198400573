import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '@suite/tm-common';
import {JawToggledAction} from 'libs/anamnese/src/lib/store/anamnese.actions';

@Component({
	selector: 'tm2-anamnese-options-jaws',
	templateUrl: './jaws.component.html',
	styleUrls: ['./_jaws.component.scss']
})
export class AnamneseOptionsJawsComponent {
	@Input() options;

	constructor(private store: Store<AppState>) {
	}

	onToggleJawbone(jb) {
		this.store.dispatch( new JawToggledAction(jb));
	}
}
