import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Store} from '@ngrx/store';

import {MediaService} from '../services';
import {MediasOfTreatmentRequested, MediaModel, DeleteMedia} from '@suite/media';
import {LoadMedias, MediaActionTypes, UpdateMedia} from './media.actions';
import {AppState} from '@suite/tm-common';


@Injectable()
export class MediaEffects {

	medias$ = createEffect(() => this.actions$.pipe(
		ofType<MediasOfTreatmentRequested>(MediaActionTypes.MediasOfTreatmentRequested),
		withLatestFrom(this.store$),
		mergeMap( ([action, store]) => {
			const params = { treatmentId: action.payload && action.payload.treatmentId ?
					                      action.payload.treatmentId :
					                      store.router.state.params.id };
			return this.mediaService.listFiles(params);
		}),
		map((medias: MediaModel[]) => new LoadMedias({medias})),
		catchError(err => {
			console.error('error loading medias');
			return throwError( () => new Error(err));
		})
	));

	updateMedia$ = createEffect(() => this.actions$.pipe(
		ofType<UpdateMedia>(MediaActionTypes.UpdateMedia),
		map( (action) => this.mediaService.updateMedium(action.payload.media.id as string, action.payload.media.changes) ),
		catchError(err => {
			console.error('error updating mediafile');
			return throwError( () => new Error(err));
		})
	), {dispatch: false});

	deletedMedias$ = createEffect(() => this.actions$.pipe(
		ofType<DeleteMedia>(MediaActionTypes.DeleteMedia),
		mergeMap( action => this.mediaService.deleteFile(action.payload.id) ),
		catchError(err => {
			console.error('error deleting mediafile');
			return throwError( () => new Error(err));
		})
	), {dispatch: false});


	constructor(private actions$: Actions,
				 private store$: Store<AppState>,
				 private mediaService: MediaService) {
	}
}
