<div *ngIf="status === TS.NewRequestReceived || status === TS.NewImprintsRequested || status === TS.DataReceived">
	<h1>{{'Setup angefragt' | translate}}</h1>
	<p>{{'Ihre Setup-Anfrage wurde erfolgreich' | translate}}<br>{{'an unsere Techniker übermittelt' | translate}}</p>
</div>
<div *ngIf="status === TS.CorrectionRequestReceived">
	<h1>{{'Setup-Korrektur' | translate}}</h1>
	<p>{{'Ihre Setup-Korrektur wurde erfolgreich' | translate}}<br>{{'an unsere Techniker übermittelt' | translate}}</p>
</div>
<div *ngIf="status === TS.CanceledByCustomer">
	<h1>{{'Behandlung storniert' | translate}}</h1>
	<p>{{'Ihre Behandlung wurde erfolgreich' | translate}}<br>{{'storniert' | translate}}</p>
</div>
<div *ngIf="status === TS.Shipped">
	<h1>{{'Behandlung läuft' | translate}}</h1>
	<p>{{'Ihre Behandlung wurde ausgeliefert' | translate}}</p>
</div>
