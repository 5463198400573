import { Component, ViewChild, OnInit, Inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import { Store } from "@ngrx/store";

import { AppState, GoAction } from "@suite/tm-common";
import { DashboardEntriesDatasource, DashboardState, DataTransferMethod, TreatmentModel } from "@suite/treatments";
import { TmMatPaginatorInit } from "@suite/material";
import { ProductUrlKey } from "libs/product/src/lib/models/product.model";
import { ProductAssetsService, ProductInfoService } from "libs/product/src/lib/services";

@Component({
	selector: 'tm2-dashboard-entries',
	templateUrl: './dashboard-entries.component.html',
	styleUrls: ['./_dashboard-entries.component.scss'],
	providers: [
		DashboardEntriesDatasource,
		{provide: MatPaginatorIntl, useClass: TmMatPaginatorInit}
	]
})
export class DashboardEntriesComponent implements  OnInit, OnChanges {
	private static readonly OVERDUE_TIMELIMIT_MS = 7 * 24 * 60 * 60 * 1000; // 7 days.

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	@Input() dashboardState: DashboardState;
	@Output() stateChange = new EventEmitter<any>();

	// table properties
	displayedColumns = ['patient', 'product', 'needForAction', 'status', 'action'];

	state:Sort|{} = {};

	constructor(public dataSource: DashboardEntriesDatasource,
				 private store: Store<AppState>,
				 private productAssetsService: ProductAssetsService,
				 private productInfoService: ProductInfoService,
				 @Inject('CONFIG') public config	) {}

	ngOnInit() {
		this.dataSource.sort = this.sort;

		this.sort.sortChange.subscribe((newSortState: Sort) => {
			this.state = { ...this.state, ...newSortState };
			this.stateChange.emit(this.state);
		});
	}

	ngOnChanges() {
		if (this.dashboardState) {
			this.dataSource.filter = this.dashboardState.filter || '';
			this.sort.active = this.dashboardState.active;
			this.sort.direction = this.dashboardState.direction;
		}
	}

	onGridStateChange(state: DashboardState) {
		if (state.filter) {
			this.dataSource.filter = state.filter;
		}
		this.stateChange.emit({...this.state, ...state});
	}
	selectEntry(row:TreatmentModel) {
		this.stateChange.emit({...this.state, id: row._id});
		this.store.dispatch( new GoAction({path: [`/treatments/edit/${row._id}`]}));
	}

	checkOnOverdue(row:TreatmentModel) {
		if (row.needForAction) {
			const timeSinceStateChangeMs = new Date().getTime() - new Date(row.statusChanged).getTime();
			return (timeSinceStateChangeMs > DashboardEntriesComponent.OVERDUE_TIMELIMIT_MS);
		}
		return false;
	}

	getStatusCssClass(row: TreatmentModel) {
		if (row.needForAction) {
			return "need-for-action";
		} else {
			return `st-${row.status}`;
		}
	}

	getProductLogoUrl(treatment: TreatmentModel): string {
		return this.productAssetsService.getProductCategoryImageUrlForTreatment(
			treatment
		);
	}

	getProductNameHtml(treatment: TreatmentModel)
	{
		const nameHtml = this.productInfoService.getProductNameHtml(treatment.product.info.urlKey as ProductUrlKey);
		//if product info could not be found, its probably a legacy treatment
		return nameHtml ? nameHtml : treatment.name;
	}

	getSendModelMethodIcon(treatment: TreatmentModel) : string{
		if( treatment.product?.options.dataTransfer === DataTransferMethod.UPS ){
			return "fa-brands fa-ups fa-2xl";
		}
		if( treatment.product?.options.dataTransfer === DataTransferMethod.OTHER ){
			return "fa-light fa-box fa-2xl";
		}
		return "";
	}


}
