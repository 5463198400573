import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TmMatPaginatorInit} from '@suite/material' ;
import { ProductUrlKey } from "libs/product/src/lib/models/product.model";
import {ACTION, TreatmentModel} from '@suite/tm-common';
import { ProductInfoService } from 'libs/product/src/lib/services';

@Component({
	selector: 'tm2-patient-details',
	templateUrl: './patient-details.component.html',
	styleUrls: ['./_patient-details.component.scss'],
	providers: [{provide: MatPaginatorIntl, useClass: TmMatPaginatorInit}]
})

export class PatientDetailsComponent implements OnChanges, AfterViewInit {

	@Input() patientTuple;
	@Output() next = new EventEmitter<any>();

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	patient;

	// table properties
	displayedColumns = ['orderNo', 'name', 'created', 'realId', 'status', 'button'];
	dataSource = new MatTableDataSource<TreatmentModel>();

	// pagination properties
	length = 100;
	pageSize = 6;
	pageEvent: PageEvent;
	pageSizeOptions = [6, 12, 18, 24, 36];

	constructor(private productInfoService: ProductInfoService) {	}

	ngOnChanges() {
		if (this.patientTuple) {
			[this.patient, this.dataSource.data] = this.patientTuple;
			this.length = this.dataSource.data.length;
			// caused by ngIf this.sort is undefined so long no tuple data available
			// so we have to set dataSourceSort again after viewChild returns !undefined
			setTimeout(() => this.dataSource.sort = this.sort, 100);
		}
	}
	ngAfterViewInit() {
		// on after init view we have to wire our data source together, even if there is no data
		// this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
		// this.dataSource.filterPredicate = this.filterPredicate;
		this.dataSource.sort = this.sort;               // watch out: sort & paginator
		this.dataSource.paginator = this.paginator;     // are not available before data arrived
	}
	selectEntry(id: string) {
		this.next.emit({type: ACTION.EDIT_TREATMENT, payload: id});
	}

	editPatient(id: string) {
		this.next.emit({type: ACTION.EDIT_PATIENT, payload: id});

	}

	getProductNameHtml(treatment: TreatmentModel)
	{
		const nameHtml = this.productInfoService.getProductNameHtml(treatment.product.info.urlKey as ProductUrlKey);
		//if product info could not be found, its probably a legacy treatment
		return nameHtml ? nameHtml : treatment.name;
	}
}
