<h3>
	<i class="fal fa-images"></i> {{'Treatment Files' | translate }} ({{getMediaList().length}})
	<button *ngIf="config.app === 'tmadmin'"
		mat-icon-button
		matTooltip="{{'RefreshFiles' | translate}}"
		(click)="onRefreshClicked()"
		[disabled]="isRefreshButtonDisabled"
		data-cy="refresh-files-button"
		>
		<mat-icon>refresh</mat-icon>
	</button>
</h3>
<div class="table-controls">
	<mat-checkbox
		[disabled]="getMediaList().length === 0"
		[checked]="allFilesChecked"
		[indeterminate]="areSomeFilesSelected()"
		(change)="onAllFileSelectionChange($event.checked)">
		{{"SelectAll" | translate}}
	</mat-checkbox>
	<button mat-raised-button
		disabled="{{selectedMediaFiles.length > 0 ? false : true}}"
		(click)="onDownloadSelectedFilesClicked()">
		<i class="fal fa-download"></i>
		{{"DownloadFiles" | translate}}
	</button>
</div>
<cdk-virtual-scroll-viewport itemSize="40"
							 *ngIf="getMediaList().length; else noFiles"
							 [style.height.px]="getMediaList().length > 5 ? 5 * 40 : getMediaList().length * 40"
							 class="media-list">

	<div class="Table" >
		<div *cdkVirtualFor="let file of getMediaList();">
			<div class="Table-row" [ngClass]="file.visibilityScope !== VisibilityScope.PUBLIC ? 'greyed-out' : ''">
				<div class="Table-row-item flex-0">
					<mat-checkbox
                    	(change)="onFileSelectionChange($event.checked, file)"
                    	[checked]="isFileSelected(file)">
    			  	</mat-checkbox>
				</div>
				<div class="Table-row-item xl" title="{{file.orgFilename}}">{{file.orgFilename | cutFileName:50}}</div>
				<div class="Table-row-item xs right">{{getFileSizeKb(file.filesize)}} KB</div>
				<div class="Table-row-item xxs">{{ file.filetype | uppercase }}</div>
				<div class="Table-row-item xxs">{{file.type === MediaTypes.User ? 'DOC' : 'TEC'}}</div>
				<div class="Table-row-item" title="{{file.created | date: 'medium' : 'EUROPE/BERLIN' : getLocale()}}">
					{{file.created | date: 'short' : 'EUROPE/BERLIN' : getLocale()}}
				</div>
				<div *ngIf="isUserAllowedToHideFile$ | async" class="Table-row-item flex-0">
					<a (click)="hideFile(file)">
						<i [ngClass]="file.visibilityScope === VisibilityScope.PUBLIC ?
						'fa-light fa-eye' :
						'fa-light fa-eye-slash'"></i>
					</a>
				</div>
				<div class="Table-row-item flex-0">
					<a (click)="downloadMediaFile(file._id)">
						<i class="fal fa-download"></i>
					</a>
				</div>
				<div *ngIf="isUserAllowedToDelete$ | async" class="Table-row-item flex-0">
					<a (click)="openDeleteConfirmationDialog(file._id)">
						<i class="fal fa-trash-alt"></i>
					</a>
				</div>
			</div>
		</div>
	</div>

</cdk-virtual-scroll-viewport>
<!--pre>{{media | json}}</pre-->

<ng-template #noFiles>
	<p>{{'no files' | translate}}</p>
</ng-template>
