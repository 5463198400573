import {createSelector} from '@ngrx/store';

export const selectCustomerState = state => state.customer;

export const selectBillingAddress = createSelector(
	selectCustomerState,
	customer => customer.billingAddress
);

export const selectShippingAddresses = createSelector(
	selectCustomerState,
	customer => customer.shippingAddresses
);

