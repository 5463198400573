import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {PatientsService} from '../services/patients.service';
import {AddPatient, LoadPatients, NewPatient, PatientActionTypes, PatientsRequested} from './patient.actions';

@Injectable()
export class PatientEffects {

	patients$ = createEffect(() => this.actions$.pipe(
		ofType<PatientsRequested>(PatientActionTypes.PatientsRequested),
		mergeMap(() => this.patientsService.getPatients()),
		map(patients => new LoadPatients({patients})),
		catchError(err => {
			console.error('error loading patients');
			return throwError( () => new Error(err));
		})
	));


	savePatient$ = createEffect(() => this.actions$.pipe(
		ofType<NewPatient>(PatientActionTypes.NewPatient),
		map(newPatient => new AddPatient(newPatient.payload) ),
		catchError(err => {
			console.error('error saving patient');
			return throwError( () => new Error(err));
		})
	), {dispatch: false});

	constructor(private actions$: Actions, private patientsService: PatientsService ) {
	}
}
