import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatExpansionModule } from "@angular/material/expansion";
import { TranslateModule } from "@ngx-translate/core";

export type CustomerNote ={
	note: string,
	firstname: string,
	lastname: string,
	updated_at: Date,
	created_at: Date,
}

@Component({
	selector: "tma-notes-on-customer",
	standalone: true,
	imports: [
		CommonModule,
		MatExpansionModule,
		TranslateModule
	],
	templateUrl: "./notes-on-customer.component.html",
	styleUrls: ["./notes-on-customer.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesOnCustomerComponent {
	customerRelevantNotes: CustomerNote[] = [];
	orderRelevantNotes: String = '';

	@Input() locale: string;
	@Input({ required: true }) set customerNotes(customerNotes: CustomerNote[]) {
		this.customerRelevantNotes = customerNotes;
	}
	@Input({ required: true }) set orderNotes(orderNotes: String) {
		this.orderRelevantNotes = orderNotes;
	}
}
