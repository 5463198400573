<section>
	<ux-product-visual
		[productKey]="productInfo.urlKey"
		[productInfo]="productInfo"
	></ux-product-visual>

	<div class="options">
		<h3>{{'Anamnestische Abfrage' | translate}}</h3>

		<label>{{'Anamnesis.ObjectivesComment' | translate}}</label>
		<textarea [(ngModel)]="formData.comment"
			placeholder="{{'Anamnesis.ObjectivesCommentPlaceholder' | translate}}"
			[ngClass]="{invalid: !formData.comment.length}"></textarea>

		<label>{{'Anamnesis.PlanningPreferences'| translate }}</label>
		<div class="header">
			<div class="question-header"></div>
			<div class="answer-header">{{'Ja' | translate}}</div>
			<div class="answer-header">{{'Nein' | translate}}</div>
			<div class="answer-header">{{'Bei Bedarf' | translate}}</div>
			<div class="details-header">
				{{'Detaillierte Anweisungen' | translate }}
				<br>
				{{ "Anamnesis.OptionalField" | translate }}
			</div>
		</div>

		<div class="row" *ngFor="let question of formData.radioItems">
			<mat-radio-group [(ngModel)]="question.value" [ngClass]="{double: question.double, invalid: !question.value}">

				<div class="question-label"
				[ngClass]="{'hidden-label': question.hideLabel}">
					{{question.label | translate}}
					<span *ngIf="question.pill" [ngClass]="{invalid:!question.value}">
						{{question.pill | translate}}
					</span>
				</div>
				<span class="invalid" [ngStyle]="{visibility: !question.value ? 'inherit' : 'hidden'}">|</span>

				<mat-radio-button value="{{ AnswerOption.YES }}"></mat-radio-button>
				<mat-radio-button value="{{ AnswerOption.NO }}"></mat-radio-button>
				<ng-template [ngIf]="!question.isObligatoryDecision" [ngIfElse]="placeholder">
					<mat-radio-button value="{{ AnswerOption.WHEN_NEEDED }}"></mat-radio-button>
				</ng-template>
				<ng-template #placeholder>
					<div class="radio-placeholder"></div>
				</ng-template>
				<mat-form-field appearance="fill">
					<input matInput maxlength="90" type="text" [(ngModel)]="question.details">
					<mat-hint align="end">{{ "Anamnesis.OptionalField" | translate }}</mat-hint>
				</mat-form-field>
			</mat-radio-group>
		</div>
		<mat-error *ngIf="!checkValid()" data-cy="required-input-missing-hint">
			{{ "Anamnesis.RequiredInputMissingHint" | translate }}
		</mat-error>
	</div>
</section>
<ux-next-back-actions [disabledNext]="!checkValid()" (action)="action.emit($event)"></ux-next-back-actions>
<!--pre>{{questions | json}}</pre-->
