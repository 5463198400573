import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {AuthService, MagentoUserRole} from './auth.service';
import {LoginAdminAction, LogoutAdminAction, SaveRedirectUrlAction} from '../store/auth.actions';
import {AppState} from 'libs/tm-common/src/lib/store/reducers';

export const adminAreaGuard = () => {
	const authService = inject(AuthService);
	return authService.isAdmin().pipe(
		map((res) => {
			if ( res["adminRole"]["role_id"] === MagentoUserRole.ADMIN )
			{
				return true;
			}
			return false;
	}));
}

@Injectable()
export class AuthTokenGuard  {

	constructor(public router: Router,
				 private authService: AuthService,
				 private store: Store<AppState>) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		return this.authService.isAdmin().pipe(
			tap(res => {
				if( res === null )
				{
					this.router.navigate(['signin']);
					return true;
				}
				if (res['isLoggedInAsAdmin'])
				{
					//just refresh the redux store entries (not an actual login)
					this.store.dispatch(new LoginAdminAction(res));
				}
				else
				{
					//make sure that magento session is deleted and clear the redux store
					this.store.dispatch(new LogoutAdminAction());
					//save the requested url to the redux store, to get redirected correctly after signin
					if (state.url)
					{
						this.store.dispatch(new SaveRedirectUrlAction(state.url));
						this.authService.setRedirectUrl(state.url);
					}
				}

			}),
			switchMap( () => of(true))
		);
	}
}
