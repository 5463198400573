import { Component, Inject, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";

import {
	MediaState,
	MediasOfTreatmentRequested,
	selectMediaEntities
} from "@suite/media/store";
import { selectIdFromUrl } from "@suite/tm-common";
import { Subscription } from "rxjs";
import { TreatmentRequested } from "@suite/treatments";
import { FileEvent, FileEventType } from "@suite/media";

export const DEFAULT_ALLOWED_EXTENSIONS = ['stl', 'ply', 'pdf', 'png', 'jpg', 'jpeg'];

@Component({
	selector: 'ux-treatment-media',
	templateUrl: './treatment-media.component.html',
	styleUrls: ['./_treatment-media.component.scss']
})
export class TreatmentMediaComponent implements OnInit, OnDestroy {
	@Input() treatmentId = '';
	@Input() allowedExtensions = DEFAULT_ALLOWED_EXTENSIONS;
	@Input() allowedMaxFileSize;
	@Input() isInputEnabled: boolean = true;

	params: { treatment: string} = null;
	media$;
	treatmentIdSubscription: Subscription;


	constructor(private store: Store<MediaState>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: { isInputEnabled: boolean }
	) {
		if (data) {
			this.isInputEnabled = data.isInputEnabled;
		}
	}

	ngOnInit(): void {
		this.media$ = this.store.pipe(
				select(selectMediaEntities),
				map(mediaEntities => Object.values(mediaEntities))
			);

		this.treatmentIdSubscription = this.store.pipe(
			select(selectIdFromUrl))
			.subscribe(id => {
					this.params = { treatment: id };
			});
	}

	ngOnDestroy(): void {
		this.treatmentIdSubscription.unsubscribe()
	}

	onFileEvent($event: FileEvent) {
		if ($event.type === FileEventType.UPLOADED) {
			this.store.dispatch(new TreatmentRequested({
				id: this.params.treatment
			}));
		}
	}

	onFilesRefreshClicked(){
		this.store.dispatch(
			new MediasOfTreatmentRequested({
				treatmentId: this.treatmentId
			})
		);
	}
}
