import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SapService } from './services/sap.service';
import { ShippingCostResolver } from './services/shipping-cost.resolver';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [SapService, ShippingCostResolver]
})
export class SapModule {}
