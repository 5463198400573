import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {EstimatedCostsRequested, EstimatedCostsUpdated, IoApiOrdersLoaded, OrderActionTypes, IoApiOrdersRequested} from './order.actions';
import { IoApiOrder } from '@suite/tm-common/models';
import { TreatmentsService } from 'libs/treatments/src/lib/services/treatments.service';
import { SapService } from 'libs/sap/src/lib/services/sap.service';
import { Store } from '@ngrx/store';
import { AppState } from 'libs/tm-common/src/lib/store/reducers/reducers';



@Injectable()
export class OrderEffects {
	// XXX This effect is only used by the tmormco app. Let's make it stay that way.
	orders$ = createEffect(() => this.actions$.pipe(
		ofType<IoApiOrdersRequested>(OrderActionTypes.IoApiOrdersRequested),
		mergeMap(action => this.treatmentsService.getTreatmentsTmOrmco(action.payload?.fromDate)),
		map(resp => resp.treatments.map(respTreatment =>
			<IoApiOrder>{
				_id: respTreatment._id,
				id: respTreatment.quotation?.docNum ?? respTreatment.orders?.at(-1)?.realId,
				createdAt: respTreatment.created.toString(),
				updatedAt: respTreatment.lastModified.toString(),
				ormcoDocId: respTreatment.quotation?.ormcoDoctorId ?? respTreatment.orders?.at(-1)?.doctorId,
				ormcoSn: respTreatment.quotation?.ormcoSn ?? respTreatment.orders?.at(-1)?.ormcoSn,
				status: respTreatment.status,
				state: null,
				treatmentId: respTreatment._id,
				patientId: respTreatment.patient.patientId,
				patientFirstName: respTreatment.patient.firstName,
				patientLastName: respTreatment.patient.lastName,
			})),
		map(resp => new IoApiOrdersLoaded({orders: resp})),
		catchError(err => {
			console.error('error loading orders');
			return throwError( () => new Error(err));
		})
	));


	estimatedCosts$ = createEffect(() => this.actions$.pipe(
		ofType<EstimatedCostsRequested>(OrderActionTypes.EstimatedCostsRequested),
		mergeMap( (action) => this.sapService.getEstimatedCosts(
				action.payload.productKey,
				action.payload.shippingMethod,
				action.payload.country,
				action.payload.transferMethod,
				action.payload.uj,
				action.payload.lj ) ),
		map( data => new EstimatedCostsUpdated({ estimatedCosts: data } )),
		catchError(err => {
			console.error('Error loading estimated costs for treatment');
			/**
			 * Return an "empty" object to prevent UI from deadlocking because
			 * of an empty redux store
			 */
			this.store$.dispatch(new EstimatedCostsUpdated({ estimatedCosts: {
				ItemPriceSum: null,
				DiscountFactor: null,
				ShippingCost: null,
				Vat: null,
				Total: -1
			} }) )
			return throwError( () => new Error(err));
		})
	));

	constructor(
		private actions$: Actions,
		private store$: Store<AppState>,
		private sapService: SapService,
		private treatmentsService: TreatmentsService
		) {
	}
}
