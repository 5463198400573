import { environment } from "../../../../environments/environment";
import { AppConfig } from "../../../../libs/config/app.config";

const host = environment.withProxy
	? "local.ca-digit.com:4200"
	: window.location.host;
export const APP_CONFIG: AppConfig = {
	app: "tm2",
	appTitle1: "TREATMENT",
	appTitle2: "MANAGER",
	version: environment.version,
	versionLabel: environment.buildInfo.label,
	apiUrl: "https://" + host + "/api/v1",
	catUrl: "https://" + host + "/cat",
	ioApiUrl: "https://" + host + "/api/v1/ioApiProxy",
	isDebugMode: environment.isDebugMode,
	locale: "de",
	production: environment.production,
	sentryDSN: environment.sentryDSN
};
