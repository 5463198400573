export const CHANGE_ADDRESS_TEXT = {
	de: `Wenn Sie Ihre Kundenstammdaten* ändern wollen, wenden Sie sich bitte an unser
	Service Team in Hilden unter Tel. 02104/80041-00

	Oder senden Sie eine E-Mail an info@scheu-dental.com mit dem Betreff: „Kundenstammdatenänderung“

	Und im Mail Text geben Sie bitte an:
	- Ihre Kundennummer
	- Ihren Benutzernamen
	- Ihre bisherigen zu ändernden Kundenstammdaten
	- Ihre neuen Kundenstammdaten


	* Kundenstammdaten sind:
	- Firmenname
	- Ansprechpartner
	- Adresse
	- Telefonnummer
	- E-Mail Adresse
	- Umsatzsteuer ID`,

	en: `If you want to change your customer master data*, please contact our
	service team in Hilden on Tel. +49 (0) 2104/80041–00

	Or send an e-mail to info@scheu-dental.com with the subject: “Customer master data change”

	And in the mail text please state:
	- Your customer ID,
	- Your username,
	- Your previous customer master data to be changed
	- Your new customer master data.


	* Customer master data are:
	- Company name
	- Contact person
	- Contact address
	- Telephone number
	- E-Mail address
	- VAT ID`
};
