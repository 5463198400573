import {createSelector} from '@ngrx/store';
import {selectRouterState} from '../reducers/reducers';

export const selectCurrentUrl = createSelector(
	selectRouterState,
	// WATCH OUT: we have to handle empty initial router state here
	(data) => data?.state ? data.state.url : ''
);

export const selectIdFromUrl = createSelector(
	selectRouterState,
	(data) => data?.state ? data.state.params.id : ''
);

export const selectQueryParams = createSelector(
	selectRouterState,
	(data) => data?.state ? data.state.queryParams : {}
);
