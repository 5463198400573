import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {IoApiOrder} from '@suite/tm-common';
import {OrderActions, OrderActionTypes} from './order.actions';
import {createFeatureSelector} from '@ngrx/store';

export interface State extends EntityState<IoApiOrder> {
	// additional entities state properties
	estimatedCosts?;
}

export function sortByUpdatedAt(o2: IoApiOrder, o1: IoApiOrder): number {
	return +new Date(o1.updatedAt) - +new Date(o2.updatedAt);
}

export function sortById(o2: IoApiOrder, o1: IoApiOrder): number {
	return +o1.id - +o2.id;
}

export const orderAdapter: EntityAdapter<IoApiOrder> = createEntityAdapter<IoApiOrder>({
	sortComparer: sortByUpdatedAt
});

export const initialState: State = orderAdapter.getInitialState({
	// additional entity state properties
	estimatedCosts: null
});

export const getOrderState = createFeatureSelector<State>('order');

export const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = orderAdapter.getSelectors(getOrderState);

export function reducer(
	state = initialState,
	action: OrderActions
): State {
	switch (action.type) {
		case OrderActionTypes.EstimatedCostsUpdated:
			return {
				...state,
				...action.payload
			};
		case OrderActionTypes.ClearEstimatedCosts:
			return {
				...state,
				estimatedCosts: null
			};
		case OrderActionTypes.AddOrder: {
			return orderAdapter.addOne(action.payload.order, state);
		}

		case OrderActionTypes.UpsertOrder: {
			return orderAdapter.upsertOne(action.payload.order, state);
		}

		case OrderActionTypes.AddOrders: {
			return orderAdapter.addMany(action.payload.orders, state);
		}

		case OrderActionTypes.UpsertOrders: {
			return orderAdapter.upsertMany(action.payload.orders, state);
		}

		case OrderActionTypes.UpdateOrder: {
			return orderAdapter.updateOne(action.payload.order, state);
		}

		case OrderActionTypes.UpdateOrders: {
			return orderAdapter.updateMany(action.payload.orders, state);
		}

		case OrderActionTypes.DeleteOrder: {
			return orderAdapter.removeOne(action.payload.id, state);
		}

		case OrderActionTypes.DeleteOrders: {
			return orderAdapter.removeMany(action.payload.ids, state);
		}

		case OrderActionTypes.IoApiOrdersLoaded: {
			return orderAdapter.setAll(action.payload.orders, state);
		}

		case OrderActionTypes.ClearOrders: {
			return orderAdapter.removeAll(state);
		}

		default: {
			return state;
		}
	}
}
