import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl} from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {AppState} from '@suite/tm-common';
import {LoginAction, LoginAdminAction, LogoutAction, selectIsAdmin, selectIsSignedIn} from '../store';
import {AuthService, ForgotPasswordService} from '../services';

enum MagentoAccountActivationState {
	NO = "0",
	YES = "1"
}

@Component({
	selector: 'auth-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.css']
})

export class SigninComponent implements OnInit {
	@ViewChild('username') usernameEl: ElementRef;
	@ViewChild('formRef') form;

	signinForm: UntypedFormGroup;

	credentials = {
		username: '', // XXX Could also be an admin username.
		pwd: '',
	};

	credentialsForm = {
		username: ['', [(control: AbstractControl) => this.UserEmailWMSValidator(control)]],
		pwd: ['', [Validators.minLength(6)]],
	};

	formAnimationState = 0; // 1 = invalid, 2 = reset, 3 = valid, 4 = continue

	sbConfig = new MatSnackBarConfig();

	registrationUrl = `${this.config.catUrl}/customer/account/create/`;
	registrationQuery = `?___store=default`;
	registrationLink = `${this.registrationUrl}${this.registrationQuery}`;

	usernameError;
	snackBarRef;

	/**
	 * constructor
	 * @param {AuthService} authService
	 * @param {ForgotPasswordService} forgotPasswordService
	 * @param {TranslateService} translate
	 * @param {MatSnackBar} snackBar
	 * @param {FormBuilder} fb
	 * @param {Store<AppState>} store
	 * @param config
	 */
	constructor(private authService: AuthService,
				private forgotPasswordService: ForgotPasswordService,
				private translate: TranslateService,
				public snackBar: MatSnackBar,
				private fb: UntypedFormBuilder,
				private store: Store<AppState>,
				@Inject('CONFIG') public config) {

		this.sbConfig.verticalPosition = 'top';
		this.sbConfig.duration = 7000;

		this.translate.onLangChange.subscribe(e => {
			if (e.lang === 'de') {
				this.registrationQuery = `?___store=default`;
			} else {
				this.registrationQuery = `?___store=en&___from-store=default`;
			}
			this.registrationLink = `${this.registrationUrl}${this.registrationQuery}`;
		});
	}

	/**
	 * onInit
	 */
	ngOnInit() {
		this.createForm();
		this.store
			.pipe(
				select(this.config.app === 'tmadmin' ? selectIsAdmin : selectIsSignedIn),
				tap(signedIn => {
					if (signedIn) {
						this.formAnimationState = 3;
						if (this.snackBarRef) {
							this.snackBarRef.dismiss();
						}
						// ...navigate to depending on route after state 3 animation
						setTimeout(() => this.authService.onSignin(), 1000);
					} else {
						this.formAnimationState = 0;
					}
				})
			)
			.subscribe({
				error: (error) => {
					this.formAnimationState = 1; // reset state for animation

					setTimeout(() => {
						this.formAnimationState = 2;
					}, 1000);

					if (error.message) {
						this.showSnackBar({key: 'auth-error'}, true);
					}
				}
			});
	}

	onSubmit() {

		this.usernameError = null;

		// first test on form error an handle it any

		// get error message on form error
		const usernameControl = this.signinForm.get('username');
		if (usernameControl && usernameControl.dirty && !usernameControl.valid) {
			this.getUsernameErrorMessage(usernameControl, (msg) => {
				this.usernameError = msg;
				setTimeout(() => (this.usernameError = null), 7000);
			});
		}

		// set animationState if form is NOT valid and return
		if (this.signinForm.status !== 'VALID') {
			return this.showErrorState();
		}

		// at this point the form is valid

		// signin form fields to credentials...
		this.credentials = this.signinForm.value;
		// and try login
		if (this.config.app === 'tmadmin') {
			this.authService.adminLogin(this.credentials.username, this.credentials.pwd)
				.pipe(
					tap(user => {
						this.store.dispatch(new LoginAdminAction(user));
					}),
					catchError((error, caught) => {
						console.error('login error', error, caught);
						this.showErrorState();
						if (error.message) {
							this.showSnackBar({key: 'auth-error'}, true);
						}
						return of();
					})
				)
				.subscribe({
					error: () => this.showErrorState()
				});
		} else {

			this.authService.login(this.credentials.username, this.credentials.pwd)
				.pipe(
					tap(user => {
						if ( user.is_active === MagentoAccountActivationState.YES ){
							this.store.dispatch(new LoginAction(user));
						}else{
							this.showSnackBar({key: 'accountNotActivatedHint'}, true);
							this.store.dispatch(new LogoutAction());
						}
					}),
					catchError((error, caught) => {
						console.error('login error', error, caught);
						this.showErrorState();
						if (error.message) {
							this.showSnackBar({key: 'auth-error'}, true);
						}
						return of();
					})
				)
				.subscribe({
					error: () => this.showErrorState()
				});
		}
	}

	private showErrorState() {
		this.formAnimationState = 1;
		setTimeout(() => (this.formAnimationState = 2), 1000);
	}

	private createForm() {
		// create form...
		this.signinForm = this.fb.group(this.credentialsForm);
		// ... and set focus to username field (timeout is needed since the form needs time to initialize)
		setTimeout(() => {
			this.usernameEl.nativeElement.focus();
		}, 0);
	}

	/**
	 *
	 * @param {AbstractControl} control
	 * @returns {{[p: string]: any}}
	 * @constructor
	 */
	UserEmailWMSValidator(control: AbstractControl) {

		// EMAIl REGEX standard RFC 5322
		const regExpEmail = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
		const validEmail = regExpEmail.test(control.value.toLocaleLowerCase());

		if (validEmail || this.config.app === 'tmadmin') {
			return null;
		}
		return {'username-invalid': {value: control.value}};
	}

	private getUsernameErrorMessage(control, next) {
		for (const key in control.errors) {
			if (control.errors.hasOwnProperty(key)) {
				this.translate.get(key).subscribe((res: string) => {
					this.translate.get('username-error', {detail: res}).subscribe((res2: string) => {
						next(res2);
					});
				});
			}
		}
	}

	/**
	 * forgotPassword
	 * @param email
	 */
	forgotPassword(email: string) {

		// test if uid field is filled there is a valid email before calling authService
		const usernameControl = this.signinForm.get('username');
		this.usernameError = null;
		if (!usernameControl.valid) {
			this.getUsernameErrorMessage(usernameControl, (msg) => {
				this.usernameError = msg;
				setTimeout(() => (this.usernameError = null), 7000);
			});
			return;
		}

		// calling ioApi to send Password Reset Mail from Magento to registered email
		this.forgotPasswordService.forgotPassword(email,
			data => {
				this.showSnackBar(data);
			},
			error => {
				this.showSnackBar(error, true);
			});
	}

	showSnackBar(data, showAsError = false) {
		this.sbConfig.panelClass = showAsError ? ['sb-error'] : ['sb-success'];
		this.translate.get(data.key, {value: data.value})
			.subscribe((res: string) => {
				this.snackBarRef = this.snackBar.open(res, undefined, this.sbConfig);
			});
	}
}
