import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {Subscription} from 'rxjs';

import {PatientsService} from '@suite/patients';
import {ACTION} from '@suite/tm-common';


@Component({
	selector: 'tm2-patient-edit',
	templateUrl: './patient-edit.component.html',
	styleUrls: ['./patient-edit.component.css'],
	providers: [
		{provide: MAT_DATE_LOCALE, useValue: ''},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})

export class PatientEditComponent implements OnChanges, OnDestroy {

	@Input() patient;
	@Output() next = new EventEmitter<any>();

	ACTION = ACTION;

	minDate = new Date('01.01.1900');
	maxDate = new Date();
	maxCharacters = 45;
	patientIdError;

	editPatientForm: UntypedFormGroup;

	patientForm = {
		patientId: ['', [Validators.required, this.validateCharacters()]],
		firstName: ['', [Validators.required, Validators.minLength(2), this.validateCharacters()]],
		lastName: ['', [Validators.required, Validators.minLength(2), this.validateCharacters()]],
		birthday: [''],
		image: ['']
	};

	translateSubscription$$: Subscription;

	constructor( @Inject('CONFIG') public config,
				  private patientsService: PatientsService,
				  private translate: TranslateService,
				  private fb: UntypedFormBuilder,
				  private dateAdapter: DateAdapter<any>) {
	}

	ngOnChanges() {
		console.log('Patient changed', this.patient);
		this.editPatientForm = this.fb.group(this.patientForm);
		if (!this.patient) {
			return;
		}
		this.initPatientForm(this.patient);
		// set default locale...
		this.dateAdapter.setLocale(this.config.locale);
		// ... and subscribe language change
		this.translateSubscription$$ = this.translate.onLangChange.subscribe(e => {
			this.dateAdapter.setLocale(e.lang);
		});
	}
	ngOnDestroy() {
		if (this.translateSubscription$$) {
			this.translateSubscription$$.unsubscribe();
		}
	}

	initPatientForm(patient) {

		let patientIdValue = patient.patientId ? patient.patientId : undefined;

		const patientIdFormField = this.editPatientForm.get('patientId');
		patientIdFormField.setValue( patientIdValue );
		if ( patientIdValue )
		{
			//edit mode, do not allow id change
			patientIdFormField.disable();
		}
		else
		{
			//creation mode
			patientIdFormField.enable();
		}

		this.editPatientForm.get('firstName').setValue(patient.firstName ? patient.firstName : undefined);
		this.editPatientForm.get('lastName').setValue(patient.lastName ? patient.lastName : undefined);
		this.editPatientForm.get('birthday').setValue(patient.birthday ? patient.birthday : undefined);
		this.editPatientForm.get('image').setValue(patient.image ? patient.image : undefined);
	}

	onNext(action) {

		const newPatientModel = this.editPatientForm.value; // patient form values shortcut

		// check if birthday is a moment object (changed) or
		// string (initial) and convert if changed to string utc again
		if (typeof newPatientModel.birthday !== 'string')
		{
			newPatientModel.birthday = newPatientModel.birthday.format();
		}

		// merge changed fields
		this.patient = {...this.patient, ...newPatientModel};
		this.next.emit({type: action, payload: this.patient});
	}

	validatePatientId() {

		const idCtrl = this.editPatientForm.get('patientId');
		if (idCtrl?.value) {
			idCtrl.setValue(idCtrl.value.trim());
		}
		const isValid = this.patientsService.uniquePatientId(idCtrl.value);

		this.patientIdError = null;

		// get error message on error
		if (!isValid) {
			this.translate.get('patientId-error').subscribe((res: string) => {
				this.patientIdError = res;
				setTimeout(() => (this.patientIdError = null), 7000);
				idCtrl.setErrors(this.patientIdError);
			});
		}
	}

	// Sloppy validator function to check for common special characters
	validateCharacters() {
		return (control) => {
		const regex = /[<>{}[\]\\\*\?@§$%&=]/;
			const isValid = !regex.test(control.value);
			return isValid ? null : { invalidCharacters: true };
		};
	}
}
