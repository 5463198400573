import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, filter, first } from "rxjs";
import { TreatmentRequested, TreatmentState, selectTreatmentFromUrl } from "../store";
import { Store, select } from "@ngrx/store";
import { TreatmentModel } from "../models/treatment.model";

@Injectable()
export class TreatmentResolver {

	constructor(private store: Store<TreatmentState>)
	{}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TreatmentModel>{
		this.store.dispatch(new TreatmentRequested({ id: route.params.id }));
		return this.store
			.pipe(
				select(selectTreatmentFromUrl),
				filter(treatment => !!treatment),
				first()
			)
	}
}
