import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';

import * as Sentry from "@sentry/angular-ivy";
import { sentryOptions } from '../../../libs/sentry/sentry.options'
import { APP_CONFIG } from './app/app.config';


if (environment.production) {
  	enableProdMode();

	//XXX TODO: Remove if-condition when management confirms sentry usage in production
	if( window.location.hostname.includes('dev') || window.location.hostname.includes('stage') ){
		Sentry.init(sentryOptions(APP_CONFIG));
	}
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
