import { Inject, Injectable } from "@angular/core";

import { DataTransferMethod, TreatmentModel } from "@suite/treatments/models";

import { ProductInfo, ProductInfoForKey, ProductInfoMagento, ProductOptions, ProductUrlKey, ToothArea } from "../models";
import { MagentoProduct } from "@suite/magento";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class ProductInfoService {
	baseUrl: string;

	constructor(
		private translate: TranslateService,
		private http: HttpClient,
		@Inject("CONFIG") private config
	) {
		this.baseUrl = this.config.apiUrl;
	}

	public getProductNameHtml(productKey: ProductUrlKey): string {
		const productInfo: ProductInfo = ProductInfoForKey[productKey as ProductUrlKey];
		let nameHtml = productInfo?.name ? this.translate.instant(productInfo.name) : "";
		if (productInfo?.variantName) {
			nameHtml += "<br>";
			nameHtml += this.translate.instant(productInfo.variantName);
		}
		return nameHtml;
	}

	public getProductOptions(treatment: TreatmentModel): ProductOptions {
		if (treatment.product?.options) {
			return treatment.product.options as ProductOptions;
		} else {
			// Case: without product options, we need to "interpret" the _actual_ order articles.
			const products = treatment.orders.at(-1).products;
			return this.getProductOptionsFromArticles(products);
		}
	}

	public getProductOptionsFromArticles(articles: MagentoProduct[]): ProductOptions {
		const productOptions: ProductOptions = {
			dataTransfer: DataTransferMethod.UPLOAD,
			lj: ToothArea.NONE,
			uj: ToothArea.NONE,
		};
		articles.forEach((element) => {
			if (this.isLowerJawArticle(element.sku)) {
				productOptions.lj = element.options["Zahnbereich"];
			}
			if (this.isUpperJawArticle(element.sku)) {
				productOptions.uj = element.options["Zahnbereich"];
			}
		});
		return productOptions;
	}

	public isUserAllowedForProduct(productKey: ProductUrlKey): Observable<boolean> {
		return this.http.get<boolean>(`${this.baseUrl}/products/${productKey}/isAllowed`);
	}

	private isUpperJawArticle(sku: string) {
		// XXX This logic will need to change with SAP.
		return sku.endsWith("5010.1");
	}

	private isLowerJawArticle(sku: string) {
		// XXX This logic will need to change with SAP.
		return sku.endsWith("5020.1");
	}

	public getToothAreaForProduct(productInfo: ProductInfoMagento, options: Partial<ProductOptions>, isUpperJaw: boolean) : string {
		if ( !productInfo ){
			return "";
		}
		//handle memotain
		if (productInfo.urlKey === ProductUrlKey.MEMOTAIN) {
			//handle legacy treatments pre 2.0.0
			if (options?.custom?.zahnbereich) {
				return isUpperJaw
					? options.custom.zahnbereich.uj?.value
					: options.custom.zahnbereich.lj?.value;
			}
		}

		//handle legacy treatments pre 2.0.0
		if (productInfo.toothArea) {
			return productInfo.toothArea;
		}
		return isUpperJaw ? options.uj : options.lj;
	}
}
