import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {map, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {fromEvent as observableFromEvent} from 'rxjs';

import {TmMatPaginatorInit} from '@suite/material';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';

@Component({
    selector: 'ux-grid-header',
    templateUrl: './grid-header.component.html',
    styleUrls: ['./grid-header.component.scss'],
    providers: [{provide: MatPaginatorIntl, useClass: TmMatPaginatorInit}]
})
export class GridHeaderComponent implements OnInit, AfterViewInit {
    @Input() dataSource;
    @Input() gridState; // XXX represents the current 'DashboardState' (see treatments -> dashboardStates in redux store)
    @Input() fWithSearch = true;
    @Output() stateChange = new EventEmitter<object>();

    @ViewChild('filter') filter: ElementRef;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // pagination properties
    paginationProps = {
        length: 500,
        pageSize: 6,
        pageIndex: 0,
        pageEvent: PageEvent,
        pageSizeOptions: [6, 12, 18, 24, 36]
    };

	// XXX this object holds information about the currently set pagination options and
	// the content of the "search" textfilter
    currentPaginationAndFilterState: {
		filter?: string,
		length?: number,
		pageIndex?: number,
		pageSize?: number,
		previousPageIndex?: number,
	} = {};

    constructor() {	}
	ngOnInit() {
		this.dataSource.paginator = this.paginator;
	}

    ngAfterViewInit(): void {

        // watch filter changes
		if (this.fWithSearch && this.filter) {
			observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
				debounceTime(200), // 350 was too long if someone types fast
				map(() => this.filter.nativeElement.value),
				distinctUntilChanged()
			).subscribe(filter => this.onFilterChange(filter));
		}
		// Overwrite current pagination properties with values from dashboard state from redux store
        this.paginationProps = {...this.paginationProps, ...this.gridState};

        // WATCH OUT: we have to use setTimeout here,
        // otherwise we will get an ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            const params = {
                pageIndex: this.paginationProps.pageIndex,
                pageSize: this.paginator.pageSize,
                length: this.paginator.length
            };
            this.paginator.pageIndex = this.paginationProps.pageIndex;
            this.paginator.page.next(params);
        }, 100);
    }

    onFilterChange(filter) {
		this.currentPaginationAndFilterState = {...this.currentPaginationAndFilterState, filter};
		//inform parent about change in filter
        this.stateChange.emit(this.currentPaginationAndFilterState);
    }

	onPageEvent($pageEvent: PageEvent){
		this.currentPaginationAndFilterState = {...this.currentPaginationAndFilterState, ...$pageEvent};
		//inform parent about change in pagination
		this.stateChange.emit( $pageEvent );
	}

}
