<section>
	<div class="anamnese-header">
		<div class="anamnese-step-header" [ngClass]="{'active': stepIndex === AnamneseProcessStep.PATIENT_SELECTION}">
			<i class="fal fa-users" ></i>
			<h3>{{'Patient' | translate}}</h3>
			<div class="indicator"></div>
		</div>
		<div class="anamnese-step-header" [ngClass]="{'active': stepIndex === AnamneseProcessStep.PRODUCT_SELECTION}">
			<i class="fal fa-list-ul"></i>
			<h3>{{'Choose Treatment' | translate}}</h3>
			<div class="indicator"></div>
		</div>
		<div class="anamnese-step-header" [ngClass]="{'active': stepIndex === AnamneseProcessStep.SETUP_CHECKOUT}">
			<i class="fal fa-shopping-cart"></i>
			<h3>{{ (isSetupProduct ? 'Setup Request' : 'Order Request') | translate}}</h3>
			<div class="indicator"></div>
		</div>
		<div *ngIf="dataTransferMethod !== DataTransferMethod.UPLOAD"
			class="anamnese-step-header"
			[ngClass]="{'active': stepIndex === AnamneseProcessStep.TRANSFER_OPTIONAL_UPLOAD}">
			<i class="{{determineStepIcon()}}"></i>
			<h3 *ngIf="dataTransferMethod === DataTransferMethod.UPS">UPS</h3>
			<h3 *ngIf="dataTransferMethod === DataTransferMethod.OTHER">{{'Upload' | translate}}</h3>
			<div class="indicator"></div>
		</div>
	</div>
	<div class="anamnese-body">
		<tm2-anamnese-patient
			*ngIf="stepIndex === AnamneseProcessStep.PATIENT_SELECTION"
			(next)="onPatientAction($event)"
		></tm2-anamnese-patient>

		<tm2-anamnese-treatment
			*ngIf="stepIndex === AnamneseProcessStep.PRODUCT_SELECTION"
			[anamnese]="anamnese$ | async"
			[patient]="patient$ | async"
			[isUserProfessionalCertified]="(isUserProfessionalCertified$ | async)"
			[previousStep]="previousProcessStep"
			(next)="onTreatmentAction($event)"
		></tm2-anamnese-treatment>

		<tm2-anamnese-setup
			*ngIf="stepIndex === AnamneseProcessStep.SETUP_CHECKOUT"
			[anamnese]="anamnese$ | async"
			[isSetupProduct]="isSetupProduct"
			(next)="onSetupAction($event)"
		></tm2-anamnese-setup>

		<tm2-anamnese-transfer
			*ngIf="stepIndex === AnamneseProcessStep.TRANSFER_OPTIONAL_UPLOAD"
			[treatment]="treatment$ | async"
			[wmsId]="(wmsId$ | async)"
			(next)="onTransferAction($event)"
		></tm2-anamnese-transfer>
	</div>
</section>
