import {Component, Input, OnChanges} from '@angular/core';
import {TreatmentModel, TreatmentPackages} from '@suite/treatments';
import {OrderStati} from '@suite/tm-common';

@Component({
	selector: 'ux-treatment-packages',
	templateUrl: './treatment-packages.component.html',
	styleUrls: ['./_treatment-packages.component.scss']
})
export class TreatmentPackagesComponent implements OnChanges {
	@Input() treatment: TreatmentModel;
	@Input({required: true}) packages: TreatmentPackages;

	protected noOfOrders = 0;
	protected noPkgs;

	protected jaws = [];

	protected firstShownCorrection;
	protected prefix;
	protected suffix;

	constructor() {}

	ngOnChanges() {
		if (!this.packages) {
			return;
		}
		// provide selection jaws
		this.jaws = [];
		if (this.treatment.product.options.uj) {
			this.jaws.push('uj');
		}
		if (this.treatment.product.options.lj) {
			this.jaws.push('lj');
		}

		this.noOfOrders = 0;
		if(this.treatment.quotation){
			this.noOfOrders = this.packages.next;
		}
		else if(this.treatment.orders){
			// get number of orders without the first one which is the dummy anamnese order
		this.treatment.orders.forEach( (order, idx) => {
			if (idx) { // skip first (anamnese order)
				if (order.status !== OrderStati.canceled) { // skip canceled orders
					this.noOfOrders++;
				}
			}
		});
		}

		// if there are more than 3 correction packages
		// then we are in the exceptional handling
		if ( this.packages.freeCorrections && this.packages.freeCorrections.length > 3)
		{
			// shortcuts
			this.noPkgs = this.packages.steps.length;
			let correctionPackages = this.packages.freeCorrections.length;

			let nextPackage = this.noOfOrders + 1;
			this.firstShownCorrection = this.noOfOrders > this.noPkgs + 2
			? this.noOfOrders - this.noPkgs - 1
			: 1;

			this.prefix = this.noOfOrders > this.noPkgs + 2
			? true
			: false;
			this.suffix = nextPackage > this.noPkgs + correctionPackages - 2
			? false
			: true;
		}
	}

}
