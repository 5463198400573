<button *ngIf="!disabledBack" mat-raised-button
		class="back-button"
		(click)="onBack()"
><i class="fal fa-chevron-double-left"></i></button>

<button mat-raised-button
		*ngIf="!hideNext"
		class="next-button"
		[disabled]="disabledNext"
		(click)="onNext()"
		color="primary"
>{{nextText | translate}}</button>

<ng-content></ng-content>
