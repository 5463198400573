import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TreatmentModel} from '@suite/tm-common';
import {TreatmentActions, TreatmentActionTypes} from './treatment.actions';
import {createFeatureSelector} from '@ngrx/store';

import {SortDirection} from '@angular/material/sort';

export interface DashboardState {
	// last selected treatmend id (if any)
	id?: string;
	// filter
	filter?: string;
	productFilter?: string;
	statusFilter?: string;
	sachbearbeiterFilter?: string;
	fromDateFilter?: Date;
	// sort
	active?: string;
	direction?: SortDirection;
	// paginator
	pageSize?: number;
	pageIndex?: number;
	length?: number;
}
export interface DashboardStates {
	tab: number;
	tabs: DashboardState[];
}
export interface TreatmentState extends EntityState<TreatmentModel> {
	// additional entities state properties
	selectedTreatmentId: string | null;
	dashboardStates: DashboardStates | null;
}


export function sortByLastModified(t2: TreatmentModel, t1: TreatmentModel): number {
	return +new Date(t1.lastModified) - +new Date(t2.lastModified);
}

export const treatmentAdapter: EntityAdapter<TreatmentModel> = createEntityAdapter<TreatmentModel>(
	{
		selectId: (treatment: TreatmentModel) => treatment._id,
		sortComparer: sortByLastModified
	}
);

export const initialTreatmentState: TreatmentState = treatmentAdapter.getInitialState({
	// additional entity state properties
	selectedTreatmentId: null,
	dashboardStates: null
});

export const selectTreatmentState = createFeatureSelector<TreatmentState>('treatment');

export const {
	selectIds: selectTreatmentIds,
	selectEntities: selectTreatmentEntities,
	selectAll: selectAllTreatments,
	selectTotal: selectTreatmentTotal,
} = treatmentAdapter.getSelectors(selectTreatmentState);

export function reducer(
	state = initialTreatmentState,
	action: TreatmentActions
): TreatmentState {

	switch (action.type) {

		case TreatmentActionTypes.TreatmentSelected:
			return { ...state, selectedTreatmentId: <string>action.payload };

		case TreatmentActionTypes.TreatmentsRequested:
			return state;

		case TreatmentActionTypes.AddTreatment: {
			return treatmentAdapter.addOne(action.payload.treatment, state);
		}

		case TreatmentActionTypes.UpsertTreatment: {
			return treatmentAdapter.upsertOne(action.payload.treatment, state);
		}

		case TreatmentActionTypes.AddTreatments: {
			return treatmentAdapter.addMany(action.payload.treatments, state);
		}

		case TreatmentActionTypes.UpsertTreatments: {
			return treatmentAdapter.upsertMany(action.payload.treatments, state);
		}

		case TreatmentActionTypes.UpdateTreatment: {
			return treatmentAdapter.updateOne(action.payload.treatment, state);
		}

		case TreatmentActionTypes.UpdateTreatments: {
			return treatmentAdapter.updateMany(action.payload.treatments, state);
		}

		case TreatmentActionTypes.DeleteTreatment: {
			return treatmentAdapter.removeOne(action.payload.id, state);
		}

		case TreatmentActionTypes.DeleteTreatments: {
			return treatmentAdapter.removeMany(action.payload.ids, state);
		}

		case TreatmentActionTypes.LoadTreatments: {
			return treatmentAdapter.setAll(action.payload.treatments, state);
		}

		case TreatmentActionTypes.ClearTreatments: {
			return treatmentAdapter.removeAll(state);
		}

		case TreatmentActionTypes.NewPickUpReference: {
			let currentTreatment = state.entities[state.selectedTreatmentId];
			let updatedTreatment = {
				...currentTreatment,
				quotation: {
					...currentTreatment.quotation,
					upsPickUpReference: action.payload
				}
			};
			return {
				...state,
				entities: {
					...state.entities,
					[state.selectedTreatmentId]: { ...updatedTreatment }
				}
			};
		}

		case TreatmentActionTypes.SaveDashboardState: {
			const dashboardStates = structuredClone(state.dashboardStates) || {tab: 0, tabs: []} as DashboardStates;
			dashboardStates.tabs[action.payload['tab']] = action.payload['state'];
			dashboardStates.tab = action.payload['tab'];
			return {
				... state,
				dashboardStates
			};
		}

		case TreatmentActionTypes.TreatmentHandlingUpdated:
			const treatment = { ...state.entities[state.selectedTreatmentId] };
			return {
				...state,
				entities: {
					...state.entities,
					[state.selectedTreatmentId]: {
						...treatment,
						product: {
							...treatment.product,
							handling: {
								...treatment.product.handling,
								...action.payload
							}
						}
					}
				}
			};

		default: {
			return state;
		}
	}
}

