import {
	Directive,
	HostListener,
	HostBinding,
	EventEmitter,
	Output,
	Input,
	ElementRef
} from "@angular/core";

import { EvalFileService } from "./eval-file.service";
import { FileUploadBoxState, UploadErrorEvent } from "./file-upload-box";

@Directive({
	selector: '[mediaFileDragDrop]'
})
export class FileDragDropDirective {

	@Input() private allowed_extensions: Array<string> = [];
	@Input() private allowed_number = 1;
	@Input() private allowed_max_size = 1024 * 1024 * 1024;
	@Input() private mediaFiles: { orgFilename: string; filesize: number }[];
	@Input() private state: FileUploadBoxState;


	@Output() private filesChange: EventEmitter<File[]> = new EventEmitter();
	@Output() private uploadErrorEvent: EventEmitter<UploadErrorEvent> = new EventEmitter();

	@HostBinding('class.hover') isHover = false;
	@HostBinding('class.dragover') isDragOver = false;
	@HostBinding('class.selection-error') hasError = false;

	constructor(
		private elRef: ElementRef,
		private evalService: EvalFileService
	) {}

	@HostListener('mouseover', ['$event'])
	public onMouseOver() {
		this.elRef.nativeElement.parentElement.classList.add('alt-bg');
		if (this.state === FileUploadBoxState.DEFAULT) {
			this.elRef.nativeElement.parentElement.classList.add('show-desc');
			this.isHover = true;
			this.isDragOver = false;
		}
		if (this.state === FileUploadBoxState.UPLOADED){
			this.elRef.nativeElement.parentElement.classList.add('uploaded-hovered');
			this.isHover = true;
			this.isDragOver = false;
		}
	}
	@HostListener('mouseleave', ['$event'])
	public onMouseLeave() {
		if (this.state === FileUploadBoxState.DEFAULT) {
			this.elRef.nativeElement.parentElement.classList.remove('alt-bg');
		}
		if (this.state === FileUploadBoxState.UPLOADED){
			this.elRef.nativeElement.parentElement.classList.remove('uploaded-hovered');
		}
		this.elRef.nativeElement.parentElement.classList.remove('show-desc');
		this.isHover = false;
	}

	@HostListener('dragover', ['$event'])
	public onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.hasError = false;
		this.isDragOver = true;
		this.elRef.nativeElement.parentElement.classList.add('alt-bg');
		this.elRef.nativeElement.parentElement.classList.add('show-desc');
	}

	@HostListener('dragleave', ['$event'])
	public onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		const elementTo = evt.toElement || evt.relatedTarget;
		const elementFrom = evt.fromElement ||  evt.relatedTarget;
		if (!elementTo?.parentNode.classList.value.includes('drop-zone') && // prevent flickering if dropover a child element
			!elementFrom?.parentNode.classList.value.includes('drop-zone')) {
			this.isDragOver = false;
			if (this.state === FileUploadBoxState.DEFAULT) {
				this.elRef.nativeElement.parentElement.classList.remove('alt-bg');
			}
			this.elRef.nativeElement.parentElement.classList.remove('show-desc');
		}
	}

	@HostListener('drop', ['$event'])
	public onDrop(evt: Event & { dataTransfer: { files: File[] } }) {
		evt.preventDefault();
		evt.stopPropagation();
		this.isDragOver = false;
		this.elRef.nativeElement.parentElement.classList.remove("dragover");
		this.elRef.nativeElement.parentElement.classList.remove("show-desc");

		if (this.state !== FileUploadBoxState.DEFAULT) {
			return;
		}

		this.hasError = this.evalService.evaluate(
			Object.values(evt.dataTransfer.files),
			{
				max: this.allowed_number,
				max_size: this.allowed_max_size,
				extensions: this.allowed_extensions,
				is_duplicate: (file: File) => this.isDuplicate(file)
			},
			this.filesChange,
			this.uploadErrorEvent
		);
	}

	isDuplicate(file: File) {
		return this.mediaFiles?.some((mediaFile) => {
			return (
				mediaFile.filesize === file.size &&
				mediaFile.orgFilename === file.name
			);
		});
	}
}
